
import { validationMixin } from 'vuelidate'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { required, maxLength } from 'vuelidate/lib/validators'
import { ProductPackageListDocument } from '@/views/productPackageListView/gql/__generated__/GetProductPackageList.query'
import { ProjectTypeEnum } from '@/__generated__/globalTypes'
import {
  CreateProductPackageDocument,
  CreateProductPackageMutation,
  CreateProductPackageMutationVariables
} from './gql/__generated__/createProductPackage.mutation'

@Component({
  mixins: [validationMixin]
})
export default class AddProductPackageDialog extends Vue {
  @Prop({ default: false }) show!: boolean
  @Prop({ default: () => [] }) existingProductPackageNames!: string[]

  name = ''
  isDisabled = false

  clickClose() {
    this.$v.$reset()
    this.name = ''
    this.$emit('close')
  }

  clickSave() {
    this.$v.$touch()
    if (this.$v.name.$pending || this.$v.name.$error) return
    this.isDisabled = true
    this.$apolloMutate<CreateProductPackageMutation, CreateProductPackageMutationVariables>({
      mutation: CreateProductPackageDocument,
      variables: {
        productPackageName: this.name,
        productPackageType: ProjectTypeEnum.Productenpropositie
      },
      error: 'E4163',
      refetchQueries: [
        {
          query: ProductPackageListDocument
        }
      ]
    }).then((result) => {
      this.isDisabled = false
      if (result !== false) this.clickClose()
    })
  }

  validations() {
    return {
      name: {
        required,
        maxLength: maxLength(50),
        isUnique: (value: string) => {
          return !this.existingProductPackageNames.includes(value)
        }
      }
    }
  }

  get nameErrors() {
    const errors = new Array<string>()
    if (!this.$v.name.$dirty) {
      return errors
    }
    if (!this.$v.name.isUnique) {
      errors.push(this.$t('productPackage.create.errors.name.isUnique').toString())
    }
    if (!this.$v.name.maxLength) {
      errors.push(this.$t('productPackage.create.errors.name.maxLength').toString())
    }
    if (!this.$v.name.required) {
      errors.push(this.$t('productPackage.create.errors.name.required').toString())
    }
    return errors
  }
}
