/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AddCustomOfferRequestAppointmentMutationVariables = Types.Exact<{
  customOfferRequestId: Types.Scalars['UUID'];
  appointmentDate: Types.Scalars['DateTime'];
  appointmentType: Types.CustomOfferAppointmentType;
}>;


export type AddCustomOfferRequestAppointmentMutation = { addCustomOfferRequestAppointment: { id: any } };


export const AddCustomOfferRequestAppointmentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddCustomOfferRequestAppointment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"customOfferRequestId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"appointmentDate"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DateTime"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"appointmentType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CustomOfferAppointmentType"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addCustomOfferRequestAppointment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"customOfferRequestId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"customOfferRequestId"}}},{"kind":"Argument","name":{"kind":"Name","value":"appointmentDate"},"value":{"kind":"Variable","name":{"kind":"Name","value":"appointmentDate"}}},{"kind":"Argument","name":{"kind":"Name","value":"appointmentType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"appointmentType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<AddCustomOfferRequestAppointmentMutation, AddCustomOfferRequestAppointmentMutationVariables>;