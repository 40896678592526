
import { AdUserDto, CustomOffer, CustomOfferRequestStatus, UsersGroupFilterType } from '@/__generated__/globalTypes'
import NewCustomOfferRequestDialog from '@/components/customOfferRequest/NewCustomOfferRequestDialog.vue'
import DebounceMixin, { debounceDelay } from '@/mixins/debounceMixin'
import QUERIES from '@/queries/queries'
import { RouteNames } from '@/router/routeNames'
import { toLocaleDateString } from '@/utils/dateUtils'
import { TableFilter, buildRequestVariables, defaultFooterProps } from '@/utils/tableUtils'
import { Component, Vue } from 'vue-property-decorator'
import { DataOptions, DataTableHeader } from 'vuetify'
import CustomOfferFollowUpDialog from './CustomOfferFollowUpDialog.vue'
import {
  CustomOfferRequestFragment,
  CustomOfferRequestsDocument,
  CustomOfferRequestsQuery,
  CustomOfferRequestsQueryVariables
} from './gql/__generated__/customOfferRequests.query'
import { RensaUsersDocument, RensaUsersQuery, RensaUsersQueryVariables } from './gql/__generated__/rensaUsers.query'

type CustomOfferItem = {
  id: string
  item: CustomOffer
}

@Component({
  components: {
    NewCustomOfferRequestDialog,
    CustomOfferFollowUpDialog
  },
  mixins: [DebounceMixin]
})
export default class CustomOfferListView extends Vue {
  customOffers: CustomOfferRequestFragment[] = []
  totalCount = 0
  tableOptions: DataOptions = {} as DataOptions
  initialPageLoaded = false
  defaultFooterProps = defaultFooterProps
  newRequestDialogVisible = false
  showCustomOfferFollowUpPopup = false
  rensaUsers: AdUserDto[] = []
  hasFlag: boolean | boolean[] | undefined = false
  selectedCustomOffer: CustomOfferItem | null = null

  search = ''
  searchFilters: TableFilter[] = [
    {
      keyPath: 'realEstate.contact.email.address',
      value: '',
      filterType: 'contains'
    },
    {
      keyPath: 'realEstate.buildNumber',
      value: '',
      filterType: 'contains'
    }
  ]

  excludeClosedStatusFilters: TableFilter[] = [
    {
      keyPath: 'currentStatus',
      value: [CustomOfferRequestStatus.Cancelled, CustomOfferRequestStatus.Completed],
      filterType: 'nin'
    }
  ]
  statusFilters: TableFilter[] = [
    {
      keyPath: 'currentStatus',
      value: ''
    }
  ]
  hideCanceledCompleted = true

  showroomSpecialistFilter: TableFilter[] = [
    {
      keyPath: 'assignedSpecialistId',
      value: ''
    }
  ]
  changeSearch: ((value: string) => void) | null = null

  created() {
    this.tableOptions.sortBy = ['sharedWaiting', 'requested']
    this.tableOptions.sortDesc = [true, true]

    // get showroom specialists
    this.$apollo.addSmartQuery<RensaUsersQuery, RensaUsersQueryVariables>(QUERIES.RensaUsers, {
      query: RensaUsersDocument,
      update: (data) => data,
      variables: {
        searchTerm: '',
        filterType: UsersGroupFilterType.Specialists
      },
      pollInterval: 60000,
      result: (result) => {
        this.rensaUsers = result?.data?.rensaUsers ?? []
      },
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4219',
          Message: error.message
        })
      }
    })

    // get custom offers
    this.$apollo.addSmartQuery<CustomOfferRequestsQuery, CustomOfferRequestsQueryVariables>(
      QUERIES.CustomOfferRequests,
      {
        query: CustomOfferRequestsDocument,
        variables: () => this.customOfferRequestTableVariables,
        fetchPolicy: 'network-only',
        update: (data) => data,
        pollInterval: 60000,
        result: (result) => {
          if (result.data) {
            this.customOffers = result.data.customOfferRequests?.items ?? []
            this.totalCount = result.data.customOfferRequests?.totalCount ?? 0
            this.initialPageLoaded = true
            this.hasFlag = result?.data?.customOfferRequests?.items?.map((x) => x.sharedWaiting)
          }
        },
        error: (error) => {
          this.$store.dispatch('showErrorDialog', {
            Code: 'E4101',
            Message: error.message
          })
        }
      }
    )
  }

  mounted() {
    this.changeSearch = this.debounce(() => {
      this.tableOptions.page = 1
      // Set the search string in all the filters
      Object.values(this.searchFilters).forEach((filter) => {
        filter.value = this.search
        filter
        this.tableOptions.page = 1
      })
    }, debounceDelay)
  }

  get customOfferRequestTableVariables() {
    const filterList = [this.searchFilters, this.showroomSpecialistFilter, this.statusFilters]
    if (this.hideCanceledCompleted) filterList.push(this.excludeClosedStatusFilters)
    return buildRequestVariables(this.tableOptions, filterList)
  }

  get language() {
    return this.$i18n.locale
  }

  get headers(): DataTableHeader[] {
    return [
      {
        text: '',
        value: 'hasFlag',
        sortable: false,
        align: 'center',
        class: 'px-0',
        cellClass: 'px-0',
        width: '2%'
      },
      {
        text: this.$t('customOffers.list.headers.appointmentType').toString(),
        value: 'appointmentType',
        width: '12%'
      },
      {
        text: this.$t('customOffers.list.headers.requestedOn').toString(),
        value: 'requested',
        width: '12%'
      },
      {
        text: this.$t('customOffers.list.headers.project').toString(),
        value: 'realEstate.project.name',
        width: '9%'
      },
      {
        text: this.$t('customOffers.list.headers.buildNr').toString(),
        value: 'realEstate.buildNumber',
        width: '9%'
      },
      {
        text: this.$t('customOffers.list.headers.rooms').toString(),
        value: 'realEstate.layout.layoutRooms.room.name',
        width: '18%',
        sortable: false
      },
      {
        text: this.$t('customOffers.list.headers.email').toString(),
        value: 'realEstate.contact.email.address',
        width: '8%'
      },
      {
        text: this.$t('customOffers.list.headers.specialist').toString(),
        value: 'assignedSpecialistId',
        width: '9%'
      },
      {
        text: this.$t('customOffers.list.headers.closingDate').toString(),
        value: 'realEstate.closeDateUtc',
        width: '10%'
      },
      {
        text: this.$t('customOffers.list.headers.status').toString(),
        value: 'requestStatus.status',
        width: '13%',
        sortable: false
      }
    ]
  }

  get customOfferItems() {
    return this.customOffers.map((customOffer) => {
      if (!customOffer) return
      return {
        id: customOffer.id,
        appointmentType: this.getTypeOfRequest(customOffer.appointmentType?.toString() ?? null),
        requested: customOffer.requested,
        project: customOffer.realEstate.project.name,
        requestedDisplayDate: toLocaleDateString(customOffer.requested, this.language) ?? '...',
        buildNumber: customOffer.realEstate.buildNumber,
        rooms: customOffer.realEstate.layout.layoutRooms.map((x) => x.room.name).join(', '),
        email: customOffer.realEstate.contact?.email.address,
        closingDate: toLocaleDateString(customOffer.realEstate.closeDateUtc, this.language) ?? ' ... ',
        status: this.getStatusText(customOffer.requestStatus?.status?.toString() ?? null),
        statusColor: this.getStatusColor(customOffer.requestStatus?.status?.toString() ?? null),
        showroomSpecialist:
          this.rensaUsers.find((x) => x.userId === customOffer.assignedSpecialistId)?.displayName ??
          customOffer.assignedSpecialistId,
        hasFlag: customOffer.sharedWaiting
      }
    })
  }

  // Create a custom offer Request Status List based on the translations
  get customOfferRequestStatusListTranslated() {
    return Object.values(CustomOfferRequestStatus).map((status) => ({
      text: this.getStatusText(status),
      value: status,
      disabled:
        this.hideCanceledCompleted &&
        [CustomOfferRequestStatus.Cancelled, CustomOfferRequestStatus.Completed].includes(status)
    }))
  }

  get showroomSpecialistsList() {
    return this.rensaUsers?.map((x) => ({
      text: x.displayName,
      value: x.userId
    }))
  }

  clearStatusFilter() {
    if (
      this.hideCanceledCompleted &&
      (this.statusFilters[0]?.value === CustomOfferRequestStatus.Cancelled ||
        this.statusFilters[0]?.value === CustomOfferRequestStatus.Completed)
    ) {
      this.statusFilters[0].value = ''
    }
  }

  toCustomOfferDetails(item: CustomOfferRequestFragment) {
    this.$router.push({ name: RouteNames.CUSTOM_OFFER_DETAILS, params: { id: item.id } })
  }

  getTypeOfRequest(typeOfRequest: string | null) {
    return this.$t(`global.customOfferGlobalAppointmentTypes.${typeOfRequest}`).toString()
  }

  getStatusColor(status: string | null) {
    switch (status) {
      case CustomOfferRequestStatus.CreateOffer:
      case CustomOfferRequestStatus.RequestForChange:
        return 'yellow'
      case CustomOfferRequestStatus.Appointment:
      case CustomOfferRequestStatus.SharedWithCustomer:
      case CustomOfferRequestStatus.Completed:
        return 'green'
      case CustomOfferRequestStatus.Cancelled:
        return 'red'
      case CustomOfferRequestStatus.New:
        return 'grey'
      default:
        return 'grey'
    }
  }

  getStatusText(status: string | null) {
    if (!status) return ''
    return this.$t(`global.customOfferStatuses.${status}`)?.toString() ?? ''
  }

  openCustomOfferFollowUpPopup(item: CustomOfferItem) {
    this.selectedCustomOffer = item
    this.showCustomOfferFollowUpPopup = true
  }

  closeCustomOfferFollowUpPopup() {
    this.selectedCustomOffer = null
    this.showCustomOfferFollowUpPopup = false
  }
}
