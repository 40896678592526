/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ExternalPreviewTokenQueryVariables = Types.Exact<{
  reid: Types.Scalars['UUID'];
}>;


export type ExternalPreviewTokenQuery = { externalPreviewToken: string };


export const ExternalPreviewTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"externalPreviewToken"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"reid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"externalPreviewToken"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"realEstateId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"reid"}}}]}]}}]} as unknown as DocumentNode<ExternalPreviewTokenQuery, ExternalPreviewTokenQueryVariables>;