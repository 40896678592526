
import { Component, Vue, Prop } from 'vue-property-decorator'
import { GET_PROJECT_ROOMS } from './projectRoomsQueries'
import {
  ProjectRoomsQuery,
  ProjectRoomsQuery_project,
  ProjectRoomsQuery_project_rooms,
  ProjectRoomsQuery_project_layouts
} from './__generated__/ProjectRoomsQuery'
import { DELETE_ROOM_MUTATION } from './projectRoomsMutations'
import { DeleteRoomMutation, DeleteRoomMutationVariables } from './__generated__/DeleteRoomMutation'
import CreateRoomComponent from '@/components/rooms/CreateRoom/CreateRoomComponent.vue'
import EditRoomComponent from '@/components/rooms/EditRoom/EditRoomComponent.vue'

type RoomItem = {
  id: string
  name: string
  position: string
  hasDesignPackages: boolean
  layouts: string
  designPackages: string
}

@Component({
  components: { CreateRoomComponent, EditRoomComponent }
})
export default class ProjectRoomsComponent extends Vue {
  @Prop() projectId!: string
  project: ProjectRoomsQuery_project | null = null
  roomIdToEdit = '00000000-0000-0000-0000-000000000000'
  search = ''
  showEdit = false

  created() {
    this.fetchData()
  }

  isReadonly() {
    return !this.$store.getters.isAdmin
  }

  fetchData() {
    this.$apollo.addSmartQuery<ProjectRoomsQuery>('projectRoomsQuery', {
      query: GET_PROJECT_ROOMS,
      variables: { projectId: this.projectId },
      result: (result) => {
        this.project = result.data.project
      },
      update: (data) => data,
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4114',
          Message: error.message
        })
      }
    })
  }

  get headers() {
    return [
      { text: this.$t('projectRoom.list.table.name'), value: 'name' },
      { text: this.$t('projectRoom.list.table.position'), value: 'position' },
      { text: this.$t('projectRoom.list.table.layouts'), value: 'layouts' },
      {
        text: this.$t('projectRoom.list.table.designPackages'),
        value: 'designPackages'
      },
      { text: '', value: 'actions', sortable: false, align: 'end' }
    ]
  }

  get roomCount(): number {
    if (!this.project) {
      return 0
    }
    return this.project.rooms.length
  }

  get layouts() {
    if (!this.project || !this.project.layouts) {
      return new Array<ProjectRoomsQuery_project_layouts>()
    }
    return this.project.layouts.sort((leftSide, rightSide): number => {
      if (leftSide.name < rightSide.name) return -1
      if (leftSide.name > rightSide.name) return 1
      return 0
    })
  }

  get rooms() {
    return this.project?.rooms
  }

  get roomItems() {
    if (!this.project) {
      return []
    }
    return this.project?.rooms
      .sort((leftSide, rightSide): number => {
        if (leftSide.name < rightSide.name) return -1
        if (leftSide.name > rightSide.name) return 1
        return 0
      })
      .map((x) => {
        return {
          id: x.id,
          name: x.name ?? '...',
          position: x.position,
          hasDesignPackages: x.designPackages != null && x.designPackages.length > 0,
          layouts: this.getLayoutText(x),
          designPackages: this.getDesignPackageText(x)
        }
      })
  }

  getLayoutText(room: ProjectRoomsQuery_project_rooms): string {
    if (!room.layoutRooms) {
      return ''
    }
    return room.layoutRooms.map((x) => x.layout.name).join(', ')
  }

  getDesignPackageText(room: ProjectRoomsQuery_project_rooms): string {
    if (!room.designPackages) {
      return ''
    }
    return room.designPackages.length.toString()
  }

  clickConfirmOrNotify(item: RoomItem) {
    if (item.hasDesignPackages) {
      this.notifyDesignPackage(item)
    } else {
      this.clickConfirm(item)
    }
  }

  clickEdit(item: RoomItem) {
    this.roomIdToEdit = item.id
    this.showEdit = true
  }

  closeEdit() {
    this.roomIdToEdit = ''
    this.showEdit = false
  }

  notifyDesignPackage(item: RoomItem) {
    this.$store.dispatch('showNotificationDialog', {
      Code: 'N4004',
      Message: item.name
    })
  }

  clickConfirm(item: RoomItem) {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: () => this.confirmRemove(item),
      Code: 'C4006',
      Message: item.name
    })
  }

  confirmRemove(item: RoomItem) {
    this.$apolloMutate<DeleteRoomMutation, DeleteRoomMutationVariables>({
      mutation: DELETE_ROOM_MUTATION,
      variables: {
        roomId: item.id
      },
      refetchQueries: ['ProjectQuery', 'ProjectRoomsQuery'],
      error: 'E4115'
    })

    this.$store.dispatch('hideConfirmationDialog')
  }
}
