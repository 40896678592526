/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateProductPackageCategoryMutationVariables = Types.Exact<{
  productPackageId: Types.Scalars['UUID'];
  categoryId: Types.Scalars['UUID'];
  allowPickByBrand: Types.Scalars['Boolean'];
}>;


export type UpdateProductPackageCategoryMutation = { updateProductPackageCategory: { id: any } };


export const UpdateProductPackageCategoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateProductPackageCategory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"productPackageId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"categoryId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"allowPickByBrand"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateProductPackageCategory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"productPackageId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"productPackageId"}}},{"kind":"Argument","name":{"kind":"Name","value":"categoryId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"categoryId"}}},{"kind":"Argument","name":{"kind":"Name","value":"allowPickByBrand"},"value":{"kind":"Variable","name":{"kind":"Name","value":"allowPickByBrand"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdateProductPackageCategoryMutation, UpdateProductPackageCategoryMutationVariables>;