/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ProductPackageCombinationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProductPackageCombinationsQuery = { masterProductPackage?: { id: any, availableCombinations: Array<{ id: any, name: string, combinationType: Types.ProductCombinationType, showBuiltInOption: boolean, position: number }> } | null };


export const ProductPackageCombinationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ProductPackageCombinations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"masterProductPackage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"availableCombinations"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"order"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"position"},"value":{"kind":"EnumValue","value":"ASC"}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"combinationType"}},{"kind":"Field","name":{"kind":"Name","value":"showBuiltInOption"}},{"kind":"Field","name":{"kind":"Name","value":"position"}}]}}]}}]}}]} as unknown as DocumentNode<ProductPackageCombinationsQuery, ProductPackageCombinationsQueryVariables>;