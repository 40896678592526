
import ProductSetCard from '@/components/productSet/ProductSetCard.vue'
import { ProductCombinationType } from '@/__generated__/globalTypes'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CombinationWithProductSets } from './types'
import ReturnedProductSets from './ReturnedProductSets.vue'

@Component({
  components: {
    ProductSetCard,
    ReturnedProductSets
  }
})
export default class RoomSelection extends Vue {
  @Prop() productSetsPerCombination!: CombinationWithProductSets[]

  getCombinationIcon(combination: ProductCombinationType) {
    const iconName = combination.toString().toLowerCase() + '.svg'
    return require('@/assets/product_combinations/' + iconName)
  }
}
