
import QUERIES from '@/queries/queries'
import { RouteNames } from '@/router/routeNames'
import { Component, Vue } from 'vue-property-decorator'
import AddActivityTemplateDialog from './AddActivityTemplateDialog.vue'
import ActivitiesTemplateImportDialog from './activitiesTemplateImportDialog/ActivitiesTemplateImportDialog.vue'
import {
  ActivityTemplatesQuery,
  ActivityTemplatesDocument,
  ActivityTemplatesQueryVariables
} from './__generated__/activityTemplates.query'
import {
  DeleteActivityTemplateMutation,
  DeleteActivityTemplateMutationVariables,
  DeleteActivityTemplateDocument
} from './__generated__/deleteActivityTemplate.mutation'

@Component({
  components: {
    AddActivityTemplateDialog,
    ActivitiesTemplateImportDialog
  }
})
export default class ActivitiesTemplates extends Vue {
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      value: 'name'
    },
    { text: '', value: 'actions', sortable: false, align: 'end' }
  ]

  RouteNames = RouteNames

  activityTemplates: ActivityTemplatesQuery | null = null

  showActivityTemplateAddModal = false

  activitiesTemplatesImportDialogActive = false

  created() {
    this.fetchData()
  }

  fetchData() {
    this.$apollo.addSmartQuery<ActivityTemplatesQuery, ActivityTemplatesQueryVariables>(QUERIES.ActivityTemplates, {
      query: ActivityTemplatesDocument,
      update: (data) => data,
      result: (result) => {
        this.activityTemplates = result.data
      },
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4234',
          Message: error
        })
      }
    })
  }

  confirmDeleteActivityTemplate(id: string) {
    this.$showConfirmationDialog({
      awaitConfirm: true,
      Callback: async () => {
        await this.deleteActivityTemplate(id)
        this.$hideConfirmationDialog()
        this.$apollo.queries.ActivityTemplatesQuery?.refetch()
      },
      Code: 'C4029'
    })
  }

  async deleteActivityTemplate(id: string) {
    await this.$apolloMutate<DeleteActivityTemplateMutation, DeleteActivityTemplateMutationVariables>({
      mutation: DeleteActivityTemplateDocument,
      variables: {
        activityTemplateId: id
      },
      refetchQueries: [
        {
          query: ActivityTemplatesDocument
        }
      ],
      error: 'E1105'
    })
  }

  get isReadonly() {
    return !this.$store.getters.isAdmin
  }

  get isLoading() {
    return this.$apollo.queries.ActivityTemplatesQuery?.loading
  }

  getProjectActivityImportTemplate() {
    const filename = `${this.$t('activityTemplate.getTemplateFilename')}`
    this.$fileApi.downloadFile(`/projectActivities/template`, filename).catch((error) => {
      this.$store.dispatch('showErrorDialog', {
        Code: 'E4237',
        Message: error
      })
    })
  }
}
