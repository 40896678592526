
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  RequestChangesCustomOfferMutation,
  RequestChangesCustomOfferMutationVariables,
  RequestChangesCustomOfferDocument
} from './gql/__generated__/requestChangesCustomOffer.mutation'

@Component
export default class RequestForChangeDialog extends Vue {
  @Prop({ default: false }) show!: boolean
  @Prop({ default: null }) customOfferRequestId!: string | null

  MESSAGE_MAX_LENGTH = 500
  notesContent = ''

  isLoading = false

  @Watch('show')
  onVisibilityChange() {
    if (!this.show) {
      this.notesContent = ''

      this.isLoading = false
    }
  }

  get notesContentErrorMessages() {
    const errors = []

    if (this.notesContent.length > this.MESSAGE_MAX_LENGTH) {
      errors.push(this.$t('global.forms.textarea.maxLength').toString())
    }

    return errors
  }

  async submitChanges() {
    try {
      this.isLoading = true
      await this.$apolloMutate<RequestChangesCustomOfferMutation, RequestChangesCustomOfferMutationVariables>({
        mutation: RequestChangesCustomOfferDocument,
        variables: {
          customOfferRequestId: this.customOfferRequestId,
          changesNotes: this.notesContent
        }
      })
      this.$emit('refetch-request-page')
    } finally {
      this.isLoading = false
      this.$emit('close')
    }
  }
}
