
import { CostType, Currency, CustomOfferRoomDtoInput } from '@/__generated__/globalTypes'
import QUERIES from '@/queries/queries'
import { getDisplayPrice } from '@/utils/currencyUtils'
import VueI18n from 'vue-i18n'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import { AvailableRoom } from './OfferUpsertPopup.vue'
import {
  SaveCustomOfferDocument,
  SaveCustomOfferMutation,
  SaveCustomOfferMutationVariables
} from './gql/__generated__/saveCustomOffer.mutation'
import { AppointmentItem } from '@/views/customOfferDetailsView/appointmentType'

export enum SumType {
  SERVICES,
  SUBTOTAL_PRODUCTS_AND_SERVICES, // products and installation materials + tiles and installation materials + lower price standard products + services
  SUBTOTAL_COORDINATION_PROVISION, // coordination comission builder + coordination commision installer
  SUBTOTAL_INSTALLATION_AND_CONSTRUCTION, // installation construction + w_installation + c_installation + cv_installation
  TOTAL_EXCL_VAT, // total of column excluding VAT
  VAT,
  TOTAL_INCL_VAT
}

type CostSpecificationPerRoom = {
  [key: string]: string | boolean | CostType | SumType | ((value: string) => true | VueI18n.TranslateResult)[]
  costTypeText: string
  isSubtotalOrTotal: boolean
  costTypeValue: CostType | SumType
  costRules: ((value: string) => true | VueI18n.TranslateResult)[]
}

export const NO_ROOM = '_'

@Component({})
export default class OfferCostSpecifications extends Vue {
  @Prop() selectableRooms!: AvailableRoom[]
  @Prop() offerRoomsCosts!: CustomOfferRoomDtoInput[]
  @Prop({ required: true }) serviceCosts!: number
  @Prop({ default: false }) divideByRooms!: boolean
  @Prop({ required: true }) disabled!: boolean
  @Prop() appointments!: AppointmentItem[]

  SumType = SumType

  readonly COSTTYPES = [
    {
      text: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.productsAndMaterials').toString(),
      value: CostType.ProductsAndInstallationMaterialsCosts,
      isSubtotalOrTotal: false
    },
    {
      text: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.tilesAndMaterials').toString(),
      value: CostType.TilesAndFastenersCosts,
      isSubtotalOrTotal: false
    },
    {
      text: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.services').toString(),
      value: SumType.SERVICES,
      isSubtotalOrTotal: false
    },
    {
      text: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.lowerPriceStandardProducts').toString(),
      value: CostType.LowerPriceStandardProductsCosts,
      isSubtotalOrTotal: false
    },
    {
      text: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.productsAndServicesSubtotal').toString(),
      value: SumType.SUBTOTAL_PRODUCTS_AND_SERVICES, //sub total of the 4 previous prices
      isSubtotalOrTotal: true
    },
    {
      text: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.coordinationComissionBuilder').toString(),
      value: CostType.CoordinationCommissionBuilderCosts,
      isSubtotalOrTotal: false
    },
    {
      text: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.coordinationComissionInstaller').toString(),
      value: CostType.CoordinationCommissionInstallerCosts,
      isSubtotalOrTotal: false
    },
    {
      text: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.coordinationProvisionSubtotal').toString(),
      value: SumType.SUBTOTAL_COORDINATION_PROVISION, // sub total of 2 previous prices
      isSubtotalOrTotal: true
    },
    {
      text: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.installationConstruction').toString(),
      value: CostType.InstallationConstructionCosts,
      isSubtotalOrTotal: false
    },
    {
      text: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.wInstallation').toString(),
      value: CostType.WInstallationCosts,
      isSubtotalOrTotal: false
    },
    {
      text: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.eInstallation').toString(),
      value: CostType.EInstallationCosts,
      isSubtotalOrTotal: false
    },
    {
      text: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.cvInstallation').toString(),
      value: CostType.CvInstallationCosts,
      isSubtotalOrTotal: false
    },
    {
      text: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.tiler').toString(),
      value: CostType.TilerInstallationCosts,
      isSubtotalOrTotal: false
    },
    {
      text: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.kitter').toString(),
      value: CostType.KitterInstallationCosts,
      isSubtotalOrTotal: false
    },
    {
      text: this.$t(
        'customOfferDetails.upsertOfferPopup.costSpecification.installationAndConstructionsubtotal'
      ).toString(),
      value: SumType.SUBTOTAL_INSTALLATION_AND_CONSTRUCTION, // sub total for 4 previous prices
      isSubtotalOrTotal: true
    },
    {
      text: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.totalExclVat').toString(),
      value: SumType.TOTAL_EXCL_VAT,
      isSubtotalOrTotal: true
    },
    {
      text: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.vat').toString(),
      value: SumType.VAT,
      isSubtotalOrTotal: true
    },
    {
      text: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.totalInclVat').toString(),
      value: SumType.TOTAL_INCL_VAT,
      isSubtotalOrTotal: true
    }
  ]

  items: CostSpecificationPerRoom[] = []

  rules = {
    required: (value: string) =>
      !!value?.toString()?.length ||
      this.$t('customOfferDetails.upsertOfferPopup.costSpecification.validations.required'),

    money: (value: string) => {
      // Can contain numbers, commas and dots
      if (!value) {
        return true
      }
      const regex = /^(?=.*[0-9])\d{0,9}(?:[.,]\d{1,2})?$/
      return regex.test(value) || this.$t('customOfferDetails.upsertOfferPopup.costSpecification.validations.money')
    },

    negativeMoney: (value: string) => {
      // Can contain zero and negative numbers, commas and dots
      if (!value) {
        return true
      }
      const regex = /^0.00$|^0.0$|^0$|^(?=.*[0-9])-\d{0,9}(?:[.,]\d{1,2})?$/
      return (
        regex.test(value) || this.$t('customOfferDetails.upsertOfferPopup.costSpecification.validations.negativeMoney')
      )
    }
  }

  created() {
    this.initializeTableData()
  }

  mounted() {
    // In the created hook, the table is not yet rendered, but the data is initialized. Once the table is rendered, we can validate the fields
    this.$emit('validate-cost-fields')
  }

  initializeTableData() {
    this.items = this.COSTTYPES.map((costType) => {
      const columns: CostSpecificationPerRoom = {
        costTypeText: costType.text,
        isSubtotalOrTotal: costType.isSubtotalOrTotal,
        costTypeValue: costType.value,
        costRules:
          costType.value === CostType.LowerPriceStandardProductsCosts
            ? [this.rules.required, this.rules.negativeMoney]
            : [this.rules.required, this.rules.money]
      }

      this.selectedRooms.forEach((room) => {
        const roomCosts = this.offerRoomsCosts.find((x) => x.roomId === room.id)
        if (!costType.isSubtotalOrTotal) {
          columns[room.id] = roomCosts?.costs.find((x) => x.key === costType.value)?.value ?? ''
        }
      })

      return columns
    })
  }

  getCostTypeTotal(costTypeValue: CostType | SumType, isSubtotalOrTotal: boolean) {
    let value = 0

    switch (costTypeValue) {
      case SumType.TOTAL_EXCL_VAT:
        value = this.totalPrice
        break
      case SumType.VAT:
        value = this.totalVat
        break
      case SumType.TOTAL_INCL_VAT:
        value = this.totalPriceWithVat
        break
      case SumType.SERVICES:
        value = this.serviceCosts
        break
      case SumType.SUBTOTAL_PRODUCTS_AND_SERVICES:
        // other kind of total/subtotal
        value =
          this.subTotalsAndTotals.reduce(
            (sum, current) => sum + Number(current.totals.find((x) => x.sumType === costTypeValue)?.value ?? 0),
            0
          ) + this.serviceCosts

        break
      default:
        if (isSubtotalOrTotal) {
          // other kind of total/subtotal
          value = this.subTotalsAndTotals.reduce(
            (sum, current) => sum + Number(current.totals.find((x) => x.sumType === costTypeValue)?.value ?? 0),
            0
          )
        } else {
          // input cost values
          const cost = this.items.find((x) => costTypeValue === x.costTypeValue)
          // sum costs of the current type of all selected rooms
          value = this.selectedRooms.reduce(
            (sum, room) => sum + parseFloat(cost?.[room.id]?.toString().replace(',', '.') ?? '0'),
            0
          )
        }
        break
    }

    return getDisplayPrice(value)
  }

  getTotalOrSubTotal(sumType: SumType, roomId: string) {
    let total = 0
    switch (sumType) {
      case SumType.TOTAL_EXCL_VAT:
        if (this.divideByRooms) {
          total = this.getRoomTotal(roomId)
        } else {
          total = this.getRoomTotal(roomId) + this.serviceCosts
        }
        break
      case SumType.TOTAL_INCL_VAT:
        if (this.divideByRooms) {
          total = this.getRoomTotal(roomId) + this.getVat(this.getRoomTotal(roomId))
        } else {
          total =
            this.getRoomTotal(roomId) +
            this.getVat(this.getRoomTotal(roomId)) +
            this.serviceCosts +
            this.getVat(this.serviceCosts)
        }
        break
      case SumType.VAT:
        if (this.divideByRooms) {
          total = this.getVat(this.getRoomTotal(roomId))
        } else {
          total = this.getVat(this.getRoomTotal(roomId)) + this.getVat(this.serviceCosts)
        }
        break
      case SumType.SERVICES:
        if (this.divideByRooms) {
          return '-'
        }

        total = this.serviceCosts
        break
      case SumType.SUBTOTAL_PRODUCTS_AND_SERVICES:
        if (this.divideByRooms) {
          total =
            this.subTotalsAndTotals?.find((x) => x.roomId === roomId)?.totals?.find((y) => y.sumType === sumType)
              ?.value ?? 0
        } else {
          total =
            (this.subTotalsAndTotals?.find((x) => x.roomId === roomId)?.totals?.find((y) => y.sumType === sumType)
              ?.value ?? 0) + this.serviceCosts ?? 0
        }
        break
      default:
        if (this.divideByRooms) {
          total =
            this.subTotalsAndTotals?.find((x) => x.roomId === roomId)?.totals?.find((y) => y.sumType === sumType)
              ?.value ?? 0
        } else {
          total =
            this.subTotalsAndTotals?.find((x) => x.roomId === roomId)?.totals?.find((y) => y.sumType === sumType)
              ?.value ?? 0
        }
        break
    }

    return getDisplayPrice(total)
  }

  get subTotalsAndTotals() {
    return this.selectedRooms?.map((room) => ({
      roomId: room.id,
      totals: [
        {
          sumType: SumType.SUBTOTAL_PRODUCTS_AND_SERVICES,
          value: this.getSummedCostItems(
            [
              CostType.ProductsAndInstallationMaterialsCosts,
              CostType.TilesAndFastenersCosts,
              CostType.LowerPriceStandardProductsCosts
            ],
            room.id
          )
        },
        {
          sumType: SumType.SUBTOTAL_COORDINATION_PROVISION,
          value: this.getSummedCostItems(
            [CostType.CoordinationCommissionInstallerCosts, CostType.CoordinationCommissionBuilderCosts],
            room.id
          )
        },
        {
          sumType: SumType.SUBTOTAL_INSTALLATION_AND_CONSTRUCTION,
          value: this.getSummedCostItems(
            [
              CostType.InstallationConstructionCosts,
              CostType.WInstallationCosts,
              CostType.EInstallationCosts,
              CostType.CvInstallationCosts,
              CostType.TilerInstallationCosts,
              CostType.KitterInstallationCosts
            ],
            room.id
          )
        }
      ]
    }))
  }

  get headers(): DataTableHeader[] {
    const headers: DataTableHeader[] = [
      {
        text: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.title').toString(),
        value: 'costSpec',
        width: '7%'
      }
    ]
    this.dynamicHeaders.forEach((h) => headers.push(h))
    if (this.divideByRooms) {
      headers.push({
        text: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.total').toString(),
        value: 'total',
        sortable: false,
        width: '4%'
      })
    }

    return headers
  }

  get dynamicHeaders(): DataTableHeader[] {
    return this.selectedRooms?.map((room) => {
      return {
        text: room.name,
        value: room.id,
        width: '4%'
      }
    })
  }

  get hasRoomsSelected() {
    return !!this.selectedRooms.length
  }

  get haveMissingFields() {
    return isNaN(this.totalPrice)
  }

  get isSaveDisabled() {
    //check if there is at least one room selected
    const hasRoomsSelected = !!this.selectedRooms.length

    //check if total has value, if it doesn't, there are still missing fields
    const haveMissingFields = isNaN(this.totalPrice)

    return !hasRoomsSelected || haveMissingFields
  }

  @Watch('totalPriceWithVat')
  onTotalChange() {
    this.$emit('disable', this.isSaveDisabled)
  }

  get selectedRooms() {
    return this.divideByRooms
      ? this.selectableRooms.filter((room) => room.selected)
      : [
          {
            id: NO_ROOM,
            name: this.$t('customOfferDetails.upsertOfferPopup.costSpecification.globalOfferColumnName').toString(),
            selected: true
          }
        ]
  }

  get totalPrice() {
    return (
      this.selectedRooms.reduce((sum, room) => {
        return (sum += this.getRoomTotal(room.id))
      }, 0) + this.serviceCosts
    )
  }

  get totalVat() {
    return this.getVat(this.totalPrice)
  }

  get totalPriceWithVat() {
    return this.totalPrice + this.totalVat
  }

  getRoomSlotName(room: AvailableRoom) {
    return `item.${room.id}`
  }

  saveCosts(id: string, shared: boolean, customOfferName: string, customOfferLink: string) {
    // The only costs we want to send to BE are the costs that are not subtotal or total
    const costsToUpdate = this.items.filter((x) => !(x.isSubtotalOrTotal || x.costTypeValue === SumType.SERVICES))
    const roomsCosts: CustomOfferRoomDtoInput[] = this.selectedRooms.map((room) => {
      const roomCosts: CustomOfferRoomDtoInput = {
        roomId: room.id === NO_ROOM ? null : room.id,
        costs: []
      }
      costsToUpdate.forEach((item) => {
        roomCosts.costs.push({
          key: item.costTypeValue as CostType,
          value: item[room.id] as string
        })
      })

      return roomCosts
    })

    return this.$apolloMutate<SaveCustomOfferMutation, SaveCustomOfferMutationVariables>({
      mutation: SaveCustomOfferDocument,
      variables: {
        customOfferId: id,
        priceCurrency: Currency.Eur,
        shareWithCustomer: shared,
        rooms: roomsCosts,
        name: customOfferName,
        link: customOfferLink
      },
      refetchQueries: [QUERIES.CustomOfferRequest],
      error: 'E4191'
    })
  }

  getSummedCostItems(costTypes: (CostType | SumType)[], roomId: string): number {
    const summedCosts = this.items?.filter((cost) => costTypes?.includes(cost?.costTypeValue ?? null))
    return summedCosts?.reduce((acc, cost) => acc + parseFloat(cost[roomId]?.toString().replace(',', '.')), 0) ?? 0
  }

  getRoomTotal(roomId: string): number {
    const roomSubTotals = this.subTotalsAndTotals.find((x) => x.roomId === roomId)?.totals
    return roomSubTotals?.reduce((acc, total) => acc + total.value, 0) ?? 0
  }

  getVat(value: number): number {
    return value * 0.21
  }
}
