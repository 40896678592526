import { Component, Vue } from 'vue-property-decorator'

@Component
export default class DebounceMixin extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  debounce(func: (...args: any[]) => void, delay: number) {
    let timer: NodeJS.Timeout
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (...args: any[]) => {
      clearTimeout(timer)
      timer = setTimeout(func.bind(this, ...args), delay)
    }
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    debounce(func: (...args: any[]) => void, delay: number): (...args: any[]) => void
  }
}

export const debounceDelay = 500
