
import { Component, Vue } from 'vue-property-decorator'
import {
  CypressTokenQuery,
  CypressTokenQueryVariables,
  CypressTokenDocument
} from './gql/__generated__/cypressAccessToken'

@Component
export default class LoginCypressComponent extends Vue {
  created() {
    const loginSecret = this.$route.query.theQuickBrownFox
    // Do request
    this.$apollo
      .query<CypressTokenQuery, CypressTokenQueryVariables>({
        query: CypressTokenDocument,
        variables: { secret: loginSecret }
      })
      .then((result) => {
        const token = result?.data?.cypressAccessToken
        this.$store.commit('setCypressToken', token)
        const cypressTokenStorageKey = `badkamer.user`
        localStorage.setItem(cypressTokenStorageKey, token)
        this.$router.push('/')
      })
  }
}
