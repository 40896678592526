
import { validationMixin } from 'vuelidate'
import { Component, Vue } from 'vue-property-decorator'
import { sendNotificationMutation } from './sendNotificationMutations'
import {
  sendNotification,
  sendNotificationVariables
} from './__generated__/sendNotification'

@Component({
  mixins: [validationMixin]
})
export default class CreateNotificationDialogComponent extends Vue {
  fromName = ''
  fromEmailAddress = ''
  toEmailAddress = ''
  subject = ''
  templateId = ''

  sendNotification() {
    this.$apolloMutate<sendNotification, sendNotificationVariables>({
      mutation: sendNotificationMutation,
      variables: {
        fromName: this.fromName,
        fromEmailAddress: this.fromEmailAddress,
        toEmailAddress: this.toEmailAddress,
        subject: this.subject,
        templateId: this.templateId
      },
      error: 'E4131'
    }).then((result) => {
      if (result !== false) {
        this.$store.dispatch('showNotificationDialog', {
          Code: 'N4007'
        })
      }
    })
  }
}
