
import { CostType, ProjectActivityTarget } from '@/__generated__/globalTypes'
import { getInputPrice } from '@/utils/currencyUtils'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { DataOptions, DataTableHeader } from 'vuetify'
import ActivityCostField from '../activities/ActivityCostField.vue'

import QUERIES from '@/queries/queries'
import {
  ActivityTemplateQuery,
  ActivityTemplateQueryVariables,
  ActivityTemplateDocument
} from './__generated__/getActivityTemplate.query'
import { AssignedActivity } from '../activities/types'
import {
  UpdateActivityTemplateMutation,
  UpdateActivityTemplateMutationVariables,
  UpdateActivityTemplateDocument
} from './__generated__/updateActivityTemplate.mutation'
import { ActivityTemplates, activityTemplateActivity } from './types'
import {
  UpdateActivityTemplateNameMutation,
  UpdateActivityTemplateNameMutationVariables,
  UpdateActivityTemplateNameDocument
} from './__generated__/updateActivityTemplateName.query'

@Component({
  components: {
    ActivityCostField
  }
})
export default class EditActivityTemplate extends Vue {
  @Prop() activityTemplateId!: string
  activityTemplates: ActivityTemplateQuery | null = null

  tableOptions: DataOptions = {} as DataOptions
  CostType = CostType

  name = ''
  previousName = ''

  isloading = false

  costTypeSlots = [
    CostType.WInstallationCosts,
    CostType.CvInstallationCosts,
    CostType.EInstallationCosts,
    CostType.InstallationConstructionCosts,
    CostType.TilerInstallationCosts,
    CostType.KitterInstallationCosts
  ]

  rules = {
    maxsize: (value: string) =>
      value?.toString()?.length <= 150 || this.$t('activityTemplate.addActivityDialog.validations.maxSize')
  }

  @Ref() updateActivitiesForm?: HTMLFormElement
  @Ref() templateNameForm?: HTMLFormElement

  created() {
    this.fetchData()

    this.tableOptions.sortBy = ['position', 'activityName']
    this.tableOptions.sortDesc = [false]
  }

  get language() {
    return this.$i18n.locale
  }

  get headers() {
    const headers: DataTableHeader[] = [
      {
        text: this.$t('activities.table.headers.combination').toString(),
        value: 'productCombination.position',
        groupable: true,
        width: '10%',
        filterable: false
      },
      { text: this.$t('activities.table.headers.activity').toString(), value: 'activityName', width: '25%' }
    ]

    this.costTypeSlots.forEach((costType) => {
      headers.push({
        text: this.$t(`activities.table.headers.${costType}`).toString(),
        value: costType,
        width: '10%',
        sortable: false,
        filterable: false
      })
    })

    return headers
  }

  getActivityTargetIcon(target?: ProjectActivityTarget): string {
    if (!target) return ''
    switch (target) {
      case ProjectActivityTarget.Meters:
        return 'mdi-arrow-expand-horizontal'
      case ProjectActivityTarget.SquareMeters:
        return 'mdi-texture-box'
      default:
        return ''
    }
  }

  getActivityTargetTitle(target?: ProjectActivityTarget): string {
    if (!target) return ''
    return this.$t(`activities.table.activityTarget.${target}`).toString()
  }

  fetchData() {
    this.$apollo.addSmartQuery<ActivityTemplateQuery, ActivityTemplateQueryVariables>(QUERIES.ActivityTemplates, {
      query: ActivityTemplateDocument,
      update: (data) => data,
      variables: () => ({
        activityId: this.activityTemplateId,
        lang: this.language
      }),
      result: (result) => {
        this.activityTemplates = result.data
        this.name = result.data?.activityTemplate?.name ?? ''

        this.$nextTick(() => {
          this.updateActivitiesForm?.validate()
        })
      },
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4233',
          Message: error
        })
      }
    })
  }

  get tableData() {
    if (this.activityTemplates?.activityTemplate?.activityTemplates.length) {
      return this.activityTemplates.activityTemplate.activityTemplates.map((activityTemplate) => ({
        id: activityTemplate.id,
        activityName: activityTemplate.activity?.activityName || '',
        activityAdditionalInfo: activityTemplate.activity?.activityAdditionalInfo || '',
        activityId: activityTemplate.activity?.id,
        activityTarget: activityTemplate.activity?.activityTarget,
        productCombination: {
          name:
            this.$t(
              `global.combinations.${activityTemplate.activity?.productCombination?.combinationType?.toLowerCase()}`
            ).toString() ?? '',
          position: activityTemplate.activity?.productCombination?.position || 0
        },
        position: activityTemplate.activity?.position,
        positionLabel: activityTemplate.activity?.positionLabel,
        W_INSTALLATION_COSTS: this.getCostTypeValue(activityTemplate, CostType.WInstallationCosts),
        E_INSTALLATION_COSTS: this.getCostTypeValue(activityTemplate, CostType.EInstallationCosts),
        CV_INSTALLATION_COSTS: this.getCostTypeValue(activityTemplate, CostType.CvInstallationCosts),
        INSTALLATION_CONSTRUCTION_COSTS: this.getCostTypeValue(
          activityTemplate,
          CostType.InstallationConstructionCosts
        ),
        TILER_INSTALLATION_COSTS: this.getCostTypeValue(activityTemplate, CostType.TilerInstallationCosts),
        KITTER_INSTALLATION_COSTS: this.getCostTypeValue(activityTemplate, CostType.KitterInstallationCosts)
      }))
    } else {
      return []
    }
  }

  getCostTypeValue(activity: activityTemplateActivity, costType: CostType) {
    const rawVal = activity.activityCosts.find((y) => y.costType === costType)?.costValue?.amount

    return rawVal ? getInputPrice(rawVal).replace('.', '') : ''
  }

  groupTable(groupBy: string, groupDesc = false) {
    this.tableOptions.groupBy = [groupBy]
    this.tableOptions.groupDesc = [groupDesc]
  }

  unGroupTable(ungroup: () => void) {
    ungroup()
    this.$nextTick(() => this.updateActivitiesForm?.validate())
  }

  onSortChange() {
    if (this.tableOptions.groupBy.length) {
      this.tableOptions.sortBy.unshift('position')
      this.tableOptions.sortDesc.unshift(false)
    }
  }

  onCombinationToggle(isOpen: boolean, toggle: () => void) {
    toggle()
    this.$nextTick(() => {
      if (!isOpen) this.updateActivitiesForm?.validate()
    })
  }

  get maxSize() {
    return this.name?.toString()?.length > 150
  }

  expandAllCombinations() {
    const categoriesToExpand = document.querySelectorAll('[data-js="expand-category"]')
    const clickEvent = new MouseEvent('click')

    categoriesToExpand.forEach((item) => {
      item.dispatchEvent(clickEvent)
    })
  }

  get isLoading() {
    return this.$apollo.queries.ActivityTemplatesQuery?.loading
  }

  async savePrice(item: ActivityTemplates) {
    if (!this.updateActivitiesForm?.validate()) return

    const mutationData = {
      activityTemplateId: this.activityTemplateId,
      projectActivityId: item.activityId,
      costs: this.costTypeSlots.map((costType) => ({
        costType: costType,
        amount: item?.[costType.toString() as keyof AssignedActivity]?.toString() ?? ''
      }))
    }

    try {
      this.isloading = true

      await this.$apolloMutate<UpdateActivityTemplateMutation, UpdateActivityTemplateMutationVariables>({
        mutation: UpdateActivityTemplateDocument,
        variables: mutationData,
        refetchQueries: [QUERIES.ActivityTemplates],
        error: 'E4225'
      })
    } finally {
      this.isloading = false
    }
  }

  updateTemplateName() {
    if (
      this.name === this.activityTemplates?.activityTemplate?.name ||
      this.name === this.previousName ||
      !this.templateNameForm?.validate()
    ) {
      return
    }
    this.$apolloMutate<UpdateActivityTemplateNameMutation, UpdateActivityTemplateNameMutationVariables>({
      mutation: UpdateActivityTemplateNameDocument,
      variables: {
        activityTemplateId: this.activityTemplateId,
        name: this.name
      },
      error: 'E1107'
    })
    this.previousName = this.name
  }
}
