
import { ProductSetAssignedActivity } from '@/__generated__/globalTypes'
import { CombinationProductSetActivityFragment } from '@/views/productPackageView/gql/__generated__/combinationProductSetActivities.query'
import { Component, Prop, Vue } from 'vue-property-decorator'
import SimpleDataTable from '../simpleDataTable/SimpleDataTable.vue'
import { TableColumn } from '../simpleDataTable/types'

@Component({
  components: {
    SimpleDataTable
  }
})
export default class ProductSetActivitiesList extends Vue {
  @Prop() productActivities!: ProductSetAssignedActivity[]
  @Prop() availableActivities!: CombinationProductSetActivityFragment[]
  @Prop() disabled?: boolean | null

  activity: string | null = null
  activitySearch = ''

  get headers(): TableColumn[] {
    return [
      {
        key: 'activity.activityName',
        label: this.$t('productPackage.edit.category.productSets.upsert.fields.activities.label').toString()
      }
    ]
  }

  get filteredActivities() {
    return this.availableActivities
      ?.map((activity) => ({
        text: `${activity.positionLabel}. ${activity.activityName}`,
        value: activity.id,
        position: activity.position
      }))
      ?.sort((a, b) => a.position - b.position)
  }

  addActivity(activityId: string) {
    if (!activityId) return

    const activity = this.availableActivities.find((x) => x.id === activityId)
    if (!activity) return

    this.$emit('add-activity', activity)

    this.$nextTick(() => {
      this.activitySearch = ''
      this.activity = null
    })
  }

  confirmRemoveActivity(activity: ProductSetAssignedActivity) {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: () => {
        this.$store.dispatch('hideConfirmationDialog')
        this.$emit('remove-activity', activity)
      },
      Code: 'C4028'
    })
  }
}
