
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ProductCombinationType } from '@/__generated__/globalTypes'

@Component
export default class ProductCombinationTabs extends Vue {
  @Prop({ required: true }) readonly combinations!: ProductCombinationType[]
  @Prop({ required: true }) readonly selected!: ProductCombinationType

  getAltText(combination: ProductCombinationType) {
    const combinationName = combination.toString().toLowerCase()
    return this.$t('global.combinations.' + combinationName) ?? combinationName
  }

  getCombinationIcon(combination: ProductCombinationType) {
    const iconName = combination.toString().toLowerCase() + '.svg'
    return require('@/assets/product_combinations/' + iconName)
  }

  handleClick(combination: ProductCombinationType) {
    this.$emit('combination-tab-click', combination)
  }
}
