/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CancelTechnicalDrawingMutationVariables = Types.Exact<{
  requestTechnicalDrawingId: Types.Scalars['UUID'];
  notes: Types.Scalars['String'];
}>;


export type CancelTechnicalDrawingMutation = { cancelTechnicalDrawing: boolean };


export const CancelTechnicalDrawingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CancelTechnicalDrawing"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"requestTechnicalDrawingId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"notes"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cancelTechnicalDrawing"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"requestTechnicalDrawingId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"requestTechnicalDrawingId"}}},{"kind":"Argument","name":{"kind":"Name","value":"notes"},"value":{"kind":"Variable","name":{"kind":"Name","value":"notes"}}}]}]}}]} as unknown as DocumentNode<CancelTechnicalDrawingMutation, CancelTechnicalDrawingMutationVariables>;