import { ProductCombinationType, ProductPackageSet, ProductSetInfoKey } from '@/__generated__/globalTypes'
import { MoneyInput } from '@/types/moneyType'

export interface ProductSet {
  id: string
  name: string
  isDefault: boolean | null
  brandLine: string | null
  price: MoneyInput | null
  categoryId: string | null
  combination: ProductCombinationType
  isProductPackageFavourite: boolean
  isNoProduct: boolean
  productCodes: string[]
}

export interface ProductSetInfoKeyWithOptional extends ProductSetInfoKey {
  isOptional: boolean
}

export interface ProductSetInfoKeyModel extends ProductSetInfoKeyWithOptional {
  value: string | null
}

export interface AssignedCategoryProductSet extends ProductPackageSet {
  isSelected: boolean
}

export enum UpsertActionType {
  UPDATE = 'UPDATE',
  SAVE = 'SAVE'
}
