/* eslint-disable @typescript-eslint/no-explicit-any */
export function deepMergeTranslations(defaultTranslation: any, currentTranslation: any): any {
  let result = { ...defaultTranslation }

  function deepMerge(a: any, b: any) {
    if (!b) return a

    // function params are read only so we need to create a new object and copy the values
    const defaultTranslation = { ...a }
    const localization = { ...b }

    for (const key in defaultTranslation) {
      const value = defaultTranslation[key]
      const localizedValue = localization[key]
      if (!localizedValue || key === '__typename') continue

      if (
        (!!localizedValue && (value == null || typeof value !== 'object')) ||
        (Array.isArray(value) && localizedValue.length)
      ) {
        defaultTranslation[key] = localizedValue
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        defaultTranslation[key] = deepMerge(defaultTranslation[key], localization[key])
      }
    }

    return defaultTranslation
  }

  result = deepMerge(result, { ...currentTranslation })
  return result
}

export function GetLocalizedContent<T>(content: any) {
  let data = content
  if (data?.localization?.localizations?.length && data.localization.localizations[0].published) {
    const localization = data.localization.localizations[0]
    data = deepMergeTranslations(data, localization)
  }

  return data as T
}
