var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"item-class":(item) => (item.isSubtotalOrTotal && item.costTypeValue !== _vm.SumType.VAT ? 'grey lighten-2' : ''),"items":_vm.items,"data-cy":"costs-specifications-table","disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.costSpec",fn:function({ item }){return [_c('span',{class:{
          'font-weight-bold': item.isSubtotalOrTotal && item.costTypeValue !== _vm.SumType.VAT
        }},[_vm._v(_vm._s(item.costTypeText))])]}},_vm._l((_vm.selectedRooms),function(room){return {key:_vm.getRoomSlotName(room),fn:function({ item }){return [_c('div',{key:room.id},[(!item.isSubtotalOrTotal && item.costTypeValue !== _vm.SumType.SERVICES)?_c('v-text-field',{key:room.id,attrs:{"disabled":_vm.disabled,"rules":item.costRules,"data-cy":"cost-specification-fields","dense":"","hide-details":"auto","prefix":"€","solo":""},on:{"input":function($event){return _vm.$emit('costs-changed')}},model:{value:(item[room.id]),callback:function ($$v) {_vm.$set(item, room.id, $$v)},expression:"item[room.id]"}}):_c('span',{class:{
            'font-weight-bold': ![_vm.SumType.SERVICES, _vm.SumType.VAT].includes(item.costTypeValue)
          }},[_vm._v(_vm._s(_vm.getTotalOrSubTotal(item.costTypeValue, room.id)))])],1)]}}}),(_vm.divideByRooms)?{key:"item.total",fn:function({ item }){return [_c('span',{class:{
          'font-weight-bold': item.isSubtotalOrTotal && item.costTypeValue !== _vm.SumType.VAT
        }},[_vm._v(_vm._s(_vm.getCostTypeTotal(item.costTypeValue, item.isSubtotalOrTotal)))])]}}:null],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }