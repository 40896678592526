
import { alphaNumSpace } from '@/utils/validationUtils'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { integer, maxLength, minValue, required } from 'vuelidate/lib/validators'
import {
  ProjectRoomsQuery_project_layouts,
  ProjectRoomsQuery_project_rooms
} from '../projectRooms/__generated__/ProjectRoomsQuery'
import { UPDATE_ROOM } from './EditRoomMutations'
import { GET_ROOM } from './EditRoomQueries'
import { RoomQuery } from './__generated__/RoomQuery'
import { UpdateRoomMutation } from './__generated__/UpdateRoomMutation'

@Component({
  name: 'EditRoomComponent',
  mixins: [validationMixin]
})
export default class EditRoomComponent extends Vue {
  @Prop({ default: false }) show!: boolean
  @Prop({ default: '' }) roomId!: string
  @Prop({ default: () => [] }) existingRooms!: ProjectRoomsQuery_project_rooms[] | null
  @Prop({ default: () => [] }) existingLayouts!: ProjectRoomsQuery_project_layouts[] | null

  name = ''
  position = 10
  selectedLayouts = new Array<string>()

  @Watch('roomId')
  onPropertyChanged(value: string) {
    this.name = ''
    this.position = 10
    this.selectedLayouts = new Array<string>()
    if (value) {
      this.fetchRoomValues()
    }
  }

  fetchRoomValues() {
    this.$apollo
      .query<RoomQuery>({
        query: GET_ROOM(this.roomId)
      })
      .then((result) => {
        const room = result.data.rooms?.[0]
        if (room) {
          this.name = room.name
          this.position = room.position
          this.selectedLayouts = room.layoutRooms.map((lr) => lr.layout.id)
        } else {
          this.$store.dispatch('showErrorDialog', {
            Code: 'E4129',
            Message: `${this.roomId}`,
            Sub: 'A'
          })
        }
      })
      .catch((error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4129',
          Message: error,
          Sub: 'B'
        })
      })
  }

  clickClose() {
    this.$v.$reset()
    this.$emit('close')
  }

  clickSave() {
    this.$v.$touch()
    if (this.$v.name.$pending || this.$v.name.$error) return
    if (this.$v.position.$pending || this.$v.position.$error) return
    if (this.$v.selectedLayouts.$pending || this.$v.selectedLayouts.$error) return

    this.$apolloMutate<UpdateRoomMutation>({
      mutation: UPDATE_ROOM(),
      variables: {
        roomId: this.roomId,
        roomName: this.name,
        roomPosition: parseInt(this.position.toString()),
        layoutIds: this.selectedLayouts
      },
      refetchQueries: ['ProjectQuery', 'ProjectRoomsQuery'],
      error: 'E4130'
    }).then((result) => {
      if (result !== false) this.clickClose()
    })
  }

  validations() {
    return {
      name: {
        required,
        maxLength: maxLength(40),
        alphaNumSpace
      },
      position: {
        integer,
        minValue: minValue(0),
        required
      },
      selectedLayouts: {
        required
      }
    }
  }

  get nameErrors() {
    const errors = new Array<string>()
    if (!this.$v.name.$dirty) {
      return errors
    }
    if (!this.$v.name.alphaNumSpace) {
      errors.push(this.$t('projectRoom.errors.name.alphaNumSpace').toString())
    }
    if (!this.$v.name.maxLength) {
      errors.push(this.$t('projectRoom.errors.name.maxLength').toString())
    }
    if (!this.$v.name.required) {
      errors.push(this.$t('projectRoom.errors.name.required').toString())
    }
    return errors
  }

  get positionErrors() {
    const errors = new Array<string>()
    if (!this.$v.position.$dirty) {
      return errors
    }
    if (!this.$v.position.integer) {
      errors.push(this.$t('projectRoom.errors.position.integer').toString())
    }
    if (!this.$v.position.minValue) {
      errors.push(this.$t('projectRoom.errors.position.minValue').toString())
    }
    if (!this.$v.position.required) {
      errors.push(this.$t('projectRoom.errors.position.required').toString())
    }
    return errors
  }

  get layoutErrors() {
    const errors = new Array<string>()
    if (!this.$v.selectedLayouts.$dirty) {
      return errors
    }
    if (!this.$v.selectedLayouts.required) {
      errors.push(this.$t('projectRoom.errors.selectedLayouts.required').toString())
    }
    return errors
  }
}
