
import { validationMixin } from 'vuelidate'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { required, maxLength } from 'vuelidate/lib/validators'
import {
  CopyBrandDocument,
  CopyBrandMutation,
  CopyBrandMutationVariables
} from './gql/__generated__/CopyBrand.mutation'

@Component({
  mixins: [validationMixin]
})
export default class CopyBrandDialog extends Vue {
  @Prop({ default: false }) show!: boolean
  @Prop({ default: () => [] }) existingBrandNames!: string[]
  @Prop() readonly brandId!: string
  @Prop() brandName!: string

  isSaving = false
  name = ''

  @Watch('show')
  onVisibilityChange() {
    this.name = this.brandName || ''
  }

  get showLoader() {
    return this.$apollo.loading || this.isSaving
  }

  clickClose() {
    this.$v.$reset()
    this.name = ''
    this.$emit('close')
  }

  clickSave() {
    this.$v.$touch()
    if (this.$v.name.$pending || this.$v.name.$error) return

    this.isSaving = true

    this.$apolloMutate<CopyBrandMutation, CopyBrandMutationVariables>({
      mutation: CopyBrandDocument,
      variables: {
        brandId: this.brandId,
        brandName: this.name
      },
      error: 'E4239'
    })
      .then((result) => {
        if (result !== false) {
          this.clickClose()
        }
      })
      .finally(() => {
        this.isSaving = false
        this.$emit('refetch-brands-list-query')
      })
  }

  validations() {
    return {
      name: {
        required,
        maxLength: maxLength(50),
        isUnique: (value: string) => {
          return !this.existingBrandNames.includes(value)
        }
      }
    }
  }

  get nameErrors() {
    const errors = new Array<string>()
    if (!this.$v.name.$dirty) {
      return errors
    }
    if (!this.$v.name.isUnique) {
      errors.push(this.$t('brand.copy.errors.name.isUnique').toString())
    }
    if (!this.$v.name.maxLength) {
      errors.push(this.$t('brand.copy.errors.name.maxLength').toString())
    }
    if (!this.$v.name.required) {
      errors.push(this.$t('brand.copy.errors.name.required').toString())
    }
    return errors
  }
}
