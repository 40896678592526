
import { validationMixin } from 'vuelidate'
import { Component, Vue, Prop } from 'vue-property-decorator'

import {
  CreateActivityTemplateMutation,
  CreateActivityTemplateMutationVariables,
  CreateActivityTemplateDocument
} from './__generated__/createActivityTemplate.mutation'

@Component({
  mixins: [validationMixin]
})
export default class AddActivityTemplateDialog extends Vue {
  @Prop({ default: false }) show!: boolean

  name = ''
  isLoading = false

  rules = {
    maxsize: (value: string) =>
      value?.toString()?.length <= 150 || this.$t('activityTemplate.addActivityDialog.validations.maxSize')
  }

  get maxSize() {
    return this.name?.toString()?.length > 150
  }

  clickClose() {
    this.$emit('close')
    this.name = ''
  }

  clickSave() {
    this.isLoading = true
    this.$apolloMutate<CreateActivityTemplateMutation, CreateActivityTemplateMutationVariables>({
      mutation: CreateActivityTemplateDocument,
      variables: {
        activityTemplateName: this.name
      },
      error: 'E1106'
    })
      .then((result) => {
        if (result !== false) this.$emit('update-activity-list')
        this.clickClose()
      })
      .finally(() => {
        this.isLoading = false
      })
  }
}
