import * as directives from '@/directives/directives'
import nl from '@/internationalization/translations/dutch'
import en from '@/internationalization/translations/english'
import CustomApolloMixin from '@/mixins/customApolloMixin'
import StoreMixin from '@/mixins/storeMixin'
import { InMemoryCache } from 'apollo-cache-inmemory'
import AppolloClient from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { createHttpLink } from 'apollo-link-http'
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import VueAppInsights from 'vue-application-insights'
import CountryFlag from 'vue-country-flag'
import VueI18n from 'vue-i18n'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './registerComponentHooks'
import router from './router'
import { RouteNames } from './router/routeNames'
import { FileApiService } from './services/fileApiService'
import store from './store'

declare module 'vue/types/vue' {
  interface Vue {
    $fileApi: FileApiService
  }
}

Vue.use(Vuelidate)
Vue.use(VueApollo)
Vue.use(VueI18n)
Vue.use(CountryFlag)
Vue.use(VueAppInsights, {
  baseName: 'DeBadkamer',
  id: process.env.VUE_APP_APPINSIGHTS_ID,
  router: router
})

Vue.mixin(StoreMixin)
Vue.mixin(CustomApolloMixin)
Vue.mixin({
  computed: {
    $fileApi: () => new FileApiService(process.env.VUE_APP_GATEWAY_URL as string, store.getters.accessToken)
  }
})

directives.Setup()

Vue.config.productionTip = false

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_GATEWAY_URL + '/graphql'
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from the store if it exists
  const token = store.getters.accessToken
  const tokenExp = store.getters.accessTokenExp
  tokenExp.setMinutes(tokenExp.getMinutes() + 1)

  if (token && tokenExp > new Date()) {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  } else {
    router.push({ name: RouteNames.LOGOUT })
  }

  return { ...headers }
})

const apolloErrorLink = onError((error) => {
  if (error.graphQLErrors?.some((e) => e.extensions?.code === 'AUTH_NOT_AUTHENTICATED')) {
    if (router.currentRoute.name !== RouteNames.LOGOUT) {
      router.push({ name: RouteNames.LOGOUT })
    }
    return
  }

  error?.graphQLErrors?.forEach((gqlError) => {
    Vue.appInsights.trackException({
      exception: gqlError,
      properties: { query: error.operation.operationName }
    })
  })
})

const client = new AppolloClient({
  link: apolloErrorLink.concat(authLink).concat(httpLink),
  cache: new InMemoryCache({
    addTypename: true
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache'
    },
    query: {
      fetchPolicy: 'no-cache'
    },
    mutate: {
      awaitRefetchQueries: true
    }
  }
})

const apolloProvider = new VueApollo({
  defaultClient: client
})

const languageKey = 'language'
const browserLanguage = navigator.language.split('-')[0]
const defaultLanguage = 'nl'
const storedLanguage = window.localStorage.getItem(languageKey)
let selectedLanguage = defaultLanguage
if (storedLanguage) {
  selectedLanguage = storedLanguage
} else if (!storedLanguage && browserLanguage == 'en') {
  selectedLanguage = 'en'
}

const i18n = new VueI18n({
  locale: selectedLanguage,
  fallbackLocale: 'nl',
  messages: { en, nl }
})

new Vue({
  i18n,
  router,
  store,
  apolloProvider,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
