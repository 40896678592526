
import CategoryUpsertModal from '@/components/categories/CategoryUpsertModal.vue'
import { CategoryNamesPerCombination, UpsertActionType } from '@/components/categories/types'
import ProductSetInfoKeyModal from '@/components/productSetInfoKeys/ProductSetInfoKeyModal.vue'
import QUERIES from '@/queries/queries'
import { ProductSetInfoKeyType } from '@/__generated__/globalTypes'
import { Component, Ref, Vue } from 'vue-property-decorator'
import { DataOptions, DataTableHeader } from 'vuetify'
import {
  CategoriesViewQueryDocument,
  CategoriesViewQueryQuery,
  ProductCategoryFragment,
  ProductCombinationFragment,
  ProductSetInfoKeytranslationsFragment
} from './gql/__generated__/CategoriesView.query'
import {
  GetCategoryPageDocument,
  GetCategoryPageQuery,
  GetCategoryPageQueryVariables
} from './gql/__generated__/categoryPage.query'

@Component({
  components: {
    CategoryUpsertModal,
    ProductSetInfoKeyModal
  }
})
export default class CategoriesView extends Vue {
  @Ref('category-upsert-modal') readonly categoryUpsertModalReference!: CategoryUpsertModal

  categoriesRequestData: ProductCategoryFragment[] = []
  productCombinations: ProductCombinationFragment[] = []
  knownProductSetInfoKeys: ProductSetInfoKeytranslationsFragment[] = []
  tableOptions: DataOptions = {} as DataOptions

  showCategoryUpsertModal = false
  showAddInfoKeyModal = false

  categoryToUpdate: ProductCategoryFragment | null = null
  upsertCategoryActionType: UpsertActionType | null = null

  EMPTY_GUID = '00000000-0000-0000-0000-000000000000'

  created() {
    this.$apollo.addSmartQuery<CategoriesViewQueryQuery>(QUERIES.CategoriesViewQuery, {
      query: CategoriesViewQueryDocument,
      variables: () => ({ currentLang: this.currentLang }),
      update: (data) => data,
      result: (result) => {
        if (result) {
          this.categoriesRequestData = result.data?.productCategories ?? []
          this.knownProductSetInfoKeys = result.data?.productSetInfoKeys ?? []
          this.productCombinations = result.data?.productCombinations ?? []

          // If this is triggered from a refetch and we're still inside the upsert category modal, then we need to refresh the category to update with new data
          if (this.categoryToUpdate)
            this.categoryToUpdate = this.categoriesRequestData?.find((x) => x.id === this.categoryToUpdate?.id) ?? null
        }
      },
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4180',
          Message: error
        })
      }
    })
  }

  isReadonly() {
    return !this.$store.getters.isAdmin
  }

  get headers() {
    return [
      { text: this.$t('application.categories.list.headers.name'), value: 'name', width: '13%' },
      { text: this.$t('application.categories.list.headers.position'), value: 'position', width: '9%' },
      {
        text: this.$t('application.categories.list.headers.productCombination'),
        value: 'productCombination.combinationType',
        width: '13%'
      },
      { text: '', value: 'actions', sortable: false, width: '0%' }
    ] as DataTableHeader[]
  }

  get currentLang() {
    return this.$i18n.locale
  }

  get tableData() {
    return this.categoriesRequestData.map((x) => ({
      id: x?.id,
      name: x?.name,
      position: x?.position,
      productCombination: x?.productCombination
    }))
  }

  navigateToCms(id: string) {
    this.$apollo
      .query<GetCategoryPageQuery, GetCategoryPageQueryVariables>({
        query: GetCategoryPageDocument,
        variables: {
          aliasId: id
        }
      })
      .catch((reason) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E1118',
          Message: reason
        })
      })
      .then((response) => {
        if (response) {
          const host = process.env.VUE_APP_CMS_URL
          const contentItemId = response.data.category?.[0]?.contentItemId
          if (contentItemId) {
            const url = `${host}/Admin/Contents/ContentItems/${contentItemId}/Edit?returnUrl=%2FAdmin%2FContents%2FContentItems`
            window.open(url, 'editCategoriesInCms')
          } else {
            this.$store.dispatch('showErrorDialog', {
              Code: 'E1119'
            })
          }
        }
      })
  }

  get categoryNamesPerCombination() {
    const categoryNamesPerCombination = []
    for (let i = 0; i < this.productCombinations.length; i++) {
      const item = {
        combinationId: this.productCombinations[i]?.id,
        categoryNames: this.categoriesRequestData
          ?.filter((x) => x?.productCombination?.id === this.productCombinations[i]?.id)
          .map((y) => y?.name)
      } as CategoryNamesPerCombination
      categoryNamesPerCombination.push(item)
    }
    return categoryNamesPerCombination
  }

  openCategoryUpsertModal(catId: string | null) {
    this.showCategoryUpsertModal = true
    this.categoryToUpdate = this.categoriesRequestData?.find((x) => x.id === catId) ?? null
    this.upsertCategoryActionType = catId ? UpsertActionType.UPDATE : UpsertActionType.SAVE
  }

  closeCategoryUpsertModal() {
    this.showCategoryUpsertModal = false
    this.categoryToUpdate = null
  }

  openAddInfoKeyModal() {
    this.showCategoryUpsertModal = false
    this.showAddInfoKeyModal = true
  }

  addNewInfoKeyToCategory(newlyCreatedInfoKey: { infoKeyId: string; labelNL: string; labelEN: string }) {
    if (!newlyCreatedInfoKey?.infoKeyId || !this.categoryToUpdate) return

    if (!this.categoryToUpdate?.assignedProductCategoryProductSetInfoKeys)
      this.categoryToUpdate.assignedProductCategoryProductSetInfoKeys = []

    this.categoryToUpdate.assignedProductCategoryProductSetInfoKeys.push({
      id: null,
      isDisplayable: true,
      isFilterable: false,
      isLocked: false,
      isOptional: true,
      position: 999,
      productSetInfoKey: {
        id: newlyCreatedInfoKey.infoKeyId,
        infoLabel: this.currentLang === 'nl' ? newlyCreatedInfoKey.labelNL : newlyCreatedInfoKey.labelEN,
        isNotEditable: false,
        key: ProductSetInfoKeyType.Other
      }
    })

    // Close this modal and open the category upsert modal again
    this.showAddInfoKeyModal = false
    this.showCategoryUpsertModal = true

    // Note: Not really recommended approach, but because of time constraint we ended up going for this refs workaround.
    this.categoryUpsertModalReference?.setCategoryModal()
    this.categoryUpsertModalReference?.upsertCategory(false)
  }
}
