
import QUERIES from '@/queries/queries'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import {
  ProjectActivityTemplateImportMutation,
  ProjectActivityTemplateImportMutationVariables,
  ProjectActivityTemplateImportDocument
} from './__generated__/projectActivityTemplateImport.mutation'

@Component
export default class importActivityTemplateDialog extends Vue {
  @Prop() show!: boolean
  @Prop() existingTemplateNames!: string
  @Prop() projectId!: string

  name = ''
  isLoading = false

  @Watch('show')
  onVisibilityChange() {
    if (!this.show) {
      this.name = ''
    }
  }

  async importActivityTemplate() {
    const mutationData = {
      activityTemplateId: this.name,
      projectId: this.projectId
    }

    try {
      this.isLoading = true
      await this.$apolloMutate<ProjectActivityTemplateImportMutation, ProjectActivityTemplateImportMutationVariables>({
        mutation: ProjectActivityTemplateImportDocument,
        variables: mutationData,
        refetchQueries: [QUERIES.Activities],
        error: 'E4232'
      })
    } finally {
      this.isLoading = false
      this.$emit('refetch-activities-query')
      this.$emit('close')
    }
  }
}
