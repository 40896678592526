/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AssignTo3DDrawingMutationVariables = Types.Exact<{
  drawerId: Types.Scalars['String'];
  requestId: Types.Scalars['UUID'];
}>;


export type AssignTo3DDrawingMutation = { assignDrawerTo3DDrawing: boolean };


export const AssignTo3DDrawingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"assignTo3DDrawing"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"drawerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"requestId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assignDrawerTo3DDrawing"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"drawerId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"drawerId"}}},{"kind":"Argument","name":{"kind":"Name","value":"requestId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"requestId"}}}]}]}}]} as unknown as DocumentNode<AssignTo3DDrawingMutation, AssignTo3DDrawingMutationVariables>;