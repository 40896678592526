export function unique<K, T>(list: T[] | null | undefined, keyGetter: (item: T) => K) {
  return list?.filter((value, index, self) => self.findIndex((x) => keyGetter(x) === keyGetter(value)) === index)
}

export function groupBy<K, T>(list: Array<T>, keyGetter: (item: T) => K) {
  const map = new Map<K, T[]>()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}
