/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeleteCustomOfferMutationVariables = Types.Exact<{
  customOfferId: Types.Scalars['UUID'];
}>;


export type DeleteCustomOfferMutation = { deleteCustomOffer: boolean };


export const DeleteCustomOfferDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteCustomOffer"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"customOfferId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteCustomOffer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"customOfferId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"customOfferId"}}}]}]}}]} as unknown as DocumentNode<DeleteCustomOfferMutation, DeleteCustomOfferMutationVariables>;