/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AddCustomProductSetInfoKeyMutationVariables = Types.Exact<{
  dutchLabel: Types.Scalars['String'];
  englishLabel: Types.Scalars['String'];
}>;


export type AddCustomProductSetInfoKeyMutation = { addCustomProductSetInfoKey: any };


export const AddCustomProductSetInfoKeyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"addCustomProductSetInfoKey"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dutchLabel"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"englishLabel"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addCustomProductSetInfoKey"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"dutchLabel"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dutchLabel"}}},{"kind":"Argument","name":{"kind":"Name","value":"englishLabel"},"value":{"kind":"Variable","name":{"kind":"Name","value":"englishLabel"}}},{"kind":"Argument","name":{"kind":"Name","value":"isDisplayable"},"value":{"kind":"BooleanValue","value":true}}]}]}}]} as unknown as DocumentNode<AddCustomProductSetInfoKeyMutation, AddCustomProductSetInfoKeyMutationVariables>;