
import { ProductPackageViewDataDocument } from '@/views/productPackageView/gql/__generated__/getProductPackageViewData.query'
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
DeleteProductSetBrandLineDocument,
DeleteProductSetBrandLineMutation,
DeleteProductSetBrandLineMutationVariables
} from './gql/__generated__/deleteProductSetBrandline.mutation'

@Component
export default class DeleteProductSetBrandLineDialog extends Vue {
  @Prop({ default: false }) show!: boolean
  @Prop() brandLineId!: string
  @Prop() productPackageId!: string

  get language() {
    return window.localStorage.getItem('language')
  }

  async deleteBrandLine() {
    try {
      this.$emit('is-loading', true)
      this.$emit('close')

      await this.$apolloMutate<DeleteProductSetBrandLineMutation, DeleteProductSetBrandLineMutationVariables>({
        mutation: DeleteProductSetBrandLineDocument,
        variables: {
          productSetBrandlineId: this.brandLineId
        },
        refetchQueries: [
          {
            query: ProductPackageViewDataDocument,
            variables: {
              language: this.language,
              productPackageId: this.productPackageId
            }
          }
        ],
        error: 'E4164'
      })
    } finally {
      this.$emit('is-loading', false)
    }
  }
}
