
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { RequestForTechicalDrawingFragment } from './gql/__generated__/technicalDrawings.query'

const MAX_FILE_SIZE = 100000000 //bytes (~100 MB)

@Component
export default class TechnicalDrawingUploadPopup extends Vue {
  @Prop({ required: true, default: false }) show!: boolean
  @Prop({ required: true }) technicalDrawingRequest!: RequestForTechicalDrawingFragment

  file: File | null = null
  isUploading = false

  acceptedFileTypes = ['application/pdf']

  @Watch('show')
  onVisibilityChange() {
    if (!this.show) this.file = null
  }

  get errors() {
    const result: string[] = []

    if (!this.file) return result

    if (this.file.size > MAX_FILE_SIZE) {
      const maxSizeInMB = Math.floor(MAX_FILE_SIZE / 1024 / 1024)
      const message = this.$t('global.dialogs.upload.errors.maxFileSize')
        .toString()
        .replace('{MAX}', maxSizeInMB.toString())
      result.push(message)
    }

    if (!this.acceptedFileTypes.includes(this.file.type)) {
      result.push(this.$t('global.dialogs.upload.errors.invalidType').toString())
    }

    return result
  }

  uploadFile() {
    if (!this.file || this.errors.length || this.isUploading) return
    this.isUploading = true

    this.$fileApi
      .uploadFile(`/technicalDrawing/${this.technicalDrawingRequest.id}`, this.file)
      .then(() => this.$emit('done'))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        this.$emit('close')
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4228',
          Message: error
        })
      })
      .finally(() => {
        this.isUploading = false
        this.file = null
      })
  }
}
