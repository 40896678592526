
import { Category, ProductPackageSet } from '@/__generated__/globalTypes'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { CategoryPreselectedProductSets, GuidEmpty } from './types'

@Component({
  name: 'PreselectedProductSet',
  mixins: [validationMixin]
})
export default class PreselectedProductSet extends Vue {
  @Prop({ required: true }) categoryCMS!: Category | undefined
  @Prop({ required: true }) assignedPreselectedProductSetsList!: ProductPackageSet[]
  @Prop({ required: true }) value!: CategoryPreselectedProductSets
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({ required: true }) v!: any
  @Prop({ required: true }) selectedPreselectedProductSetIds!: string[]
  @Prop({ required: true }) isPriceVariant!: boolean
  @Prop({ required: true }) isAccentTile!: boolean
  GuidEmpty: string = GuidEmpty

  get selectedPreselectedProductSets() {
    return (
      this.assignedPreselectedProductSets.find((x) => this.selectedPreselectedProductSetIds?.includes(x.value))
        ?.value ?? this.assignedPreselectedProductSets.find((x) => x.value === GuidEmpty)?.value
    )
  }

  get getProductSetIdErrors() {
    const errors = new Array<string>()
    if (!this.v.productSetIds.$dirty) {
      return errors
    }
    if (!this.v.productSetIds.required) {
      errors.push(`${this.getPreselectedProductSetsTitle()} ${this.$t('global.isRequired').toString().toLowerCase()}.`)
    }

    return errors
  }

  getPreselectedProductSetsTitle() {
    return `${this.$t('global.preselected')
      .toString()
      .replaceAll('{{CATEGORYNAME}}', this.categoryCMS?.name?.toLowerCase() ?? '')}`
  }

  get assignedPreselectedProductSets() {
    if (!this.assignedPreselectedProductSetsList?.length) return []
    return this.assignedPreselectedProductSetsList.map((x) => ({ text: x.productSet?.name, value: x.productSet.id }))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPreselectedDropDownValueChange(inputValue: any) {
    this.v.productSetIds.$touch()
    this.$emit('input', {
      productCategoryId: this.value.productCategoryId,
      productSetIds: inputValue
    } as CategoryPreselectedProductSets)
  }
}
