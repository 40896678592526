/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeleteRealEstateFileMutationVariables = Types.Exact<{
  realEstateId: Types.Scalars['UUID'];
  filename: Types.Scalars['String'];
}>;


export type DeleteRealEstateFileMutation = { deleteRealEstateFile: boolean };


export const DeleteRealEstateFileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteRealEstateFile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"realEstateId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filename"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteRealEstateFile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"realEstateId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"realEstateId"}}},{"kind":"Argument","name":{"kind":"Name","value":"fileName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filename"}}}]}]}}]} as unknown as DocumentNode<DeleteRealEstateFileMutation, DeleteRealEstateFileMutationVariables>;