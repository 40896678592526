
import { DrawingRequestStatus, UsersGroupFilterType } from '@/__generated__/globalTypes'
import AssignDrawerDialog from '@/components/assignDrawerDialog/AssignDrawerDialog.vue'
import { DrawingTypes } from '@/components/assignDrawerDialog/types'
import DrawingUploadDialog from '@/components/drawings/DrawingUploadDialog.vue'
import { DrawingRequest } from '@/components/drawings/types'
import DebounceMixin, { debounceDelay } from '@/mixins/debounceMixin'
import QUERIES from '@/queries/queries'
import { RouteNames } from '@/router/routeNames'
import { toLocaleDateTimeString } from '@/utils/dateUtils'
import { TableFilter, buildRequestVariables, defaultFooterProps } from '@/utils/tableUtils'
import { Component, Vue } from 'vue-property-decorator'
import { DataOptions, DataTableHeader } from 'vuetify'
import {
  RensaUsersDocument,
  RensaUsersFragment,
  RensaUsersQuery,
  RensaUsersQueryVariables
} from '../customOfferListView/gql/__generated__/rensaUsers.query'
import {
  ForceCancel3DDrawingForRoomSelectionDocument,
  ForceCancel3DDrawingForRoomSelectionMutation,
  ForceCancel3DDrawingForRoomSelectionMutationVariables
} from './gql/__generated__/forceCancelRequests3DDrawings.mutation'
import {
  RequestFor3DDrawingFragment,
  RequestsFor3DDrawingsDocument,
  RequestsFor3DDrawingsQuery
} from './gql/__generated__/requestsFor3DDrawings.query'

@Component({
  components: {
    DrawingUploadDialog,
    AssignDrawerDialog
  },
  mixins: [DebounceMixin]
})
export default class DrawingsView extends Vue {
  search = ''
  userFilters: TableFilter[] = [
    {
      keyPath: 'realEstate.project.name',
      value: '',
      filterType: 'contains'
    },
    {
      keyPath: 'realEstate.buildNumber',
      value: '',
      filterType: 'contains'
    },
    {
      keyPath: 'realEstate.contact.legalName',
      value: '',
      filterType: 'contains'
    }
  ]
  defaultFilterAssignedDrawerId: TableFilter[] = [
    {
      keyPath: 'assignedDrawerId',
      value: ''
    }
  ]
  statusFilters: TableFilter[] = [
    {
      keyPath: 'request3DDrawingStatus',
      value: ''
    }
  ]
  closedStatusFilters: TableFilter[] = [
    {
      keyPath: 'request3DDrawingStatus',
      value: [DrawingRequestStatus.Cancelled, DrawingRequestStatus.Ready],
      filterType: 'nin'
    }
  ]
  hideClosedDrawings = true
  drawingsRequestData: (RequestFor3DDrawingFragment | null)[] = []
  totalCount = 0
  tableOptions: DataOptions = {} as DataOptions
  selectedDrawing: DrawingRequest | null = null
  showDrawingUploadDialog = false
  changeSearch: ((value: string) => void) | null = null
  selectedDrawingStatus: string = DrawingRequestStatus.Requested
  DAY_IN_MILLISECONDS = 86400000
  isDownloading = false
  showAssignDrawerDialog = false
  drawingSpecialists: RensaUsersFragment[] = []

  formatDate = toLocaleDateTimeString
  defaultFooterProps = defaultFooterProps
  DrawingRequestStatus = DrawingRequestStatus
  RouteNames = RouteNames
  DrawingTypes = DrawingTypes

  created() {
    this.$apollo.addSmartQuery<RequestsFor3DDrawingsQuery>(QUERIES.RequestsFor3DDrawings, {
      query: RequestsFor3DDrawingsDocument,
      variables: () => this.drawingRequestTableVariables,
      fetchPolicy: 'network-only',
      update: (data) => data,
      pollInterval: 60000,
      result: (result) => {
        this.totalCount = result.data.requestsFor3DDrawings?.totalCount ?? 0
        this.drawingsRequestData = result.data.requestsFor3DDrawings?.items ?? []
      },
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4174',
          Message: error
        })
      }
    })

    this.$apollo.addSmartQuery<RensaUsersQuery, RensaUsersQueryVariables>(QUERIES.RensaUsers, {
      query: RensaUsersDocument,
      fetchPolicy: 'network-only',
      update: (data) => data,
      variables: {
        searchTerm: '',
        filterType: UsersGroupFilterType.Draftsman
      },
      result: (result) => {
        this.drawingSpecialists = result.data?.rensaUsers ?? []
      },
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4227',
          Message: error
        })
      }
    })
  }

  mounted() {
    this.changeSearch = this.debounce(() => {
      // Resets to the first page
      this.tableOptions.page = 1

      // Set the search string in all the filters
      Object.values(this.userFilters).forEach((filter) => {
        filter.value = this.search
      })
    }, debounceDelay)
  }

  get headers(): DataTableHeader[] {
    return [
      { text: this.$t('drawings.list.headers.requested').toString(), value: 'requested', width: '15%' },
      { text: this.$t('drawings.list.headers.project').toString(), value: 'realEstate.project.name', width: '10%' },
      { text: this.$t('drawings.list.headers.buildNr').toString(), value: 'realEstate.buildNumber', width: '8%' },
      { text: this.$t('drawings.list.headers.room').toString(), value: 'designPackage.room.name', width: '10%' },
      { text: this.$t('drawings.list.headers.designName').toString(), value: 'selectionName', width: '12%' },
      { text: this.$t('drawings.list.headers.contact').toString(), value: 'realEstate.contact.legalName', width: '9%' },
      {
        text: this.$t('drawings.list.headers.email').toString(),
        value: 'realEstate.contact.email.address',
        width: '8%'
      },
      {
        text: this.$t('drawings.list.headers.assignedDrawerName').toString(),
        value: 'assignedDrawerName',
        width: '10%',
        sortable: false
      },
      { text: this.$t('drawings.list.headers.deadline').toString(), value: 'deadline', width: '12%' },
      { text: this.$t('drawings.list.headers.status').toString(), value: 'request3DDrawingStatus', width: '6%' },
      { text: '', value: 'actions', sortable: false, width: '0%' }
    ]
  }

  get drawingRequestTableVariables() {
    const filterList = [this.userFilters, this.defaultFilterAssignedDrawerId, this.statusFilters]
    if (this.hideClosedDrawings) filterList.push(this.closedStatusFilters)
    return buildRequestVariables(this.tableOptions, filterList)
  }

  get tableData(): DrawingRequest[] {
    return this.drawingsRequestData.map((x) => ({
      id: x?.id?.toString() ?? '',
      requested: x?.requested?.toString() ?? '',
      project: x?.realEstate?.project?.name,
      buildNr: x?.realEstate?.buildNumber,
      room: x?.designPackage?.room?.name,
      designName: x?.selectionName,
      contact: x?.realEstate?.contact?.legalName,
      email: x?.realEstate?.contact?.email?.address,
      deadline: x?.deadline?.toString() ?? '',
      status: x?.request3DDrawingStatus,
      roomSelectionId: x?.roomSelectionId?.toString() ?? '',
      assignedDrawerName: x?.assignedDrawer?.displayName
    }))
  }

  // Create a dranwings Request Status List based on the translations
  get drawingRequestStatusListTranslated() {
    return Object.values(DrawingRequestStatus)
      .filter((status) => status !== DrawingRequestStatus.NotRequested)
      .map((status) => ({
        text: this.$t(`drawings.status.${status}`).toString(),
        value: status,
        disabled:
          this.hideClosedDrawings &&
          status &&
          [DrawingRequestStatus.Cancelled, DrawingRequestStatus.Ready].includes(status)
      }))
  }

  get language() {
    return this.$i18n.locale
  }

  clearStatusFilter() {
    if (
      this.hideClosedDrawings &&
      (this.statusFilters[0]?.value === DrawingRequestStatus.Cancelled ||
        this.statusFilters[0]?.value === DrawingRequestStatus.Ready)
    ) {
      this.statusFilters[0].value = ''
    }
  }

  assignDrawer(item: DrawingRequest) {
    this.selectedDrawing = item
    this.showAssignDrawerDialog = true
  }

  closeAssignDrawerDialog() {
    this.showAssignDrawerDialog = false
    this.selectedDrawing = null
  }

  onAssignDrawer() {
    this.closeAssignDrawerDialog()
    this.$apollo.queries[QUERIES.RequestsFor3DDrawings].refetch()
  }

  upload3DDrawing(item: DrawingRequest) {
    this.selectedDrawing = item
    this.showDrawingUploadDialog = true
  }

  close3DDrawingDialog() {
    this.showDrawingUploadDialog = false
    this.selectedDrawing = null
  }

  onUpload3DDrawing() {
    this.close3DDrawingDialog()
    this.$apollo.queries[QUERIES.RequestsFor3DDrawings].refetch()
  }

  isRequestCancellable(request: DrawingRequest) {
    const requestDate = new Date(request.requested)
    const currentDate = new Date()
    const diff = currentDate.getTime() - requestDate.getTime()
    return diff <= this.DAY_IN_MILLISECONDS
  }

  getStatusColor(request: DrawingRequest) {
    switch (request.status) {
      case DrawingRequestStatus.Ready:
        return 'green'
      case DrawingRequestStatus.Cancelled:
        return 'red'
      case DrawingRequestStatus.Requested:
        return this.isRequestCancellable(request) ? 'orange darken-2' : 'yellow darken-2'
      default:
        return 'grey'
    }
  }

  getStatusTranslation(request: DrawingRequest) {
    const statusKey =
      request.status === DrawingRequestStatus.Requested && this.isRequestCancellable(request)
        ? 'REQUESTED_AND_CANCELABLE'
        : request.status
    return this.$t(`drawings.status.${statusKey}`)
  }

  getStatusIcon(request: DrawingRequest) {
    switch (request.status) {
      case DrawingRequestStatus.Ready:
        return 'mdi-check-circle-outline'
      case DrawingRequestStatus.Cancelled:
        return 'mdi-close-circle-outline'
      case DrawingRequestStatus.Requested:
        if (this.isRequestCancellable(request)) {
          return 'mdi-clock-alert-outline'
        }
        break
      default:
        break
    }

    return 'mdi-progress-clock'
  }

  download3DDrawing(item: DrawingRequest) {
    this.isDownloading = true

    this.$fileApi
      .downloadFile(`/3ddrawing/${item.roomSelectionId}`, item.designName?.replaceAll('.', '_') ?? '3DDrawing')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4175',
          Message: error
        })
      })
      .finally(() => {
        this.isDownloading = false
      })
  }

  onUserFilterUpdate() {
    this.tableOptions.page = 1
  }

  cancelDrawingRequest(item: DrawingRequest) {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: () => this.confirmCancelDrawingRequest(item),
      Code: 'C4024'
    })
  }

  async confirmCancelDrawingRequest(item: DrawingRequest) {
    this.isDownloading = true
    try {
      await this.$apolloMutate<
        ForceCancel3DDrawingForRoomSelectionMutation,
        ForceCancel3DDrawingForRoomSelectionMutationVariables
      >({
        mutation: ForceCancel3DDrawingForRoomSelectionDocument,
        refetchQueries: [QUERIES.RequestsFor3DDrawings],
        variables: {
          request3DDrawingId: item.id
        },
        error: (err) => {
          const error = err.error?.graphQLErrors?.[0] ?? err.error?.networkError?.result?.errors?.[0]
          return {
            Code: 'E4210',
            Message: error?.extensions?.message ?? err.error,
            Callback: err.Callback
          }
        }
      })
    } finally {
      this.$store.dispatch('hideConfirmationDialog')
      this.isDownloading = false
    }
  }
}
