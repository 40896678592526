/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CopyProductSetMutationVariables = Types.Exact<{
  productSetId: Types.Scalars['UUID'];
  productPackageId: Types.Scalars['UUID'];
  productCombinationId: Types.Scalars['UUID'];
  name: Types.Scalars['String'];
}>;


export type CopyProductSetMutation = { copyProductSet: any };


export const CopyProductSetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"copyProductSet"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"productSetId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"productPackageId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"productCombinationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"copyProductSet"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"productSetId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"productSetId"}}},{"kind":"Argument","name":{"kind":"Name","value":"productPackageId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"productPackageId"}}},{"kind":"Argument","name":{"kind":"Name","value":"productCombinationId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"productCombinationId"}}},{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}}]}]}}]} as unknown as DocumentNode<CopyProductSetMutation, CopyProductSetMutationVariables>;