
import {
  ProjectDossierContact,
  RealEstate,
  UploadFileType,
  TupleOfStringAndUploadFileTypeInput,
  FileExtension
} from '@/__generated__/globalTypes'
import { toLocaleDateTimeString } from '@/utils/dateUtils'
import { SmartQuery } from 'vue-apollo/types/vue-apollo'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  DeleteRealEstateFileDocument,
  DeleteRealEstateFileMutation,
  DeleteRealEstateFileMutationVariables
} from './gql/__generated__/deleteRealEstateFile.mutation'
import { ProjectRealEstatesDocument } from './gql/__generated__/getProjectRealEstates.query'
import {
  GetRealEstateFilenamesDocument,
  GetRealEstateFilenamesQuery,
  GetRealEstateFilenamesQueryVariables
} from './gql/__generated__/getRealEstateFilenames.query'
import {
  SendDossierDocument,
  SendDossierMutation,
  SendDossierMutationVariables
} from './gql/__generated__/sendDossier.mutation'

const MAX_FILE_SIZE = 100000000 //bytes (~100 MB)
const EMAIL_REGEX = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/

interface IDocument {
  isDeletable: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lastModified?: any | null
  name: string
  path: string
  type: UploadFileType
  fileExtension: FileExtension
}

@Component
export default class RealEstateDocumentsDialog extends Vue {
  @Prop({ default: false }) show!: boolean
  @Prop({ default: null }) realEstate!: RealEstate | null
  @Prop() isSendDossier!: boolean
  @Prop() projectWideDossierEmails!: ProjectDossierContact[]

  filename = ''
  file?: File | null = null
  isLoading = false

  realEstateFiles = Array<IDocument>()
  filesQuery?: SmartQuery<this, GetRealEstateFilenamesQuery, GetRealEstateFilenamesQueryVariables>
  selectedDocuments = Array<IDocument>()
  recipients = ''
  headerCode = ''

  FileExtension = FileExtension

  maxFileNameSize = 120

  // the'd0cf11e0' is a header code that is specific to a .msg file.
  acceptedFileTypes = ['application/pdf', 'application/vnd.ms-outlook', 'd0cf11e0', '.msg']
  created() {
    this.filesQuery = this.$apollo.addSmartQuery<GetRealEstateFilenamesQuery, GetRealEstateFilenamesQueryVariables>(
      'GetRealEstateFilenamesQuery',
      {
        query: GetRealEstateFilenamesDocument,
        variables: () => ({
          realEstateId: this.realEstate?.id
        }),
        fetchPolicy: 'network-only',
        update: (data) => data,
        skip: () => !this.realEstate?.id,
        result: (result) => {
          this.realEstateFiles = result.data.realEstate?.files ?? []
        }
      }
    )
  }

  @Watch('show', { immediate: true })
  onVisibilityChange() {
    if (!this.show) {
      this.file = null
      this.filename = ''
      this.recipients = this.buyersEmailAddress + this.projectDefaultEmailAddresses
      this.isLoading = false
      this.realEstateFiles = []
      this.selectedDocuments = []
    }
  }

  @Watch('buyersEmailAddress')
  onBuyersEmailAdressChange() {
    if (this.buyersEmailAddress) {
      this.recipients = this.buyersEmailAddress + this.projectDefaultEmailAddresses
    }
  }

  get projectDefaultEmailAddresses() {
    if (!this.projectWideDossierEmails?.length) return ''
    return this.projectWideDossierEmails.map((x) => x.email?.address).join(';   ') + ';'
  }

  get buyersEmailAddress() {
    if (!this.realEstate?.contact?.email.address) return ''
    return this.realEstate.contact.email.address + ';' + '  '
  }

  //getting the header code for a .msg file
  handleHeader() {
    const control = document.getElementById('upload-files') as HTMLInputElement

    const files = control.files

    if (!files) return
    const blob = files[0]

    const fileReader = new FileReader()

    fileReader.onloadend = (e: ProgressEvent<FileReader>) => {
      const arr = new Uint8Array(e.target?.result as ArrayBuffer).subarray(0, 4)
      let header = ''

      for (let j = 0; j < arr.length; j++) {
        header += arr[j].toString(16)
      }

      this.headerCode = header
    }

    fileReader.readAsArrayBuffer(blob)
  }

  get fileErrors() {
    const result: string[] = []

    if (!this.file) return result

    if (this.file.size > MAX_FILE_SIZE) {
      const maxSizeInMB = Math.floor(MAX_FILE_SIZE / 1024 / 1024)
      const message = this.$t('global.dialogs.upload.errors.maxFileSize')
        .toString()
        .replace('{MAX}', maxSizeInMB.toString())
      result.push(message)
    }

    if (!this.acceptedFileTypes.includes(this.file.type || this.headerCode)) {
      result.push(this.$t('global.dialogs.upload.errors.invalidType').toString())
    }

    return result
  }

  get filenameErrors() {
    const result = Array<string>()

    if (!this.filename) {
      return result
    }

    if (/[./\\'"<>]/g.test(this.filename)) {
      result.push(this.$t('global.dialogs.upload.errors.invalidName').toString())
    }

    if (this.filename.length > this.maxFileNameSize) {
      const message = this.$t('global.dialogs.upload.errors.maxNameSize')
        .toString()
        .replace('{MAX}', this.maxFileNameSize.toString())
      result.push(message)
    }

    return result
  }

  get recipientErrors() {
    const result = Array<string>()

    if (!this.recipients) {
      return result
    }

    if (this.recipientValues.some((email) => !!email.trim() && !EMAIL_REGEX.test(email))) {
      result.push(this.$t('global.dialogs.upload.errors.invalidEmails').toString())
    }

    return result
  }

  get recipientValues() {
    return this.recipients.split(/[\s;,]+/).filter((x) => !!x)
  }

  get isUploadDataValid() {
    return !!this.file && !!this.filename && !this.fileErrors.length && !this.filenameErrors.length && !this.isLoading
  }

  get isSendDossierDataValid() {
    return !this.isLoading && !!this.selectedDocuments.length && !this.recipientErrors.length
  }

  get language() {
    return this.$i18n.locale
  }

  isDocumentSelected(file: IDocument) {
    return this.selectedDocuments.includes(file)
  }

  uploadFile() {
    // validates if there is a file and filename with no errors
    if (!this.isUploadDataValid) return

    this.isLoading = true

    this.$fileApi
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .uploadFile(`/realEstate/${this.realEstate?.id}/${encodeURIComponent(this.filename)}`, this.file!)
      .then(() => this.filesQuery?.refetch())
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4207',
          Message: error
        })
      })
      .finally(() => {
        this.isLoading = false
        this.file = null
      })
  }

  downloadFile(event: MouseEvent, file: string, name: string, type: UploadFileType) {
    event.stopPropagation()
    const fileName = `${name}_${this.typeTranslation(type)}`

    return this.$fileApi.downloadFile(
      `/realEstate/${this.realEstate?.id}/${encodeURIComponent(encodeURIComponent(file))}`,
      fileName
    )
  }

  deleteFile(event: MouseEvent, file: string) {
    event.stopPropagation()

    this.$showConfirmationDialog({
      Callback: () => {
        this.$hideConfirmationDialog()
        this.$apolloMutate<DeleteRealEstateFileMutation, DeleteRealEstateFileMutationVariables>({
          mutation: DeleteRealEstateFileDocument,
          variables: {
            realEstateId: this.realEstate?.id,
            filename: file
          },
          error: 'E4208',
          refetchQueries: [
            {
              query: GetRealEstateFilenamesDocument,
              variables: {
                realEstateId: this.realEstate?.id
              }
            }
          ]
        })
      },
      Code: 'C4023'
    })
  }

  sendDossier() {
    if (!this.isSendDossierDataValid) return

    if (!this.realEstate?.dossierSentDateUtc) {
      return this.callSendDossierMutation()
    }

    // if it's not the first time sending the dossier to this real estate show a warning message
    return this.$showConfirmationDialog({
      Code: 'C4025',
      Callback: () => {
        this.$hideConfirmationDialog()
        this.callSendDossierMutation()
      }
    })
  }

  callSendDossierMutation() {
    this.isLoading = true
    return this.$apolloMutate<SendDossierMutation, SendDossierMutationVariables>({
      mutation: SendDossierDocument,
      variables: {
        realEstateId: this.realEstate?.id,
        fileNames: this.selectedDocuments.map(
          (d) => ({ item1: d.path, item2: d.type } as TupleOfStringAndUploadFileTypeInput)
        ),
        emailRecipients: this.recipientValues
      },
      refetchQueries: [
        {
          query: ProjectRealEstatesDocument,
          variables: { projectId: this.$route.params?.projectId }
        }
      ],
      error: 'E4209'
    })
      .then(() => this.$emit('close'))
      .finally(() => (this.isLoading = false))
  }

  toLocaleDateTimeString(date?: string) {
    if (!date) return ''
    return toLocaleDateTimeString(date, this.language, false)
  }

  goToPage(type: UploadFileType) {
    switch (type) {
      case UploadFileType.TechnicalDrawing:
        window.open(`/project/${this.realEstate?.project?.id}/real-estate-order/${this.realEstate?.id}`, '_blank')
        break
      case UploadFileType.CustomOffer:
        window.open(`/custom-offer-request/${this.realEstate?.customOfferRequest?.id}`, '_blank')
        break
      case UploadFileType.Drawing3D:
      case UploadFileType.Manual:
      default:
        break
    }
  }

  typeTranslation(uploadFileType: UploadFileType) {
    return this.$t(`global.uploadFileTypes.${uploadFileType}`)
  }
}
