
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Cost } from '../roomSelection/types'
import { FileType } from '../customOfferRequest/OfferUpsertPopup.vue'
import { getDisplayPrice } from '@/utils/currencyUtils'
import { CostType } from '@/__generated__/globalTypes'
@Component
export default class CustomOfferSelection extends Vue {
  @Prop() customOfferId!: string
  @Prop() selectionName!: string
  @Prop() costs!: Cost[]

  isDownloadingOffer = false
  getDisplayPrice = getDisplayPrice

  get totalWithoutVat() {
    return this.costs.reduce((sum, cost) => {
      if ([CostType.TotalSurchargeCosts, CostType.VatCosts].includes(cost.type)) {
        return sum
      }

      return sum + (cost.amount || 0)
    }, 0)
  }

  get totalVat() {
    return this.totalWithoutVat * 0.21
  }

  get costDefs() {
    return [
      {
        text: this.$t('customerOrder.costItems.priceProductsAndMaterials').toString(),
        value: this.getCostAmount(CostType.ProductsAndInstallationMaterialsCosts)
      },
      {
        text: this.$t('customerOrder.costItems.priceReturnPrices').toString(),
        value: this.getCostAmount(CostType.LowerPriceStandardProductsCosts)
      },
      {
        text: this.$t('customerOrder.costItems.subTotal_productsAndMaterials_returnPrices').toString(),
        value: this.getCostsSum(
          CostType.ProductsAndInstallationMaterialsCosts,
          CostType.LowerPriceStandardProductsCosts
        ),
        isSubtotalOrTotal: true
      },

      {
        text: this.$t('customerOrder.costItems.priceCoordinationProvisionContractor').toString(),
        value: this.getCostAmount(CostType.CoordinationCommissionBuilderCosts)
      },
      {
        text: this.$t('customerOrder.costItems.priceCoordinationProvisionInstaller').toString(),
        value: this.getCostAmount(CostType.CoordinationCommissionInstallerCosts)
      },
      {
        text: this.$t(
          'customerOrder.costItems.subTotal_coordinationProvisionInstaller_coordinationProvisionContractor'
        ).toString(),
        value: this.getCostsSum(
          CostType.CoordinationCommissionBuilderCosts,
          CostType.CoordinationCommissionInstallerCosts
        ), // sub total of 2 previous prices
        isSubtotalOrTotal: true
      },

      {
        text: this.$t('customerOrder.costItems.priceConstructionCosts').toString(),
        value: this.getCostAmount(CostType.InstallationConstructionCosts)
      },
      {
        text: this.$t('customerOrder.costItems.priceWInstallation').toString(),
        value: this.getCostAmount(CostType.WInstallationCosts)
      },
      {
        text: this.$t('customerOrder.costItems.priceEInstallation').toString(),
        value: this.getCostAmount(CostType.EInstallationCosts)
      },
      {
        text: this.$t('customerOrder.costItems.priceCVInstallation').toString(),
        value: this.getCostAmount(CostType.CvInstallationCosts)
      },
      {
        text: this.$t(
          'customerOrder.costItems.subTotal_priceConstructionCosts_priceWInstallation_priceEInstallation_priceCVInstallation_priceTiler_priceKitter'
        ).toString(),
        value: this.getCostsSum(
          CostType.InstallationConstructionCosts,
          CostType.WInstallationCosts,
          CostType.EInstallationCosts,
          CostType.CvInstallationCosts
        ), // sub total for 4 previous prices
        isSubtotalOrTotal: true
      },

      {
        text: this.$t('customerOrder.costItems.totalExVat').toString(),
        value: this.totalWithoutVat,
        isSubtotalOrTotal: true
      },
      {
        text: this.$t('customerOrder.costItems.priceVat').toString(),
        value: this.totalVat,
        isSubtotalOrTotal: true
      },
      {
        text: this.$t('customerOrder.costItems.totalIncVat').toString(),
        value: this.totalWithoutVat + this.totalVat,
        isSubtotalOrTotal: true
      }
    ]
  }

  downloadOffer() {
    if (!this.customOfferId) return

    this.isDownloadingOffer = true
    this.$fileApi
      .downloadFile(`/customOffer/${this.customOfferId}/${FileType.OFFER}`, `${FileType.OFFER}_${this.selectionName}`)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4195',
          Message: error
        })
      })
      .finally(() => (this.isDownloadingOffer = false))
  }

  getCostAmount(type: CostType) {
    return this.costs.find((x) => x.type === type)?.amount ?? 0
  }

  getCostsSum(...types: CostType[]) {
    return types.reduce((sum, type) => {
      return sum + this.getCostAmount(type)
    }, 0)
  }
}
