
import { ProductSetInfoKeyDataType, ProductSetInfoKeyType } from '@/__generated__/globalTypes'
import { ProductSetInfoKeyModel, ProductSetInfoKeyWithOptional } from '@/components/productPackages/types'
import EnumField from '@/components/updateProductSetInfos/EnumField.vue'
import LengthMeasurementField from '@/components/updateProductSetInfos/LengthMeasurementField.vue'
import MultiEnumField from '@/components/updateProductSetInfos/MultiEnumField.vue'
import PercentageField from '@/components/updateProductSetInfos/PercentageField.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import AreaMeasurementField from './AreaMeasurementField.vue'
const defaultToMeter: ProductSetInfoKeyType[] = [
  ProductSetInfoKeyType.BathCasingLinear,
  ProductSetInfoKeyType.ShowerPartitionWallLinear,
  ProductSetInfoKeyType.ShowerEnclosureProfileLinear,
  ProductSetInfoKeyType.ToiletSurroundLinear
]

@Component({
  components: {
    LengthMeasurementField,
    AreaMeasurementField,
    PercentageField,
    EnumField,
    MultiEnumField
  }
})
export default class UpdateProductSetInfos extends Vue {
  @Prop() value!: ProductSetInfoKeyModel[]
  @Prop() applyRequiredValidation?: boolean
  @Prop() disabled?: boolean | null

  ProductSetInfoKeyDataType = ProductSetInfoKeyDataType
  ProductSetInfoKeyType = ProductSetInfoKeyType

  rules = {
    allowAnything: () => {
      return true
    },
    required: (value: string) => {
      return (
        !!value?.toString()?.length || this.$t('productPackage.edit.category.productSets.upsert.validations.required')
      )
    },
    numeric: (value: string) => {
      return (
        !value?.toString()?.length ||
        (Number.isInteger(Number(value)) && Number(value) >= 0) ||
        this.$t('productPackage.edit.category.productSets.upsert.validations.numeric')
      )
    },
    decimal: (value: string) => {
      // Can contain numbers, commas and dots
      const regex = /^[0-9]{1,9}([,.][0-9]{1,2})?$/
      return (
        !value?.toString()?.length ||
        regex.test(value) ||
        this.$t('productPackage.edit.category.productSets.upsert.validations.decimal')
      )
    },
    percentage: (value: string) => {
      // Can contain percentages between 0 and 100
      const valNumber = Number(value?.replace(',', '.'))
      return (
        !value?.toString()?.length ||
        (valNumber >= 0 && valNumber <= 100) ||
        this.$t('productPackage.edit.category.productSets.upsert.validations.percentage')
      )
    },
    decimalPlaces2: (value: string) => {
      // Can contain percentages between 0 and 100
      const regex = /^[0-9]*([,.][0-9]{1,2})?$/
      return (
        !value?.toString()?.length ||
        regex.test(value) ||
        this.$t('productPackage.edit.category.productSets.upsert.validations.decimalPlaces2')
      )
    },
    color: (value: string) => {
      const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
      return (
        !value?.toString()?.length ||
        regex.test(value) ||
        this.$t('productPackage.edit.category.productSets.upsert.validations.color')
      )
    }
  }

  get selectInputBoolValues() {
    return [
      { text: this.$t('global.yes'), value: '1' },
      { text: this.$t('global.no'), value: '0' }
    ]
  }

  get requiredProductSetInfoKeys() {
    return this.value?.filter((x) => x.key !== ProductSetInfoKeyType.Other) ?? []
  }

  get optionalProductSetInfoKeys() {
    return this.value?.filter((x) => x.key === ProductSetInfoKeyType.Other) ?? []
  }

  requiredIfNeeded(item: ProductSetInfoKeyWithOptional) {
    return !!this.applyRequiredValidation && !item.isOptional ? [this.rules.required] : [this.rules.allowAnything]
  }

  getDefaultLengthMeasure(dataKey: ProductSetInfoKeyType) {
    if (defaultToMeter.includes(dataKey)) return 'm'
  }
}
