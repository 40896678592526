
import { validationMixin } from 'vuelidate'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  mixins: [validationMixin]
})
export default class CreateNotificationDialogComponent extends Vue {
  code = ''
  message = ''
  sub = ''

  createDialog() {
    this.$store.dispatch('showNotificationDialog', {
      Code: this.code,
      Message: this.message,
      Sub: this.sub,
    })
  }
}
