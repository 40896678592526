/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeleteProductSetBrandLineMutationVariables = Types.Exact<{
  productSetBrandlineId: Types.Scalars['UUID'];
}>;


export type DeleteProductSetBrandLineMutation = { deleteProductSetBrandline: boolean };


export const DeleteProductSetBrandLineDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"deleteProductSetBrandLine"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"productSetBrandlineId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteProductSetBrandline"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"productSetBrandlineId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"productSetBrandlineId"}}}]}]}}]} as unknown as DocumentNode<DeleteProductSetBrandLineMutation, DeleteProductSetBrandLineMutationVariables>;