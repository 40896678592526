
import { ProductSetInfoKeytranslationsFragment } from '@/views/categoriesView/gql/__generated__/CategoriesView.query'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import {
  AddCustomProductSetInfoKeyDocument,
  AddCustomProductSetInfoKeyMutation,
  AddCustomProductSetInfoKeyMutationVariables
} from './gql/__generated__/addCustomProductSetInfoKey.mutation'

@Component({
  mixins: [validationMixin]
})
export default class ProductSetInfoKeyModal extends Vue {
  @Prop({ required: true, default: false }) show!: boolean
  @Prop({ default: () => [] }) knownProductSetInfoKeys!: ProductSetInfoKeytranslationsFragment[]

  //Structure to save
  labelNL = ''
  labelEN = ''

  isLoading = false

  get existingLabelsNL() {
    return this.knownProductSetInfoKeys.map((x) => x.infoLabelNL)
  }

  get existingLabelsEN() {
    return this.knownProductSetInfoKeys.map((x) => x.infoLabelEN)
  }

  validations() {
    return {
      labelNL: {
        required,
        isUnique: (value: string) => !this.existingLabelsNL.some((x) => x.toLowerCase() === value.toLowerCase())
      },
      labelEN: {
        required,
        isUnique: (value: string) => !this.existingLabelsEN.some((x) => x.toLowerCase() === value.toLowerCase())
      }
    }
  }

  get labelNLErrors() {
    const errors = new Array<string>()
    if (!this.$v.labelNL.$dirty) {
      return errors
    }
    if (!this.$v.labelNL.isUnique) {
      errors.push(this.$t('application.categories.list.upsert.infoKeys.add.errors.label.isUnique').toString())
    }
    if (!this.$v.labelNL.required) {
      errors.push(this.$t('application.categories.list.upsert.infoKeys.add.errors.label.required').toString())
    }
    return errors
  }

  get labelENErrors() {
    const errors = new Array<string>()
    if (!this.$v.labelEN.$dirty) {
      return errors
    }
    if (!this.$v.labelEN.isUnique) {
      errors.push(this.$t('application.categories.list.upsert.infoKeys.add.errors.label.isUnique').toString())
    }
    if (!this.$v.labelEN.required) {
      errors.push(this.$t('application.categories.list.upsert.infoKeys.add.errors.label.required').toString())
    }
    return errors
  }

  async createInfoKey() {
    this.$v.$touch()
    if (this.$v.labelNL.$pending || this.$v.labelNL.$error) return
    if (this.$v.labelEN.$pending || this.$v.labelEN.$error) return

    try {
      this.isLoading = true
      const result = await this.$apolloMutate<
        AddCustomProductSetInfoKeyMutation,
        AddCustomProductSetInfoKeyMutationVariables
      >({
        mutation: AddCustomProductSetInfoKeyDocument,
        variables: { dutchLabel: this.labelNL, englishLabel: this.labelEN },
        error: 'E4204'
      })

      if (result !== false) {
        this.$emit('info-key-saved', {
          infoKeyId: result.data?.addCustomProductSetInfoKey,
          labelNL: this.labelNL,
          labelEN: this.labelEN
        })

        this.labelNL = ''
        this.labelEN = ''
        this.$v.$reset()
      }
    } finally {
      this.isLoading = false
    }
  }
}
