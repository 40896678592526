import jwtDecode from 'jwt-decode'

export type FromIndex = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [index: string]: any
}

export function GetClaimForToken<T>(token: string, claimName: string): T | null {
  if (token) {
    const decodedToken = jwtDecode(token) as FromIndex
    return decodedToken[claimName] as T
  }
  return null
}

export default {
  GetClaimForToken  
}

export type Token = {
  aud: string | null
  contact: string | null
  email: string | null
  exp: number | null
  iat: number | null
  iss: string | null
  nameid: string | null
  nbf: number | null
  realestate: string | null
  role: string | null
  unique_name: string | null  
}