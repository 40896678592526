
import { Component, Vue } from 'vue-property-decorator'
import CreateConfirmationDialogComponent from '@/components/tools/CreateConfirmationDialog.vue'
import CreateErrorDialogComponent from '@/components/tools/CreateErrorDialog.vue'
import CreateNotificationDialogComponent from '@/components/tools/CreateNotificationDialog.vue'
import SendNotification from '@/components/tools/sendNotification/SendNotification.vue'

@Component({
  name: 'ToolsComponent',
  components: {
    CreateConfirmationDialogComponent,
    CreateErrorDialogComponent,
    CreateNotificationDialogComponent,
    SendNotification
  }
})
export default class ToolsComponent extends Vue {
  title = ''
  message = ''
  close = ''

  get appUrl() {
    return process.env.VUE_APP_APP_URL
  }

  get coreUrl() {
    return process.env.VUE_APP_CORE_URL + '/graphql'
  }

  get gatewayUrl() {
    return process.env.VUE_APP_GATEWAY_URL + '/graphql'
  }

  get cmsUrl() {
    return process.env.VUE_APP_CMS_URL
  }

  copyToken() {
    if (this.$store.state.oidcStore.access_token) {
      const token =
        '{ "Authorization": "bearer ' + this.$store.state.oidcStore.access_token + '" }'
      navigator.clipboard.writeText(token)
    }
  }
}
