/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SyncWithEstimateMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SyncWithEstimateMutation = { syncWithEstimate: boolean };


export const SyncWithEstimateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SyncWithEstimate"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"syncWithEstimate"}}]}}]} as unknown as DocumentNode<SyncWithEstimateMutation, SyncWithEstimateMutationVariables>;