var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"c-style-listing"},[_c('v-row',{attrs:{"justify":"space-between","no-gutters":""}},[_c('h1',{attrs:{"data-cy":"style-list-title"}},[_vm._v(_vm._s(_vm.$t('style.list.title')))]),_c('div',[_c('v-btn',{staticClass:"text-none",attrs:{"disabled":_vm.isReadonly,"to":{
          name: _vm.routeNames.PRODUCT_PACKAGE_IMPORTS,
          params: { propositionType: _vm.Badkamercore_ProjectType.DeBadkamerwerelden }
        },"data-cy":"view-style-imports-btn","dark":"","depressed":"","rounded":""}},[_vm._v(_vm._s(_vm.$t('style.list.viewImports')))]),_c('v-btn',{staticClass:"text-none ml-2",attrs:{"disabled":_vm.isReadonly,"data-cy":"add-style-btn","dark":"","depressed":"","rounded":""},on:{"click":_vm.openForm}},[_vm._v(_vm._s(_vm.$t('style.list.addStyle')))])],1)]),_c('v-divider',{staticClass:"my-6"}),(_vm.$apollo.queries.StylesViewQuery && _vm.$apollo.queries.StylesViewQuery.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"tile":true,"type":"table-thead, table-tbody, table-tfoot"}}):_c('div',[(_vm.styles)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.styles,"sort-by":['productPackage.isMain', 'name'],"sort-desc":"","data-cy":"style-list-table","hide-default-header":""},scopedSlots:_vm._u([{key:"item.productPackage.isMain",fn:function({ item }){return [_c('v-tooltip',{attrs:{"right":"","maxWidth":"500","color":"rgba(0,0,0,0.9)"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"data-cy":"ismain-icon","color":item.productPackage?.isMain ? 'blue lighten-1' : 'grey lighten-2'}},item.productPackage?.isMain ? on : undefined),[_vm._v("mdi-archive-star-outline")])]}}],null,true)},[_c('span',{},[_vm._v(_vm._s(_vm.$t('productPackage.list.isMainTooltip')))])])]}},{key:"item.name",fn:function({ item }){return [_c('b',{attrs:{"data-cy":"style-list-table-name"}},[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function({ item }){return [(item?.productPackageId)?_c('v-btn',{staticClass:"text-none",attrs:{"disabled":_vm.isReadonly,"to":{
            name: _vm.routeNames.PRODUCT_PACKAGE,
            params: {
              propositionType: _vm.Badkamercore_ProjectType.DeBadkamerwerelden,
              productPackageId: item.productPackageId
            }
          },"data-cy":"style-list-table-edit-btn","light":"","rounded":"","small":""}},[_vm._v(_vm._s(_vm.$t('style.list.edit')))]):_vm._e(),(item?.productPackageId)?_c('v-btn',{staticClass:"text-none ml-2",attrs:{"disabled":_vm.isReadonly,"data-cy":"style-list-table-copy-btn","light":"","rounded":"","small":""},on:{"click":function($event){return _vm.openStyleCopyModal(item)}}},[_vm._v(_vm._s(_vm.$t('style.list.copy')))]):_vm._e(),_c('v-btn',{staticClass:"text-none ml-2",attrs:{"disabled":_vm.isReadonly,"data-cy":"style-list-table-edit-content-btn","light":"","rounded":"","small":"","target":"_blank"},on:{"click":() => _vm.navigateToCms(item)}},[_vm._v(_vm._s(_vm.$t('style.list.editContent')))]),_c('v-btn',{staticClass:"text-none ml-2",attrs:{"disabled":_vm.isReadonly || !!item.productPackage?.isMain,"data-cy":"style-list-table-remove-btn","light":"","rounded":"","small":""},on:{"click":() => _vm.confirmDeleteStyle(item)}},[_vm._v(_vm._s(_vm.$t('global.remove')))])]}}],null,false,3115481299)}):_vm._e()],1),_c('add-style-dialog',{attrs:{"existingStyles":_vm.styleNames,"show":_vm.showForm},on:{"close":_vm.closeForm}}),_c('copy-style-dialog',{attrs:{"existingStyleNames":_vm.styleNames,"styleId":_vm.styleToCopyId,"styleName":_vm.styleToCopyName,"show":_vm.showStyleCopyModal},on:{"close":function($event){_vm.showStyleCopyModal = false},"refetch-styles-list-query":function($event){return _vm.$apollo.queries.StylesViewQuery.refetch()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }