/* eslint-disable */
import type * as Types from '../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateActivityTemplateMutationVariables = Types.Exact<{
  activityTemplateName: Types.Scalars['String'];
}>;


export type CreateActivityTemplateMutation = { createActivityTemplate: { name: string } };


export const CreateActivityTemplateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createActivityTemplate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"activityTemplateName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createActivityTemplate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"activityTemplateName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"activityTemplateName"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<CreateActivityTemplateMutation, CreateActivityTemplateMutationVariables>;