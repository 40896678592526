/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SendInvitationToRealEstatesMutationVariables = Types.Exact<{
  realEstateIds: Array<Types.Scalars['UUID']> | Types.Scalars['UUID'];
}>;


export type SendInvitationToRealEstatesMutation = { sendInvitationToRealEstates: boolean };


export const SendInvitationToRealEstatesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"sendInvitationToRealEstates"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"realEstateIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendInvitationToRealEstates"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"realEstateIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"realEstateIds"}}}]}]}}]} as unknown as DocumentNode<SendInvitationToRealEstatesMutation, SendInvitationToRealEstatesMutationVariables>;