/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeleteCustomOfferRequestAppointmentMutationVariables = Types.Exact<{
  customOfferRequestId: Types.Scalars['UUID'];
  customOfferRequestAppointmentId: Types.Scalars['UUID'];
}>;


export type DeleteCustomOfferRequestAppointmentMutation = { deleteCustomOfferRequestAppointment: boolean };


export const DeleteCustomOfferRequestAppointmentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteCustomOfferRequestAppointment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"customOfferRequestId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"customOfferRequestAppointmentId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteCustomOfferRequestAppointment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"customOfferRequestId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"customOfferRequestId"}}},{"kind":"Argument","name":{"kind":"Name","value":"customOfferRequestAppointmentId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"customOfferRequestAppointmentId"}}}]}]}}]} as unknown as DocumentNode<DeleteCustomOfferRequestAppointmentMutation, DeleteCustomOfferRequestAppointmentMutationVariables>;