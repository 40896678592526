
import CustomOfferSelection from '@/components/customOfferSelection/customOfferSelection.vue'
import RoomSelection from '@/components/roomSelection/RoomSelection.vue'
import { CombinationWithProductSets, Cost, Product, ProductSet } from '@/components/roomSelection/types'
import QUERIES from '@/queries/queries'
import { getDisplayPrice } from '@/utils/currencyUtils'
import { toLocaleDateTimeString, toLocaleDateString } from '@/utils/dateUtils'
import { FileType } from '@/components/customOfferRequest/OfferUpsertPopup.vue'
import {
  CostType,
  OrderCustomOffer,
  ProductSetDetails,
  RealEstate,
  RealEstateOrder,
  SelectionType,
  ProjectActivityTarget,
  CostEntry,
  Maybe
} from '@/__generated__/globalTypes'
import { Component, Vue } from 'vue-property-decorator'
import {
  ProductPackageSetFragment,
  ProductSetDetailsDocument,
  ProductSetDetailsQuery,
  ProductSetDetailsQueryVariables
} from '../drawingRequestDetailsView/gql/__generated__/getProductSetDetails.query'
import {
  RealEstateOrderDocument,
  RealEstateOrderQuery,
  RealEstateOrderQueryVariables
} from './gql/__generated__/getRealEstateOrder.query'
import {
  OrderCategoryDetailsDocument,
  OrderCategoryDetailsQuery,
  OrderCategoryDetailsQueryVariables,
  OrderCategoryFragment
} from './gql/__generated__/orderCategoryDetails.query'
import { GetLocalizedContent } from '@/utils/translationUtils'

type RoomWithCombinationsWithSelectedProductSets = {
  roomId: string
  roomName: string
  selectionName: string
  selectionType: SelectionType
  customOfferId: string
  combinations: CombinationWithProductSets[]
  costs: Cost[]
}

type DisplayCost = {
  displayText: string
  amount: number
}

@Component({
  components: {
    RoomSelection,
    CustomOfferSelection
  }
})
export default class RealEstateOrderView extends Vue {
  realEstateOrderData: RealEstateOrder | null = null
  realEstateData: RealEstate | null = null
  productSetsCmsData: ProductSetDetails[] = []
  productSetsWithInfoKeys: ProductPackageSetFragment[] = []
  globalCustomOffer: OrderCustomOffer | null = null
  categoriesData: OrderCategoryDetailsQuery | null = null

  currentRoomTab = null

  isDownloadingOffer = false
  isDownloadingDrawing = false

  getDisplayPrice = getDisplayPrice
  selectionType = SelectionType
  CostType = CostType

  created() {
    this.$apollo.addSmartQuery<RealEstateOrderQuery, RealEstateOrderQueryVariables>(QUERIES.RealEstateOrderQuery, {
      query: RealEstateOrderDocument,
      variables: () => ({
        realEstateId: this.$route.params.realEstateId
      }),
      update: (data) => data,
      result: (result) => {
        this.realEstateOrderData = (result.data?.realEstateOrder as RealEstateOrder) ?? null
        this.realEstateData = (result.data?.realEstate as RealEstate) ?? null
        this.globalCustomOffer = (result.data?.realEstateOrder?.globalCustomOffer as OrderCustomOffer) ?? null
      },
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4187',
          Message: error
        })
      }
    })
    this.$apollo.addSmartQuery<ProductSetDetailsQuery, ProductSetDetailsQueryVariables>(QUERIES.ProductSetDetails, {
      query: ProductSetDetailsDocument,
      variables: (): ProductSetDetailsQueryVariables => ({
        productSetIds: this.productSetIds,
        stringyfiedProductSetIds: this.productSetIds,
        productPackageId: this.realEstateOrderData?.rooms?.[0]?.productPackageId ?? '',
        currentLang: this.currentLang
      }),
      update: (data) => data,
      result: (result) => {
        this.productSetsCmsData = (result.data.productSetDetails ?? []) as ProductSetDetails[]
        this.productSetsWithInfoKeys = result.data.productPackage?.assignedProductSets ?? []
      },
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4179',
          Message: error
        })
      },
      skip: () =>
        !this.realEstateOrderData ||
        !!this.realEstateOrderData?.globalCustomOffer ||
        !this.realEstateOrderData?.rooms?.[0]?.productPackageId
    }),
      this.$apollo.addSmartQuery<OrderCategoryDetailsQuery, OrderCategoryDetailsQueryVariables>(
        QUERIES.OrderCategoryDetails,
        {
          query: OrderCategoryDetailsDocument,
          variables: () => ({
            defaultLang: 'nl',
            currentLang: this.currentLang,
            productCategoryIds: this.categoryIds,
            productCategoryIdsStringified: this.categoryIds,
            designPackageIds: this.designPackageIds
          }),
          update: (data) => data,
          result: (result) => {
            this.categoriesData = result.data
          },
          error: (error) => {
            this.$store.dispatch('showErrorDialog', {
              Code: 'E4241',
              Message: error
            })
          },
          skip: () => !this.categoryIds?.length
        }
      )
  }

  get productSets() {
    return (
      this.realEstateOrderData?.rooms.flatMap((r) => r.productCombinationSets?.flatMap((c) => c.selectedProductSets)) ??
      []
    )
  }

  get productSetIds(): string[] {
    return this.productSets.map((x) => x?.productSetId)
  }

  get categoryIds(): string[] {
    return [...new Set(this.productSets.map((x) => x?.productCategoryId))]
  }

  get designPackageIds(): string[] {
    return [...new Set(this.realEstateOrderData?.rooms?.map((x) => x.designPackageId)?.filter((x) => !!x))]
  }

  get currentLang() {
    return this.$i18n.locale
  }

  get roomsWithCombinationsWithSelectedProductSets(): RoomWithCombinationsWithSelectedProductSets[] {
    const rooms: RoomWithCombinationsWithSelectedProductSets[] =
      // Rooms
      this.realEstateOrderData?.rooms?.map((room) => {
        const walls = this.categoriesData?.designPackages?.find((dp) => dp.id === room.designPackageId)?.assignedWalls
        return {
          roomId: room.roomId.toString(),
          roomName: room.roomName,
          selectionName:
            room.selectionName +
            (room.selectionType === SelectionType.Custom && room.customOfferVersion
              ? ` - v${room.customOfferVersion}`
              : ''),
          selectionType: room.selectionType,
          customOfferId: room.customOfferId,
          // Combinations
          combinations:
            room.productCombinationSets?.map(
              (combination) =>
                ({
                  id: combination.productCombinationId.toString(),
                  name:
                    this.$t(`global.combinations.${combination.productCombinationType.toLowerCase()}`).toString() ??
                    combination.productCombinationType.toString(),
                  combinationType: combination.productCombinationType,
                  // ProductSets
                  productSets:
                    combination.selectedProductSets
                      .reduce((list: ProductSet[], ps) => {
                        // If this product set has a fallback, then we skip it. The m2, m1 and price of this product set should already be merged with the
                        // "original" product set (product set with the same id as this fallback id), meaning that we can skip this one.
                        const isToCeilingYes = ps.isToTheCeiling && ps.answerYesNo === '1'
                        if (
                          (ps.fallBackProductId && !ps.isToTheCeiling) ||
                          (ps.isToTheCeiling && ps.answerYesNo === '0')
                        )
                          return list

                        // If this is "To the ceiling Yes", we need to show the wall tile (fallback product set), otherwise just grab the current product set id
                        const cmsItem = this.productSetsCmsData.find(
                          (p) => p.alias?.alias === (isToCeilingYes ? ps.fallBackProductId : ps.productSetId)
                        )
                        const category = this.categoriesData?.productCategories?.find(
                          (c) => c.id === ps.productCategoryId
                        )
                        const categoryCms = GetLocalizedContent<OrderCategoryFragment>(
                          this.categoriesData?.categoriesCms?.find((c) => c?.alias?.alias === ps.productCategoryId)
                        )
                        const wallName = ps.wallId ? walls?.find((w) => w?.id === ps.wallId)?.name : ''
                        const fallbackProductSet = combination.selectedProductSets.find(
                          (f) => f.productSetId === ps.fallBackProductId
                        )

                        // For the m2, m1 and costs we need the values of this product set and all the product sets that have the same id set as the fallBackProductSetId
                        // Except the wall tile to ceiling Yes. That needs to be a separate card. I know... Unnecessarily complex...
                        const productSetsToMerge =
                          combination.selectedProductSets.filter(
                            (m) =>
                              m.fallBackProductId === ps.productSetId && !(m.isToTheCeiling && m.answerYesNo === '1')
                          ) ?? []
                        let mergedSquareMeters = ps.area
                        let mergedLinearMeters = ps.length
                        const mergedCosts = this.mergeCosts(
                          ps.costs,
                          productSetsToMerge.flatMap((m) => m.costs)
                        )

                        productSetsToMerge.forEach((m) => {
                          mergedSquareMeters += m.area
                          mergedLinearMeters += m.length
                        })

                        const productSetToShow = isToCeilingYes ? fallbackProductSet : ps
                        if (!productSetToShow) return list

                        const productSet = {
                          id: productSetToShow.productSetId?.toString(),
                          name: productSetToShow.name,
                          description: cmsItem?.shortDescriptionHTML?.html ?? '',
                          imagePath: cmsItem?.thumbnail?.urls?.[0] ?? cmsItem?.images?.urls?.[0] ?? '',
                          // Costs
                          costs: mergedCosts,
                          // Products/articles
                          assignedProductSetProducts: productSetToShow.articles?.reduce(
                            (articleList: Product[], article) => {
                              if (!article) return articleList

                              const duplicate = articleList.find((pl) => pl.code === article.code)

                              if (duplicate) {
                                // If it's a duplicate, update the values (based on price type)
                                switch (article.priceType) {
                                  case ProjectActivityTarget.Meters:
                                    if (mergedLinearMeters)
                                      duplicate.length = (duplicate.length ?? 0) + mergedLinearMeters
                                    break

                                  case ProjectActivityTarget.SquareMeters:
                                    if (mergedSquareMeters) duplicate.area = (duplicate.area ?? 0) + mergedSquareMeters
                                    break

                                  case ProjectActivityTarget.ProductSet:
                                    duplicate.quantity = (duplicate.quantity ?? 0) + 1 + productSetsToMerge.length
                                    break

                                  default:
                                    break
                                }

                                // Also update the price
                                duplicate.price = (duplicate.price ?? 0) + (parseFloat(article.price.amount) ?? 0)

                                return articleList
                              }

                              return [
                                ...articleList,
                                {
                                  id: article.articleId,
                                  description: article.name ?? '',
                                  code: article.code,
                                  thumbnailUrl: article.thumbnailUrl ?? '',
                                  priceType: article.priceType,
                                  area:
                                    article.priceType === ProjectActivityTarget.SquareMeters
                                      ? mergedSquareMeters ?? 0
                                      : undefined,
                                  length:
                                    article.priceType === ProjectActivityTarget.Meters
                                      ? mergedLinearMeters ?? 0
                                      : undefined,
                                  quantity: 1 + productSetsToMerge.length,
                                  price: article.price.amount ? parseFloat(article.price.amount) : null
                                }
                              ]
                            },
                            []
                          ),
                          productSetInfos:
                            this.productSetsWithInfoKeys
                              ?.find((x) => x.productSet.id === productSetToShow.productSetId)
                              ?.displayableProductSetInfos?.map((y) => ({
                                label: y?.label ?? '',
                                value: y?.localizedValue?.join(', ') ?? ''
                              })) ?? [],
                          position: category?.position,
                          categoryName: (wallName ? `${categoryCms?.name} ${wallName}` : categoryCms?.name) ?? ''
                        }
                        list.push(productSet)

                        return list
                      }, [])
                      ?.sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0)) ?? [],
                  returnedProductSets:
                    combination?.returnedProductSets.map((x) => ({
                      id: x?.productSetId,
                      name: x?.name,
                      costs: x?.costs.map((costs) => ({
                        amount: costs?.cost?.amount ?? 0,
                        type: costs?.type as CostType
                      })),
                      articles: x?.articles?.map((article) => ({
                        id: article?.articleId,
                        name: article?.name ?? '',
                        code: article?.code,
                        price: article.price.amount ? parseFloat(article.price.amount) : null
                      }))
                    })) ?? []
                } ?? [])
            ) ?? [],
          costs: room.costs.map((cost) => ({
            amount: cost?.cost.amount ?? 0,
            type: cost?.type as CostType
          }))
        }
      }) ?? []
    return rooms
  }

  get buildNumber() {
    return this.realEstateOrderData?.buildNumber ?? ''
  }

  get projectName() {
    return this.realEstateOrderData?.projectName ?? ''
  }

  get layout() {
    return this.realEstateData?.layout?.name ?? ''
  }

  get contactEmail() {
    return this.realEstateData?.contact?.email.address ?? ''
  }

  get lastLogin() {
    const date = toLocaleDateTimeString(this.realEstateData?.contact?.lastLogin, this.currentLang)
    return date ? date.charAt(0).toUpperCase() + date.slice(1) : ''
  }

  get requestedDate() {
    const date = toLocaleDateTimeString(this.realEstateOrderData?.createdDate, this.currentLang)
    return date ? date.charAt(0).toUpperCase() + date.slice(1) : ''
  }

  get customOfferDate() {
    const date = toLocaleDateString(this.realEstateOrderData?.createdDate, this.currentLang)
    return date
  }

  // Getters for all the prices and sub totals
  get priceProductsAndMaterials(): DisplayCost {
    return {
      amount: this.getSummedCostItems([CostType.ProductsAndInstallationMaterialsCosts]),
      displayText: this.$t('customerOrder.costItems.priceProductsAndMaterials').toString() ?? ''
    }
  }
  get priceTilesAndFasteners(): DisplayCost {
    return {
      amount: this.getSummedCostItems([CostType.TilesAndFastenersCosts]),
      displayText: this.$t('customerOrder.costItems.priceTilesAndFasteners').toString() ?? ''
    }
  }

  get priceServices(): DisplayCost {
    return {
      amount: this.getSummedCostItems([CostType.DrawingCosts, CostType.CustomOfferCosts]),
      displayText: this.$t('customerOrder.costItems.priceServices').toString() ?? ''
    }
  }

  get priceReturnPrices(): DisplayCost {
    return {
      amount: this.getSummedCostItems([CostType.LowerPriceStandardProductsCosts]),
      displayText: this.$t('customerOrder.costItems.priceReturnPrices').toString() ?? ''
    }
  }

  get subTotal_productsAndMaterials_services_returnPrices(): DisplayCost {
    return {
      amount: [
        this.priceProductsAndMaterials,
        this.priceTilesAndFasteners,
        this.priceServices,
        this.priceReturnPrices
      ].reduce((acc, cost) => acc + cost.amount, 0),
      displayText:
        this.$t('customerOrder.costItems.subTotal_productsAndMaterials_services_returnPrices').toString() ?? ''
    }
  }

  get priceCoordinationProvisionInstaller(): DisplayCost {
    return {
      amount: this.getSummedCostItems([CostType.CoordinationCommissionInstallerCosts]),
      displayText: this.$t('customerOrder.costItems.priceCoordinationProvisionInstaller').toString() ?? ''
    }
  }

  get priceCoordinationProvisionContractor(): DisplayCost {
    return {
      amount: this.getSummedCostItems([CostType.CoordinationCommissionBuilderCosts]),
      displayText: this.$t('customerOrder.costItems.priceCoordinationProvisionContractor').toString() ?? ''
    }
  }

  get subTotal_coordinationProvisionInstaller_coordinationProvisionContractor(): DisplayCost {
    return {
      amount: [this.priceCoordinationProvisionInstaller, this.priceCoordinationProvisionContractor].reduce(
        (acc, cost) => acc + cost.amount,
        0
      ),
      displayText:
        this.$t(
          'customerOrder.costItems.subTotal_coordinationProvisionInstaller_coordinationProvisionContractor'
        ).toString() ?? ''
    }
  }

  get priceConstructionCosts(): DisplayCost {
    return {
      amount: this.getSummedCostItems([CostType.InstallationConstructionCosts]),
      displayText: this.$t('customerOrder.costItems.priceConstructionCosts').toString() ?? ''
    }
  }

  get priceWInstallation(): DisplayCost {
    return {
      amount: this.getSummedCostItems([CostType.WInstallationCosts]),
      displayText: this.$t('customerOrder.costItems.priceWInstallation').toString() ?? ''
    }
  }

  get priceEInstallation(): DisplayCost {
    return {
      amount: this.getSummedCostItems([CostType.EInstallationCosts]),
      displayText: this.$t('customerOrder.costItems.priceEInstallation').toString() ?? ''
    }
  }

  get priceCVInstallation(): DisplayCost {
    return {
      amount: this.getSummedCostItems([CostType.CvInstallationCosts]),
      displayText: this.$t('customerOrder.costItems.priceCVInstallation').toString() ?? ''
    }
  }

  get priceTiler(): DisplayCost {
    return {
      amount: this.getSummedCostItems([CostType.TilerInstallationCosts]),
      displayText: this.$t('customerOrder.costItems.priceTiler').toString() ?? ''
    }
  }

  get priceKitter(): DisplayCost {
    return {
      amount: this.getSummedCostItems([CostType.KitterInstallationCosts]),
      displayText: this.$t('customerOrder.costItems.priceKitter').toString() ?? ''
    }
  }

  get subTotal_priceConstructionCosts_priceWInstallation_priceEInstallation_priceCVInstallation_priceTiler_priceKitter(): DisplayCost {
    return {
      amount: [
        this.priceConstructionCosts,
        this.priceWInstallation,
        this.priceEInstallation,
        this.priceCVInstallation,
        this.priceTiler,
        this.priceKitter
      ].reduce((acc, cost) => acc + cost.amount, 0),
      displayText:
        this.$t(
          'customerOrder.costItems.subTotal_priceConstructionCosts_priceWInstallation_priceEInstallation_priceCVInstallation_priceTiler_priceKitter'
        ).toString() ?? ''
    }
  }

  get totalExVat(): DisplayCost {
    return {
      amount: [
        this.subTotal_productsAndMaterials_services_returnPrices,
        this.subTotal_coordinationProvisionInstaller_coordinationProvisionContractor,
        this
          .subTotal_priceConstructionCosts_priceWInstallation_priceEInstallation_priceCVInstallation_priceTiler_priceKitter
      ].reduce((acc, cost) => acc + cost.amount, 0),
      displayText: this.$t('customerOrder.costItems.totalExVat').toString() ?? ''
    }
  }

  get priceVat(): DisplayCost {
    return {
      amount: this.getSummedCostItems([CostType.VatCosts]),
      displayText: this.$t('customerOrder.costItems.priceVat').toString() ?? ''
    }
  }

  get totalIncVat(): DisplayCost {
    return {
      amount: this.getSummedCostItems([CostType.TotalSurchargeCosts]),
      displayText: this.$t('customerOrder.costItems.totalIncVat').toString() ?? ''
    }
  }

  get customOfferServiceCost() {
    return this.getSummedCostItems([CostType.CustomOfferCosts])
  }

  getSummedCostItems(costTypes: CostType[]): number {
    const summedCosts = this.realEstateOrderData?.costs?.filter((cost) =>
      costTypes?.includes((cost?.type as CostType) ?? null)
    )
    return summedCosts?.reduce((acc, cost) => acc + cost?.cost.amount, 0) ?? 0
  }

  getRoomSelectionName(room: RoomWithCombinationsWithSelectedProductSets): string {
    let roomSelectionName: string = room.selectionName
    if (room.selectionType == SelectionType.Standard) {
      roomSelectionName = this.$t(`global.selectionTypesDefaultSelectionName.${room.selectionType}`).toString()
    }

    return roomSelectionName ? `${roomSelectionName},` : ''
  }

  downloadOffer() {
    if (!this.globalCustomOffer?.customOfferId) return

    this.isDownloadingOffer = true
    this.$fileApi
      .downloadFile(
        `/customOffer/${this.globalCustomOffer?.customOfferId}/${FileType.OFFER}`,
        `${FileType.OFFER}_${this.globalCustomOffer?.selectionName}`
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4195',
          Message: error
        })
      })
      .finally(() => (this.isDownloadingOffer = false))
  }

  downloadDrawing() {
    if (!this.globalCustomOffer?.customOfferId) return

    this.isDownloadingDrawing = true
    this.$fileApi
      .downloadFile(
        `/customOffer/${this.globalCustomOffer?.customOfferId}/${FileType.DRAWING}`,
        `${FileType.DRAWING}_${this.globalCustomOffer?.selectionName}`
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4195',
          Message: error
        })
      })
      .finally(() => (this.isDownloadingDrawing = false))
  }

  mergeCosts(productSetCosts: Maybe<CostEntry>[], toMergeCosts: Maybe<CostEntry>[]) {
    const costTypes = [...new Set([...productSetCosts.map((x) => x?.type), ...toMergeCosts.map((x) => x?.type)])]
    const allCosts = [...productSetCosts, ...toMergeCosts]
    return costTypes.reduce((list: { amount: any; type: CostType }[], current) => {
      const totalAmount = allCosts
        .filter((x) => x?.type === current)
        .reduce((sum, currentVal) => (sum += currentVal?.cost?.amount ?? 0), 0)
      list.push({
        type: current!,
        amount: totalAmount
      })
      return list
    }, [])
  }
}
