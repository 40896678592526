/* eslint-disable */
import type * as Types from '../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type MasterProductPackageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MasterProductPackageQuery = { masterProductPackage?: { id: any, name: string } | null };


export const MasterProductPackageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MasterProductPackage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"masterProductPackage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<MasterProductPackageQuery, MasterProductPackageQueryVariables>;