/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RensaUsersQueryVariables = Types.Exact<{
  searchTerm: Types.Scalars['String'];
  filterType: Types.UsersGroupFilterType;
}>;


export type RensaUsersQuery = { rensaUsers: Array<{ userId?: string | null, displayName?: string | null }> };

export type RensaUsersFragment = { userId?: string | null, displayName?: string | null };

export const RensaUsersFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"rensaUsers"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ADUserDto"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userId"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}}]} as unknown as DocumentNode<RensaUsersFragment, unknown>;
export const RensaUsersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"RensaUsers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"searchTerm"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filterType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UsersGroupFilterType"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rensaUsers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"searchTerm"},"value":{"kind":"Variable","name":{"kind":"Name","value":"searchTerm"}}},{"kind":"Argument","name":{"kind":"Name","value":"filterType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filterType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"rensaUsers"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"rensaUsers"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ADUserDto"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userId"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}}]} as unknown as DocumentNode<RensaUsersQuery, RensaUsersQueryVariables>;