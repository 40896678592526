/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateProjectActivityCostsMutationVariables = Types.Exact<{
  projectId: Types.Scalars['UUID'];
  projectActivityId: Types.Scalars['UUID'];
  costs: Array<Types.ProjectActivityCostDtoInput> | Types.ProjectActivityCostDtoInput;
}>;


export type UpdateProjectActivityCostsMutation = { updateProjectActivityCosts: { id: any } };


export const UpdateProjectActivityCostsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateProjectActivityCosts"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"projectId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"projectActivityId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"costs"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ProjectActivityCostDtoInput"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateProjectActivityCosts"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"projectId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"projectId"}}},{"kind":"Argument","name":{"kind":"Name","value":"projectActivityId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"projectActivityId"}}},{"kind":"Argument","name":{"kind":"Name","value":"costs"},"value":{"kind":"Variable","name":{"kind":"Name","value":"costs"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdateProjectActivityCostsMutation, UpdateProjectActivityCostsMutationVariables>;