import gql from 'graphql-tag'

export const GET_PROJECT_LIST_DIALOG = gql`
  query ProjectListDialogQuery {
    projects {
      items {
        id
        name
        layouts {
          id
          name
        }
      }
    }
  }
`
export const ENRICH_PROJECT = gql`
  mutation EnrichProject(
    $projectToCopyTowardsId: UUID!
    $projectToCopyFromId: UUID!
    $layoutToCopyTowardsId: UUID!
    $layoutToCopyFromId: UUID!
  ) {
    enrichProject(
      projectToCopyTowardsId: $projectToCopyTowardsId
      projectToCopyFromId: $projectToCopyFromId
      layoutToCopyTowardsId: $layoutToCopyTowardsId
      layoutToCopyFromId: $layoutToCopyFromId
    ) {
      name
    }
  }
`
