/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SendNoLoginNotificationMutationVariables = Types.Exact<{
  realEstateId: Types.Scalars['UUID'];
}>;


export type SendNoLoginNotificationMutation = { sendNoLoginNotification: boolean };


export const SendNoLoginNotificationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SendNoLoginNotification"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"realEstateId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendNoLoginNotification"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"realEstateId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"realEstateId"}}}]}]}}]} as unknown as DocumentNode<SendNoLoginNotificationMutation, SendNoLoginNotificationMutationVariables>;