import gql from 'graphql-tag'

export const UPDATE_ROOM = () => {
  return gql`
    mutation UpdateRoomMutation(
      $roomId: UUID!
      $roomName: String!
      $roomPosition: Int!
      $layoutIds: [UUID!]!
    ) {
      updateRoom(
        roomId: $roomId
        roomName: $roomName
        roomPosition: $roomPosition
        layoutIds: $layoutIds
      )
    }
  `
}
