
import { ConfirmationDialogModel } from '@/types/dialogTypes'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'

@Component({
  mixins: [validationMixin]
})
export default class ConfirmationDialog extends Vue {
  confirmClicked = false
  MESSAGE_MAX_LENGTH = 500
  notes = ''

  @Watch('show')
  onShowChanged() {
    this.confirmClicked = false
  }

  get show(): boolean {
    return this.$store.state.confirmationDialog.IsVisible
  }

  get model(): ConfirmationDialogModel {
    return this.$store.state.confirmationDialog
  }

  get cancel(): string {
    return this.$t('global.cancel').toString()
  }

  get code(): string {
    if (this.model.Sub) {
      return `${this.model.Code}${this.model.Sub}`
    } else {
      return `${this.model.Code}`
    }
  }

  get confirm(): string {
    return this.$t('global.confirm').toString()
  }

  get description(): string {
    if (!this.model.Code) {
      return ''
    }
    const description = this.$t(`global.dialogs.confirmation.${this.model.Code}.description`).toString()
    if (this.hasMessage) {
      return `${description}: ${this.model.Message}`
    } else {
      return description
    }
  }

  get hasTextArea() {
    return this.model.HasTextArea
  }

  get hasMessage(): boolean {
    if (this.model.Message) return true
    return false
  }

  get title(): string {
    if (!this.model.Code) {
      return ''
    }
    return this.$t(`global.dialogs.confirmation.${this.model.Code}.title`).toString()
  }

  get awaitConfirm() {
    return this.model.awaitConfirm
  }

  get noteErrorMessages() {
    const errors: string[] = []

    if (!this.hasTextArea) return errors

    if (this.notes.length > this.MESSAGE_MAX_LENGTH) {
      errors.push(this.$t('global.forms.textarea.maxLength').toString())
    }

    return errors
  }

  clickCancel() {
    this.model.CancelCallback?.()
    this.$store.dispatch('hideConfirmationDialog')
  }

  clickConfirm() {
    this.confirmClicked = true
    this.$store.commit('updateConfirmationDialogNotes', {
      Notes: this.notes
    })
    this.model.Callback?.()
    this.$store.dispatch('hideConfirmationDialog')
  }
}
