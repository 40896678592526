
import {
  EndContactRequestMutation,
  EndContactRequestMutationVariables,
  EndContactRequestDocument
} from './gql/__generated__/endContactRequest.mutation'
import { GetContactRequestsDetailsDocument } from '@/views/contactRequestDetailsView/gql/__generated__/getContactRequestDetails.query'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { RouteNames } from '@/router/routeNames'

@Component
export default class CloseContactRequestDialog extends Vue {
  @Prop({ default: false }) show!: boolean
  @Prop({ required: true }) contactRequestId!: string
  @Prop({ required: true }) contactRequestBuildnumber!: string

  MESSAGE_MAX_LENGTH = 500
  closeMessageText = ''
  routeNames = RouteNames

  isLoading = false

  @Watch('show')
  onVisibilityChange() {
    if (!this.show) {
      this.closeMessageText = ''
      this.isLoading = false
    }
  }

  get refetchQueries() {
    return [
      {
        query: GetContactRequestsDetailsDocument,
        variables: { requestId: this.contactRequestId }
      }
    ]
  }

  get resolveContentErrorMessages() {
    const errors = []

    if (this.closeMessageText.length > this.MESSAGE_MAX_LENGTH) {
      errors.push(this.$t('global.forms.textarea.maxLength').toString())
    }

    return errors
  }

  get hasErrors() {
    return !!this.resolveContentErrorMessages.length
  }

  completeRequest() {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: () => {
        this.closeMessage()
        this.$store.dispatch('hideConfirmationDialog')
      },
      Code: 'C4021'
    })
  }

  async closeMessage() {
    this.isLoading = true
    try {
      await this.$apolloMutate<EndContactRequestMutation, EndContactRequestMutationVariables>({
        mutation: EndContactRequestDocument,
        variables: {
          requestId: this.contactRequestId,
          closingNote: this.closeMessageText
        },
        refetchQueries: this.refetchQueries,
        error: 'E4206'
      })
    } finally {
      this.isLoading = false
      this.$emit('close')
      this.$router.push({ name: this.routeNames.CONTACT_REQUESTS })
    }
  }
}
