// e.g. € 20,-
export function getDisplayPrice(value: number, fractionDigits = 2) {
  if (typeof Intl === 'object') {
    const formatter = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
      currency: 'EUR'
    })

    return isNaN(value) ? '-' : `${formatter.format(value)}`
  }
  return `${value}`
}

// e.g. 20.000,00
export function getInputPrice(value: number, fractionDigits = 2) {
  if (typeof Intl === 'object') {
    const formatter = new Intl.NumberFormat('nl-NL', {
      style: 'decimal',
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits
    })

    return `${formatter.format(value)}`
  }
  return `${value}`
}
