
import { Component, Prop, Vue } from 'vue-property-decorator'
import { TableColumn, TableData } from './types'

@Component
export default class SimpleDataTable extends Vue {
  @Prop() headers!: TableColumn[]
  @Prop() data!: TableData<never>[]
  @Prop({ default: true }) itemRemovable?: boolean

  getDefaultValue(item: TableData<never>, key: string) {
    return key.split('.').reduce((prev, cur) => prev?.[cur], item)
  }
}
