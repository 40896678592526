
import { ProductSetStatus } from '@/__generated__/globalTypes'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import DebounceMixin, { debounceDelay } from '@/mixins/debounceMixin'
import QUERIES from '@/queries/queries'
import { getDisplayPrice } from '@/utils/currencyUtils'
import { buildRequestVariables, defaultFooterProps, TableFilter } from '@/utils/tableUtils'
import { CategoryWithProductSetInfoKeysStructure } from '@/views/productPackageView/types'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { DataOptions, DataTableHeader } from 'vuetify'
import {
  AddProductPackageFavouriteDocument,
  AddProductPackageFavouriteMutation,
  AddProductPackageFavouriteMutationVariables
} from './gql/__generated__/addProductPackageFavourite.mutation'
import {
  DeleteProductSetDocument,
  DeleteProductSetMutation,
  DeleteProductSetMutationVariables
} from './gql/__generated__/deleteProductSet.mutation'
import {
  ProductSetListDocument,
  ProductSetListQuery,
  ProductSetListQueryVariables
} from './gql/__generated__/getProductSetList.query'
import {
  GetProductSetPageDocument,
  GetProductSetPageQuery,
  GetProductSetPageQueryVariables
} from './gql/__generated__/getProductSetPage.query'
import {
  RemoveProductPackageFavouriteDocument,
  RemoveProductPackageFavouriteMutation,
  RemoveProductPackageFavouriteMutationVariables
} from './gql/__generated__/removeProductPackageFavourite.mutation'
import {
  SetProductSetAvailabilityDocument,
  SetProductSetAvailabilityMutation,
  SetProductSetAvailabilityMutationVariables
} from './gql/__generated__/setProductSetAvailability.mutation'
import ProductSetBulkUpdateModal from './ProductSetBulkUpdateModal.vue'
import { AssignedCategoryProductSet } from './types'
import { CombinationProductSetActivityFragment } from '@/views/productPackageView/gql/__generated__/combinationProductSetActivities.query'
import QrGenerator from './QrGenerator.vue'

@Component({
  components: {
    ConfirmDialog,
    ProductSetBulkUpdateModal,
    QrGenerator
  },
  mixins: [DebounceMixin]
})
export default class ProductSetList extends Vue {
  @Prop({ required: true }) readonly productPackageId!: string
  @Prop({ default: false }) readonly isMainProductPackage?: boolean
  @Prop() categoryWithProductSetFields?: CategoryWithProductSetInfoKeysStructure | null
  @Prop() readonly refetchKey!: number
  @Prop({ required: true, default: false }) readonly isMasterProductPackage!: boolean
  @Prop({ required: true, default: false }) readonly isPriceVariantsVisible!: boolean
  @Prop() isNoProductNeeded?: boolean | null
  @Prop() combinationProductSetActivities?: CombinationProductSetActivityFragment[]

  @Ref('productSetListDiv') readonly productSetListDiv!: HTMLDivElement

  isLoading = false

  // General list data
  productSetsData: AssignedCategoryProductSet[] = []

  // QR code generator inputs
  showQRCodeDialog = false
  qrCodeProductSet: AssignedCategoryProductSet | null = null

  // Search
  search = ''
  totalCount = 0
  productSetStatus = ProductSetStatus

  // Deletion data
  showDeleteProductSetDialog = false
  productSetToDelete: AssignedCategoryProductSet | null = null

  // Data for bulk updating
  showProductSetBulkUpdateModal = false

  productSetHeaders: DataTableHeader[] = [
    {
      value: 'isProductPackageFavourite',
      sortable: false,
      text: '',
      class: 'c-product-set-list__header--xs'
    },
    {
      align: 'start',
      value: 'productSet.name',
      text: this.$t('productPackage.edit.category.productSets.list.table.name').toString(),
      sortable: true
    },
    {
      value: 'productSet.brandline.name',
      text: this.$t('productPackage.edit.category.productSets.list.table.category').toString(),
      sortable: true
    },
    {
      value: 'productSet.costPrice.amount',
      text: this.$t('productPackage.edit.category.productSets.list.table.price').toString(),
      sortable: false
    },
    {
      value: 'priceVariants',
      text: this.$t('productPackage.edit.category.productSets.list.table.priceVariant').toString(),
      sortable: false
    },
    {
      value: 'status',
      text: this.$t('global.status').toString()
    },
    {
      align: 'end',
      value: 'actions',
      text: '',
      sortable: false
    }
  ]

  tableOptions = {
    sortBy: ['productSet.brandline.name'],
    sortDesc: [false]
  } as DataOptions

  searchFilters: TableFilter[] = [
    {
      keyPath: 'productSet.name',
      value: '',
      filterType: 'contains'
    },
    {
      keyPath: 'productSet.assignedProductSetProducts.some.product.code',
      value: '',
      filterType: 'contains'
    },
    {
      keyPath: 'productSet.brandline.name',
      value: '',
      filterType: 'contains'
    }
  ]

  statusFilters: TableFilter[] = [
    {
      keyPath: 'status',
      value: ProductSetStatus.Retired,
      filterType: 'neq'
    }
  ]

  changeSearch: ((value: boolean) => void) | null = null

  get loading() {
    return this.$apollo.queries.ProductSetListQuery.loading || this.isLoading
  }

  get footerProps() {
    const props = { ...defaultFooterProps }
    props.itemsPerPageOptions = [...props.itemsPerPageOptions, -1]
    return props
  }

  get productPackageCategoryId() {
    return this.categoryWithProductSetFields?.id
  }

  created() {
    this.$apollo.addSmartQuery<ProductSetListQuery, ProductSetListQueryVariables>(QUERIES.ProductSetListQuery, {
      query: ProductSetListDocument,
      fetchPolicy: 'network-only',
      variables: () => ({
        productPackageId: this.productPackageId,
        productPackageCategoryId: this.productPackageCategoryId,
        lang: this.lang,
        ...buildRequestVariables(this.tableOptions, [this.searchFilters, this.statusFilters], this.totalCount)
      }),
      update: (data) => data,
      result: (result) => {
        this.productSetsData = (result.data.productPackage?.assignedCategoryProductSets?.items ??
          []) as AssignedCategoryProductSet[]
        this.totalCount = result.data.productPackage?.assignedCategoryProductSets?.totalCount ?? 0
      },
      skip: () => {
        return !this.productPackageId || !this.productPackageCategoryId
      }
    })
  }

  get lang() {
    return this.$i18n.locale
  }

  mounted() {
    this.changeSearch = this.debounce(() => {
      // Resets to the first page
      this.tableOptions.page = 1
      // Set the search string in all the filters
      Object.values(this.searchFilters).forEach((filter) => {
        filter.value = this.search
      })
    }, debounceDelay)

    if (!this.isPriceVariantsVisible) {
      this.productSetHeaders = this.productSetHeaders.filter((x) => x.value != 'priceVariants')
    }
  }

  @Watch('refetchKey')
  refetchProductSets() {
    this.refreshQuery()
  }

  scrollToTop() {
    this.productSetListDiv?.parentElement?.scrollIntoView()
  }

  get allRowsSelected() {
    // First filter out the isNoProduct items, then check if all the remaining items are selected
    return !this.productSetsData.filter((x) => !x.isNoProduct && !x.isNoProductNeeded).some((x) => !x.isSelected)
  }

  get noRowSelected() {
    return !this.productSetsData.some((x) => x.isSelected)
  }

  get selectedProductSets() {
    return this.productSetsData.filter((x) => x.isSelected).map((x) => x.productSet.id)
  }

  navigateToProductSetCMSItem(item: AssignedCategoryProductSet) {
    this.$apollo
      .query<GetProductSetPageQuery, GetProductSetPageQueryVariables>({
        query: GetProductSetPageDocument,
        variables: {
          alias: item.productSet.id
        }
      })
      .catch((reason) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4166',
          Message: reason
        })
      })
      .then((response) => {
        if (response) {
          const host = process.env.VUE_APP_CMS_URL
          const contentItemId = response.data.productSetDetails?.[0]?.contentItemId
          if (contentItemId) {
            const url = `${host}/Admin/Contents/ContentItems/${contentItemId}/Edit?returnUrl=%2FAdmin%2FContents%2FContentItems`
            window.open(url, 'editProductBrandLineInCms')
          } else {
            this.$store.dispatch('showErrorDialog', {
              Code: 'E4167'
            })
          }
        }
      })
  }

  setDeleteProductSetDialogVisibility(item: AssignedCategoryProductSet) {
    this.productSetToDelete = item
    this.showDeleteProductSetDialog = true
  }

  getProductSetPrice(item: AssignedCategoryProductSet) {
    return item.productSet.costPrice ? getDisplayPrice(item.productSet.costPrice.amount) : '-'
  }

  closeConfirmDialog() {
    this.productSetToDelete = null
    this.showDeleteProductSetDialog = false
  }

  getProductSetListQuery() {
    return {
      query: ProductSetListDocument,
      variables: {
        productPackageId: this.productPackageId,
        productPackageCategoryId: this.productPackageCategoryId,
        ...buildRequestVariables(this.tableOptions, [this.searchFilters, this.statusFilters], this.totalCount)
      }
    }
  }

  async deleteProductSet() {
    if (!this.productSetToDelete) return

    this.isLoading = true
    this.showDeleteProductSetDialog = false
    try {
      await this.$apolloMutate<DeleteProductSetMutation, DeleteProductSetMutationVariables>({
        mutation: DeleteProductSetDocument,
        refetchQueries: [this.getProductSetListQuery()],
        variables: {
          productSetId: this.productSetToDelete?.productSet.id,
          productPackageCategoryId: this.productPackageCategoryId
        },
        error: (err) => {
          const error = err.error?.graphQLErrors?.[0] ?? err.error?.networkError?.result?.errors?.[0]
          return {
            Code: 'E4165',
            Message: error?.extensions?.message ?? err.error,
            Callback: err.Callback
          }
        }
      })
    } finally {
      this.productSetToDelete = null
      this.isLoading = false
    }
  }

  async toggleFavourite(item: AssignedCategoryProductSet) {
    if (this.isLoading) return

    try {
      this.isLoading = true
      await this.$apolloMutate<
        AddProductPackageFavouriteMutation | RemoveProductPackageFavouriteMutation,
        AddProductPackageFavouriteMutationVariables | RemoveProductPackageFavouriteMutationVariables
      >({
        mutation: item.isProductPackageFavourite
          ? RemoveProductPackageFavouriteDocument
          : AddProductPackageFavouriteDocument,
        variables: {
          productPackageId: this.$route.params.productPackageId,
          productSetId: item.productSet.id
        },
        refetchQueries: [this.getProductSetListQuery()],
        error: 'E4171'
      })
    } finally {
      this.isLoading = false
    }
  }

  isEditable(item: AssignedCategoryProductSet) {
    return !item.isNoProduct
  }

  isReadonly(item: AssignedCategoryProductSet) {
    return item.isNoProduct || item.isNoProductNeeded
  }

  getProductCodes(item: AssignedCategoryProductSet) {
    return item.productSet?.assignedProductSetProducts?.map((x) => x.product?.code)?.join(', ')
  }

  async refreshQuery() {
    await this.$apollo.queries.ProductSetListQuery.refetch()
  }

  canChangeStatus(item: AssignedCategoryProductSet) {
    return (
      (!item.isProductPackageDefault || (item.isProductPackageDefault && item.status !== ProductSetStatus.Available)) &&
      !item.isNoProduct &&
      !item.isNoProductNeeded &&
      [ProductSetStatus.Available, ProductSetStatus.Disabled].includes(item.status)
    )
  }

  getStatusActionText(status: ProductSetStatus) {
    return status === ProductSetStatus.Available ? this.$t('global.disable') : this.$t('global.enable')
  }

  isDisabled(status: ProductSetStatus) {
    return status === ProductSetStatus.Disabled
  }

  toggleSelectAll(value: boolean) {
    // Set all the items to the selectAll value, except the no products, they should always be false.
    this.productSetsData = this.productSetsData.map((item) => ({
      ...item,
      isSelected: item.isNoProduct || item.isNoProductNeeded ? false : value
    }))
  }

  async changeStatus(item: AssignedCategoryProductSet) {
    if (!this.canChangeStatus(item)) {
      return
    }

    this.isLoading = true

    try {
      await this.$apolloMutate<SetProductSetAvailabilityMutation, SetProductSetAvailabilityMutationVariables>({
        mutation: SetProductSetAvailabilityDocument,
        variables: {
          productSetId: item.productSet.id,
          productPackageCategoryId: item.productPackageCategory?.id,
          isAvailable: item.status === ProductSetStatus.Disabled
        },
        refetchQueries: [this.getProductSetListQuery()],
        error: 'E4188'
      })
    } finally {
      this.isLoading = false
    }
  }

  closeProductSetBulkUpdateModal(ok?: boolean) {
    this.showProductSetBulkUpdateModal = false

    if (ok) {
      this.productSetsData = this.productSetsData.map((item) => ({
        ...item,
        isSelected: false
      }))
      this.refetchProductSets()
    }
  }

  openQrGenerator(productSet: AssignedCategoryProductSet) {
    if (!this.isMainProductPackage) return

    this.qrCodeProductSet = productSet
    this.showQRCodeDialog = true
  }
}
