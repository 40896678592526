import gql from 'graphql-tag'

export const GET_PROJECT_ROOMS = gql`
  query ProjectRoomsQuery($projectId: UUID!) {
    project(id: $projectId) {
      id
      layouts {
        id
        name
      }
      rooms {
        id
        name
        position
        layoutRooms {
          layout {
            id
            name
          }
        }
        designPackages {
          id
          name
        }
      }
    }
  }
`
