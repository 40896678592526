/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ForceRealEstateSelectionMutationVariables = Types.Exact<{
  realEstateId: Types.Scalars['UUID'];
  selectionType: Types.SelectionType;
}>;


export type ForceRealEstateSelectionMutation = { forceRealEstateSelection: boolean };


export const ForceRealEstateSelectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"forceRealEstateSelection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"realEstateId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"selectionType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SelectionType"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"forceRealEstateSelection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"realEstateId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"realEstateId"}}},{"kind":"Argument","name":{"kind":"Name","value":"selectionType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"selectionType"}}}]}]}}]} as unknown as DocumentNode<ForceRealEstateSelectionMutation, ForceRealEstateSelectionMutationVariables>;