
import { validationMixin } from 'vuelidate'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ProductSetInfoKey, ProductSetInfoKeyDataType } from '@/__generated__/globalTypes'
import { FieldInformation } from './types'

@Component({
  name: 'EnumField',
  mixins: [validationMixin]
})
export default class EnumField extends Vue {
  @Prop({ required: true }) readonly id!: string
  @Prop({ required: true }) readonly isOptional!: boolean
  @Prop({ required: true }) readonly productSetInfoKey!: ProductSetInfoKey
  @Prop({ required: true }) value!: FieldInformation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({ required: true }) v!: any
  @Prop({ required: true }) disabled!: boolean

  get enumItems() {
    return this.productSetInfoKey.possibleValues
  }

  get select() {
    return this.enumItems.find((x) => x.key == this.value.item2)
  }

  onValueChange(inputValue?: string | null) {
    this.v?.item2.$touch()

    if (inputValue === undefined) {
      inputValue = null
    }

    this.$emit('input', {
      item1: this.id,
      item2: inputValue,
      isOptional: this.isOptional,
      dataType: ProductSetInfoKeyDataType.Enum
    } as FieldInformation)
  }

  get getErrors() {
    const errors = new Array<string>()

    if (!this.v.item2.$dirty) {
      return errors
    }
    if (!this.v.item2.required) {
      errors.push(`${this.getLabelText()} ${this.$t('global.validationRequired')}`)
    }

    return errors
  }

  //TODO: check how to use default value
  getLabelText() {
    return this.$t(`projectDesignPackage.labels.restrictionKeys.${this.productSetInfoKey.key}`)
  }
}
