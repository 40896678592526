
import UpdateProductSetInfos from '@/components/updateProductSetInfos/UpdateProductSetInfos.vue'
import { CategoryWithProductSetInfoKeysStructure } from '@/views/productPackageView/types'
import ProductSetActivitiesList from './ProductSetActivitiesList.vue'
import {
  CostType,
  Currency,
  ProductSetCost,
  ProductSetInfoKeyType,
  TupleOfCostTypeAndStringAndBooleanInput
} from '@/__generated__/globalTypes'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import {
  UpdateMultipleProductSetPricesDocument,
  UpdateMultipleProductSetPricesMutation,
  UpdateMultipleProductSetPricesMutationVariables
} from './gql/__generated__/bulkUpdateProductSets.mutation'
import { ProductSetInfoKeyModel } from './types'
import { CombinationProductSetActivityFragment } from '@/views/productPackageView/gql/__generated__/combinationProductSetActivities.query'
import { AssignedProductSetActivityFragment } from './gql/__generated__/getProductSetList.query'

@Component({
  components: {
    UpdateProductSetInfos,
    ProductSetActivitiesList
  }
})
export default class ProductSetBulkUpdateModal extends Vue {
  @Prop() show!: boolean
  @Prop() categoryWithProductSetFields?: CategoryWithProductSetInfoKeysStructure | null
  @Prop() productSetIds!: string[]
  @Ref() bulkUpdateProductSetForm?: HTMLFormElement
  @Prop() isNoProductNeeded?: boolean | null
  @Prop() combinationProductSetActivities?: CombinationProductSetActivityFragment[]

  isLoading = false

  mActivities: AssignedProductSetActivityFragment[] = []

  // Structure to save
  mProductSetInfoKeys: ProductSetInfoKeyModel[] = []
  mCosts = [
    {
      costType: CostType.WInstallationCosts,
      cost: { amount: '' },
      isStandard: true
    },
    {
      costType: CostType.EInstallationCosts,
      cost: { amount: '' },
      isStandard: true
    },
    {
      costType: CostType.CvInstallationCosts,
      cost: { amount: '' },
      isStandard: true
    },
    {
      costType: CostType.InstallationConstructionCosts,
      cost: { amount: '' },
      isStandard: true
    },
    {
      costType: CostType.TilerInstallationCosts,
      cost: { amount: '' },
      isStandard: true
    },
    {
      costType: CostType.WInstallationCosts,
      cost: { amount: '' },
      isStandard: false
    },
    {
      costType: CostType.EInstallationCosts,
      cost: { amount: '' },
      isStandard: false
    },
    {
      costType: CostType.CvInstallationCosts,
      cost: { amount: '' },
      isStandard: false
    },
    {
      costType: CostType.InstallationConstructionCosts,
      cost: { amount: '' },
      isStandard: false
    },
    {
      costType: CostType.TilerInstallationCosts,
      cost: { amount: '' },
      isStandard: false
    }
  ] as ProductSetCost[]

  ProductSetInfoKeyType = ProductSetInfoKeyType

  rules = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    moneyOrEmpty: (value: any) => {
      // Can contain numbers, commas and dots. Or is a null value (nothing).
      const regex = /^[0-9]{0,9}([,.][0-9]{0,2})?$/
      return regex.test(value) || this.$t('productPackage.edit.category.productSets.upsert.validations.money')
    }
  }

  get standardCosts() {
    return this.mCosts.filter((x) => x.isStandard === true)
  }

  get nonStandardCosts() {
    return this.mCosts.filter((x) => x.isStandard === false)
  }

  get productPackageCategoryId() {
    return this.categoryWithProductSetFields?.id
  }

  @Watch('show')
  onshowUpdate() {
    this.resetForm()
  }

  addActivity(activity: CombinationProductSetActivityFragment, isForStandardProductSet: boolean) {
    if (
      !this.mActivities.some(
        (x) => x.activity?.id === activity?.id && x.isForStandardProductSet === isForStandardProductSet
      )
    ) {
      this.mActivities.push({
        id: '',
        activity,
        isForStandardProductSet
      })
    }
  }

  removeActivity(assignedProductSetActivity: AssignedProductSetActivityFragment, isForStandardProductSet: boolean) {
    this.mActivities = this.mActivities.filter(
      (x) =>
        !(
          x.activity?.id === assignedProductSetActivity.activity?.id &&
          x.isForStandardProductSet === isForStandardProductSet
        )
    )
  }

  resetForm() {
    // Map the costs to update to the cost structure, or set the price to an epmty string if it is not set.
    this.mCosts = this.mCosts.map(
      (x): ProductSetCost => ({
        ...x,
        cost: {
          amount: '',
          currency: Currency.Eur
        }
      })
    )

    // Set structure for the info keys
    this.mProductSetInfoKeys =
      this.categoryWithProductSetFields?.productSetInfoKeys?.map(
        (infoKeyStructure) =>
          ({
            ...infoKeyStructure,
            value: null
          } as ProductSetInfoKeyModel)
      ) ?? []

    this.mActivities = []
  }

  getProductSetsMutationData(): UpdateMultipleProductSetPricesMutationVariables {
    return {
      productPackageCategoryId: this.productPackageCategoryId,
      productSetIds: this.productSetIds,
      costDetails: this.mCosts
        .filter((x) => !!x.cost?.amount)
        .map(
          (x: ProductSetCost): TupleOfCostTypeAndStringAndBooleanInput => ({
            item1: x.costType,
            item2: x.cost.amount,
            item3: !!x.isStandard
          })
        ),
      productSetInfos:
        this.mProductSetInfoKeys
          .filter((x) => !!x.value)
          .map((x) => ({
            item1: x.id,
            item2: x.value?.toString() ?? ''
          })) ?? [],
      activities: this.mActivities.map((x) => ({
        item1: x.activity?.id ?? '',
        item2: x.isForStandardProductSet
      }))
    }
  }

  async updateProductSets() {
    if (!this.bulkUpdateProductSetForm?.validate()) return

    const requestData = this.getProductSetsMutationData()

    this.isLoading = true
    try {
      await this.bulkUpdateProductSets(requestData)

      this.$emit('close', true)
    } catch {
      this.$emit('close')
    } finally {
      this.isLoading = false
    }
  }

  async bulkUpdateProductSets(variables: UpdateMultipleProductSetPricesMutationVariables) {
    await this.$apolloMutate<UpdateMultipleProductSetPricesMutation, UpdateMultipleProductSetPricesMutationVariables>({
      mutation: UpdateMultipleProductSetPricesDocument,
      variables,
      error: 'E4197'
    })
  }
}
