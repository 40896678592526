
import {
  CustomOffer,
  CustomOfferRequestStatus,
  CustomOfferRoom,
  CustomOfferRoomDtoInput,
  KeyValuePairOfCostTypeAndStringInput,
  Room
} from '@/__generated__/globalTypes'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import OfferCostSpecifications, { NO_ROOM } from './OfferCostSpecifications.vue'
import {
  CreateCustomOfferDocument,
  CreateCustomOfferMutation,
  CreateCustomOfferMutationVariables
} from './gql/__generated__/createCustomOffer.mutation'
import { AppointmentItem } from '@/views/customOfferDetailsView/appointmentType'

const MAX_FILE_SIZE = 100000000 //bytes (~100 MB)

export type AvailableRoom = {
  id: string
  name: string
  selected: boolean
}

export enum FileType {
  DRAWING = 'drawing',
  OFFER = 'offer'
}

@Component({
  components: { OfferCostSpecifications }
})
export default class OfferUpsertPopup extends Vue {
  @Prop() requestRooms!: Room[]
  @Prop() offerToUpdate?: CustomOffer | null
  @Prop({ required: true }) offerRequestId!: string
  @Prop({ required: true }) disabled!: boolean
  @Prop({ required: true }) serviceCosts!: number
  @Prop({ default: false }) divideByRooms!: boolean
  @Prop({ default: false }) isReadonly!: boolean
  @Prop() appointments!: AppointmentItem[]

  customOfferName = ''
  customOfferLink = ''
  selectableRooms: AvailableRoom[] = []
  offerId = ''
  isSharedWithCustomer = true
  offerRooms: (CustomOfferRoom | null)[] = []
  offerRoomsCosts: CustomOfferRoomDtoInput[] = []
  offerItemsChanged = false

  @Ref('offerCostSpecifications') offerCostSpecificationsRef?: OfferCostSpecifications

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Ref() upsertOfferForm?: any

  acceptedFileTypes = ['application/pdf']
  FileType = FileType
  isSelectingDrawingFile = false
  isSelectingOfferFile = false
  isDownloadingOfferFile = false
  isDownloadingDrawingFile = false
  isUploading = false
  drawingFile: File | null = null
  offerFile: File | null = null

  isSaveDisabled = true
  isSaveDisabledFromChild = true //checks if all fields are filled and at least one room is selected
  isSavingOffer = false

  created() {
    const offerAssignedRooms = this.offerToUpdate?.assignedRooms
    this.selectableRooms = this.requestRooms?.map((room) => {
      return {
        id: room.id,
        name: room.name,
        selected: offerAssignedRooms?.find((x) => x?.roomId === room.id) ? true : false
      }
    })

    if (this.offerToUpdate) {
      this.isSaveDisabled = false
      this.isSaveDisabledFromChild = false
      this.offerId = this.offerToUpdate.id
      this.customOfferName = this.offerToUpdate.name
      this.customOfferLink = this.offerToUpdate.link
      this.isSharedWithCustomer = this.offerToUpdate.isSharedWithCustomer
      this.offerRooms = this.offerToUpdate.assignedRooms
      this.offerRoomsCosts =
        this.offerRooms?.map((room) => {
          return {
            roomId: room?.roomId ?? NO_ROOM,
            costs:
              room?.assignedCosts.map((cost) => {
                if (cost) {
                  return {
                    key: cost.costType,
                    value: cost.cost.amount.toString() ?? ''
                  } as KeyValuePairOfCostTypeAndStringInput
                }
              }) ?? []
          } as CustomOfferRoomDtoInput
        }) ?? []
    }
  }

  get title() {
    const titleKey = this.offerToUpdate ? 'titleEdit' : 'titleAdd'
    return this.$t(`customOfferDetails.upsertOfferPopup.${titleKey}`)
  }

  get assignedRooms() {
    return this.offerToUpdate?.assignedRooms ?? []
  }

  get drawingFileName() {
    // there is no name for the drawingFile on an offer, so we always return 'drawing.pdf' for existing offers.
    return this.offerToUpdate ? 'drawing.pdf' : this.drawingFile?.name ?? ''
  }

  get drawingUploadErrors() {
    return this.fileErrors(this.drawingFile)
  }

  get offerFileName() {
    return this.offerFile?.name ?? this.offerToUpdate?.name ?? ''
  }

  get offerUploadErrors() {
    return this.fileErrors(this.offerFile)
  }

  get isSharedToggleEnabled() {
    return (
      !this.offerToUpdate?.isSharedWithCustomer ||
      this.offerToUpdate?.customOfferRequest?.requestStatus?.status === CustomOfferRequestStatus.SharedWithCustomer ||
      this.offerToUpdate?.customOfferRequest?.requestStatus?.status === CustomOfferRequestStatus.RequestForChange
    )
  }

  get disableSave() {
    return this.disabled || this.isSaveDisabled || !this.customOfferName
  }

  get hasFutureAppointments() {
    return this.appointments?.[this.appointments.length - 1]?.dateUTC > new Date()
  }

  validateForm() {
    this.upsertOfferForm?.validate()
  }

  updateFileName() {
    this.customOfferName = this.offerFile?.name?.replace('.pdf', '') ?? ''
    this.offerItemsChanged = true
    this.updateDisable(null)
  }

  updateOfferFileName() {
    if (!this.offerFile) return
    // Since we can't just change the name of the file (it's readonly), we will have to create a new file with a new name.
    this.offerFile = new File(
      [this.offerFile],
      this.customOfferName ? this.customOfferName?.replace('.pdf', '') + '.pdf' : 'offer.pdf',
      {
        type: this.acceptedFileTypes[0]
      }
    )
    this.updateDisable(null)
  }

  drawingUpdated() {
    this.offerItemsChanged = true
    this.updateDisable(null)
  }

  //function called when a file is changed or the total cost is updated
  updateDisable(disableFromChild: boolean | null) {
    //if offerToUpdate is not null, it means the offer already has files
    const hasFiles = !!((this.offerFile && this.drawingFile) || this.offerToUpdate)
    if (disableFromChild !== null) {
      this.isSaveDisabledFromChild = disableFromChild
    }

    //we check if it has files in 'hasFiles'
    //if the cost specifications fields are all filled in 'isSaveDisabledFromChild'
    //and if at least one room is selected also in 'isSaveDisabledFromChild'
    //if anything is missing, disable save
    this.isSaveDisabled = !hasFiles || this.isSaveDisabledFromChild
  }

  handleDrawingFileImport() {
    this.isSelectingDrawingFile = true
    window.addEventListener(
      'focus',
      () => {
        this.isSelectingDrawingFile = false
      },
      { once: true }
    )
    document.getElementById('drawingUpload')?.click()
  }

  handleOfferFileImport() {
    this.isSelectingOfferFile = true
    window.addEventListener(
      'focus',
      () => {
        this.isSelectingOfferFile = false
      },
      { once: true }
    )
    document.getElementById('offerUpload')?.click()
  }

  async uploadFile(file: File, type: FileType, errors: string[]) {
    if (!file || errors.length || this.isUploading) return
    this.isUploading = true

    await this.$fileApi
      .uploadFile(`/customoffer/${this.offerId}/${type}`, file)
      .catch((error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4192',
          Message: error
        })
      })
      .finally(() => {
        this.isUploading = false
      })
  }

  downloadFile(type: FileType) {
    if (!this.offerId) return

    let fileName = this.offerFileName
    if (type === FileType.DRAWING) {
      this.isDownloadingDrawingFile = true
      fileName = this.drawingFileName
    } else this.isDownloadingOfferFile = true

    this.$fileApi
      .downloadFile(`/customOffer/${this.offerId}/${type}`, fileName)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4195',
          Message: error
        })
      })
      .finally(() => {
        if (type === FileType.DRAWING) this.isDownloadingDrawingFile = false
        else this.isDownloadingOfferFile = false
      })
  }

  fileErrors(file: File | null | undefined) {
    const result: string[] = []

    if (!file) {
      if (!this.offerToUpdate) {
        result.push(this.$t('global.dialogs.upload.errors.required').toString())
      }
      return result
    }

    if (file.size > MAX_FILE_SIZE) {
      const maxSizeInMB = Math.floor(MAX_FILE_SIZE / 1024 / 1024)
      const message = this.$t('global.dialogs.upload.errors.maxFileSize')
        .toString()
        .replace('{MAX}', maxSizeInMB.toString())
      result.push(message)
    }

    if (!this.acceptedFileTypes.includes(file.type)) {
      result.push(this.$t('global.dialogs.upload.errors.invalidType').toString())
    }

    return result
  }

  async createOffer() {
    const result = await this.$apollo.mutate<CreateCustomOfferMutation, CreateCustomOfferMutationVariables>({
      mutation: CreateCustomOfferDocument,
      variables: {
        customOfferRequestId: this.offerRequestId
      }
    })

    this.offerId = result.data?.createCustomOffer.id
  }

  async saveOffer() {
    try {
      if (!this.upsertOfferForm?.validate()) return //check if all inputs respect the rules
      await this.$showConfirmationDialog({
        awaitConfirm: true,
        Callback: async () => {
          this.isSavingOffer = true

          if (this.hasFutureAppointments) {
            this.$store.dispatch('showErrorDialog', {
              Code: 'E1115'
            })
          } else {
            if (!this.offerToUpdate) await this.createOffer()

            if (this.drawingFile) {
              await this.uploadFile(this.drawingFile, FileType.DRAWING, this.drawingUploadErrors)
            }
            if (this.offerFile) {
              await this.uploadFile(this.offerFile, FileType.OFFER, this.offerUploadErrors)
            }
            await this.offerCostSpecificationsRef?.saveCosts(
              this.offerId,
              this.isSharedWithCustomer,
              this.customOfferName,
              this.customOfferLink
            )
          }

          this.$emit('close')
          this.$hideConfirmationDialog()
        },
        Code: 'C4031'
      })
    } finally {
      this.isSavingOffer = false
    }
  }

  onClosePopupClick() {
    // If the form is editable, a confirmation dialog is shown to prevent the user from loosing the changes
    if (this.disabled) {
      this.closePopup()
    } else {
      this.$store.dispatch('showConfirmationDialog', {
        Callback: () => this.closePopup(),
        Code: 'C4013'
      })
    }
  }

  closePopup() {
    this.$store.dispatch('hideConfirmationDialog')
    this.$emit('close')
  }
}
