var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"c-productpackage_listpage"},[_c('v-row',{attrs:{"justify":"space-between","no-gutters":""}},[_c('h1',{attrs:{"data-cy":"product-list-title"}},[_vm._v(_vm._s(_vm.$t('productPackage.list.title')))]),_c('div',[_c('v-btn',{staticClass:"text-none mr-2",attrs:{"to":{
          name: _vm.RouteNames.PRODUCT_PACKAGE_IMPORTS,
          params: { propositionType: _vm.Badkamercore_ProjectType.Productenpropositie }
        },"data-cy":"view-product-imports-btn","dark":"","depressed":"","rounded":""}},[_vm._v(_vm._s(_vm.$t('productPackage.list.viewProductPackageImports')))]),_c('v-btn',{staticClass:"text-none",attrs:{"disabled":_vm.isReadonly,"dark":"","data-cy":"add-product-package-btn","depressed":"","rounded":""},on:{"click":function($event){_vm.showProductPackageAddModal = true}}},[_vm._v(_vm._s(_vm.$t('productPackage.list.addProductPackage')))])],1)]),_c('v-divider',{staticClass:"my-6"}),(_vm.isLoading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"tile":true,"type":"table-thead, table-tbody, table-tfoot"}}):_c('div',[(_vm.productPackages)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.productPackages,"sort-by":['isMain', 'name'],"sort-desc":"","data-cy":"product-list-table","hide-default-header":""},scopedSlots:_vm._u([{key:"item.isMain",fn:function({ item }){return [_c('v-tooltip',{attrs:{"right":"","maxWidth":"500","color":"rgba(0,0,0,0.9)"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"data-cy":"ismain-icon","color":item.isMain ? 'blue lighten-1' : 'grey lighten-2'}},item.isMain ? on : undefined),[_vm._v("mdi-archive-star-outline")])]}}],null,true)},[_c('span',{},[_vm._v(_vm._s(_vm.$t('productPackage.list.isMainTooltip')))])])]}},{key:"item.name",fn:function({ item }){return [_c('b',{attrs:{"data-cy":"product-list-table-name"}},[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{staticClass:"text-none",attrs:{"disabled":_vm.isReadonly,"to":{
            name: _vm.RouteNames.PRODUCT_PACKAGE,
            params: {
              propositionType: _vm.Badkamercore_ProjectType.Productenpropositie,
              productPackageId: item.id
            }
          },"data-cy":"product-list-table-edit-btn","light":"","rounded":"","small":""}},[_vm._v(_vm._s(_vm.$t('productPackage.list.edit')))]),_c('v-btn',{staticClass:"text-none ml-2",attrs:{"disabled":_vm.isReadonly,"data-cy":"product-list-table-copy-btn","light":"","rounded":"","small":""},on:{"click":() => _vm.openProductPackageCopyModal(item)}},[_vm._v(_vm._s(_vm.$t('productPackage.list.copy')))]),_c('v-btn',{staticClass:"text-none ml-2",attrs:{"disabled":_vm.isReadonly,"data-cy":"product-list-table-edit-content-btn","light":"","rounded":"","small":"","target":"_blank"},on:{"click":function($event){return _vm.navigateToCms(item.id)}}},[_vm._v(_vm._s(_vm.$t('productPackage.list.editContent')))]),_c('v-btn',{staticClass:"text-none ml-2",attrs:{"disabled":_vm.isReadonly || item.isMain,"data-cy":"product-list-table-remove-btn","light":"","rounded":"","small":""},on:{"click":() => _vm.confirmDeleteProductPackage(item.id)}},[_vm._v(_vm._s(_vm.$t('global.remove')))])]}}],null,false,1513093511)}):_vm._e()],1),_c('add-product-package-dialog',{attrs:{"existingProductPackageNames":_vm.productPackageNames,"show":_vm.showProductPackageAddModal},on:{"close":function($event){_vm.showProductPackageAddModal = false}}}),_c('copy-product-package-dialog',{attrs:{"existingProductPackageNames":_vm.productPackageNames,"productPackageId":_vm.productPackageToCopyId,"productPackageName":_vm.productPackageToCopyName,"show":_vm.showProductPackageCopyModal},on:{"close":function($event){_vm.showProductPackageCopyModal = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }