
import { Badkamercore_ProjectType, ProjectDossierContact, ProjectStatus } from '@/__generated__/globalTypes'
import SimpleDataTable from '@/components/simpleDataTable/SimpleDataTable.vue'
import { TableColumn } from '@/components/simpleDataTable/types'
import QUERIES from '@/queries/queries'
import { toLocaleDateTimeString } from '@/utils/dateUtils'
import { fractionToPercentage } from '@/utils/numberUtils'
import { FetchResult } from 'apollo-link'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { helpers, required } from 'vuelidate/lib/validators'
import ProjectDetailsMarginField from './ProjectDetailsMarginField.vue'
import {
  CheckCustomOfferForProject,
  CheckCustomOfferForProjectVariables
} from './__generated__/CheckCustomOfferForProject'
import {
  UpdateProjectCostSettings,
  UpdateProjectCostSettingsVariables
} from './__generated__/UpdateProjectCostSettings'
import {
  CheckDivideCustomOfferByRoomsDocument,
  CheckDivideCustomOfferByRoomsMutation,
  CheckDivideCustomOfferByRoomsMutationVariables
} from './gql/__generated__/checkDivideCustomOfferByRooms.mutation'
import {
  GetProjectDetailsDocument,
  GetProjectDetailsQuery,
  GetProjectDetailsQueryVariables,
  ProjectDetailsFragment
} from './gql/__generated__/getProjectDetails.query'
import {
  UpdateProjectDossierContactsDocument,
  UpdateProjectDossierContactsMutation,
  UpdateProjectDossierContactsMutationVariables
} from './gql/__generated__/updateProjectDossierContacts.mutation'
import {
  TOGGLE_CUSTOM_OFFER_SETTING,
  TOGGLE_USE_RETURNPRICECALCULATION_SETTING,
  UPDATE_PROJECT_COST_SETTINGS
} from './projectDetailsMutations'
import type { ProjectItem, ProjectMargins } from './types'
import ProjectDetailsSwitchTooltip from './ProjectDetailsSwitchTooltip.vue'

const EMPTY_GUID = '00000000-0000-0000-0000-000000000000'
const EMAIL_REGEX = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/

@Component({
  components: {
    ProjectDetailsMarginField,
    SimpleDataTable,
    ProjectDetailsSwitchTooltip
  },
  mixins: [validationMixin]
})
export default class ProjectDetails extends Vue {
  @Prop() projectId!: string
  isCustomOfferSettingEnabled = false
  divideCustomOfferByRooms = false
  dontUseProjectActivityCosts = false
  isUseReturnPriceCalculationSettingEnabled = false
  project: ProjectDetailsFragment | null = null
  projectMargins: ProjectMargins = {
    companyMarginSanitary: null,
    companyMarginTiles: null,
    marginInstaller: null,
    marginTiler: null,
    marginBuilder: null,
    correctionInstallationWFactor: null,
    correctionInstallationEFactor: null,
    correctionInstallationCVFactor: null,
    correctionTilerFactor: null,
    correctionConstructionFactor: null
  }
  isDownloadingCosts = false
  isDownloadingOverview = false
  dossierEmailAddressTableHeaders: TableColumn[] = [{ key: 'email.address', label: 'Email' }]
  dossierEmailAddressTableData: ProjectDossierContact[] = []
  newDossierEmail = ''

  created() {
    this.fetchData()
  }

  fetchData() {
    this.$apollo.addSmartQuery<GetProjectDetailsQuery, GetProjectDetailsQueryVariables>(QUERIES.ProjectDetailsQuery, {
      query: GetProjectDetailsDocument,
      variables: { projectId: this.projectId },
      fetchPolicy: 'network-only',
      update: (data) => data,
      result: (result) => {
        this.project = result.data.project ?? null
        this.isCustomOfferSettingEnabled = result.data.project?.isCustomOfferFeatureActive || false
        this.divideCustomOfferByRooms = this.project?.divideCustomOfferByRooms || false
        this.dontUseProjectActivityCosts = !this.project?.dontUseProjectActivityCosts || false
        this.isUseReturnPriceCalculationSettingEnabled = result.data.project?.useMinusReturnPriceCalculation || false
        this.dossierEmailAddressTableData = result.data.project?.assignedProjectDossierContacts || []
        // We get the margins in fractions (e.g. 0.1 = 10%), so we need to convert them to decimals
        this.projectMargins = {
          companyMarginSanitary: fractionToPercentage(this.project?.companyMarginSanitary),
          companyMarginTiles: fractionToPercentage(this.project?.companyMarginTiles),
          marginInstaller: fractionToPercentage(this.project?.marginInstaller),
          marginTiler: fractionToPercentage(this.project?.marginTiler),
          marginBuilder: fractionToPercentage(this.project?.marginBuilder),
          correctionInstallationWFactor: fractionToPercentage(
            this.project?.correctionInstallationWFactor ?? this.project?.correctionInstallationFactor
          ),
          correctionInstallationEFactor: fractionToPercentage(
            this.project?.correctionInstallationEFactor ?? this.project?.correctionInstallationFactor
          ),
          correctionInstallationCVFactor: fractionToPercentage(
            this.project?.correctionInstallationCVFactor ?? this.project?.correctionInstallationFactor
          ),
          correctionTilerFactor: fractionToPercentage(this.project?.correctionTilerFactor),
          correctionConstructionFactor: fractionToPercentage(this.project?.correctionConstructionFactor)
        }
      },
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4106',
          Message: error.message,
          Sub: 'B'
        })
      }
    })
  }

  get isStylesProject() {
    return this.project?.projectType === Badkamercore_ProjectType.DeBadkamerwerelden
  }

  get isBrandsProject() {
    return this.project?.projectType === Badkamercore_ProjectType.Merkenpropositie
  }

  get isProductProposition() {
    return this.project?.projectType === Badkamercore_ProjectType.Productenpropositie
  }

  get projectDetails(): ProjectItem | null {
    if (this.project) {
      return {
        projectDeveloperName: this.project.projectDeveloperName,
        status: this.getStatusText(this.project.status),
        projectType: this.project.projectType,
        statusColor: this.getStatusColor(this.project.status),
        requestForQuoteCount: this.getRequestForQuoteCount(this.project),
        publishedOn: toLocaleDateTimeString(this.project.publishedOn, this.$i18n.locale) ?? '...',
        closedOn: toLocaleDateTimeString(this.project.closedOn, this.$i18n.locale) ?? '...',
        lastImportedOn: toLocaleDateTimeString(this.project.lastImportedOn, this.$i18n.locale) ?? '...',
        layouts: this.project?.layouts?.flatMap((x) => x.name).join(', '),
        realEstateCount: this.project?.realEstates?.length,
        rooms: this.project?.rooms?.flatMap((x) => x.name).join(', '),
        assignedStyles:
          this.project?.assignedStyles
            ?.map((x) => x.style.name)
            ?.sort((leftSide, rightSide): number => {
              if (leftSide < rightSide) return -1
              if (leftSide > rightSide) return 1
              return 0
            })
            ?.join(', ') || '...',
        assignedBrands:
          this.project?.assignedBrands
            ?.map((x) => x.brand.name)
            ?.sort((leftSide, rightSide): number => {
              if (leftSide < rightSide) return -1
              if (leftSide > rightSide) return 1
              return 0
            })
            ?.join(', ') || '...',
        designPackageCount: this.project.rooms.flatMap((x) => x.designPackages).length,
        shellOnlyAllowed: this.getshellOnlyAllowedText(this.project),
        productPackages:
          this.project.assignedProductPackages
            ?.map((x) => x.productPackage.name)
            ?.sort((leftSide, rightSide): number => {
              if (leftSide < rightSide) return -1
              if (leftSide > rightSide) return 1
              return 0
            })
            ?.join(', ') || '...'
      }
    }
    return null
  }

  get projectMarginFieldKeys() {
    if (!this.projectMargins) return null
    return Object.keys(this.projectMargins)
  }

  get isPreviewProject() {
    return this.project?.status === ProjectStatus.Preview
  }

  get isProjectPublished() {
    return this.project?.status === ProjectStatus.Open
  }

  getStatusColor(status: string) {
    switch (status) {
      case 'PREVIEW':
        return 'grey'
      case 'OPEN':
        return 'green'
      default:
        return 'white'
    }
  }

  getStatusText(status: string) {
    switch (status) {
      case 'PREVIEW':
        return this.$t('project.status.preview').toString()
      case 'OPEN':
        return this.$t('project.status.open').toString()
      case 'CLOSED':
        return this.$t('project.status.closed').toString()
      case 'ENDED':
        return this.$t('project.status.ended').toString()
      default:
        return this.$t('project.status.unknown').toString()
    }
  }

  getRequestForQuoteCount(project: ProjectDetailsFragment): number {
    let count = 0
    if (project.realEstates) {
      for (const realEstate of project.realEstates) {
        if (realEstate.requestForQuotes?.length > 0) {
          count++
        }
      }
    }
    return count
  }

  getshellOnlyAllowedText(project: ProjectDetailsFragment): string {
    if (project.shellOnlyAllowed) {
      return this.$t('global.yes').toString()
    } else {
      return this.$t('global.no').toString()
    }
  }

  validations() {
    const percentagePositive = (value: string | number) => this.customBetween(value, 0, 100)
    const percentageAllowNegative = (value: string | number) => this.customBetween(value, -100, 100)
    const decimal = helpers.regex('decimal', /^-?\d*([.,]\d{1,2})?$/)

    return {
      projectMargins: {
        companyMarginSanitary: {
          required,
          decimal,
          percentagePositive
        },
        companyMarginTiles: {
          required,
          decimal,
          percentagePositive
        },
        marginInstaller: {
          required,
          decimal,
          percentagePositive
        },
        marginTiler: {
          required,
          decimal,
          percentagePositive
        },
        marginBuilder: {
          required,
          decimal,
          percentagePositive
        },
        correctionInstallationWFactor: {
          required,
          decimal,
          percentageAllowNegative
        },
        correctionInstallationEFactor: {
          required,
          decimal,
          percentageAllowNegative
        },
        correctionInstallationCVFactor: {
          required,
          decimal,
          percentageAllowNegative
        },
        correctionTilerFactor: {
          required,
          decimal,
          percentageAllowNegative
        },
        correctionConstructionFactor: {
          required,
          decimal,
          percentageAllowNegative
        }
      }
    }
  }

  customBetween(value: string | number, min: number, max: number) {
    if (value === null || value === undefined || value === '') {
      return true
    }
    const numberValue = Number(value.toString().replace(',', '.'))
    return numberValue >= min && numberValue <= max
  }

  // Intersect keys of ProjectMargins and ProjectDetailsQuery_projects types
  // to prevent invalid parameter keys
  getNewValidOrCurrentMargin(key: keyof ProjectMargins & keyof ProjectDetailsFragment) {
    const inputValue = this.projectMargins[key]
    if (!this.$v.projectMargins[key]?.$error && inputValue !== null) {
      // parse percentage to fraction with 2 decimals (e.g. 10.55% => 0.1055)
      return (parseFloat(inputValue?.toString()?.replace(',', '.')) / 100).toFixed(4)
    }
    // Otherwise return the previous value
    return this.project?.[key]?.toString() ?? null
  }

  updateMargins() {
    if (!this.project || this.project.status !== ProjectStatus.Preview) return

    const variables: UpdateProjectCostSettingsVariables = {
      projectId: this.projectId,
      companyMarginSanitary: this.getNewValidOrCurrentMargin('companyMarginSanitary'),
      companyMarginTiles: this.getNewValidOrCurrentMargin('companyMarginTiles'),
      marginInstaller: this.getNewValidOrCurrentMargin('marginInstaller'),
      marginTiler: this.getNewValidOrCurrentMargin('marginTiler'),
      marginBuilder: this.getNewValidOrCurrentMargin('marginBuilder'),
      correctionInstallationWFactor: this.getNewValidOrCurrentMargin('correctionInstallationWFactor'),
      correctionInstallationEFactor: this.getNewValidOrCurrentMargin('correctionInstallationEFactor'),
      correctionInstallationCVFactor: this.getNewValidOrCurrentMargin('correctionInstallationCVFactor'),
      correctionTilerFactor: this.getNewValidOrCurrentMargin('correctionTilerFactor'),
      correctionConstructionFactor: this.getNewValidOrCurrentMargin('correctionConstructionFactor')
    }

    this.$apolloMutate<UpdateProjectCostSettings, UpdateProjectCostSettingsVariables>({
      mutation: UPDATE_PROJECT_COST_SETTINGS,
      variables,
      error: 'E4168'
    })
  }

  handleCustomOfferSettingToggle() {
    this.$apolloMutate<CheckCustomOfferForProject, CheckCustomOfferForProjectVariables>({
      mutation: TOGGLE_CUSTOM_OFFER_SETTING,
      variables: {
        projectId: this.projectId,
        isActive: this.isCustomOfferSettingEnabled
      },
      error: 'E4186'
    })
  }

  handleCustomOfferDivideByRoomsSettingToggle() {
    this.$apolloMutate<CheckDivideCustomOfferByRoomsMutation, CheckDivideCustomOfferByRoomsMutationVariables>({
      mutation: CheckDivideCustomOfferByRoomsDocument,
      variables: {
        projectId: this.projectId,
        isActive: this.divideCustomOfferByRooms
      },
      error: 'E4221'
    })
  }

  handleUseReturnPriceCalculationSettingToggle() {
    this.$apolloMutate<CheckCustomOfferForProject, CheckCustomOfferForProjectVariables>({
      mutation: TOGGLE_USE_RETURNPRICECALCULATION_SETTING,
      variables: {
        projectId: this.projectId,
        isActive: this.isUseReturnPriceCalculationSettingEnabled
      },
      error: 'E4194'
    })
  }

  get emailErrors() {
    const result = Array<string>()

    if (!this.newDossierEmail) {
      return result
    }

    if (!EMAIL_REGEX.test(this.newDossierEmail)) {
      result.push(this.$t('projectDetails.errors.invalidEmail').toString())
    }

    if (this.dossierEmailAddressTableData.some((x) => x.email?.address === this.newDossierEmail)) {
      result.push(this.$t('projectDetails.errors.duplicateEmail').toString())
    }

    return result
  }

  async addDossierContact() {
    if (!this.newDossierEmail || this.emailErrors?.length) return

    this.dossierEmailAddressTableData.push({
      id: EMPTY_GUID,
      email: {
        address: this.newDossierEmail
      }
    })

    await this.updateDossierContacts()

    // Reset the input
    this.newDossierEmail = ''
  }

  async removeDossierContact(contact: ProjectDossierContact) {
    if (this.emailErrors?.length) return
    this.dossierEmailAddressTableData = this.dossierEmailAddressTableData.filter((x) => x.id !== contact.id)
    await this.updateDossierContacts()
  }

  async updateDossierContacts() {
    const variables = {
      projectId: this.projectId,
      contacts: this.dossierEmailAddressTableData.map((x) => ({
        id: x.id,
        email: x.email?.address ?? ''
      }))
    }

    const updatedContactsData = await this.$apolloMutate<
      UpdateProjectDossierContactsMutation,
      UpdateProjectDossierContactsMutationVariables
    >({
      mutation: UpdateProjectDossierContactsDocument,
      variables,
      error: 'E4222'
    })

    if (updatedContactsData !== false) {
      const updatedContacts = updatedContactsData as FetchResult
      this.dossierEmailAddressTableData = updatedContacts.data?.updateProjectDossierContacts
    }
  }

  downloadCostBreakdown() {
    this.isDownloadingCosts = true
    this.$fileApi
      .downloadFile(`/costBreakdown/${this.projectId}`, this.project?.name?.replace('.', '_') ?? 'ProjectCostBreakdown')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4189',
          Message: error
        })
      })
      .finally(() => (this.isDownloadingCosts = false))
  }

  downloadProjectOverview() {
    this.isDownloadingOverview = true
    this.$fileApi
      .downloadFile(
        `/overview/${this.projectId}`,
        `${this.project?.name ?? 'Project'} ${this.$t('projectDetails.downloadOverviewFile')}`
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4211',
          Message: error
        })
      })
      .finally(() => (this.isDownloadingOverview = false))
  }
}
