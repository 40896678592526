
import { CostType, ProjectActivityTarget } from '@/__generated__/globalTypes'
import { Cost, ProductSet } from '@/components/roomSelection/types'
import { getDisplayPrice } from '@/utils/currencyUtils'
import { encodeMediaUrl } from '@/utils/urlUtils'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ProductSetCard extends Vue {
  @Prop() readonly productSet!: ProductSet

  ProjectActivityTarget = ProjectActivityTarget
  cdnUrl = process.env.VUE_APP_CMS_URL
  getDisplayPrice = getDisplayPrice

  CostType = CostType

  get backgroundImage() {
    const path = this.productSet.imagePath
    return `url('${path ? this.cdnUrl + encodeMediaUrl(path) : require('@/assets/no-image.png')}')`
  }

  get costs(): Cost[] {
    return this.productSet?.costs ?? []
  }

  get productSetInfos() {
    return this.productSet.productSetInfos ?? []
  }
}
