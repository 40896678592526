/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AddProductPackageFavouriteMutationVariables = Types.Exact<{
  productPackageId: Types.Scalars['UUID'];
  productSetId: Types.Scalars['UUID'];
}>;


export type AddProductPackageFavouriteMutation = { addProductPackageFavourite: boolean };


export const AddProductPackageFavouriteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"addProductPackageFavourite"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"productPackageId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"productSetId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addProductPackageFavourite"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"productPackageId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"productPackageId"}}},{"kind":"Argument","name":{"kind":"Name","value":"productSetId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"productSetId"}}}]}]}}]} as unknown as DocumentNode<AddProductPackageFavouriteMutation, AddProductPackageFavouriteMutationVariables>;