var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"c-brand-listing"},[_c('v-row',{attrs:{"justify":"space-between","no-gutters":""}},[_c('h1',{attrs:{"data-cy":"brand-list-title"}},[_vm._v(_vm._s(_vm.$t('brand.list.title')))]),_c('div',[_c('v-btn',{staticClass:"text-none",attrs:{"disabled":_vm.isReadonly,"to":{
          name: _vm.routeNames.PRODUCT_PACKAGE_IMPORTS,
          params: {
            propositionType: _vm.Badkamercore_ProjectType.Merkenpropositie
          }
        },"dark":"","data-cy":"view-brand-imports-btn","depressed":"","rounded":""}},[_vm._v(_vm._s(_vm.$t('brand.list.viewImports')))]),_c('v-btn',{staticClass:"text-none ml-2",attrs:{"disabled":_vm.isReadonly,"dark":"","data-cy":"add-brand-btn","depressed":"","rounded":""},on:{"click":_vm.openForm}},[_vm._v(_vm._s(_vm.$t('brand.list.addBrand')))])],1)]),_c('v-divider',{staticClass:"my-6"}),(_vm.$apollo.queries.BrandsViewQuery && _vm.$apollo.queries.BrandsViewQuery.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"tile":true,"type":"table-thead, table-tbody, table-tfoot"}}):_c('div',[(_vm.brands)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.brands,"sort-by":['productPackage.isMain', 'name'],"sort-desc":"","data-cy":"brand-list-table","hide-default-header":""},scopedSlots:_vm._u([{key:"item.productPackage.isMain",fn:function({ item }){return [_c('v-tooltip',{attrs:{"right":"","maxWidth":"500","color":"rgba(0,0,0,0.9)"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"data-cy":"ismain-icon","color":item.productPackage?.isMain ? 'blue lighten-1' : 'grey lighten-2'}},item.productPackage?.isMain ? on : undefined),[_vm._v("mdi-archive-star-outline")])]}}],null,true)},[_c('span',{},[_vm._v(_vm._s(_vm.$t('productPackage.list.isMainTooltip')))])])]}},{key:"item.name",fn:function({ item }){return [_c('b',{attrs:{"data-cy":"brand-list-table-name"}},[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function({ item }){return [(item?.productPackageId)?_c('v-btn',{staticClass:"text-none",attrs:{"disabled":_vm.isReadonly,"to":{
            name: _vm.routeNames.PRODUCT_PACKAGE,
            params: {
              propositionType: _vm.Badkamercore_ProjectType.Merkenpropositie,
              productPackageId: item.productPackageId
            }
          },"data-cy":"brand-list-table-edit-btn","light":"","rounded":"","small":""}},[_vm._v(_vm._s(_vm.$t('brand.list.edit')))]):_vm._e(),(item?.productPackageId)?_c('v-btn',{staticClass:"text-none ml-2",attrs:{"disabled":_vm.isReadonly,"data-cy":"brand-list-table-copy-btn","light":"","rounded":"","small":""},on:{"click":function($event){return _vm.openBrandCopyModal(item)}}},[_vm._v(_vm._s(_vm.$t('brand.list.copy')))]):_vm._e(),_c('v-btn',{staticClass:"text-none ml-2",attrs:{"disabled":_vm.isReadonly,"data-cy":"brand-list-table-edit-content-btn","light":"","rounded":"","small":""},on:{"click":() => _vm.navigateToCms(item)}},[_vm._v(_vm._s(_vm.$t('brand.list.editContent'))+" ")]),_c('v-btn',{staticClass:"text-none ml-2",attrs:{"disabled":_vm.isReadonly || !!item.productPackage?.isMain,"data-cy":"brand-list-table-remove-btn","light":"","rounded":"","small":""},on:{"click":() => _vm.confirmDeleteBrand(item)}},[_vm._v(_vm._s(_vm.$t('global.remove')))])]}}],null,false,2427005904)}):_vm._e()],1),_c('add-brand-dialog',{attrs:{"existingBrands":_vm.brandNames,"show":_vm.showForm},on:{"close":_vm.closeForm}}),_c('copy-brand-dialog',{attrs:{"existingBrandNames":_vm.brandNames,"brandId":_vm.brandToCopyId,"brandName":_vm.brandToCopyName,"show":_vm.showBrandCopyModal},on:{"close":function($event){_vm.showBrandCopyModal = false},"refetch-brands-list-query":function($event){return _vm.$apollo.queries.BrandsViewQuery.refetch()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }