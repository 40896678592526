
import { GetProjectDetailsDocument } from '@/components/projectDetails/gql/__generated__/getProjectDetails.query'
import { ProjectRealEstatesDocument } from '@/components/projectRealEstates/gql/__generated__/getProjectRealEstates.query'
import QUERIES from '@/queries/queries'
import { Badkamercore_ProjectType, ProjectStatus } from '@/__generated__/globalTypes'
import { RefetchQueryDescription } from 'apollo-client/core/watchQueryOptions'
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  CloseProjectDocument,
  CloseProjectMutation,
  CloseProjectMutationVariables
} from './gql/__generated__/closeProject.mutation'
import {
  ProjectFragment,
  ProjectViewDocument,
  ProjectViewQuery,
  ProjectViewQueryVariables
} from './gql/__generated__/projectView.query'
import {
  PublishProjectDocument,
  PublishProjectMutation,
  PublishProjectMutationVariables
} from './gql/__generated__/publishProject.mutation'
import {
  ResetProjectStatusDocument,
  ResetProjectStatusMutation,
  ResetProjectStatusMutationVariables
} from './gql/__generated__/resetProjectStatus.mutation'

@Component({})
export default class ProjectView extends Vue {
  @Prop() projectId!: string

  project: ProjectFragment | null = null

  created() {
    this.fetchData()
  }

  isReadonly() {
    return !this.$store.getters.isAdmin
  }

  fetchData() {
    this.$apollo.addSmartQuery<ProjectViewQuery, ProjectViewQueryVariables>(QUERIES.ProjectViewQuery, {
      query: ProjectViewDocument,
      variables: { projectId: this.projectId },
      fetchPolicy: 'network-only',
      result: (result) => {
        this.project = result?.data?.project ?? null
      },
      update: (data) => data,
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4106',
          Message: error,
          Sub: 'A'
        })
      }
    })
  }

  get showPublish() {
    return this.project && (this.project.status == ProjectStatus.Preview || this.project.status == ProjectStatus.Closed)
  }
  get showUnpublish() {
    return !this.showPublish
  }
  get showClose() {
    return this.project && this.project.status != ProjectStatus.Closed
  }
  get showReopen() {
    return !this.showClose
  }
  get isStylesProject() {
    return this.project?.projectType === Badkamercore_ProjectType.DeBadkamerwerelden
  }
  get isBrandsProject() {
    return this.project?.projectType === Badkamercore_ProjectType.Merkenpropositie
  }

  get isProductPropositionProject() {
    return this.project?.projectType === Badkamercore_ProjectType.Productenpropositie
  }

  get disablePublish() {
    return !this.project || this.project.status == ProjectStatus.Closed || this.isReadonly()
  }
  get disableUnpublish() {
    return !this.project || this.project.status != ProjectStatus.Open || this.isReadonly()
  }
  get disableClose() {
    return !this.project || this.project.status == ProjectStatus.Closed || this.isReadonly()
  }
  get disableReopen() {
    return !this.project || this.project.status != ProjectStatus.Closed || this.isReadonly()
  }

  get queriesToRefetch(): RefetchQueryDescription {
    return [
      {
        query: ProjectViewDocument,
        variables: { projectId: this.projectId }
      },
      {
        query: ProjectRealEstatesDocument,
        variables: { projectId: this.projectId }
      },
      {
        query: GetProjectDetailsDocument,
        variables: { projectId: this.projectId }
      },
      QUERIES.DesignPackagesForProjectQuery,
      QUERIES.ArticlePrices
    ]
  }

  confirmOrNotify() {
    if (this.project?.isPublishable ?? false) {
      this.confirmPublish()
    } else {
      this.notifyUnpublishable()
    }
  }

  notifyUnpublishable() {
    this.$store.dispatch('showNotificationDialog', {
      Code: 'N4001'
    })
  }

  confirmPublish() {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: this.publishProject,
      Code: 'C4001'
    })
  }

  confirmUnpublish() {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: this.unpublishProject,
      Code: 'C4002'
    })
  }

  confirmClose() {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: this.closeProject,
      Code: 'C4003'
    })
  }

  confirmReopen() {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: this.resetProjectStatus,
      Code: 'C4004'
    })
  }

  publishProject() {
    this.$apolloMutate<PublishProjectMutation, PublishProjectMutationVariables>({
      mutation: PublishProjectDocument,
      variables: { projectId: this.projectId },
      refetchQueries: this.queriesToRefetch,
      error: 'E4107'
    })
    this.$store.dispatch('hideConfirmationDialog')
  }

  unpublishProject() {
    this.$apolloMutate<ResetProjectStatusMutation, ResetProjectStatusMutationVariables>({
      mutation: ResetProjectStatusDocument,
      variables: {
        projectId: this.$route.params.projectId
      },
      refetchQueries: this.queriesToRefetch,
      error: 'E4108'
    })
    this.$store.dispatch('hideConfirmationDialog')
  }

  closeProject() {
    this.$apolloMutate<CloseProjectMutation, CloseProjectMutationVariables>({
      mutation: CloseProjectDocument,
      variables: {
        projectId: this.projectId
      },
      refetchQueries: this.queriesToRefetch,
      error: 'E4109'
    })
    this.$store.dispatch('hideConfirmationDialog')
  }

  resetProjectStatus() {
    this.$apolloMutate<ResetProjectStatusMutation, ResetProjectStatusMutationVariables>({
      mutation: ResetProjectStatusDocument,
      variables: {
        projectId: this.$route.params.projectId
      },
      refetchQueries: this.queriesToRefetch,
      error: 'E4110'
    })
    this.$store.dispatch('hideConfirmationDialog')
  }
}
