
import { RouteNames } from '@/router/routeNames'
import { Component, Vue } from 'vue-property-decorator'

const Locales = {
  EN: 'en',
  NL: 'nl'
}

const LANGUAGE_KEY = 'language'

@Component
export default class HeaderComponent extends Vue {
  routeNames = RouteNames

  get toolsIsEnabled() {
    return process.env.VUE_APP_ENABLE_TOOLS_PAGE == 'true'
  }

  get userName() {
    return this.$store.getters.username
  }

  get isCurrentLocaleEN() {
    return this.$i18n.locale == Locales.EN
  }

  get isCurrentLocaleNL() {
    return this.$i18n.locale == Locales.NL
  }

  get activeClassForPropositionLink() {
    return ['styles', 'brands', 'product-packages'].includes(this.$route.name ?? '') ? 'c-header__active' : ''
  }

  get activeClassForRequestsLink() {
    return [
      'drawings',
      RouteNames.DRAWING_REQUEST_DETAILS,
      RouteNames.TECHNICAL_DRAWINGS,
      'custom-offers',
      RouteNames.CUSTOM_OFFER_DETAILS,
      RouteNames.CONTACT_REQUESTS
    ].includes(this.$route.name ?? '')
      ? 'c-header__active'
      : ''
  }

  get activeClassForApplicationLink() {
    return ['categories'].includes(this.$route.name ?? '') ? 'c-header__active' : ''
  }

  get isLogoutPage() {
    return this.$route.name === RouteNames.LOGOUT
  }

  switchLocale() {
    const newLocale = this.isCurrentLocaleNL ? Locales.EN : Locales.NL
    window.localStorage.setItem(LANGUAGE_KEY, newLocale)
    this.$i18n.locale = newLocale
  }

  clickLogout() {
    this.$router.push({
      name: 'logout'
    })
  }
}
