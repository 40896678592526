export const RouteNames = {
  HOME: 'home',
  LOGOUT: 'logout',
  CYPRESSLOGIN: 'cypresslogin',
  OIDC_CALLBACK: 'oidcCallback',
  PROJECTS: 'projects',
  PROJECT: 'project',
  PROJECT_DETAILS: 'project-details',
  PROJECT_REAL_ESTATES: 'project-real-estates',
  PROJECT_LIST_ROOMS: 'project-list-rooms',
  PROJECT_LIST_DESIGN_PACKAGES: 'project-list-design-packages',
  PROJECT_CREATE_DESIGN_PACKAGE: 'project-create-design-package',
  PROJECT_EDIT_DESIGN_PACKAGE: 'project-edit-design-package',
  PROJECT_STYLES: 'project-styles',
  PROJECT_BRANDS: 'project-brands',
  PROJECT_PRODUCT_PACKAGES: 'project-product-packages',
  STYLES: 'styles',
  BRANDS: 'brands',
  PRODUCT_PACKAGES: 'product-packages',
  PRODUCT_PACKAGE: 'product-package',
  MASTER_PRODUCT_PACKAGE: 'master-product-package',
  PRODUCT_PACKAGE_IMPORTS: 'product-package-imports',
  TOOLS: 'tools',
  DRAWING_REQUEST_DETAILS: 'drawing-request-details',
  DRAWINGS_3D: 'drawings',
  TECHNICAL_DRAWINGS: 'technical-drawings',
  CATEGORIES: 'categories',
  ARTICLE_PRICES: 'article-prices',
  ACTIVITIES: 'activities',
  CUSTOM_OFFERS: 'custom-offers',
  CUSTOM_OFFER_DETAILS: 'custom-offer-details',
  REAL_ESTATE_ORDER: 'real-estate-order',
  CONTACT_REQUESTS: 'contact-requests',
  CONTACT_REQUEST_DETAILS: 'contact-request-details',
  REAL_ESTATE_DETAILS: 'real-estate-details',
  EDIT_ACTIVITY_TEMPLATE: 'edit-activity-template',
  ACTIVITIES_TEMPLATES: 'activities-templates'
}
