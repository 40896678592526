/* eslint-disable */
import type * as Types from '../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ResolveSharedWaitingCustomOfferMutationVariables = Types.Exact<{
  customOfferRequestId: Types.Scalars['UUID'];
  notes: Types.Scalars['String'];
}>;


export type ResolveSharedWaitingCustomOfferMutation = { resolveSharedWaitingCustomOffer: boolean };


export const ResolveSharedWaitingCustomOfferDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"resolveSharedWaitingCustomOffer"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"customOfferRequestId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"notes"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resolveSharedWaitingCustomOffer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"customOfferRequestId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"customOfferRequestId"}}},{"kind":"Argument","name":{"kind":"Name","value":"notes"},"value":{"kind":"Variable","name":{"kind":"Name","value":"notes"}}}]}]}}]} as unknown as DocumentNode<ResolveSharedWaitingCustomOfferMutation, ResolveSharedWaitingCustomOfferMutationVariables>;