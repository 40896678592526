
import DebounceMixin from '@/mixins/debounceMixin'
import { CategoryWithProductSetInfoKeysStructure } from '@/views/productPackageView/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import {
  GetProductBrandLinePageDocument,
  GetProductBrandLinePageQuery,
  GetProductBrandLinePageQueryVariables
} from './gql/__generated__/getProductBrandLinePage.query'
import {
  UpdateProductPackageCategoryDocument,
  UpdateProductPackageCategoryMutation,
  UpdateProductPackageCategoryMutationVariables
} from './gql/__generated__/updateProductPackageCategory.mutation'

import {
  Badkamercore_ProjectType,
  Category,
  ProductPackageCategory,
  ProductPackageSet,
  ProductSetBrandline
} from '@/__generated__/globalTypes'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import DeleteProductSetBrandLineDialog from '@/components/productPackages/DeleteProductSetBrandLineDialog.vue'
import { getDisplayPrice } from '@/utils/currencyUtils'
import { CombinationProductSetActivityFragment } from '@/views/productPackageView/gql/__generated__/combinationProductSetActivities.query'
import { ProductPackageViewDataDocument } from '@/views/productPackageView/gql/__generated__/getProductPackageViewData.query'
import { maxLength, required } from 'vuelidate/lib/validators'
import { UpsertActionType } from '../categories/types'
import ProductSetCopyModal from './ProductSetCopyModal.vue'
import ProductSetList from './ProductSetList.vue'
import ProductSetUpsertModal from './ProductSetUpsertModal.vue'
import {
  CreateProductSetBrandlineDocument,
  CreateProductSetBrandlineMutation,
  CreateProductSetBrandlineMutationVariables
} from './gql/__generated__/createProductSetBrandline.mutation'
import { ProductSet } from './types'

@Component({
  mixins: [DebounceMixin],
  components: {
    ProductSetList,
    DeleteProductSetBrandLineDialog,
    ConfirmDialog,
    ProductSetUpsertModal,
    ProductSetCopyModal
  }
})
export default class ProductCategorySection extends Vue {
  @Prop({ required: true }) readonly productPackageId!: string
  @Prop({ default: false }) readonly isMainProductPackage?: boolean
  @Prop({ required: true }) readonly combinationId!: string
  @Prop({ required: true }) readonly category!: CategoryWithProductSetInfoKeysStructure
  @Prop({ required: true }) readonly categoriesCmsContent: Category[] | undefined
  @Prop({ required: true }) readonly allCategories!: ProductPackageCategory[] | undefined
  @Prop({ required: false }) readonly combinationProductSetActivities?: CombinationProductSetActivityFragment[]
  @Prop({ required: true, default: false }) readonly isMasterProductPackage!: boolean

  productSetListKey = 0
  newBrandLineName = ''
  brandLineLoading = false

  showDeleteBrandLineDialog = false
  brandLineIdToDelete = ''
  disableBrandLineToggle = false

  // Data for adding/updating product sets
  showProductSetUpsertModal = false
  productSetToUpdate: ProductPackageSet | null = null
  isProductSetToUpdateDefault: boolean | null = null
  isNoProductNeededToUpdateDefault: boolean | null = null
  upsertProductSetActionType: UpsertActionType | null = null

  // Data for copying a product set
  showProductSetCopyModal = false
  productSetToCopyId = ''
  productSetToCopyName = ''

  brandLineHeaders: DataTableHeader[] = [
    {
      align: 'start',
      value: 'name',
      text: ''
    },
    {
      align: 'end',
      value: 'actions',
      text: ''
    }
  ]

  get brandLines() {
    return this.category.assignedBrandlines || []
  }

  get newBrandLineNameErrors() {
    const errors = new Array<string>()
    if (!this.$v.newBrandLineName.$dirty) {
      return errors
    }
    if (!this.$v.newBrandLineName.maxLength) {
      errors.push(this.$t('productPackage.edit.errors.brandLine.maxLength').toString())
    }
    if (!this.$v.newBrandLineName.required) {
      errors.push(this.$t('productPackage.edit.errors.brandLine.required').toString())
    }
    if (!this.$v.newBrandLineName.isUnique) {
      errors.push(this.$t('productPackage.edit.errors.brandLine.isUnique').toString())
    }
    return errors
  }

  get language() {
    return window.localStorage.getItem('language')
  }

  get queriesToRefetch() {
    return [
      {
        query: ProductPackageViewDataDocument,
        variables: {
          language: this.language,
          productPackageId: this.productPackageId
        }
      }
    ]
  }

  get isPriceVariantsVisible(): boolean {
    return (
      !this.isMasterProductPackage &&
      [
        Badkamercore_ProjectType.DeBadkamerwerelden.toString(),
        Badkamercore_ProjectType.Merkenpropositie.toString()
      ].includes(this.$route.params.propositionType)
    )
  }

  get categoryCmsContent(): Category | undefined {
    return this.categoriesCmsContent?.find((cat) => cat.alias?.alias === this.category.categoryId)
  }

  validations() {
    return {
      newBrandLineName: {
        required,
        maxLength: maxLength(100),
        isUnique: () => {
          return !this.category.assignedBrandlines.some(
            (b) => b?.name?.toLowerCase() === this.newBrandLineName?.toLowerCase()
          )
        }
      }
    }
  }

  setLoading(value: boolean) {
    this.brandLineLoading = value
  }

  async addBrandLine() {
    // Check validation
    this.$v.newBrandLineName.$touch()

    if (this.$v.newBrandLineName.$pending || this.$v.newBrandLineName.$error) return

    try {
      this.brandLineLoading = true
      const result = await this.$apolloMutate<
        CreateProductSetBrandlineMutation,
        CreateProductSetBrandlineMutationVariables
      >({
        mutation: CreateProductSetBrandlineDocument,
        refetchQueries: this.queriesToRefetch,
        variables: {
          brandlineName: this.newBrandLineName,
          categoryId: this.category.categoryId
        },
        error: 'E4162'
      })

      if (result !== false) {
        this.newBrandLineName = ''
        this.$v.$reset()
      }
    } finally {
      this.brandLineLoading = false
    }
  }

  async changeAllowPickByBrand(value: boolean) {
    if (this.disableBrandLineToggle) return

    this.disableBrandLineToggle = true

    this.$store.dispatch('showConfirmationDialog', {
      Callback: async () => {
        this.$store.dispatch('hideConfirmationDialog')
        try {
          await this.$apolloMutate<UpdateProductPackageCategoryMutation, UpdateProductPackageCategoryMutationVariables>(
            {
              mutation: UpdateProductPackageCategoryDocument,
              variables: {
                productPackageId: this.productPackageId,
                categoryId: this.category.categoryId,
                allowPickByBrand: value
              },
              refetchQueries: this.queriesToRefetch,
              error: 'E4161'
            }
          )
        } finally {
          this.disableBrandLineToggle = false
        }
      },
      CancelCallback: () => {
        this.disableBrandLineToggle = false
        this.category.allowPickByBrand = !value
      },
      Code: 'C4022'
    })
  }

  setDeleteBrandLineDialogVisibility(brandLine: ProductSetBrandline) {
    this.brandLineIdToDelete = brandLine.id
    this.showDeleteBrandLineDialog = true
  }

  navigateToBrandLineCMSItem(item: ProductSetBrandline) {
    this.$apollo
      .query<GetProductBrandLinePageQuery, GetProductBrandLinePageQueryVariables>({
        query: GetProductBrandLinePageDocument,
        variables: {
          alias: item.id
        }
      })
      .catch((reason) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4158',
          Message: reason
        })
      })
      .then((response) => {
        if (response) {
          const host = process.env.VUE_APP_CMS_URL
          const contentItemId = response.data.productBrandLineDetails?.[0]?.contentItemId
          if (contentItemId) {
            const url = `${host}/Admin/Contents/ContentItems/${contentItemId}/Edit?returnUrl=%2FAdmin%2FContents%2FContentItems`
            window.open(url, 'editProductBrandLineInCms')
          } else {
            this.$store.dispatch('showErrorDialog', {
              Code: 'E4159'
            })
          }
        }
      })
  }

  getProductSetPrice(item: ProductSet) {
    return item.price ? getDisplayPrice(item.price.amount) : '-'
  }

  openProductSetUpsertModal(productSet?: ProductPackageSet) {
    // Set the product set to edit
    this.productSetToUpdate = productSet ?? null
    this.isProductSetToUpdateDefault = productSet?.isProductPackageDefault ?? null
    this.isNoProductNeededToUpdateDefault = productSet?.isNoProductNeeded ?? null
    this.upsertProductSetActionType = productSet ? UpsertActionType.UPDATE : UpsertActionType.SAVE
    this.showProductSetUpsertModal = true
  }

  openProductSetCopyModal(productSet: ProductPackageSet) {
    this.showProductSetCopyModal = true
    this.productSetToCopyId = productSet.productSet.id
    this.productSetToCopyName =
      productSet.productSet.name + this.$t('productPackage.edit.category.productSets.copy.form.defaultFieldValue')
  }

  closeProductSetCopyModal(ok?: boolean) {
    this.showProductSetCopyModal = false
    this.productSetToCopyId = ''
    this.productSetToCopyName = ''

    if (ok) this.refetchProductSets()
  }

  closeProductSetUpsertModal(ok?: boolean) {
    this.showProductSetUpsertModal = false
    this.productSetToUpdate = null
    this.upsertProductSetActionType = null

    if (ok) this.refetchProductSets()
  }

  refetchProductSets() {
    this.productSetListKey += 1
  }
}
