
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ValidationRule } from 'vuelidate/lib/validators'

enum MeasurementUnits {
  mm2 = 1,
  cm2 = 100,
  m2 = 1000000
}

@Component
export default class AreaMeasurementField extends Vue {
  @Prop() modelValue!: string | null
  @Prop({ default: () => [] }) rules?: ValidationRule[]
  @Prop({ default: '' }) label?: string
  @Prop({ required: true }) disabled!: boolean
  @Prop({ default: false }) disableMeasurementUnitSelection?: boolean
  @Prop({ validator: (input: string) => ['mm2', 'cm2', 'm2'].includes(input), default: 'm2' })
  defaultMeasurementUnit?: string

  measurementUnit = 1000000
  measurementUnits = [
    {
      text: 'mm2',
      value: 1
    },
    {
      text: 'cm2',
      value: 100
    },
    {
      text: 'm2',
      value: 1000000
    }
  ]

  @Watch('defaultMeasurementUnit', { immediate: true })
  onDefaultMeasurementUnitChange(unit: string) {
    this.measurementUnit = MeasurementUnits[unit as keyof typeof MeasurementUnits]
  }

  get displayValue() {
    return this.recalculateDisplayValue()
  }

  set displayValue(value: string | null) {
    value = value === null ? value : value.replace(',', '.')
    const displayVal = Number(value)
    if (value === null || !value?.length || isNaN(displayVal)) {
      this.$emit('update:modelValue', null)
      return
    }

    const emitVal = (displayVal * this.measurementUnit).toString()
    this.$emit('update:modelValue', emitVal)
  }

  recalculateDisplayValue() {
    const modelVal = Number(this.modelValue)
    if (this.modelValue === null || isNaN(modelVal)) return null

    return (modelVal / this.measurementUnit).toString()
  }

  rulesInternal = {
    allowAnything: () => {
      return true
    },
    milimeters: (value: string) => {
      const valNumber = Number(value?.replace(',', '.'))
      return (
        !value?.toString()?.length ||
        (valNumber >= 0 && valNumber <= 1000000) ||
        this.$t('productPackage.edit.category.productSets.upsert.validations.maxValue_1000000')
      )
    },
    centimeters: (value: string) => {
      const valNumber = Number(value?.replace(',', '.'))
      return (
        !value?.toString()?.length ||
        (valNumber >= 0 && valNumber <= 100000) ||
        this.$t('productPackage.edit.category.productSets.upsert.validations.maxValue_100000')
      )
    },
    meters: (value: string) => {
      const valNumber = Number(value?.replace(',', '.'))
      return (
        !value?.toString()?.length ||
        (valNumber >= 0 && valNumber <= 1000) ||
        this.$t('productPackage.edit.category.productSets.upsert.validations.maxValue_1000')
      )
    },
    decimals: (value: string) => {
      const regex = /^[0-9]{1,9}([,.][0-9]{1,2})?$/
      return (
        !value?.toString()?.length ||
        regex.test(value) ||
        this.$t('productPackage.edit.category.productSets.upsert.validations.decimalPlaces2')
      )
    }
  }

  customRule() {
    switch (this.measurementUnit) {
      case 1:
        return [this.rulesInternal.milimeters]
      case 100:
        return [this.rulesInternal.centimeters]
      case 1000000:
        return [this.rulesInternal.meters]
    }

    return [this.rulesInternal.allowAnything]
  }
}
