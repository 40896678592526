
import { Component, Vue, Prop } from 'vue-property-decorator'
import {
  ProjectBrandsDocument,
  ProjectBrandsQuery,
  ProjectBrandsQueryVariables
} from './gql/__generated__/ProjectBrands.query'
import SimpleDataTable from '@/components/simpleDataTable/SimpleDataTable.vue'
import {
  UpdateProjectBrandsDocument,
  UpdateProjectBrandsMutation,
  UpdateProjectBrandsMutationVariables
} from './gql/__generated__/UpdateProjectBrands.mutation'
import QUERIES from '@/queries/queries'
import { Brand } from './types'
import { TableColumn } from '@/components/simpleDataTable/types'

@Component({
  components: {
    SimpleDataTable
  }
})
export default class ProjectBrandsView extends Vue {
  @Prop() readonly projectId!: string
  availableBrands: Brand[] = []
  assignedBrands: Brand[] = []
  loading = false
  brandSearch = ''

  created() {
    this.fetchData()
  }

  isReadonly() {
    return !this.$store.getters.isAdmin
  }

  get headers(): TableColumn[] {
    return [
      {
        key: 'name',
        label: this.$t('projectBrands.list.tableHeaders.name').toString()
      },
      {
        key: 'designPackageCount',
        label: this.$t('projectBrands.list.tableHeaders.designPackageCount').toString()
      }
    ]
  }

  fetchData() {
    this.$apollo.addSmartQuery<ProjectBrandsQuery, ProjectBrandsQueryVariables>(QUERIES.ProjectBrandsQuery, {
      query: ProjectBrandsDocument,
      variables: () => ({
        projectId: this.projectId
      }),
      result: (result) => {
        this.availableBrands =
          result.data.brands?.map((x) => ({
            id: x.id,
            name: x.name
          })) ?? []
        this.assignedBrands =
          result.data.brands
            ?.filter((x) => !!x.usedInProjects.length)
            ?.map((x) => {
              const designPackageCount =
                x.usedInProjects?.[0]?.project?.rooms
                  ?.flatMap((y) => y.designPackages)
                  ?.filter((y) => y.brand?.id == x.id)?.length ?? 0
              return {
                id: x.id,
                name: x.name,
                designPackageCount,
                isReadOnly: !!designPackageCount
              }
            }) ?? []
      },
      update: (data) => data,
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4117',
          Message: error.message
        })
      }
    })
  }

  get filteredAvailableBrands(): Brand[] {
    return (
      this.availableBrands
        ?.filter(
          (x) =>
            !this.assignedBrands.some((y) => y.id === x.id) &&
            x.name?.toLowerCase()?.includes(this.brandSearch?.toLowerCase() ?? '')
        )
        ?.sort((leftSide, rightSide): number => {
          if (leftSide.name < rightSide.name) return -1
          if (leftSide.name > rightSide.name) return 1
          return 0
        }) ?? []
    )
  }

  addBrand(brandId: string) {
    const selectedBrand = this.availableBrands.find((x) => x.id === brandId)
    if (!selectedBrand || this.assignedBrands.some((x) => x.id === brandId)) return

    this.assignedBrands.push({ ...selectedBrand, designPackageCount: 0 })

    this.$nextTick(() => {
      this.brandSearch = ''
    })
  }

  confirmRemoveBrand(brand: Brand) {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: () => {
        this.$store.dispatch('hideConfirmationDialog')
        this.assignedBrands = this.assignedBrands.filter((x) => x.id !== brand.id)
      },
      Code: 'C4041'
    })
  }

  saveBrands() {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: () => {
        this.loading = true
        this.$apolloMutate<UpdateProjectBrandsMutation, UpdateProjectBrandsMutationVariables>({
          mutation: UpdateProjectBrandsDocument,
          variables: {
            projectId: this.projectId,
            brandIds: this.assignedBrands.map((x) => x.id)
          },
          error: 'E4145'
        })
          .then((result) => {
            if (result !== false) this.$apollo.queries.ProjectBrands.refetch()
          })
          .finally(() => {
            this.loading = false
          })
      },
      Code: 'C4043'
    })
  }
}
