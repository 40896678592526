
import { validationMixin } from 'vuelidate'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { required, maxLength } from 'vuelidate/lib/validators'
import {
  CopyStyleDocument,
  CopyStyleMutation,
  CopyStyleMutationVariables
} from './gql/__generated__/CopyStyle.mutation'

@Component({
  mixins: [validationMixin]
})
export default class CopyStyleDialog extends Vue {
  @Prop({ default: false }) show!: boolean
  @Prop({ default: () => [] }) existingStyleNames!: string[]
  @Prop() readonly styleId!: string
  @Prop() styleName!: string

  isSaving = false
  name = ''

  @Watch('show')
  onVisibilityChange() {
    this.name = this.styleName || ''
  }

  get showLoader() {
    return this.$apollo.loading || this.isSaving
  }

  clickClose() {
    this.$v.$reset()
    this.name = ''
    this.$emit('close')
  }

  clickSave() {
    this.$v.$touch()
    if (this.$v.name.$pending || this.$v.name.$error) return

    this.isSaving = true

    this.$apolloMutate<CopyStyleMutation, CopyStyleMutationVariables>({
      mutation: CopyStyleDocument,
      variables: {
        styleId: this.styleId,
        styleName: this.name
      },
      error: 'E4240'
    })
      .then((result) => {
        if (result !== false) {
          this.clickClose()
        }
      })
      .finally(() => {
        this.isSaving = false
        this.$emit('refetch-styles-list-query')
      })
  }

  validations() {
    return {
      name: {
        required,
        maxLength: maxLength(50),
        isUnique: (value: string) => {
          return !this.existingStyleNames.includes(value)
        }
      }
    }
  }

  get nameErrors() {
    const errors = new Array<string>()
    if (!this.$v.name.$dirty) {
      return errors
    }
    if (!this.$v.name.isUnique) {
      errors.push(this.$t('style.copy.errors.name.isUnique').toString())
    }
    if (!this.$v.name.maxLength) {
      errors.push(this.$t('style.copy.errors.name.maxLength').toString())
    }
    if (!this.$v.name.required) {
      errors.push(this.$t('style.copy.errors.name.required').toString())
    }
    return errors
  }
}
