
import { ProductCombinationType } from '@/__generated__/globalTypes'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { SelectedCombination } from './types'

@Component({
  name: 'ProductCombinations'
})
export default class ProductCombinations extends Vue {
  @Prop({ required: true }) readonly combinations!: SelectedCombination[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({ required: true }) v!: any
  @Prop({ default: false }) disabled!: boolean

  ProductCombinationType = ProductCombinationType

  getAltText(combinationType: ProductCombinationType) {
    return this.$t('global.combinations.' + (combinationType as string).toLowerCase()).toString()
  }

  handleClick(combination: SelectedCombination) {
    if (this.disabled) return

    this.v.$touch()
    combination.isSelected = !combination.isSelected
    combination.values = []
    combination.combinationInfo = null
    combination.categoriesWithAssignedProductSets = []
    this.$emit('selectedCombinationsChanged', this.combinations)
  }

  getCombinationIcon(combinationType: ProductCombinationType) {
    const iconName = (combinationType as string).toLowerCase() + '.svg'
    return require('@/assets/product_combinations/' + iconName)
  }

  getIsActive(combination: SelectedCombination) {
    return combination.isSelected
  }

  get getErrors() {
    const errors = new Array<string>()
    if (!this.v.$dirty) {
      return errors
    }
    if (!this.v.selectedCombination) {
      errors.push(this.$t('projectDesignPackage.errors.combinations.selectedCombination').toString())
    }
    return errors
  }
}
