
import { validationMixin } from 'vuelidate'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { integer, minValue, required, maxLength } from 'vuelidate/lib/validators'
import { alphaNumSpace } from '@/utils/validationUtils'
import {
  ProjectRoomsQuery_project_layouts,
  ProjectRoomsQuery_project_rooms
} from '../projectRooms/__generated__/ProjectRoomsQuery'
import { createRoomMutation } from './createRoomMutations'
import { CreateRoom, CreateRoomVariables } from './__generated__/CreateRoom'
import ProjectListDialog from '@/components/projectDetails/ProjectListDialog.vue'

@Component({
  name: 'CreateRoomComponent',
  mixins: [validationMixin],
  components: { ProjectListDialog }
})
export default class CreateRoomComponent extends Vue {
  @Prop({ default: '' }) projectId!: string
  @Prop({ default: () => [] }) existingRooms!: ProjectRoomsQuery_project_rooms[] | null
  @Prop({ default: () => [] }) existingLayouts!: ProjectRoomsQuery_project_layouts[] | null

  isDialogOpened = false
  name = ''
  position = 10
  selectedLayouts = new Array<string>()

  clickImport() {
    this.$v.$touch()
    if (this.$v.selectedLayouts.$pending || this.$v.selectedLayouts.$error || this.selectedLayouts.length !== 1) {
      return
    }

    this.isDialogOpened = true
  }

  closeProjectListDialog() {
    this.isDialogOpened = false
  }

  get isShowProjectListDialog() {
    return this.isDialogOpened
  }

  clickSave() {
    this.$v.$touch()
    if (this.$v.name.$pending || this.$v.name.$error) return
    if (this.$v.position.$pending || this.$v.position.$error) return
    if (this.$v.selectedLayouts.$pending || this.$v.selectedLayouts.$error) return

    this.$apolloMutate<CreateRoom, CreateRoomVariables>({
      mutation: createRoomMutation,
      variables: {
        projectId: this.projectId,
        roomName: this.name,
        roomPosition: parseInt(this.position.toString()),
        layoutIds: this.selectedLayouts
      },
      refetchQueries: ['ProjectQuery', 'ProjectRoomsQuery'],
      error: 'E4121'
    }).then((result) => {
      if (result === false) return

      this.$v.$reset()
      this.name = ''
      this.selectedLayouts = new Array<string>()
    })
  }

  validations() {
    return {
      name: {
        required,
        maxLength: maxLength(40),
        alphaNumSpace,
        isUnique: (value: string) => {
          return !this.existingRooms?.find((x: ProjectRoomsQuery_project_rooms): boolean => x.name === value)
        }
      },
      position: {
        integer,
        minValue: minValue(0),
        required
      },
      selectedLayouts: {
        required
      }
    }
  }

  get nameErrors() {
    const errors = new Array<string>()
    if (!this.$v.name.$dirty) {
      return errors
    }
    if (!this.$v.name.alphaNumSpace) {
      errors.push(this.$t('projectRoom.errors.name.alphaNumSpace').toString())
    }
    if (!this.$v.name.isUnique) {
      errors.push(this.$t('projectRoom.errors.name.isUnique').toString())
    }
    if (!this.$v.name.maxLength) {
      errors.push(this.$t('projectRoom.errors.name.maxLength').toString())
    }
    if (!this.$v.name.required) {
      errors.push(this.$t('projectRoom.errors.name.required').toString())
    }
    return errors
  }

  get positionErrors() {
    const errors = new Array<string>()
    if (!this.$v.position.$dirty) {
      return errors
    }
    if (!this.$v.position.integer) {
      errors.push(this.$t('projectRoom.errors.position.integer').toString())
    }
    if (!this.$v.position.minValue) {
      errors.push(this.$t('projectRoom.errors.position.minValue').toString())
    }
    if (!this.$v.position.required) {
      errors.push(this.$t('projectRoom.errors.position.required').toString())
    }
    return errors
  }

  get layoutErrors() {
    const errors = new Array<string>()
    if (!this.$v.selectedLayouts.$dirty) {
      return errors
    }
    if (!this.$v.selectedLayouts.required) {
      errors.push(this.$t('projectRoom.errors.selectedLayouts.required').toString())
    }
    return errors
  }
}
