import gql from 'graphql-tag'

export const COPY_PRODUCT_PACKAGE = gql`
  mutation copyProductPackage($productPackageId: UUID!, $productPackageName: String!) {
    copyProductPackage(productPackageId: $productPackageId, name: $productPackageName)
  }
`

// This is currently not being used. But I needed the query to test the CREATE_PRODUCT_PACKAGE, so I added it here for when we need it. You're welcome. :)
// export const DELETE_PRODUCT_PACKAGE = gql`
//   mutation deleteProductPackage($productPackageId: UUID!) {
//     deleteProductPackage(productPackageId: $productPackageId)
//   }
// `

export const ASSIGN_PRODUCT_PACKAGE_TO_PROJECT = gql`
  mutation assignProductPackageToProject($projectId: UUID!, $productPackageId: UUID!) {
    assignProductPackageToProject(projectId: $projectId, productPackageId: $productPackageId) {
      productPackage {
        id
      }
    }
  }
`

export const ASSIGN_SINGLE_PRODUCT_PACKAGE_TO_PROJECT = gql`
  mutation assignSingleProductPackageToProject($projectId: UUID!, $productPackageId: UUID!) {
    assignSingleProductPackageToProject(projectId: $projectId, productPackageId: $productPackageId) {
      productPackage {
        id
      }
    }
  }
`

export const UNASSIGN_PRODUCT_PACKAGE_FROM_PROJECT = gql`
  mutation unassignProductPackageFromProject($projectId: UUID!, $productPackageId: UUID!) {
    unassignProductPackageFromProject(projectId: $projectId, productPackageId: $productPackageId)
  }
`
