
import QUERIES from '@/queries/queries'
import {
  Badkamercore_ProjectType,
  Brand,
  Currency,
  ProductCombinationType,
  ProductPackage,
  ProjectStatus,
  Room,
  Style
} from '@/__generated__/globalTypes'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { DataTableHeader } from 'vuetify'
import {
  DeleteDesignPackageDocument,
  DeleteDesignPackageMutation,
  DeleteDesignPackageMutationVariables
} from './gql/__generated__/deleteDesignPackage.mutation'
import {
  GetDesignPackageNamesDocument,
  GetDesignPackageNamesQuery,
  GetDesignPackageNamesQueryVariables
} from './gql/__generated__/getDesignPackageNames.query'
import {
  GetDesignPackagePageDocument,
  GetDesignPackagePageQuery,
  GetDesignPackagePageQueryVariables
} from './gql/__generated__/getDesignPackagePage.query'
import {
  GetDesignPackagesForProjectDocument,
  GetDesignPackagesForProjectQuery,
  GetDesignPackagesForProjectQueryVariables
} from './gql/__generated__/getDesignPackagesForProject.query'
import {
  GenerateVariantDesignPackagesDocument,
  GenerateVariantDesignPackagesMutation,
  GenerateVariantDesignPackagesMutationVariables
} from './gql/__generated__/generateVariantDesignPackages.mutation'
type DesignPackageItem = {
  id: string
  room: string
  style: string | null | undefined
  brand: string | null | undefined
  productPackage: string | null | undefined
  name: string
  hasBath: string
  hasToiletLabel: string
  isXL: string
  currency: string
  price: number
  position: number
  isStandard: string
  combinations: ProductCombinationType[] | null | undefined
  layouts: string
}

type TextBooleanOption = {
  text: string
  value: boolean
}

@Component({
  mixins: [validationMixin]
})
export default class ListDesignPackagesComponent extends Vue {
  @Prop({ default: () => [] }) projectId!: string

  selectedRoom: Room | null = null
  selectedStyle: Style | null = null
  selectedBrand: Brand | null = null
  selectedProductPackage: ProductPackage | null = null
  search = ''
  fetchedData: GetDesignPackagesForProjectQuery | null = null

  isLoading = false

  selectedStandard: TextBooleanOption | null = null
  designPackageNames: { [id: string]: string | null } = {}
  isGeneratingDesignPackages = false

  readonly combinationPresentationOrder: ProductCombinationType[] = [
    ProductCombinationType.Washbasin,
    ProductCombinationType.Shower,
    ProductCombinationType.Toilet,
    ProductCombinationType.Bathtub,
    ProductCombinationType.Fountain,
    ProductCombinationType.Radiator,
    ProductCombinationType.Tiles
  ]

  created() {
    this.fetchData()

    this.$apollo.addSmartQuery<GetDesignPackageNamesQuery, GetDesignPackageNamesQueryVariables>(
      QUERIES.GetDesignPackageNamesQuery,
      {
        query: GetDesignPackageNamesDocument,
        variables: () => ({
          designPackageIds: this.designPackagesIdsWithoutName
        }),
        result: (result) => {
          const data = result.data.designPackagePage
          const ids = [...this.designPackagesIdsWithoutName]
          ids.forEach((id) => {
            this.designPackageNames[id] = data?.find((x) => x?.alias?.alias === id)?.displayName ?? null
          })

          // We need this to force the update of the 'designPackageNames' data value,
          // and so the component renders with the new data
          this.designPackageNames = { ...this.designPackageNames }
        },
        update: (data) => data,
        skip: () => {
          return !this.designPackagesIdsWithoutName.length
        }
      }
    )
  }

  @Watch('fetchedData')
  onPropertyChanged(value: GetDesignPackagesForProjectQuery | null) {
    if (value) {
      const project = value.project
      let notificationCode: string | null = null

      if (!project) return

      if (project?.rooms?.length < 1) {
        // No rooms
        notificationCode = 'N4003'
      } else if (this.isStylesProject && project?.assignedStyles?.length < 1) {
        // styles project without any assigned styles
        notificationCode = 'N4002'
      } else if (this.isBrandsProject && project?.assignedBrands?.length < 1) {
        // brands project without any assigned brands
        notificationCode = 'N4009'
      } else if (this.isProductPropositionProject && project?.assignedProductPackages?.length < 1) {
        // product proposition project without any assigned product packages
        notificationCode = 'N4010'
      }

      if (notificationCode) {
        this.$store.dispatch('showNotificationDialog', {
          Code: notificationCode
        })
      }
    }
  }

  beforeDestroy() {
    this.$store.dispatch('hideNotificationDialog')
  }

  get designPackagesIdsWithoutName() {
    return this.designPackages.filter(({ id }) => this.designPackageNames[id] === undefined).map((x) => x.id)
  }

  get isReadonly() {
    return !this.$store.getters.isAdmin || this.project?.status !== ProjectStatus.Preview
  }

  get headers(): DataTableHeader[] {
    const projectTypeMap = {
      [Badkamercore_ProjectType.DeBadkamerwerelden]: 'style',
      [Badkamercore_ProjectType.Merkenpropositie]: 'brand',
      [Badkamercore_ProjectType.Productenpropositie]: 'productPackage',
      [Badkamercore_ProjectType.MasterWithDefault]: 'master'
    }

    const projectTypeColumnKey =
      projectTypeMap[this.project?.projectType ?? Badkamercore_ProjectType.Productenpropositie]

    const columns = [
      {
        text: this.$t(`projectDesignPackage.labels.designPackageName`).toString(),
        align: 'start',
        sortable: true,
        value: 'name',
        width: '10%'
      },
      {
        text: this.$t('projectDesignPackage.labels.room').toString(),
        align: 'start',
        sortable: true,
        value: 'room',
        width: '10%'
      },
      {
        text: this.$t('projectDesignPackage.labels.layout').toString(),
        align: 'start',
        sortable: true,
        value: 'layouts',
        width: '10%'
      },
      {
        text: this.$t(`projectDesignPackage.labels.${projectTypeColumnKey}`).toString(),
        align: 'start',
        sortable: true,
        value: projectTypeColumnKey,
        width: '10%'
      },
      this.isStylesProject || this.isBrandsProject
        ? {
            text: this.$t('projectDesignPackage.labels.variant').toString(),
            align: 'start',
            sortable: true,
            value: 'variant',
            width: '12%'
          }
        : null,
      {
        text: this.$t('projectDesignPackage.labels.standard').toString(),
        align: 'center',
        sortable: true,
        value: 'isStandard',
        width: '8%'
      },
      {
        text: this.$t('projectDesignPackage.labels.bath').toString(),
        value: 'hasBath',
        align: 'center',
        width: '5%'
      },
      {
        text: this.$t('projectDesignPackage.labels.toilet').toString(),
        value: 'hasToiletLabel',
        align: 'center',
        width: '6%'
      },
      {
        text: this.$t('projectDesignPackage.labels.xl').toString(),
        value: 'isXL',
        align: 'center',
        width: '6%'
      },
      this.isStylesProject || this.isBrandsProject
        ? {
            text: this.$t('projectDesignPackage.labels.totalPrice').toString(),
            value: 'price',
            width: '9%'
          }
        : null,
      {
        text: this.$t('projectDesignPackage.labels.productCombinations').toString(),
        sortable: false,
        value: 'combinations',
        width: '9%'
      },
      {
        text: this.$t('projectDesignPackage.labels.position').toString(),
        value: 'position',
        width: '8%'
      },
      {
        text: this.$t('global.actions').toString(),
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '5%'
      }
    ]

    return columns.filter((c) => !!c) as DataTableHeader[]
  }

  get project() {
    return this.fetchedData?.project
  }

  get rooms() {
    return this.project?.rooms
  }

  get styles() {
    return this.project?.assignedStyles.flatMap((x) => x.style)
  }

  get brands() {
    return this.project?.assignedBrands.flatMap((x) => x.brand)
  }

  get productPackages() {
    return this.fetchedData?.project?.assignedProductPackages.flatMap((x) => x.productPackage)
  }

  get selectBooleanOptions(): TextBooleanOption[] {
    return [
      {
        text: this.$t('global.yes').toString(),
        value: true
      },
      {
        text: this.$t('global.no').toString(),
        value: false
      }
    ]
  }

  get isStylesProject() {
    return this.project?.projectType === Badkamercore_ProjectType.DeBadkamerwerelden
  }

  get isBrandsProject() {
    return this.project?.projectType === Badkamercore_ProjectType.Merkenpropositie
  }

  get isProductPropositionProject() {
    return this.project?.projectType === Badkamercore_ProjectType.Productenpropositie
  }

  get designPackages(): DesignPackageItem[] {
    let rooms = this.project?.rooms
    if (!rooms) {
      return Array<DesignPackageItem>()
    }
    if (this.selectedRoom?.id) {
      rooms = rooms.filter((x) => x.id == this.selectedRoom?.id)
    }

    const designPackageItems = Array<DesignPackageItem>()
    for (const room of rooms) {
      let designPackages = room.designPackages

      if (this.selectedStyle?.id) {
        designPackages = designPackages.filter((x) => x.style?.id === this.selectedStyle?.id)
      }

      if (this.selectedBrand?.id) {
        designPackages = designPackages.filter((x) => x.brand?.id === this.selectedBrand?.id)
      }

      if (this.selectedProductPackage) {
        designPackages = designPackages.filter((x) => x.productPackage?.id === this.selectedProductPackage?.id)
      }

      if (this.selectedStandard) {
        designPackages = designPackages.filter((x) => x.isStandard === this.selectedStandard?.value)
      }

      const items = designPackages.map<DesignPackageItem>((x) => {
        return {
          id: x.id,
          room: room.name,
          style: x.style?.name,
          brand: x.brand?.name,
          productPackage: x.productPackage?.name,
          name: this.designPackageNames[x.id] || x.name,
          variant: x.name,
          hasBath: this.getBooleanText(x.hasBath),
          hasToiletLabel: this.getBooleanText(x.hasToiletLabel),
          isXL: this.getBooleanText(x.isXL),
          currency: this.getCurrencyCharacter(x.price.currency),
          price: Number(x.price.amount),
          position: x.position,
          isStandard: this.getBooleanText(x.isStandard),
          combinations: this.combinationPresentationOrder.filter((comb) =>
            x.productCombinationTypes.some((v) => v == comb)
          ),
          layouts: x.room?.layoutRooms?.map((x) => x?.layout?.name)?.join(', ')
        }
      })
      designPackageItems.push(...items)
    }

    return designPackageItems
  }

  get designPackageCount() {
    if (!this.designPackages) {
      return 0
    }
    return this.designPackages.length
  }

  fetchData() {
    this.$apollo.addSmartQuery<GetDesignPackagesForProjectQuery, GetDesignPackagesForProjectQueryVariables>(
      QUERIES.DesignPackagesForProjectQuery,
      {
        query: GetDesignPackagesForProjectDocument,
        variables: () => ({
          projectId: this.projectId
        }),
        result: (result) => {
          this.fetchedData = result.data
        },
        fetchPolicy: 'network-only',
        update: (data) => data,
        skip: () => !this.projectId,
        error: (error) => {
          this.$store.dispatch('showErrorDialog', {
            Code: 'E4122',
            Message: error.message
          })
        }
      }
    )
  }

  getCurrencyCharacter(currencyISO4217: Currency) {
    switch (currencyISO4217) {
      case Currency.Eur:
        return '€'
      default:
        return '?'
    }
  }

  getBooleanText(value: boolean) {
    return value ? this.$t('global.yes').toString() : ''
  }

  getCombinationIcon(combination: unknown) {
    const iconName = (combination as string).toLowerCase() + '.svg'
    return require('@/assets/product_combinations/' + iconName)
  }

  clickAdd() {
    this.$router.push({
      name: 'project-create-design-package'
    })
  }

  clickDelete(item: DesignPackageItem) {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: () => this.confirmDelete(item),
      Code: 'C4005'
    })
  }

  clickEdit(item: DesignPackageItem) {
    this.$router.push({ name: 'project-edit-design-package', params: { designPackageId: item.id } })
  }

  clickDetails(item: DesignPackageItem) {
    this.$router.push({ name: 'project-design-package-details', params: { designPackageId: item.id } })
  }

  clickNavigateCms(item: DesignPackageItem) {
    this.$apollo
      .query<GetDesignPackagePageQuery, GetDesignPackagePageQueryVariables>({
        query: GetDesignPackagePageDocument,
        variables: {
          designPackageId: item.id
        }
      })
      .then((response) => {
        if (response) {
          const host = process.env.VUE_APP_CMS_URL
          const contentItemId = response.data.designPackagePage?.[0]?.contentItemId
          if (contentItemId) {
            const url = `${host}/Admin/Contents/ContentItems/${contentItemId}/Edit?returnUrl=%2FAdmin%2FContents%2FContentItems`
            window.open(url, 'editStyleInCms')
          } else {
            this.$store.dispatch('showErrorDialog', {
              Code: 'E4135'
            })
          }
        }
      })
      .catch((reason) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4134',
          Message: reason
        })
      })
  }

  confirmDelete(item: DesignPackageItem) {
    this.isLoading = true
    this.$apolloMutate<DeleteDesignPackageMutation, DeleteDesignPackageMutationVariables>({
      mutation: DeleteDesignPackageDocument,
      variables: {
        designPackageId: item.id
      },
      refetchQueries: [
        {
          query: GetDesignPackagesForProjectDocument,
          variables: {
            projectId: this.projectId
          }
        }
      ],
      error: 'E4123'
    }).finally(() => {
      this.isLoading = false
      this.$store.dispatch('hideConfirmationDialog')
    })
  }

  clickGeneratePriceVariants() {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: () => this.confirmGeneratePriceVariants(),
      Code: 'C4039'
    })
  }

  confirmGeneratePriceVariants() {
    this.isGeneratingDesignPackages = true

    this.$apolloMutate<GenerateVariantDesignPackagesMutation, GenerateVariantDesignPackagesMutationVariables>({
      mutation: GenerateVariantDesignPackagesDocument,
      variables: {
        projectId: this.projectId
      },
      refetchQueries: [
        {
          query: GetDesignPackagesForProjectDocument,
          variables: {
            projectId: this.projectId
          }
        }
      ],
      error: 'E4238'
    }).finally(() => (this.isGeneratingDesignPackages = false))
    this.$store.dispatch('hideConfirmationDialog')
  }
}
