
import { validationMixin } from 'vuelidate'
import { Component, Vue } from 'vue-property-decorator'
import { NotificationDialogModel } from '@/types/dialogTypes'

@Component({
  mixins: [validationMixin]
})
export default class NotificationDialogComponent extends Vue {
  get show(): boolean {
    return this.$store.state.notificationDialog.IsVisible
  }

  get model(): NotificationDialogModel {
    return this.$store.state.notificationDialog
  }

  get close(): string {
    return this.$t('global.close').toString()
  }

  get code(): string {
    if (this.model.Sub) {
      return `${this.model.Code}${this.model.Sub}`
    } else {
      return `${this.model.Code}`
    }
  }

  get description(): string {
    if (!this.model.Code) {
      return ''
    }
    const description = this.$t(
      `global.dialogs.notification.${this.model.Code}.description`
    ).toString()
    if (this.hasMessage) {
      return `${description}: ${this.model.Message}`
    } else {
      return description
    }
  }

  get hasMessage(): boolean {
    if (this.model.Message) return true
    return false
  }

  get title(): string {
    if (!this.model.Code) {
      return ''
    }
    return this.$t(`global.dialogs.notification.${this.model.Code}.title`).toString()
  }

  clickClose() {
    this.$store.dispatch('hideNotificationDialog')
  }
}
