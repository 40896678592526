
import { Component, Vue, Prop } from 'vue-property-decorator'
import {
  ProjectStylesDocument,
  ProjectStylesQuery,
  ProjectStylesQueryVariables
} from './gql/__generated__/ProjectStyles.query'
import QUERIES from '@/queries/queries'
import { Style } from './types'
import { TableColumn } from '@/components/simpleDataTable/types'
import SimpleDataTable from '../simpleDataTable/SimpleDataTable.vue'
import {
  UpdateProjectStylesDocument,
  UpdateProjectStylesMutation,
  UpdateProjectStylesMutationVariables
} from './gql/__generated__/UpdateProjectStyles.mutation'

@Component({
  components: {
    SimpleDataTable
  }
})
export default class ProjectStylesView extends Vue {
  @Prop() readonly projectId!: string
  availableStyles: Style[] = []
  assignedStyles: Style[] = []
  loading = false
  styleSearch = ''

  created() {
    this.fetchData()
  }

  isReadonly() {
    return !this.$store.getters.isAdmin
  }

  get headers(): TableColumn[] {
    return [
      {
        key: 'name',
        label: this.$t('projectStyles.list.tableHeaders.name').toString()
      },
      {
        key: 'designPackageCount',
        label: this.$t('projectStyles.list.tableHeaders.designPackageCount').toString()
      }
    ]
  }

  fetchData() {
    this.$apollo.addSmartQuery<ProjectStylesQuery, ProjectStylesQueryVariables>(QUERIES.ProjectStylesQuery, {
      query: ProjectStylesDocument,
      variables: () => ({
        projectId: this.projectId
      }),
      result: (result) => {
        this.availableStyles =
          result.data.styles?.map((x) => ({
            id: x.id,
            name: x.name
          })) ?? []
        this.assignedStyles =
          result.data.styles
            ?.filter((x) => !!x.usedInProjects.length)
            ?.map((x) => {
              const designPackageCount =
                x.usedInProjects?.[0]?.project?.rooms
                  ?.flatMap((y) => y.designPackages)
                  ?.filter((y) => y.style?.id == x.id)?.length ?? 0
              return {
                id: x.id,
                name: x.name,
                designPackageCount,
                isReadOnly: !!designPackageCount
              }
            }) ?? []
      },
      update: (data) => data,
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4117',
          Message: error.message
        })
      }
    })
  }

  get filteredAvailableStyles(): Style[] {
    return (
      this.availableStyles
        ?.filter(
          (x) =>
            !this.assignedStyles.some((y) => y.id === x.id) &&
            x.name?.toLowerCase()?.includes(this.styleSearch?.toLowerCase() ?? '')
        )
        ?.sort((leftSide, rightSide): number => {
          if (leftSide.name < rightSide.name) return -1
          if (leftSide.name > rightSide.name) return 1
          return 0
        }) ?? []
    )
  }

  addStyle(styleId: string) {
    const selectedStyle = this.availableStyles.find((x) => x.id === styleId)
    if (!selectedStyle || this.assignedStyles.some((x) => x.id === styleId)) return

    this.assignedStyles.push({ ...selectedStyle, designPackageCount: 0 })

    this.$nextTick(() => {
      this.styleSearch = ''
    })
  }

  confirmRemoveStyle(style: Style) {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: () => {
        this.$store.dispatch('hideConfirmationDialog')
        this.assignedStyles = this.assignedStyles.filter((x) => x.id !== style.id)
      },
      Code: 'C4040'
    })
  }

  saveStyles() {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: () => {
        this.loading = true
        this.$apolloMutate<UpdateProjectStylesMutation, UpdateProjectStylesMutationVariables>({
          mutation: UpdateProjectStylesDocument,
          variables: {
            projectId: this.projectId,
            styleIds: this.assignedStyles.map((x) => x.id)
          },
          error: 'E4118'
        })
          .then((result) => {
            if (result !== false) this.$apollo.queries.ProjectStyles.refetch()
          })
          .finally(() => {
            this.loading = false
          })
      },
      Code: 'C4042'
    })
  }
}
