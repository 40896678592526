
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

const MAX_FILE_SIZE = 100000000 //bytes (~100 MB)

@Component
export default class ActivitiesTemplateImportDialog extends Vue {
  @Prop() show!: boolean
  @Prop() existingImportNames!: string[]

  name = ''
  file?: File | null = null
  isUploading = false

  // the ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] is also a MIME type for excel documents, but in a different(newer) format (Office Open XML) instead of the older one ['application/vnd.ms-excel'] (.xls)
  acceptedFileTypes = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ]

  @Watch('show')
  onVisibilityChange() {
    if (!this.show) {
      this.file = null
      this.name = ''
    }
  }

  rules = {
    maxsize: (value: string) =>
      value?.toString()?.length <= 150 || this.$t('activityTemplate.importActivityTemplateDialog.validations.maxSize')
  }

  get nameErrors() {
    const result: string[] = []

    if (!this.name) return result

    if (this.existingImportNames.includes(this.name.replace(/\s/g, ''))) {
      result.push(this.$t('global.dialogs.upload.errors.duplicateName').toString())
    }

    return result
  }

  get fileErrors() {
    const result: string[] = []

    if (!this.file) return result

    if (this.file.size > MAX_FILE_SIZE) {
      const maxSizeInMB = Math.floor(MAX_FILE_SIZE / 1024 / 1024)
      const message = this.$t('global.dialogs.upload.errors.maxFileSize')
        .toString()
        .replace('{MAX}', maxSizeInMB.toString())
      result.push(message)
    }

    if (!this.acceptedFileTypes.includes(this.file.type)) {
      result.push(this.$t('global.dialogs.upload.errors.invalidType').toString())
    }

    return result
  }

  uploadFile() {
    if (!this.file || this.fileErrors.length || this.isUploading) return
    this.isUploading = true

    this.$fileApi
      .uploadFile(`/projectActivities/${encodeURIComponent(this.name)}`, this.file)
      .then(() => this.$emit('file-uploaded'))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        this.$emit('close')
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4232',
          Message: error
        })
      })
      .finally(() => {
        this.isUploading = false
        this.name = ''
        this.file = null
        this.$emit('close')
      })
  }
}
