
import { Badkamercore_ProjectType, ProductPackage } from '@/__generated__/globalTypes'
import AddProductPackageDialog from '@/components/projectProductPackages/AddProductPackageDialog.vue'
import CopyProductPackageDialog from '@/components/projectProductPackages/CopyProductPackageDialog.vue'
import QUERIES from '@/queries/queries'
import { DataTableHeader } from 'vuetify'
import { RouteNames } from '@/router/routeNames'
import { Component, Vue } from 'vue-property-decorator'
import {
  DeleteProductPackageDocument,
  DeleteProductPackageMutation,
  DeleteProductPackageMutationVariables
} from './gql/__generated__/DeleteProductPackage.mutation'
import {
  ProductPackageListDocument,
  ProductPackageListQuery,
  ProductPackageListQueryVariables
} from './gql/__generated__/GetProductPackageList.query'
import {
  GetProductPackagePageDocument,
  GetProductPackagePageQuery,
  GetProductPackagePageQueryVariables
} from './gql/__generated__/GetProductPackagePage.query'

@Component({
  components: {
    AddProductPackageDialog,
    CopyProductPackageDialog
  }
})
export default class ProductPackageListView extends Vue {
  headers: DataTableHeader[] = [
    { text: '', value: 'isMain', sortable: true, align: 'center', cellClass: 'c-productpackage_listpage__icon-column' },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: 'name'
    },
    { text: '', value: 'actions', sortable: false, align: 'end' }
  ]
  productPackages: ProductPackage[] | null = null

  RouteNames = RouteNames
  Badkamercore_ProjectType = Badkamercore_ProjectType
  showProductPackageAddModal = false

  // Data for copying a product package
  showProductPackageCopyModal = false
  productPackageToCopyId = ''
  productPackageToCopyName = ''

  created() {
    this.fetchData()
  }

  get isReadonly() {
    return !this.$store.getters.isAdmin
  }

  get isLoading() {
    return this.$apollo.queries.ProductPackagesViewQuery?.loading
  }

  get productPackageNames() {
    return this.productPackages?.map((x) => x.name)
  }

  fetchData() {
    this.$apollo.addSmartQuery<ProductPackageListQuery, ProductPackageListQueryVariables>(
      QUERIES.ProductPackageListViewQuery,
      {
        query: ProductPackageListDocument,
        update: (data) => data,
        fetchPolicy: 'network-only',
        result: (result) => {
          this.productPackages = result.data.productPackages as ProductPackage[]
        },
        error: (error) => {
          this.$store.dispatch('showErrorDialog', {
            Code: 'E4150',
            Message: error
          })
        }
      }
    )
  }

  confirmDeleteProductPackage(id: string) {
    this.$showConfirmationDialog({
      awaitConfirm: true,
      Callback: async () => {
        await this.deleteProductPackage(id)
        this.$hideConfirmationDialog()
      },
      Code: 'C4016'
    })
  }

  async deleteProductPackage(id: string) {
    await this.$apolloMutate<DeleteProductPackageMutation, DeleteProductPackageMutationVariables>({
      mutation: DeleteProductPackageDocument,
      variables: {
        productPackageId: id
      },
      refetchQueries: [
        {
          query: ProductPackageListDocument
        }
      ],
      error: 'E4200'
    })
  }

  navigateToCms(id: string) {
    this.$apollo
      .query<GetProductPackagePageQuery, GetProductPackagePageQueryVariables>({
        query: GetProductPackagePageDocument,
        variables: {
          aliasId: id
        }
      })
      .catch((reason) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4151',
          Message: reason
        })
      })
      .then((response) => {
        if (response) {
          const host = process.env.VUE_APP_CMS_URL
          const contentItemId = response.data.productPackagePage?.[0]?.contentItemId
          if (contentItemId) {
            const url = `${host}/Admin/Contents/ContentItems/${contentItemId}/Edit?returnUrl=%2FAdmin%2FContents%2FContentItems`
            window.open(url, 'editProductPackageInCms')
          } else {
            this.$store.dispatch('showErrorDialog', {
              Code: 'E4152'
            })
          }
        }
      })
  }

  openProductPackageCopyModal(productPackage: ProductPackage) {
    this.showProductPackageCopyModal = true
    this.productPackageToCopyId = productPackage.id
    this.productPackageToCopyName = productPackage.name + this.$t('productPackage.copy.form.defaultFieldValue')
  }
}
