import gql from 'graphql-tag'

export const sendNotificationMutation = gql`
  mutation sendNotification(
    $fromName: String!
    $fromEmailAddress: String!
    $toEmailAddress: String!
    $subject: String!
    $templateId: String!
  ) {
    sendNotification(
      fromName: $fromName
      fromEmailAddress: $fromEmailAddress
      toEmailAddress: $toEmailAddress
      subject: $subject
      templateId: $templateId
    )
  }
`
