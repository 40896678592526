
import QUERIES from '@/queries/queries'
import { RouteNames } from '@/router/routeNames'
import { toLocaleDateTimeString } from '@/utils/dateUtils'
import { ContactType, MessageDto, MessageStatus } from '@/__generated__/globalTypes'
import { Component, Prop, Vue } from 'vue-property-decorator'
import MessageThread from '@/components/messageThread/MessageThread.vue'
import CloseContactRequestDialog from '@/components/closeContactRequestDialog/CloseContactRequestDialog.vue'
import {
  GetContactRequestsDetailsQuery,
  GetContactRequestsDetailsQueryVariables,
  GetContactRequestsDetailsDocument,
  ContactRequestDetailsFragment,
  ContactRequestMessageDetailFragment
} from './gql/__generated__/getContactRequestDetails.query'
import {
  UpdateContactRequestStatusDocument,
  UpdateContactRequestStatusMutation,
  UpdateContactRequestStatusMutationVariables
} from './gql/__generated__/updateContactRequestStatus.mutation'

@Component({
  components: {
    MessageThread,
    CloseContactRequestDialog
  }
})
export default class ContactRequestDetailsView extends Vue {
  @Prop({ required: true }) contactRequestId!: string
  contactRequestDetailId = ''
  MESSAGE_MAX_LENGTH = 500
  contactRequestDataMessages: ContactRequestMessageDetailFragment[] | null = null
  contactRequestData: ContactRequestDetailsFragment | null = null
  contactRequestPreferencePhoneNumber = ''
  contactRequestPreference: ContactType = ContactType.NoPreference
  MessageStatus = MessageStatus
  RouteNames = RouteNames
  showCompleteDialog = false

  created() {
    this.$apollo.addSmartQuery<GetContactRequestsDetailsQuery, GetContactRequestsDetailsQueryVariables>(
      QUERIES.GetContactRequestsDetailsQuery,
      {
        query: GetContactRequestsDetailsDocument,
        variables: () => ({ requestId: this.contactRequestId }),
        fetchPolicy: 'network-only',
        update: (data) => data,
        result: (result) => {
          this.contactRequestDataMessages =
            (result.data?.contactRequestByIdAsync?.messages as ContactRequestMessageDetailFragment[]) ?? ''
          this.contactRequestData =
            (result.data?.contactRequestByIdAsync?.messages?.[0] as ContactRequestDetailsFragment) ?? ''
          this.contactRequestDetailId = result.data?.contactRequestByIdAsync?.messages?.[0]?.id ?? ''
          this.contactRequestPreferencePhoneNumber = result.data?.contactRequestByIdAsync?.phone ?? ''
          this.contactRequestPreference = (result.data?.contactRequestByIdAsync?.contactType as ContactType) ?? ''
          if (this.contactRequestData?.status === MessageStatus.New) {
            this.changeStatus(MessageStatus.Pending)
          }
        }
      }
    )
  }

  get language() {
    return this.$i18n.locale
  }

  get contactRequestPhoneNumber() {
    if (this.contactRequestPreferencePhoneNumber.length) {
      return this.contactRequestPreferencePhoneNumber
    } else return this.contactRequestData?.realEstate?.contact?.phone?.number
  }

  get isCompleted() {
    return this.contactRequestData?.status === MessageStatus.Closed
  }

  get requestDate() {
    return toLocaleDateTimeString(this.contactRequestData?.dateCreated, this.language)
  }

  get modificationDate() {
    return toLocaleDateTimeString(
      this.contactRequestData?.dateModified ?? this.contactRequestData?.dateCreated,
      this.language
    )
  }

  closeCloseContactRequestPopup() {
    this.showCompleteDialog = false
  }
  completeRequest() {
    this.showCompleteDialog = true
  }

  async changeStatus(status: MessageStatus) {
    const result = await this.$apolloMutate<
      UpdateContactRequestStatusMutation,
      UpdateContactRequestStatusMutationVariables
    >({
      mutation: UpdateContactRequestStatusDocument,
      variables: {
        requestId: this.contactRequestDetailId,
        status
      },
      error: 'E4206'
    })

    if (result !== false && result.data?.updateMessageStatus) {
      this.contactRequestData = {
        ...this.contactRequestData,
        ...result.data.updateMessageStatus
      } as ContactRequestDetailsFragment
    }
  }

  getMessageDateAndSender(msg: MessageDto) {
    return `[${new Date(Date.parse(msg.dateCreated)).toLocaleDateString(this.language, {
      dateStyle: 'full'
    })}] ${msg.sender}:`
  }
}
