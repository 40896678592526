import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
// @ts-ignore
import ActivitiesList from '@/components/activities/ActivitiesList.vue'
import EditActivityTemplate from '@/components/activitiesTemplates/EditActivityTemplate.vue'
import ActivitiesTemplates from '@/components/activitiesTemplates/ActivitiesTemplates.vue'
import ArticlePricesList from '@/components/articlePrices/ArticlePrices.vue'
import DesignPackageComponent from '@/components/designPackages/DesignPackageComponent.vue'
import ListDesignPackagesComponent from '@/components/designPackages/ListDesignPackagesComponent.vue'
import ProjectBrands from '@/components/projectBrands/ProjectBrands.vue'
import ProjectDetails from '@/components/projectDetails/ProjectDetails.vue'
import ProjectProductPackages from '@/components/projectProductPackages/ProjectProductPackages.vue'
import ProjectRealEstates from '@/components/projectRealEstates/ProjectRealEstates.vue'
import ProjectStyles from '@/components/projectStyles/ProjectStyles.vue'
import ListRoomComponent from '@/components/rooms/projectRooms/ProjectRoomsComponent.vue'
import { oidcSettings } from '@/config/oidc'
import store from '@/store'
import OidcCallback from '@/views/OidcCallback.vue'
import BrandsView from '@/views/brandsView/BrandsView.vue'
import CategoriesView from '@/views/categoriesView/CategoriesView.vue'
import ContactRequestDetailsView from '@/views/contactRequestDetailsView/ContactRequestDetailsView.vue'
import ContactRequestListView from '@/views/contactRequestListView/ContactRequestListView.vue'
import CustomOfferDetailsView from '@/views/customOfferDetailsView/CustomOfferDetailsView.vue'
import CustomOfferListView from '@/views/customOfferListView/CustomOfferListView.vue'
import DrawingRequestDetailsView from '@/views/drawingRequestDetailsView/DrawingRequestDetailsView.vue'
import DrawingsView from '@/views/drawingsView/DrawingsView.vue'
import ProductPackageImportsView from '@/views/productPackageImportsView/ProductPackageImportsView.vue'
import ProductPackageListView from '@/views/productPackageListView/ProductPackageListView.vue'
import ProductPackageView from '@/views/productPackageView/ProductPackageView.vue'
import ProjectListView from '@/views/projectListView/ProjectListView.vue'
import ProjectView from '@/views/projectView/ProjectView.vue'
import RealEstateDetailsView from '@/views/realEstateDetailsView/RealEstateDetailsView.vue'
import RealEstateOrderView from '@/views/realEstateOrderView/RealEstateOrderView.vue'
import StylesView from '@/views/stylesView/StylesView.vue'
import TechnicalDrawingsView from '@/views/technicalDrawingsView/TechnicalDrawingsView.vue'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import LoginCypress from '../views/LoginCypress/LoginCypress.vue'
import Logout from '../views/Logout.vue'
import Tools from '../views/Tools.vue'
import { RouteNames } from './routeNames'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    redirect: '/project'
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
      store.dispatch('removeOidcUser')
      next()
    }
  },
  {
    path: '/cypress-login',
    name: 'cypresslogin',
    component: LoginCypress
  },
  {
    path: '/oidc-callback', // Needs to match redirectUri (redirect_uri if you use snake case) in you oidcSettings
    name: 'oidcCallback',
    component: OidcCallback
  },
  {
    path: '/project',
    name: 'projects',
    component: ProjectListView
  },
  {
    path: '/project/:projectId',
    name: 'project',
    component: ProjectView,
    props: true,
    children: [
      {
        path: 'details',
        name: 'project-details',
        component: ProjectDetails,
        props: true
      },
      {
        path: 'real-estates',
        name: 'project-real-estates',
        component: ProjectRealEstates,
        props: true
      },
      {
        path: 'rooms',
        name: 'project-list-rooms',
        component: ListRoomComponent,
        props: true
      },
      {
        path: 'design-packages',
        name: 'project-list-design-packages',
        component: ListDesignPackagesComponent,
        props: true
      },
      {
        path: 'design-packages/create',
        name: 'project-create-design-package',
        component: DesignPackageComponent,
        props: { designPackageId: undefined }
      },
      {
        path: 'design-packages/:designPackageId/edit',
        name: 'project-edit-design-package',
        component: DesignPackageComponent,
        props: true
      },
      {
        path: 'design-packages/:designPackageId/details',
        name: 'project-design-package-details',
        component: DesignPackageComponent,
        props: { isReadOnly: true }
      },
      {
        path: 'styles',
        name: 'project-styles',
        component: ProjectStyles,
        props: true
      },
      {
        path: 'brands',
        name: 'project-brands',
        component: ProjectBrands,
        props: true
      },
      {
        path: 'product-packages',
        name: 'project-product-packages',
        component: ProjectProductPackages,
        props: true
      },
      {
        path: 'article-prices',
        name: RouteNames.ARTICLE_PRICES,
        component: ArticlePricesList,
        props: true
      },
      {
        path: 'activities',
        name: RouteNames.ACTIVITIES,
        component: ActivitiesList,
        props: true
      }
    ]
  },
  {
    path: '/style',
    name: RouteNames.STYLES,
    component: StylesView
  },
  {
    path: '/custom-offers',
    name: RouteNames.CUSTOM_OFFERS,
    component: CustomOfferListView
  },
  {
    path: '/brand',
    name: RouteNames.BRANDS,
    component: BrandsView
  },
  {
    path: '/3d-drawings',
    name: RouteNames.DRAWINGS_3D,
    component: DrawingsView
  },
  {
    path: '/drawing-request/:id',
    name: RouteNames.DRAWING_REQUEST_DETAILS,
    component: DrawingRequestDetailsView
  },
  {
    path: '/technical-drawings',
    name: RouteNames.TECHNICAL_DRAWINGS,
    component: TechnicalDrawingsView
  },
  {
    path: '/contact-requests',
    name: RouteNames.CONTACT_REQUESTS,
    component: ContactRequestListView
  },
  {
    path: '/contact-request/:contactRequestId',
    name: RouteNames.CONTACT_REQUEST_DETAILS,
    props: true,
    component: ContactRequestDetailsView
  },
  {
    path: '/product-packages',
    name: RouteNames.PRODUCT_PACKAGES,
    component: ProductPackageListView
  },
  {
    path: '/product-package/:propositionType/:productPackageId',
    name: RouteNames.PRODUCT_PACKAGE,
    props: true,
    component: ProductPackageView
  },
  {
    path: '/master-product-package/',
    name: RouteNames.MASTER_PRODUCT_PACKAGE,
    component: ProductPackageView
  },
  {
    path: '/categories',
    name: RouteNames.CATEGORIES,
    component: CategoriesView
  },
  {
    path: '/activities-templates',
    name: RouteNames.ACTIVITIES_TEMPLATES,
    component: ActivitiesTemplates
  },
  {
    path: '/activity-template/:activityTemplateId',
    name: RouteNames.EDIT_ACTIVITY_TEMPLATE,
    component: EditActivityTemplate,
    props: true
  },
  {
    path: '/product-package-imports/:propositionType',
    name: RouteNames.PRODUCT_PACKAGE_IMPORTS,
    component: ProductPackageImportsView,
    props: true
  },
  {
    path: '/project/:projectId/real-estate-order/:realEstateId',
    name: RouteNames.REAL_ESTATE_ORDER,
    component: RealEstateOrderView
  },
  {
    path: '/custom-offer-request/:id',
    name: RouteNames.CUSTOM_OFFER_DETAILS,
    component: CustomOfferDetailsView,
    props: true
  },
  {
    path: '/real-estate/:id',
    name: RouteNames.REAL_ESTATE_DETAILS,
    component: RealEstateDetailsView,
    props: true
  },
  {
    path: '/tools',
    name: 'tools',
    component: Tools,
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
      if (process.env.VUE_APP_ENABLE_TOOLS_PAGE == 'true') {
        next()
      } else {
        next('/')
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const oidcStorageKey = `oidc.user:${oidcSettings.authority}:${oidcSettings.clientId}`
  const cypressTokenStorageKey = `badkamer.user`

  if (localStorage.getItem(oidcStorageKey)) {
    return vuexOidcCreateRouterMiddleware(store)(to, from, next)
  }

  const cypressToken = localStorage.getItem(cypressTokenStorageKey)
  if (cypressToken) {
    store.commit('setCypressToken', cypressToken)
  }

  next()
})

export default router
