
import { Component, Prop, Vue } from 'vue-property-decorator'
import { FieldInformation } from './types'
import { ProductSetInfoKey, ProductSetInfoKeyDataType } from '@/__generated__/globalTypes'

@Component({
  name: 'BoolField'
})
export default class BoolField extends Vue {
  @Prop({ required: true }) readonly id!: string
  @Prop({ required: true }) readonly isOptional!: boolean
  @Prop({ required: true })
  readonly productSetInfoKey!: ProductSetInfoKey
  @Prop({ required: true }) value!: FieldInformation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({ required: true }) v!: any
  @Prop({ required: true }) disabled!: boolean

  selectValues = [
    {
      text: this.$t('global.yes'),
      value: true
    },
    {
      text: this.$t('global.no'),
      value: false
    }
  ]

  onValueChange(inputValue?: boolean | null) {
    this.v?.item2.$touch()
    if (inputValue === undefined) {
      inputValue = null
    }
    if (!this.isOptional) {
      inputValue = inputValue ?? false
    }
    this.$emit('input', {
      item1: this.id,
      item2: inputValue,
      isOptional: this.isOptional,
      dataType: ProductSetInfoKeyDataType.Bool
    } as FieldInformation)
  }

  getLabelText() {
    return this.$t(`projectDesignPackage.labels.restrictionKeys.${this.productSetInfoKey.key}`)
  }

  get getPreSelectValue() {
    if (this.value?.item2 == '1') {
      return true
    }
    if (this.value?.item2 == '0') {
      return false
    }

    return null
  }
}
