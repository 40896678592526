
import UpdateProductSetInfos from '@/components/updateProductSetInfos/UpdateProductSetInfos.vue'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import {
  AddManualProductDocument,
  AddManualProductMutation,
  AddManualProductMutationVariables
} from './gql/__generated__/addManualProduct.mutation'
import { ProjectActivityTarget } from '@/__generated__/globalTypes'

@Component({
  components: {
    UpdateProductSetInfos
  }
})
export default class AddProductModal extends Vue {
  @Prop({ default: false }) show!: boolean

  @Ref() addProductForm?: HTMLFormElement

  isLoading = false

  // Structure to save
  name = ''
  code = ''
  supplierCode = ''
  price = ''
  priceTypeSelection = ''

  rules = {
    required: (value: string) =>
      !!value?.length || this.$t('productPackage.edit.category.productSets.addProductModal.validations.required'),
    money: (value: string) => {
      const regex = /^[0-9]{1,9}([,.][0-9]{1,2})?$/
      return regex.test(value) || this.$t('productPackage.edit.category.productSets.addProductModal.validations.money')
    },
    maxsize: (value: string) =>
      value?.toString()?.length <= 150 ||
      this.$t('productPackage.edit.category.productSets.addProductModal.validations.maxSize')
  }

  get priceTypes() {
    return [
      {
        text: this.$t('productPackage.edit.category.productSets.upsert.priceTypes.METERS'),
        value: ProjectActivityTarget.Meters
      },
      {
        text: this.$t('productPackage.edit.category.productSets.upsert.priceTypes.SQUARE_METERS'),
        value: ProjectActivityTarget.SquareMeters
      },
      {
        text: this.$t('productPackage.edit.category.productSets.upsert.priceTypes.PRODUCT_SET'),
        value: ProjectActivityTarget.ProductSet
      }
    ]
  }

  @Watch('show')
  onshowUpdate() {
    this.addProductForm?.reset()
  }

  async saveProduct() {
    if (!this.addProductForm?.validate()) return

    const requestData = {
      productCode: this.code,
      productDescription: this.name,
      productSupplierCode: this.supplierCode,
      productPriceEuros: Number(this.price.replace(',', '.')) ?? 0,
      priceType: this.priceTypeSelection as ProjectActivityTarget
    }

    this.isLoading = true
    const newProduct = await this.$apolloMutate<AddManualProductMutation, AddManualProductMutationVariables>({
      mutation: AddManualProductDocument,
      variables: requestData,
      error: 'E4223'
    })
    this.isLoading = false

    if (newProduct !== false) {
      const newlyCreatedProduct = newProduct.data?.addManualProduct
      if (!newlyCreatedProduct) return

      this.$emit('new-product-created', newlyCreatedProduct)
      this.$emit('close')
    }
  }
}
