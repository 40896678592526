
import { ErrorDialogModel } from '@/types/dialogTypes'
import { Component, Vue } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class ErrorDialog extends Vue {
  get show(): boolean {
    return this.$store.state.errorDialog.IsVisible
  }

  get model(): ErrorDialogModel {
    return this.$store.state.errorDialog
  }

  get close(): string {
    return this.$t('global.close').toString()
  }

  get code(): string {
    if (this.model.Sub) {
      return `${this.model.Code}${this.model.Sub}`
    } else {
      return `${this.model.Code}`
    }
  }

  get description(): string {
    if (!this.model.Code) {
      return ''
    }
    const description = this.$t(`global.dialogs.error.${this.model.Code}.description`).toString()
    if (this.hasMessage) {
      return this.replaceTokens(`${description}: ${this.model.Message}`)
    } else {
      return this.replaceTokens(description)
    }
  }

  get hasMessage(): boolean {
    if (this.model.Message) return true
    return false
  }

  get title(): string {
    if (!this.model.Code) {
      return ''
    }
    return this.$t(`global.dialogs.error.${this.model.Code}.title`).toString()
  }

  private replaceTokens(message: string): string {
    if (!this.model.Data) {
      return message
    }
    let outMessage = message
    this.model.Data.forEach(
      (dataItem) => (outMessage = outMessage.replaceAll(`{{${dataItem.key.toUpperCase()}}}`, dataItem.value))
    )
    return outMessage
  }

  clickClose() {
    this.model.Callback?.()
    this.$store.dispatch('hideErrorDialog')
  }
}
