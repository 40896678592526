/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The confirmed status types for a real estate
 */
export enum ConfirmedStatusType {
  ADDITIONAL_WORK = "ADDITIONAL_WORK",
  CASCO = "CASCO",
  STANDARD = "STANDARD",
}

/**
 * Contact type.
 */
export enum ContactType {
  EMAIL = "EMAIL",
  NO_PREFERENCE = "NO_PREFERENCE",
  PHONE = "PHONE",
}

/**
 * Available cost type in badkamer
 */
export enum CostType {
  COORDINATION_COMMISSION_BUILDER_COSTS = "COORDINATION_COMMISSION_BUILDER_COSTS",
  COORDINATION_COMMISSION_COSTS = "COORDINATION_COMMISSION_COSTS",
  COORDINATION_COMMISSION_INSTALLER_COSTS = "COORDINATION_COMMISSION_INSTALLER_COSTS",
  CUSTOM_OFFER_COSTS = "CUSTOM_OFFER_COSTS",
  CV_INSTALLATION_COSTS = "CV_INSTALLATION_COSTS",
  DRAWING_COSTS = "DRAWING_COSTS",
  E_INSTALLATION_COSTS = "E_INSTALLATION_COSTS",
  INSTALLATION_CONSTRUCTION_COSTS = "INSTALLATION_CONSTRUCTION_COSTS",
  LOWER_PRICE_STANDARD_PRODUCTS_COSTS = "LOWER_PRICE_STANDARD_PRODUCTS_COSTS",
  PRODUCTS_AND_INSTALLATION_MATERIALS_COSTS = "PRODUCTS_AND_INSTALLATION_MATERIALS_COSTS",
  TILER_INSTALLATION_COSTS = "TILER_INSTALLATION_COSTS",
  TILES_AND_FASTENERS_COSTS = "TILES_AND_FASTENERS_COSTS",
  TOTAL_SURCHARGE_COSTS = "TOTAL_SURCHARGE_COSTS",
  VAT_COSTS = "VAT_COSTS",
  W_INSTALLATION_COSTS = "W_INSTALLATION_COSTS",
}

/**
 * Available currencies in badkamer according to ISO 4217 standard
 */
export enum Currency {
  EUR = "EUR",
}

/**
 * The type of a custom offer request.
 */
export enum CustomOfferAppointmentType {
  CONTACT = "CONTACT",
  MEETING = "MEETING",
}

/**
 * The valid status for a custom offer request.
 */
export enum CustomOfferRequestStatus {
  APPOINTMENT_PLANNED = "APPOINTMENT_PLANNED",
  CANCELLED = "CANCELLED",
  MAKE_AN_APPOINTMENT = "MAKE_AN_APPOINTMENT",
  NEW = "NEW",
  REQUEST_IN_PROGRESS = "REQUEST_IN_PROGRESS",
  SHARED_WITH_CUSTOMER = "SHARED_WITH_CUSTOMER",
}

/**
 * Custom Offer Status.
 */
export enum CustomOfferStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}

/**
 * The valid status for a drawing request.
 */
export enum DrawingRequestStatus {
  CANCELLED = "CANCELLED",
  NOT_REQUESTED = "NOT_REQUESTED",
  READY = "READY",
  REQUESTED = "REQUESTED",
}

/**
 * Staatus that an import process can have.
 */
export enum ImportStatus {
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
  NEW = "NEW",
  PROCESSING = "PROCESSING",
}

/**
 * Available product set info Keys in badkamer
 */
export enum ProductCombinationInfoKeyType {
  HAS_BUILTINTAPS = "HAS_BUILTINTAPS",
  NOT_SET = "NOT_SET",
}

/**
 * Available product combinations in badkamer
 */
export enum ProductCombinationType {
  BATHTUB = "BATHTUB",
  FOUNTAIN = "FOUNTAIN",
  NOT_SET = "NOT_SET",
  RADIATOR = "RADIATOR",
  SHOWER = "SHOWER",
  TILES = "TILES",
  TOILET = "TOILET",
  UTILITIES = "UTILITIES",
  WASHBASIN = "WASHBASIN",
}

/**
 * Available Product Set Info compare operations
 */
export enum ProductSetInfoCompareOperation {
  DIFF = "DIFF",
  EQ = "EQ",
  GEQ = "GEQ",
  GT = "GT",
  LEQ = "LEQ",
  LT = "LT",
}

/**
 * Available data types for Informations.
 */
export enum ProductSetInfoKeyDataType {
  BOOL = "BOOL",
  ENUM = "ENUM",
  NUMBER = "NUMBER",
  TEXT = "TEXT",
}

/**
 * Available product set info Keys in badkamer
 */
export enum ProductSetInfoKeyType {
  ALLOW_BATHTYPE_FREESTANDING = "ALLOW_BATHTYPE_FREESTANDING",
  ALLOW_BATHTYPE_WHIRLPOOL = "ALLOW_BATHTYPE_WHIRLPOOL",
  ALLOW_DRAINAGE = "ALLOW_DRAINAGE",
  ALLOW_DRAINAGE_GUTTER = "ALLOW_DRAINAGE_GUTTER",
  ALLOW_POSITION_TAPS_BUILTIN = "ALLOW_POSITION_TAPS_BUILTIN",
  ALLOW_SHOWER_TOILET = "ALLOW_SHOWER_TOILET",
  BASIC_INCLUDED = "BASIC_INCLUDED",
  BATHTYPE = "BATHTYPE",
  BATH_DRAINAGE = "BATH_DRAINAGE",
  BATH_POSITION_TAPS = "BATH_POSITION_TAPS",
  BRAND = "BRAND",
  COLOUR = "COLOUR",
  CORNER_BASIN = "CORNER_BASIN",
  DEPTH = "DEPTH",
  DEPTH_RANGE = "DEPTH_RANGE",
  DRAINAGE = "DRAINAGE",
  ELECTRICITY = "ELECTRICITY",
  FOR_RESERVOIR_TYPE = "FOR_RESERVOIR_TYPE",
  FOUNTAIN_POSITION_TAPS = "FOUNTAIN_POSITION_TAPS",
  GRIP = "GRIP",
  HAS_BOWL = "HAS_BOWL",
  HAS_BUILTINTAPS = "HAS_BUILTINTAPS",
  HAS_PARTITIONWALL = "HAS_PARTITIONWALL",
  HAS_TAPHOLES = "HAS_TAPHOLES",
  HAS_WHIRLPOOL = "HAS_WHIRLPOOL",
  HEIGHT = "HEIGHT",
  INSTALL_WIDTH = "INSTALL_WIDTH",
  IS_NO_PRODUCT = "IS_NO_PRODUCT",
  IS_SHOWER_DOOR_BETWEEN_WALLS = "IS_SHOWER_DOOR_BETWEEN_WALLS",
  LENGTH = "LENGTH",
  MATERIAL = "MATERIAL",
  MIN_DEPTH = "MIN_DEPTH",
  MIN_LENGTH = "MIN_LENGTH",
  MIN_WIDTH = "MIN_WIDTH",
  NOT_SET = "NOT_SET",
  NUMBER_DRAWERS = "NUMBER_DRAWERS",
  NUMBER_TAPS = "NUMBER_TAPS",
  NUMBER_WASHBASINS = "NUMBER_WASHBASINS",
  POSITION_TAPS = "POSITION_TAPS",
  RADIATOR_CAPACITY = "RADIATOR_CAPACITY",
  RADIATOR_TYPE = "RADIATOR_TYPE",
  RESERVOIR_TYPE = "RESERVOIR_TYPE",
  SERIES = "SERIES",
  SHOWER_DRAINAGE = "SHOWER_DRAINAGE",
  SHOWER_POSITION_TAPS = "SHOWER_POSITION_TAPS",
  SHOWER_TOILET = "SHOWER_TOILET",
  WASHBASIN_POSITION_TAPS = "WASHBASIN_POSITION_TAPS",
  WIDTH = "WIDTH",
}

/**
 * Available product set status in badkamer
 */
export enum ProductSetStatus {
  AVAILABLE = "AVAILABLE",
  DISABLED = "DISABLED",
  OBSOLETE = "OBSOLETE",
  RETIRED = "RETIRED",
}

/**
 * The possible statusses of a project.
 */
export enum ProjectStatus {
  CLOSED = "CLOSED",
  ENDED = "ENDED",
  OPEN = "OPEN",
  PREVIEW = "PREVIEW",
}

/**
 * Available reminder notification types.
 */
export enum ReminderNotificationType {
  NO_LOGIN = "NO_LOGIN",
  NO_QUOTE = "NO_QUOTE",
}

/**
 * The request for quote selection options
 */
export enum SelectionType {
  CUSTOM = "CUSTOM",
  DESIGN_PACKAGE = "DESIGN_PACKAGE",
  SHELL_ONLY = "SHELL_ONLY",
  STANDARD = "STANDARD",
}

/**
 * Showroom locations.
 */
export enum ShowroomLocation {
  BUNNIK = "BUNNIK",
  DRACHTEN = "DRACHTEN",
}

export enum SortOperationKind {
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * The valid types for a project
 */
export enum badkamercore_ProjectType {
  DE_BADKAMERWERELDEN = "DE_BADKAMERWERELDEN",
  MERKENPROPOSITIE = "MERKENPROPOSITIE",
  PRODUCTENPROPOSITIE = "PRODUCTENPROPOSITIE",
}

/**
 * Properties for adding a new default product set.
 */
export interface AddDefaultProductSetDtoInput {
  setAsProjectDefault: boolean;
  productCategoryId: any;
  productSetId: any;
}

/**
 * Brand represents a single brand for decorating rooms.
 */
export interface BrandFilter {
  AND?: BrandFilter[] | null;
  OR?: BrandFilter[] | null;
  name?: string | null;
  name_not?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  usedInProjects_some?: ProjectBrandFilter | null;
  usedInProjects_none?: ProjectBrandFilter | null;
  usedInProjects_all?: ProjectBrandFilter | null;
  usedInProjects_any?: boolean | null;
  usedInDesignPackages_some?: DesignPackageFilter | null;
  usedInDesignPackages_none?: DesignPackageFilter | null;
  usedInDesignPackages_all?: DesignPackageFilter | null;
  usedInDesignPackages_any?: boolean | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * Brand represents a single brand for decorating rooms.
 */
export interface BrandSort {
  name?: SortOperationKind | null;
  id?: SortOperationKind | null;
}

/**
 * CustomOffer represents a CustomOffer.
 */
export interface CustomOfferFilter {
  AND?: CustomOfferFilter[] | null;
  OR?: CustomOfferFilter[] | null;
  customOfferRequestId?: any | null;
  customOfferRequestId_not?: any | null;
  customOfferRequestId_in?: any[] | null;
  customOfferRequestId_not_in?: any[] | null;
  customOfferRequestId_gt?: any | null;
  customOfferRequestId_not_gt?: any | null;
  customOfferRequestId_gte?: any | null;
  customOfferRequestId_not_gte?: any | null;
  customOfferRequestId_lt?: any | null;
  customOfferRequestId_not_lt?: any | null;
  customOfferRequestId_lte?: any | null;
  customOfferRequestId_not_lte?: any | null;
  customOfferRequest?: RequestForCustomOfferFilter | null;
  version?: number | null;
  version_not?: number | null;
  version_in?: number[] | null;
  version_not_in?: number[] | null;
  version_gt?: number | null;
  version_not_gt?: number | null;
  version_gte?: number | null;
  version_not_gte?: number | null;
  version_lt?: number | null;
  version_not_lt?: number | null;
  version_lte?: number | null;
  version_not_lte?: number | null;
  strVersion?: string | null;
  strVersion_not?: string | null;
  strVersion_contains?: string | null;
  strVersion_not_contains?: string | null;
  strVersion_starts_with?: string | null;
  strVersion_not_starts_with?: string | null;
  strVersion_ends_with?: string | null;
  strVersion_not_ends_with?: string | null;
  strVersion_in?: string[] | null;
  strVersion_not_in?: string[] | null;
  notes?: string | null;
  notes_not?: string | null;
  notes_contains?: string | null;
  notes_not_contains?: string | null;
  notes_starts_with?: string | null;
  notes_not_starts_with?: string | null;
  notes_ends_with?: string | null;
  notes_not_ends_with?: string | null;
  notes_in?: (string | null)[] | null;
  notes_not_in?: (string | null)[] | null;
  isSharedWithCustomer?: boolean | null;
  isSharedWithCustomer_not?: boolean | null;
  createdAtUtc?: any | null;
  createdAtUtc_not?: any | null;
  createdAtUtc_in?: any[] | null;
  createdAtUtc_not_in?: any[] | null;
  createdAtUtc_gt?: any | null;
  createdAtUtc_not_gt?: any | null;
  createdAtUtc_gte?: any | null;
  createdAtUtc_not_gte?: any | null;
  createdAtUtc_lt?: any | null;
  createdAtUtc_not_lt?: any | null;
  createdAtUtc_lte?: any | null;
  createdAtUtc_not_lte?: any | null;
  hasFiles?: boolean | null;
  hasFiles_not?: boolean | null;
  assignedRooms_some?: CustomOfferRoomFilter | null;
  assignedRooms_none?: CustomOfferRoomFilter | null;
  assignedRooms_all?: CustomOfferRoomFilter | null;
  assignedRooms_any?: boolean | null;
  name?: string | null;
  name_not?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  status?: CustomOfferStatus | null;
  status_not?: CustomOfferStatus | null;
  status_in?: CustomOfferStatus[] | null;
  status_not_in?: CustomOfferStatus[] | null;
  status_gt?: CustomOfferStatus | null;
  status_not_gt?: CustomOfferStatus | null;
  status_gte?: CustomOfferStatus | null;
  status_not_gte?: CustomOfferStatus | null;
  status_lt?: CustomOfferStatus | null;
  status_not_lt?: CustomOfferStatus | null;
  status_lte?: CustomOfferStatus | null;
  status_not_lte?: CustomOfferStatus | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * CustomOfferRoomCost represents the costs of using a CustomOfferRoomCost.
 */
export interface CustomOfferRoomCostFilter {
  AND?: CustomOfferRoomCostFilter[] | null;
  OR?: CustomOfferRoomCostFilter[] | null;
  customOfferRoomId?: any | null;
  customOfferRoomId_not?: any | null;
  customOfferRoomId_in?: any[] | null;
  customOfferRoomId_not_in?: any[] | null;
  customOfferRoomId_gt?: any | null;
  customOfferRoomId_not_gt?: any | null;
  customOfferRoomId_gte?: any | null;
  customOfferRoomId_not_gte?: any | null;
  customOfferRoomId_lt?: any | null;
  customOfferRoomId_not_lt?: any | null;
  customOfferRoomId_lte?: any | null;
  customOfferRoomId_not_lte?: any | null;
  customOfferRoom?: CustomOfferRoomFilter | null;
  costType?: CostType | null;
  costType_not?: CostType | null;
  costType_in?: CostType[] | null;
  costType_not_in?: CostType[] | null;
  costType_gt?: CostType | null;
  costType_not_gt?: CostType | null;
  costType_gte?: CostType | null;
  costType_not_gte?: CostType | null;
  costType_lt?: CostType | null;
  costType_not_lt?: CostType | null;
  costType_lte?: CostType | null;
  costType_not_lte?: CostType | null;
  cost?: MoneyFilter | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * Contains information about a custom offer room.
 */
export interface CustomOfferRoomDtoInput {
  roomId: any;
  costs: KeyValuePairOfCostTypeAndStringInput[];
}

/**
 * CustomOfferRoom represents the costs of using a CustomOfferRoom.
 */
export interface CustomOfferRoomFilter {
  AND?: CustomOfferRoomFilter[] | null;
  OR?: CustomOfferRoomFilter[] | null;
  customOfferId?: any | null;
  customOfferId_not?: any | null;
  customOfferId_in?: any[] | null;
  customOfferId_not_in?: any[] | null;
  customOfferId_gt?: any | null;
  customOfferId_not_gt?: any | null;
  customOfferId_gte?: any | null;
  customOfferId_not_gte?: any | null;
  customOfferId_lt?: any | null;
  customOfferId_not_lt?: any | null;
  customOfferId_lte?: any | null;
  customOfferId_not_lte?: any | null;
  customOffer?: CustomOfferFilter | null;
  roomId?: any | null;
  roomId_not?: any | null;
  roomId_in?: any[] | null;
  roomId_not_in?: any[] | null;
  roomId_gt?: any | null;
  roomId_not_gt?: any | null;
  roomId_gte?: any | null;
  roomId_not_gte?: any | null;
  roomId_lt?: any | null;
  roomId_not_lt?: any | null;
  roomId_lte?: any | null;
  roomId_not_lte?: any | null;
  room?: RoomFilter | null;
  roomSelectionId?: any | null;
  roomSelectionId_not?: any | null;
  roomSelectionId_in?: (any | null)[] | null;
  roomSelectionId_not_in?: (any | null)[] | null;
  roomSelectionId_gt?: any | null;
  roomSelectionId_not_gt?: any | null;
  roomSelectionId_gte?: any | null;
  roomSelectionId_not_gte?: any | null;
  roomSelectionId_lt?: any | null;
  roomSelectionId_not_lt?: any | null;
  roomSelectionId_lte?: any | null;
  roomSelectionId_not_lte?: any | null;
  assignedCosts_some?: CustomOfferRoomCostFilter | null;
  assignedCosts_none?: CustomOfferRoomCostFilter | null;
  assignedCosts_all?: CustomOfferRoomCostFilter | null;
  assignedCosts_any?: boolean | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * DesignPackageCost represents the costs of using a DesignPackage.
 */
export interface DesignPackageCostFilter {
  AND?: DesignPackageCostFilter[] | null;
  OR?: DesignPackageCostFilter[] | null;
  designPackageId?: any | null;
  designPackageId_not?: any | null;
  designPackageId_in?: any[] | null;
  designPackageId_not_in?: any[] | null;
  designPackageId_gt?: any | null;
  designPackageId_not_gt?: any | null;
  designPackageId_gte?: any | null;
  designPackageId_not_gte?: any | null;
  designPackageId_lt?: any | null;
  designPackageId_not_lt?: any | null;
  designPackageId_lte?: any | null;
  designPackageId_not_lte?: any | null;
  designPackage?: DesignPackageFilter | null;
  costType?: CostType | null;
  costType_not?: CostType | null;
  costType_in?: CostType[] | null;
  costType_not_in?: CostType[] | null;
  costType_gt?: CostType | null;
  costType_not_gt?: CostType | null;
  costType_gte?: CostType | null;
  costType_not_gte?: CostType | null;
  costType_lt?: CostType | null;
  costType_not_lt?: CostType | null;
  costType_lte?: CostType | null;
  costType_not_lte?: CostType | null;
  cost?: MoneyFilter | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * DesignPackageDefaultProductSet represents a default ProductSet for a ProductCategory in a DesignPackage.
 */
export interface DesignPackageDefaultProductSetFilter {
  AND?: DesignPackageDefaultProductSetFilter[] | null;
  OR?: DesignPackageDefaultProductSetFilter[] | null;
  designPackageId?: any | null;
  designPackageId_not?: any | null;
  designPackageId_in?: any[] | null;
  designPackageId_not_in?: any[] | null;
  designPackageId_gt?: any | null;
  designPackageId_not_gt?: any | null;
  designPackageId_gte?: any | null;
  designPackageId_not_gte?: any | null;
  designPackageId_lt?: any | null;
  designPackageId_not_lt?: any | null;
  designPackageId_lte?: any | null;
  designPackageId_not_lte?: any | null;
  designPackage?: DesignPackageFilter | null;
  productCategoryId?: any | null;
  productCategoryId_not?: any | null;
  productCategoryId_in?: any[] | null;
  productCategoryId_not_in?: any[] | null;
  productCategoryId_gt?: any | null;
  productCategoryId_not_gt?: any | null;
  productCategoryId_gte?: any | null;
  productCategoryId_not_gte?: any | null;
  productCategoryId_lt?: any | null;
  productCategoryId_not_lt?: any | null;
  productCategoryId_lte?: any | null;
  productCategoryId_not_lte?: any | null;
  productCategory?: ProductCategoryFilter | null;
  productSetId?: any | null;
  productSetId_not?: any | null;
  productSetId_in?: any[] | null;
  productSetId_not_in?: any[] | null;
  productSetId_gt?: any | null;
  productSetId_not_gt?: any | null;
  productSetId_gte?: any | null;
  productSetId_not_gte?: any | null;
  productSetId_lt?: any | null;
  productSetId_not_lt?: any | null;
  productSetId_lte?: any | null;
  productSetId_not_lte?: any | null;
  productSet?: ProductSetFilter | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * Design Package represents a set of materials, including installation costs, for a single room.
 */
export interface DesignPackageFilter {
  AND?: DesignPackageFilter[] | null;
  OR?: DesignPackageFilter[] | null;
  name?: string | null;
  name_not?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  position?: number | null;
  position_not?: number | null;
  position_in?: number[] | null;
  position_not_in?: number[] | null;
  position_gt?: number | null;
  position_not_gt?: number | null;
  position_gte?: number | null;
  position_not_gte?: number | null;
  position_lt?: number | null;
  position_not_lt?: number | null;
  position_lte?: number | null;
  position_not_lte?: number | null;
  hasWashbasin?: boolean | null;
  hasWashbasin_not?: boolean | null;
  hasShower?: boolean | null;
  hasShower_not?: boolean | null;
  hasToilet?: boolean | null;
  hasToilet_not?: boolean | null;
  hasBathtub?: boolean | null;
  hasBathtub_not?: boolean | null;
  hasFountain?: boolean | null;
  hasFountain_not?: boolean | null;
  hasTiles?: boolean | null;
  hasTiles_not?: boolean | null;
  hasRadiator?: boolean | null;
  hasRadiator_not?: boolean | null;
  hasUtilities?: boolean | null;
  hasUtilities_not?: boolean | null;
  isFixedPrice?: boolean | null;
  isFixedPrice_not?: boolean | null;
  hasBath?: boolean | null;
  hasBath_not?: boolean | null;
  isXL?: boolean | null;
  isXL_not?: boolean | null;
  hasToiletLabel?: boolean | null;
  hasToiletLabel_not?: boolean | null;
  isStandard?: boolean | null;
  isStandard_not?: boolean | null;
  price?: MoneyFilter | null;
  styleId?: any | null;
  styleId_not?: any | null;
  styleId_in?: (any | null)[] | null;
  styleId_not_in?: (any | null)[] | null;
  styleId_gt?: any | null;
  styleId_not_gt?: any | null;
  styleId_gte?: any | null;
  styleId_not_gte?: any | null;
  styleId_lt?: any | null;
  styleId_not_lt?: any | null;
  styleId_lte?: any | null;
  styleId_not_lte?: any | null;
  style?: StyleFilter | null;
  brandId?: any | null;
  brandId_not?: any | null;
  brandId_in?: (any | null)[] | null;
  brandId_not_in?: (any | null)[] | null;
  brandId_gt?: any | null;
  brandId_not_gt?: any | null;
  brandId_gte?: any | null;
  brandId_not_gte?: any | null;
  brandId_lt?: any | null;
  brandId_not_lt?: any | null;
  brandId_lte?: any | null;
  brandId_not_lte?: any | null;
  brand?: BrandFilter | null;
  productPackageId?: any | null;
  productPackageId_not?: any | null;
  productPackageId_in?: (any | null)[] | null;
  productPackageId_not_in?: (any | null)[] | null;
  productPackageId_gt?: any | null;
  productPackageId_not_gt?: any | null;
  productPackageId_gte?: any | null;
  productPackageId_not_gte?: any | null;
  productPackageId_lt?: any | null;
  productPackageId_not_lt?: any | null;
  productPackageId_lte?: any | null;
  productPackageId_not_lte?: any | null;
  productPackage?: ProductPackageFilter | null;
  room?: RoomFilter | null;
  assignedRestrictions_some?: DesignPackageProductSetRestrictionFilter | null;
  assignedRestrictions_none?: DesignPackageProductSetRestrictionFilter | null;
  assignedRestrictions_all?: DesignPackageProductSetRestrictionFilter | null;
  assignedRestrictions_any?: boolean | null;
  assignedCosts_some?: DesignPackageCostFilter | null;
  assignedCosts_none?: DesignPackageCostFilter | null;
  assignedCosts_all?: DesignPackageCostFilter | null;
  assignedCosts_any?: boolean | null;
  assignedDefaultProducts_some?: DesignPackageDefaultProductSetFilter | null;
  assignedDefaultProducts_none?: DesignPackageDefaultProductSetFilter | null;
  assignedDefaultProducts_all?: DesignPackageDefaultProductSetFilter | null;
  assignedDefaultProducts_any?: boolean | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * DesignPackageProductSetRestriction represents a retriction on using a ProductSet in a DesignPackage.
 * Restriction can be baucause of room dimensions or other.
 */
export interface DesignPackageProductSetRestrictionFilter {
  AND?: DesignPackageProductSetRestrictionFilter[] | null;
  OR?: DesignPackageProductSetRestrictionFilter[] | null;
  designPackageId?: any | null;
  designPackageId_not?: any | null;
  designPackageId_in?: any[] | null;
  designPackageId_not_in?: any[] | null;
  designPackageId_gt?: any | null;
  designPackageId_not_gt?: any | null;
  designPackageId_gte?: any | null;
  designPackageId_not_gte?: any | null;
  designPackageId_lt?: any | null;
  designPackageId_not_lt?: any | null;
  designPackageId_lte?: any | null;
  designPackageId_not_lte?: any | null;
  designPackage?: DesignPackageFilter | null;
  productSetInfoKey?: ProductSetInfoKeyType | null;
  productSetInfoKey_not?: ProductSetInfoKeyType | null;
  productSetInfoKey_in?: ProductSetInfoKeyType[] | null;
  productSetInfoKey_not_in?: ProductSetInfoKeyType[] | null;
  productSetInfoKey_gt?: ProductSetInfoKeyType | null;
  productSetInfoKey_not_gt?: ProductSetInfoKeyType | null;
  productSetInfoKey_gte?: ProductSetInfoKeyType | null;
  productSetInfoKey_not_gte?: ProductSetInfoKeyType | null;
  productSetInfoKey_lt?: ProductSetInfoKeyType | null;
  productSetInfoKey_not_lt?: ProductSetInfoKeyType | null;
  productSetInfoKey_lte?: ProductSetInfoKeyType | null;
  productSetInfoKey_not_lte?: ProductSetInfoKeyType | null;
  productSetInfoCompareValue?: string | null;
  productSetInfoCompareValue_not?: string | null;
  productSetInfoCompareValue_contains?: string | null;
  productSetInfoCompareValue_not_contains?: string | null;
  productSetInfoCompareValue_starts_with?: string | null;
  productSetInfoCompareValue_not_starts_with?: string | null;
  productSetInfoCompareValue_ends_with?: string | null;
  productSetInfoCompareValue_not_ends_with?: string | null;
  productSetInfoCompareValue_in?: string[] | null;
  productSetInfoCompareValue_not_in?: string[] | null;
  productSetInfoCompareOperation?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_not?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_in?: ProductSetInfoCompareOperation[] | null;
  productSetInfoCompareOperation_not_in?: ProductSetInfoCompareOperation[] | null;
  productSetInfoCompareOperation_gt?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_not_gt?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_gte?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_not_gte?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_lt?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_not_lt?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_lte?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_not_lte?: ProductSetInfoCompareOperation | null;
  productCategoryId?: any | null;
  productCategoryId_not?: any | null;
  productCategoryId_in?: any[] | null;
  productCategoryId_not_in?: any[] | null;
  productCategoryId_gt?: any | null;
  productCategoryId_not_gt?: any | null;
  productCategoryId_gte?: any | null;
  productCategoryId_not_gte?: any | null;
  productCategoryId_lt?: any | null;
  productCategoryId_not_lt?: any | null;
  productCategoryId_lte?: any | null;
  productCategoryId_not_lte?: any | null;
  productCategory?: ProductCategoryFilter | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * Design Package represents a set of materials, including installation costs, for a single room.
 */
export interface DesignPackageSort {
  name?: SortOperationKind | null;
  position?: SortOperationKind | null;
  hasWashbasin?: SortOperationKind | null;
  hasShower?: SortOperationKind | null;
  hasToilet?: SortOperationKind | null;
  hasBathtub?: SortOperationKind | null;
  hasFountain?: SortOperationKind | null;
  hasTiles?: SortOperationKind | null;
  hasRadiator?: SortOperationKind | null;
  hasUtilities?: SortOperationKind | null;
  isFixedPrice?: SortOperationKind | null;
  hasBath?: SortOperationKind | null;
  isXL?: SortOperationKind | null;
  hasToiletLabel?: SortOperationKind | null;
  isStandard?: SortOperationKind | null;
  price?: MoneySort | null;
  styleId?: SortOperationKind | null;
  style?: StyleSort | null;
  brandId?: SortOperationKind | null;
  brand?: BrandSort | null;
  productPackageId?: SortOperationKind | null;
  productPackage?: ProductPackageSort | null;
  room?: RoomSort | null;
  id?: SortOperationKind | null;
}

/**
 * Email object describes and Email address.
 */
export interface EmailFilter {
  AND?: EmailFilter[] | null;
  OR?: EmailFilter[] | null;
  address?: string | null;
  address_not?: string | null;
  address_contains?: string | null;
  address_not_contains?: string | null;
  address_starts_with?: string | null;
  address_not_starts_with?: string | null;
  address_ends_with?: string | null;
  address_not_ends_with?: string | null;
  address_in?: string[] | null;
  address_not_in?: string[] | null;
}

/**
 * Email object describes and Email address.
 */
export interface EmailSort {
  address?: SortOperationKind | null;
}

export interface KeyValuePairOfCostTypeAndStringInput {
  key: CostType;
  value: string;
}

/**
 * Layout for one or more RealEstate objects in a Project.
 */
export interface LayoutFilter {
  AND?: LayoutFilter[] | null;
  OR?: LayoutFilter[] | null;
  layoutRooms_some?: LayoutRoomFilter | null;
  layoutRooms_none?: LayoutRoomFilter | null;
  layoutRooms_all?: LayoutRoomFilter | null;
  layoutRooms_any?: boolean | null;
  name?: string | null;
  name_not?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * Relational class that keeps track of relations (many to many) between Layout and Room.
 */
export interface LayoutRoomFilter {
  AND?: LayoutRoomFilter[] | null;
  OR?: LayoutRoomFilter[] | null;
  layoutId?: any | null;
  layoutId_not?: any | null;
  layoutId_in?: any[] | null;
  layoutId_not_in?: any[] | null;
  layoutId_gt?: any | null;
  layoutId_not_gt?: any | null;
  layoutId_gte?: any | null;
  layoutId_not_gte?: any | null;
  layoutId_lt?: any | null;
  layoutId_not_lt?: any | null;
  layoutId_lte?: any | null;
  layoutId_not_lte?: any | null;
  layout?: LayoutFilter | null;
  roomId?: any | null;
  roomId_not?: any | null;
  roomId_in?: any[] | null;
  roomId_not_in?: any[] | null;
  roomId_gt?: any | null;
  roomId_not_gt?: any | null;
  roomId_gte?: any | null;
  roomId_not_gte?: any | null;
  roomId_lt?: any | null;
  roomId_not_lt?: any | null;
  roomId_lte?: any | null;
  roomId_not_lte?: any | null;
  room?: RoomFilter | null;
}

/**
 * Layout for one or more RealEstate objects in a Project.
 */
export interface LayoutSort {
  name?: SortOperationKind | null;
  id?: SortOperationKind | null;
}

/**
 * Money object represents money (currency and value).
 */
export interface MoneyFilter {
  AND?: MoneyFilter[] | null;
  OR?: MoneyFilter[] | null;
  currency?: Currency | null;
  currency_not?: Currency | null;
  currency_in?: Currency[] | null;
  currency_not_in?: Currency[] | null;
  currency_gt?: Currency | null;
  currency_not_gt?: Currency | null;
  currency_gte?: Currency | null;
  currency_not_gte?: Currency | null;
  currency_lt?: Currency | null;
  currency_not_lt?: Currency | null;
  currency_lte?: Currency | null;
  currency_not_lte?: Currency | null;
  amount?: any | null;
  amount_not?: any | null;
  amount_in?: any[] | null;
  amount_not_in?: any[] | null;
  amount_gt?: any | null;
  amount_not_gt?: any | null;
  amount_gte?: any | null;
  amount_not_gte?: any | null;
  amount_lt?: any | null;
  amount_not_lt?: any | null;
  amount_lte?: any | null;
  amount_not_lte?: any | null;
}

/**
 * Money object represents money (currency and value).
 */
export interface MoneySort {
  currency?: SortOperationKind | null;
  amount?: SortOperationKind | null;
}

/**
 * PhoneNumber object describes a phone number.
 */
export interface PhoneNumberFilter {
  AND?: PhoneNumberFilter[] | null;
  OR?: PhoneNumberFilter[] | null;
  number?: string | null;
  number_not?: string | null;
  number_contains?: string | null;
  number_not_contains?: string | null;
  number_starts_with?: string | null;
  number_not_starts_with?: string | null;
  number_ends_with?: string | null;
  number_not_ends_with?: string | null;
  number_in?: string[] | null;
  number_not_in?: string[] | null;
}

/**
 * PhoneNumber object describes a phone number.
 */
export interface PhoneNumberSort {
  number?: SortOperationKind | null;
}

/**
 * ProductCategory represents a category of product sets present in one ProductPackage.
 * ProductSet are grouped into ProductCategory
 */
export interface ProductCategoryFilter {
  AND?: ProductCategoryFilter[] | null;
  OR?: ProductCategoryFilter[] | null;
  name?: string | null;
  name_not?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  title?: string | null;
  title_not?: string | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  translatedTitle?: string | null;
  translatedTitle_not?: string | null;
  translatedTitle_contains?: string | null;
  translatedTitle_not_contains?: string | null;
  translatedTitle_starts_with?: string | null;
  translatedTitle_not_starts_with?: string | null;
  translatedTitle_ends_with?: string | null;
  translatedTitle_not_ends_with?: string | null;
  translatedTitle_in?: (string | null)[] | null;
  translatedTitle_not_in?: (string | null)[] | null;
  brandlineHeader?: string | null;
  brandlineHeader_not?: string | null;
  brandlineHeader_contains?: string | null;
  brandlineHeader_not_contains?: string | null;
  brandlineHeader_starts_with?: string | null;
  brandlineHeader_not_starts_with?: string | null;
  brandlineHeader_ends_with?: string | null;
  brandlineHeader_not_ends_with?: string | null;
  brandlineHeader_in?: string[] | null;
  brandlineHeader_not_in?: string[] | null;
  translatedBrandlineHeader?: string | null;
  translatedBrandlineHeader_not?: string | null;
  translatedBrandlineHeader_contains?: string | null;
  translatedBrandlineHeader_not_contains?: string | null;
  translatedBrandlineHeader_starts_with?: string | null;
  translatedBrandlineHeader_not_starts_with?: string | null;
  translatedBrandlineHeader_ends_with?: string | null;
  translatedBrandlineHeader_not_ends_with?: string | null;
  translatedBrandlineHeader_in?: (string | null)[] | null;
  translatedBrandlineHeader_not_in?: (string | null)[] | null;
  position?: number | null;
  position_not?: number | null;
  position_in?: number[] | null;
  position_not_in?: number[] | null;
  position_gt?: number | null;
  position_not_gt?: number | null;
  position_gte?: number | null;
  position_not_gte?: number | null;
  position_lt?: number | null;
  position_not_lt?: number | null;
  position_lte?: number | null;
  position_not_lte?: number | null;
  productCombinationId?: any | null;
  productCombinationId_not?: any | null;
  productCombinationId_in?: any[] | null;
  productCombinationId_not_in?: any[] | null;
  productCombinationId_gt?: any | null;
  productCombinationId_not_gt?: any | null;
  productCombinationId_gte?: any | null;
  productCombinationId_not_gte?: any | null;
  productCombinationId_lt?: any | null;
  productCombinationId_not_lt?: any | null;
  productCombinationId_lte?: any | null;
  productCombinationId_not_lte?: any | null;
  productCombination?: ProductCombinationFilter | null;
  noProductProductSetId?: any | null;
  noProductProductSetId_not?: any | null;
  noProductProductSetId_in?: (any | null)[] | null;
  noProductProductSetId_not_in?: (any | null)[] | null;
  noProductProductSetId_gt?: any | null;
  noProductProductSetId_not_gt?: any | null;
  noProductProductSetId_gte?: any | null;
  noProductProductSetId_not_gte?: any | null;
  noProductProductSetId_lt?: any | null;
  noProductProductSetId_not_lt?: any | null;
  noProductProductSetId_lte?: any | null;
  noProductProductSetId_not_lte?: any | null;
  noProductProductSet?: ProductSetFilter | null;
  noProductNeededProductSetId?: any | null;
  noProductNeededProductSetId_not?: any | null;
  noProductNeededProductSetId_in?: (any | null)[] | null;
  noProductNeededProductSetId_not_in?: (any | null)[] | null;
  noProductNeededProductSetId_gt?: any | null;
  noProductNeededProductSetId_not_gt?: any | null;
  noProductNeededProductSetId_gte?: any | null;
  noProductNeededProductSetId_not_gte?: any | null;
  noProductNeededProductSetId_lt?: any | null;
  noProductNeededProductSetId_not_lt?: any | null;
  noProductNeededProductSetId_lte?: any | null;
  noProductNeededProductSetId_not_lte?: any | null;
  noProductNeededProductSet?: ProductSetFilter | null;
  assignedBrandlines_some?: ProductSetBrandlineFilter | null;
  assignedBrandlines_none?: ProductSetBrandlineFilter | null;
  assignedBrandlines_all?: ProductSetBrandlineFilter | null;
  assignedBrandlines_any?: boolean | null;
  assignedProductSetRestrictions_some?: ProductSetRestrictionFilter | null;
  assignedProductSetRestrictions_none?: ProductSetRestrictionFilter | null;
  assignedProductSetRestrictions_all?: ProductSetRestrictionFilter | null;
  assignedProductSetRestrictions_any?: boolean | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * ProductCategory represents a category of product sets present in one ProductPackage.
 * ProductSet are grouped into ProductCategory
 */
export interface ProductCategorySort {
  name?: SortOperationKind | null;
  title?: SortOperationKind | null;
  translatedTitle?: SortOperationKind | null;
  brandlineHeader?: SortOperationKind | null;
  translatedBrandlineHeader?: SortOperationKind | null;
  position?: SortOperationKind | null;
  productCombinationId?: SortOperationKind | null;
  productCombination?: ProductCombinationSort | null;
  noProductProductSetId?: SortOperationKind | null;
  noProductProductSet?: ProductSetSort | null;
  noProductNeededProductSetId?: SortOperationKind | null;
  noProductNeededProductSet?: ProductSetSort | null;
  id?: SortOperationKind | null;
}

/**
 * ProductCombination represents a combination of product sets present in one ProductPackage.
 * ProductSet are grouped into ProductCombination
 */
export interface ProductCombinationFilter {
  AND?: ProductCombinationFilter[] | null;
  OR?: ProductCombinationFilter[] | null;
  name?: string | null;
  name_not?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  position?: number | null;
  position_not?: number | null;
  position_in?: number[] | null;
  position_not_in?: number[] | null;
  position_gt?: number | null;
  position_not_gt?: number | null;
  position_gte?: number | null;
  position_not_gte?: number | null;
  position_lt?: number | null;
  position_not_lt?: number | null;
  position_lte?: number | null;
  position_not_lte?: number | null;
  combinationType?: ProductCombinationType | null;
  combinationType_not?: ProductCombinationType | null;
  combinationType_in?: ProductCombinationType[] | null;
  combinationType_not_in?: ProductCombinationType[] | null;
  combinationType_gt?: ProductCombinationType | null;
  combinationType_not_gt?: ProductCombinationType | null;
  combinationType_gte?: ProductCombinationType | null;
  combinationType_not_gte?: ProductCombinationType | null;
  combinationType_lt?: ProductCombinationType | null;
  combinationType_not_lt?: ProductCombinationType | null;
  combinationType_lte?: ProductCombinationType | null;
  combinationType_not_lte?: ProductCombinationType | null;
  assignedRestrictions_some?: ProductCombinationRestrictionFilter | null;
  assignedRestrictions_none?: ProductCombinationRestrictionFilter | null;
  assignedRestrictions_all?: ProductCombinationRestrictionFilter | null;
  assignedRestrictions_any?: boolean | null;
  assignedCategories_some?: ProductCategoryFilter | null;
  assignedCategories_none?: ProductCategoryFilter | null;
  assignedCategories_all?: ProductCategoryFilter | null;
  assignedCategories_any?: boolean | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * ProductCombinationRestriction represents a retriction on using a ProductSet in a ProductCombination.
 * Restriction can be baucause of room dimensions or other.
 */
export interface ProductCombinationRestrictionFilter {
  AND?: ProductCombinationRestrictionFilter[] | null;
  OR?: ProductCombinationRestrictionFilter[] | null;
  productCombinationInfoKey?: ProductCombinationInfoKeyType | null;
  productCombinationInfoKey_not?: ProductCombinationInfoKeyType | null;
  productCombinationInfoKey_in?: ProductCombinationInfoKeyType[] | null;
  productCombinationInfoKey_not_in?: ProductCombinationInfoKeyType[] | null;
  productCombinationInfoKey_gt?: ProductCombinationInfoKeyType | null;
  productCombinationInfoKey_not_gt?: ProductCombinationInfoKeyType | null;
  productCombinationInfoKey_gte?: ProductCombinationInfoKeyType | null;
  productCombinationInfoKey_not_gte?: ProductCombinationInfoKeyType | null;
  productCombinationInfoKey_lt?: ProductCombinationInfoKeyType | null;
  productCombinationInfoKey_not_lt?: ProductCombinationInfoKeyType | null;
  productCombinationInfoKey_lte?: ProductCombinationInfoKeyType | null;
  productCombinationInfoKey_not_lte?: ProductCombinationInfoKeyType | null;
  productCombinationInfoValue?: string | null;
  productCombinationInfoValue_not?: string | null;
  productCombinationInfoValue_contains?: string | null;
  productCombinationInfoValue_not_contains?: string | null;
  productCombinationInfoValue_starts_with?: string | null;
  productCombinationInfoValue_not_starts_with?: string | null;
  productCombinationInfoValue_ends_with?: string | null;
  productCombinationInfoValue_not_ends_with?: string | null;
  productCombinationInfoValue_in?: string[] | null;
  productCombinationInfoValue_not_in?: string[] | null;
  productCombinationId?: any | null;
  productCombinationId_not?: any | null;
  productCombinationId_in?: any[] | null;
  productCombinationId_not_in?: any[] | null;
  productCombinationId_gt?: any | null;
  productCombinationId_not_gt?: any | null;
  productCombinationId_gte?: any | null;
  productCombinationId_not_gte?: any | null;
  productCombinationId_lt?: any | null;
  productCombinationId_not_lt?: any | null;
  productCombinationId_lte?: any | null;
  productCombinationId_not_lte?: any | null;
  productCombination?: ProductCombinationFilter | null;
  productSetInfoKey2?: ProductSetInfoKeyType | null;
  productSetInfoKey2_not?: ProductSetInfoKeyType | null;
  productSetInfoKey2_in?: (ProductSetInfoKeyType | null)[] | null;
  productSetInfoKey2_not_in?: (ProductSetInfoKeyType | null)[] | null;
  productSetInfoKey2_gt?: ProductSetInfoKeyType | null;
  productSetInfoKey2_not_gt?: ProductSetInfoKeyType | null;
  productSetInfoKey2_gte?: ProductSetInfoKeyType | null;
  productSetInfoKey2_not_gte?: ProductSetInfoKeyType | null;
  productSetInfoKey2_lt?: ProductSetInfoKeyType | null;
  productSetInfoKey2_not_lt?: ProductSetInfoKeyType | null;
  productSetInfoKey2_lte?: ProductSetInfoKeyType | null;
  productSetInfoKey2_not_lte?: ProductSetInfoKeyType | null;
  productSetInfoCompareValue2?: string | null;
  productSetInfoCompareValue2_not?: string | null;
  productSetInfoCompareValue2_contains?: string | null;
  productSetInfoCompareValue2_not_contains?: string | null;
  productSetInfoCompareValue2_starts_with?: string | null;
  productSetInfoCompareValue2_not_starts_with?: string | null;
  productSetInfoCompareValue2_ends_with?: string | null;
  productSetInfoCompareValue2_not_ends_with?: string | null;
  productSetInfoCompareValue2_in?: (string | null)[] | null;
  productSetInfoCompareValue2_not_in?: (string | null)[] | null;
  productSetInfoCompareOperation2?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation2_not?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation2_in?: (ProductSetInfoCompareOperation | null)[] | null;
  productSetInfoCompareOperation2_not_in?: (ProductSetInfoCompareOperation | null)[] | null;
  productSetInfoCompareOperation2_gt?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation2_not_gt?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation2_gte?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation2_not_gte?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation2_lt?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation2_not_lt?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation2_lte?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation2_not_lte?: ProductSetInfoCompareOperation | null;
  productSetInfoKey?: ProductSetInfoKeyType | null;
  productSetInfoKey_not?: ProductSetInfoKeyType | null;
  productSetInfoKey_in?: ProductSetInfoKeyType[] | null;
  productSetInfoKey_not_in?: ProductSetInfoKeyType[] | null;
  productSetInfoKey_gt?: ProductSetInfoKeyType | null;
  productSetInfoKey_not_gt?: ProductSetInfoKeyType | null;
  productSetInfoKey_gte?: ProductSetInfoKeyType | null;
  productSetInfoKey_not_gte?: ProductSetInfoKeyType | null;
  productSetInfoKey_lt?: ProductSetInfoKeyType | null;
  productSetInfoKey_not_lt?: ProductSetInfoKeyType | null;
  productSetInfoKey_lte?: ProductSetInfoKeyType | null;
  productSetInfoKey_not_lte?: ProductSetInfoKeyType | null;
  productSetInfoCompareValue?: string | null;
  productSetInfoCompareValue_not?: string | null;
  productSetInfoCompareValue_contains?: string | null;
  productSetInfoCompareValue_not_contains?: string | null;
  productSetInfoCompareValue_starts_with?: string | null;
  productSetInfoCompareValue_not_starts_with?: string | null;
  productSetInfoCompareValue_ends_with?: string | null;
  productSetInfoCompareValue_not_ends_with?: string | null;
  productSetInfoCompareValue_in?: string[] | null;
  productSetInfoCompareValue_not_in?: string[] | null;
  productSetInfoCompareOperation?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_not?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_in?: ProductSetInfoCompareOperation[] | null;
  productSetInfoCompareOperation_not_in?: ProductSetInfoCompareOperation[] | null;
  productSetInfoCompareOperation_gt?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_not_gt?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_gte?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_not_gte?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_lt?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_not_lt?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_lte?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_not_lte?: ProductSetInfoCompareOperation | null;
  productCategoryId?: any | null;
  productCategoryId_not?: any | null;
  productCategoryId_in?: any[] | null;
  productCategoryId_not_in?: any[] | null;
  productCategoryId_gt?: any | null;
  productCategoryId_not_gt?: any | null;
  productCategoryId_gte?: any | null;
  productCategoryId_not_gte?: any | null;
  productCategoryId_lt?: any | null;
  productCategoryId_not_lt?: any | null;
  productCategoryId_lte?: any | null;
  productCategoryId_not_lte?: any | null;
  productCategory?: ProductCategoryFilter | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * ProductCombination represents a combination of product sets present in one ProductPackage.
 * ProductSet are grouped into ProductCombination
 */
export interface ProductCombinationSort {
  name?: SortOperationKind | null;
  position?: SortOperationKind | null;
  combinationType?: SortOperationKind | null;
  id?: SortOperationKind | null;
}

/**
 * Product item represents a single product that is used in product sets.
 */
export interface ProductFilter {
  AND?: ProductFilter[] | null;
  OR?: ProductFilter[] | null;
  code?: string | null;
  code_not?: string | null;
  code_contains?: string | null;
  code_not_contains?: string | null;
  code_starts_with?: string | null;
  code_not_starts_with?: string | null;
  code_ends_with?: string | null;
  code_not_ends_with?: string | null;
  code_in?: string[] | null;
  code_not_in?: string[] | null;
  supplierCode?: string | null;
  supplierCode_not?: string | null;
  supplierCode_contains?: string | null;
  supplierCode_not_contains?: string | null;
  supplierCode_starts_with?: string | null;
  supplierCode_not_starts_with?: string | null;
  supplierCode_ends_with?: string | null;
  supplierCode_not_ends_with?: string | null;
  supplierCode_in?: (string | null)[] | null;
  supplierCode_not_in?: (string | null)[] | null;
  brand?: string | null;
  brand_not?: string | null;
  brand_contains?: string | null;
  brand_not_contains?: string | null;
  brand_starts_with?: string | null;
  brand_not_starts_with?: string | null;
  brand_ends_with?: string | null;
  brand_not_ends_with?: string | null;
  brand_in?: (string | null)[] | null;
  brand_not_in?: (string | null)[] | null;
  description?: string | null;
  description_not?: string | null;
  description_contains?: string | null;
  description_not_contains?: string | null;
  description_starts_with?: string | null;
  description_not_starts_with?: string | null;
  description_ends_with?: string | null;
  description_not_ends_with?: string | null;
  description_in?: (string | null)[] | null;
  description_not_in?: (string | null)[] | null;
  longDescription?: string | null;
  longDescription_not?: string | null;
  longDescription_contains?: string | null;
  longDescription_not_contains?: string | null;
  longDescription_starts_with?: string | null;
  longDescription_not_starts_with?: string | null;
  longDescription_ends_with?: string | null;
  longDescription_not_ends_with?: string | null;
  longDescription_in?: (string | null)[] | null;
  longDescription_not_in?: (string | null)[] | null;
  marktingText?: string | null;
  marktingText_not?: string | null;
  marktingText_contains?: string | null;
  marktingText_not_contains?: string | null;
  marktingText_starts_with?: string | null;
  marktingText_not_starts_with?: string | null;
  marktingText_ends_with?: string | null;
  marktingText_not_ends_with?: string | null;
  marktingText_in?: (string | null)[] | null;
  marktingText_not_in?: (string | null)[] | null;
  statusCode?: number | null;
  statusCode_not?: number | null;
  statusCode_in?: number[] | null;
  statusCode_not_in?: number[] | null;
  statusCode_gt?: number | null;
  statusCode_not_gt?: number | null;
  statusCode_gte?: number | null;
  statusCode_not_gte?: number | null;
  statusCode_lt?: number | null;
  statusCode_not_lt?: number | null;
  statusCode_lte?: number | null;
  statusCode_not_lte?: number | null;
  isNotAvailable?: boolean | null;
  isNotAvailable_not?: boolean | null;
  costPrice?: MoneyFilter | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * ProductPackageCategory represents a category of product sets present in one DesignPackage.
 * ProductSet are grouped into ProductCategory and this class represents the specific settings in a given DesignPackage.
 */
export interface ProductPackageCategoryFilter {
  AND?: ProductPackageCategoryFilter[] | null;
  OR?: ProductPackageCategoryFilter[] | null;
  productPackageId?: any | null;
  productPackageId_not?: any | null;
  productPackageId_in?: any[] | null;
  productPackageId_not_in?: any[] | null;
  productPackageId_gt?: any | null;
  productPackageId_not_gt?: any | null;
  productPackageId_gte?: any | null;
  productPackageId_not_gte?: any | null;
  productPackageId_lt?: any | null;
  productPackageId_not_lt?: any | null;
  productPackageId_lte?: any | null;
  productPackageId_not_lte?: any | null;
  productPackage?: ProductPackageFilter | null;
  productCategoryId?: any | null;
  productCategoryId_not?: any | null;
  productCategoryId_in?: any[] | null;
  productCategoryId_not_in?: any[] | null;
  productCategoryId_gt?: any | null;
  productCategoryId_not_gt?: any | null;
  productCategoryId_gte?: any | null;
  productCategoryId_not_gte?: any | null;
  productCategoryId_lt?: any | null;
  productCategoryId_not_lt?: any | null;
  productCategoryId_lte?: any | null;
  productCategoryId_not_lte?: any | null;
  productCategory?: ProductCategoryFilter | null;
  isSelectionOptional?: boolean | null;
  isSelectionOptional_not?: boolean | null;
  allowPickByBrand?: boolean | null;
  allowPickByBrand_not?: boolean | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * ProductPackageCategory represents a category of product sets present in one DesignPackage.
 * ProductSet are grouped into ProductCategory and this class represents the specific settings in a given DesignPackage.
 */
export interface ProductPackageCategorySort {
  productPackageId?: SortOperationKind | null;
  productPackage?: ProductPackageSort | null;
  productCategoryId?: SortOperationKind | null;
  productCategory?: ProductCategorySort | null;
  isSelectionOptional?: SortOperationKind | null;
  allowPickByBrand?: SortOperationKind | null;
  id?: SortOperationKind | null;
}

/**
 * ProductPackage represents a single product package for decorating rooms.
 */
export interface ProductPackageFilter {
  AND?: ProductPackageFilter[] | null;
  OR?: ProductPackageFilter[] | null;
  name?: string | null;
  name_not?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  usedInProjects_some?: ProjectProductPackageFilter | null;
  usedInProjects_none?: ProjectProductPackageFilter | null;
  usedInProjects_all?: ProjectProductPackageFilter | null;
  usedInProjects_any?: boolean | null;
  usedInDesignPackages_some?: DesignPackageFilter | null;
  usedInDesignPackages_none?: DesignPackageFilter | null;
  usedInDesignPackages_all?: DesignPackageFilter | null;
  usedInDesignPackages_any?: boolean | null;
  assignedProductSets_some?: ProductPackageSetFilter | null;
  assignedProductSets_none?: ProductPackageSetFilter | null;
  assignedProductSets_all?: ProductPackageSetFilter | null;
  assignedProductSets_any?: boolean | null;
  assignedProductPackageCategories_some?: ProductPackageCategoryFilter | null;
  assignedProductPackageCategories_none?: ProductPackageCategoryFilter | null;
  assignedProductPackageCategories_all?: ProductPackageCategoryFilter | null;
  assignedProductPackageCategories_any?: boolean | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * Relational class that keeps track of relations (many to many) between ProductPackage,
 * a  ProductSet, a ProductCombination and optional ProductCategory.
 */
export interface ProductPackageSetFilter {
  AND?: ProductPackageSetFilter[] | null;
  OR?: ProductPackageSetFilter[] | null;
  productPackageId?: any | null;
  productPackageId_not?: any | null;
  productPackageId_in?: any[] | null;
  productPackageId_not_in?: any[] | null;
  productPackageId_gt?: any | null;
  productPackageId_not_gt?: any | null;
  productPackageId_gte?: any | null;
  productPackageId_not_gte?: any | null;
  productPackageId_lt?: any | null;
  productPackageId_not_lt?: any | null;
  productPackageId_lte?: any | null;
  productPackageId_not_lte?: any | null;
  productPackage?: ProductPackageFilter | null;
  productSetId?: any | null;
  productSetId_not?: any | null;
  productSetId_in?: any[] | null;
  productSetId_not_in?: any[] | null;
  productSetId_gt?: any | null;
  productSetId_not_gt?: any | null;
  productSetId_gte?: any | null;
  productSetId_not_gte?: any | null;
  productSetId_lt?: any | null;
  productSetId_not_lt?: any | null;
  productSetId_lte?: any | null;
  productSetId_not_lte?: any | null;
  status?: ProductSetStatus | null;
  status_not?: ProductSetStatus | null;
  status_in?: ProductSetStatus[] | null;
  status_not_in?: ProductSetStatus[] | null;
  status_gt?: ProductSetStatus | null;
  status_not_gt?: ProductSetStatus | null;
  status_gte?: ProductSetStatus | null;
  status_not_gte?: ProductSetStatus | null;
  status_lt?: ProductSetStatus | null;
  status_not_lt?: ProductSetStatus | null;
  status_lte?: ProductSetStatus | null;
  status_not_lte?: ProductSetStatus | null;
  productSet?: ProductSetFilter | null;
  productCombinationId?: any | null;
  productCombinationId_not?: any | null;
  productCombinationId_in?: any[] | null;
  productCombinationId_not_in?: any[] | null;
  productCombinationId_gt?: any | null;
  productCombinationId_not_gt?: any | null;
  productCombinationId_gte?: any | null;
  productCombinationId_not_gte?: any | null;
  productCombinationId_lt?: any | null;
  productCombinationId_not_lt?: any | null;
  productCombinationId_lte?: any | null;
  productCombinationId_not_lte?: any | null;
  productCombination?: ProductCombinationFilter | null;
  productPackageCategoryId?: any | null;
  productPackageCategoryId_not?: any | null;
  productPackageCategoryId_in?: any[] | null;
  productPackageCategoryId_not_in?: any[] | null;
  productPackageCategoryId_gt?: any | null;
  productPackageCategoryId_not_gt?: any | null;
  productPackageCategoryId_gte?: any | null;
  productPackageCategoryId_not_gte?: any | null;
  productPackageCategoryId_lt?: any | null;
  productPackageCategoryId_not_lt?: any | null;
  productPackageCategoryId_lte?: any | null;
  productPackageCategoryId_not_lte?: any | null;
  productPackageCategory?: ProductPackageCategoryFilter | null;
  isProductPackageDefault?: boolean | null;
  isProductPackageDefault_not?: boolean | null;
  isProductPackageFavourite?: boolean | null;
  isProductPackageFavourite_not?: boolean | null;
  isDefault?: boolean | null;
  isDefault_not?: boolean | null;
  designPackageReturnPrice?: MoneyFilter | null;
  salePrice?: MoneyFilter | null;
  saleCoordinationPrice?: MoneyFilter | null;
  saleInstallationPrice?: MoneyFilter | null;
  isNoProduct?: boolean | null;
  isNoProduct_not?: boolean | null;
  isNoProductNeeded?: boolean | null;
  isNoProductNeeded_not?: boolean | null;
}

/**
 * Relational class that keeps track of relations (many to many) between ProductPackage,
 * a  ProductSet, a ProductCombination and optional ProductCategory.
 */
export interface ProductPackageSetSort {
  productPackageId?: SortOperationKind | null;
  productPackage?: ProductPackageSort | null;
  productSetId?: SortOperationKind | null;
  status?: SortOperationKind | null;
  productSet?: ProductSetSort | null;
  productCombinationId?: SortOperationKind | null;
  productCombination?: ProductCombinationSort | null;
  productPackageCategoryId?: SortOperationKind | null;
  productPackageCategory?: ProductPackageCategorySort | null;
  isProductPackageDefault?: SortOperationKind | null;
  isProductPackageFavourite?: SortOperationKind | null;
  isDefault?: SortOperationKind | null;
  designPackageReturnPrice?: MoneySort | null;
  salePrice?: MoneySort | null;
  saleCoordinationPrice?: MoneySort | null;
  saleInstallationPrice?: MoneySort | null;
  isNoProduct?: SortOperationKind | null;
  isNoProductNeeded?: SortOperationKind | null;
}

/**
 * ProductPackage represents a single product package for decorating rooms.
 */
export interface ProductPackageSort {
  name?: SortOperationKind | null;
  id?: SortOperationKind | null;
}

/**
 * ProductSetBrandline represents a single brandline of product sets.
 */
export interface ProductSetBrandlineFilter {
  AND?: ProductSetBrandlineFilter[] | null;
  OR?: ProductSetBrandlineFilter[] | null;
  name?: string | null;
  name_not?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  position?: number | null;
  position_not?: number | null;
  position_in?: number[] | null;
  position_not_in?: number[] | null;
  position_gt?: number | null;
  position_not_gt?: number | null;
  position_gte?: number | null;
  position_not_gte?: number | null;
  position_lt?: number | null;
  position_not_lt?: number | null;
  position_lte?: number | null;
  position_not_lte?: number | null;
  productCategoryId?: any | null;
  productCategoryId_not?: any | null;
  productCategoryId_in?: any[] | null;
  productCategoryId_not_in?: any[] | null;
  productCategoryId_gt?: any | null;
  productCategoryId_not_gt?: any | null;
  productCategoryId_gte?: any | null;
  productCategoryId_not_gte?: any | null;
  productCategoryId_lt?: any | null;
  productCategoryId_not_lt?: any | null;
  productCategoryId_lte?: any | null;
  productCategoryId_not_lte?: any | null;
  productCategory?: ProductCategoryFilter | null;
  includedProductSets_some?: ProductSetFilter | null;
  includedProductSets_none?: ProductSetFilter | null;
  includedProductSets_all?: ProductSetFilter | null;
  includedProductSets_any?: boolean | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * ProductSetBrandline represents a single brandline of product sets.
 */
export interface ProductSetBrandlineSort {
  name?: SortOperationKind | null;
  position?: SortOperationKind | null;
  productCategoryId?: SortOperationKind | null;
  productCategory?: ProductCategorySort | null;
  id?: SortOperationKind | null;
}

/**
 * ProductSetCost represents the costs of installing and assembling a ProductSet.
 */
export interface ProductSetCostFilter {
  AND?: ProductSetCostFilter[] | null;
  OR?: ProductSetCostFilter[] | null;
  productSetId?: any | null;
  productSetId_not?: any | null;
  productSetId_in?: any[] | null;
  productSetId_not_in?: any[] | null;
  productSetId_gt?: any | null;
  productSetId_not_gt?: any | null;
  productSetId_gte?: any | null;
  productSetId_not_gte?: any | null;
  productSetId_lt?: any | null;
  productSetId_not_lt?: any | null;
  productSetId_lte?: any | null;
  productSetId_not_lte?: any | null;
  productSet?: ProductSetFilter | null;
  costType?: CostType | null;
  costType_not?: CostType | null;
  costType_in?: CostType[] | null;
  costType_not_in?: CostType[] | null;
  costType_gt?: CostType | null;
  costType_not_gt?: CostType | null;
  costType_gte?: CostType | null;
  costType_not_gte?: CostType | null;
  costType_lt?: CostType | null;
  costType_not_lt?: CostType | null;
  costType_lte?: CostType | null;
  costType_not_lte?: CostType | null;
  cost?: MoneyFilter | null;
  isStandard?: boolean | null;
  isStandard_not?: boolean | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * ProductSet present in one or more ProductPackage.
 */
export interface ProductSetFilter {
  AND?: ProductSetFilter[] | null;
  OR?: ProductSetFilter[] | null;
  name?: string | null;
  name_not?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  status?: ProductSetStatus | null;
  status_not?: ProductSetStatus | null;
  status_in?: ProductSetStatus[] | null;
  status_not_in?: ProductSetStatus[] | null;
  status_gt?: ProductSetStatus | null;
  status_not_gt?: ProductSetStatus | null;
  status_gte?: ProductSetStatus | null;
  status_not_gte?: ProductSetStatus | null;
  status_lt?: ProductSetStatus | null;
  status_not_lt?: ProductSetStatus | null;
  status_lte?: ProductSetStatus | null;
  status_not_lte?: ProductSetStatus | null;
  isNotAvailable?: boolean | null;
  isNotAvailable_not?: boolean | null;
  productSetBrandlineId?: any | null;
  productSetBrandlineId_not?: any | null;
  productSetBrandlineId_in?: (any | null)[] | null;
  productSetBrandlineId_not_in?: (any | null)[] | null;
  productSetBrandlineId_gt?: any | null;
  productSetBrandlineId_not_gt?: any | null;
  productSetBrandlineId_gte?: any | null;
  productSetBrandlineId_not_gte?: any | null;
  productSetBrandlineId_lt?: any | null;
  productSetBrandlineId_not_lt?: any | null;
  productSetBrandlineId_lte?: any | null;
  productSetBrandlineId_not_lte?: any | null;
  brandline?: ProductSetBrandlineFilter | null;
  costPrice?: MoneyFilter | null;
  fixedPrice?: MoneyFilter | null;
  assignedProductSetProducts_some?: ProductSetProductFilter | null;
  assignedProductSetProducts_none?: ProductSetProductFilter | null;
  assignedProductSetProducts_all?: ProductSetProductFilter | null;
  assignedProductSetProducts_any?: boolean | null;
  assignedProductSetInfos_some?: ProductSetInfoFilter | null;
  assignedProductSetInfos_none?: ProductSetInfoFilter | null;
  assignedProductSetInfos_all?: ProductSetInfoFilter | null;
  assignedProductSetInfos_any?: boolean | null;
  assignedCosts_some?: ProductSetCostFilter | null;
  assignedCosts_none?: ProductSetCostFilter | null;
  assignedCosts_all?: ProductSetCostFilter | null;
  assignedCosts_any?: boolean | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * ProductSetInfo represents a piece of information about a single ProductSet.
 */
export interface ProductSetInfoFilter {
  AND?: ProductSetInfoFilter[] | null;
  OR?: ProductSetInfoFilter[] | null;
  languageCode?: string | null;
  languageCode_not?: string | null;
  languageCode_contains?: string | null;
  languageCode_not_contains?: string | null;
  languageCode_starts_with?: string | null;
  languageCode_not_starts_with?: string | null;
  languageCode_ends_with?: string | null;
  languageCode_not_ends_with?: string | null;
  languageCode_in?: (string | null)[] | null;
  languageCode_not_in?: (string | null)[] | null;
  productSetId?: any | null;
  productSetId_not?: any | null;
  productSetId_in?: any[] | null;
  productSetId_not_in?: any[] | null;
  productSetId_gt?: any | null;
  productSetId_not_gt?: any | null;
  productSetId_gte?: any | null;
  productSetId_not_gte?: any | null;
  productSetId_lt?: any | null;
  productSetId_not_lt?: any | null;
  productSetId_lte?: any | null;
  productSetId_not_lte?: any | null;
  productSet?: ProductSetFilter | null;
  productSetInfoKeyId?: any | null;
  productSetInfoKeyId_not?: any | null;
  productSetInfoKeyId_in?: any[] | null;
  productSetInfoKeyId_not_in?: any[] | null;
  productSetInfoKeyId_gt?: any | null;
  productSetInfoKeyId_not_gt?: any | null;
  productSetInfoKeyId_gte?: any | null;
  productSetInfoKeyId_not_gte?: any | null;
  productSetInfoKeyId_lt?: any | null;
  productSetInfoKeyId_not_lt?: any | null;
  productSetInfoKeyId_lte?: any | null;
  productSetInfoKeyId_not_lte?: any | null;
  productSetInfoKey?: ProductSetInfoKeyFilter | null;
  value?: string | null;
  value_not?: string | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  unit?: string | null;
  unit_not?: string | null;
  unit_contains?: string | null;
  unit_not_contains?: string | null;
  unit_starts_with?: string | null;
  unit_not_starts_with?: string | null;
  unit_ends_with?: string | null;
  unit_not_ends_with?: string | null;
  unit_in?: (string | null)[] | null;
  unit_not_in?: (string | null)[] | null;
  key?: ProductSetInfoKeyType | null;
  key_not?: ProductSetInfoKeyType | null;
  key_in?: ProductSetInfoKeyType[] | null;
  key_not_in?: ProductSetInfoKeyType[] | null;
  key_gt?: ProductSetInfoKeyType | null;
  key_not_gt?: ProductSetInfoKeyType | null;
  key_gte?: ProductSetInfoKeyType | null;
  key_not_gte?: ProductSetInfoKeyType | null;
  key_lt?: ProductSetInfoKeyType | null;
  key_not_lt?: ProductSetInfoKeyType | null;
  key_lte?: ProductSetInfoKeyType | null;
  key_not_lte?: ProductSetInfoKeyType | null;
  label?: string | null;
  label_not?: string | null;
  label_contains?: string | null;
  label_not_contains?: string | null;
  label_starts_with?: string | null;
  label_not_starts_with?: string | null;
  label_ends_with?: string | null;
  label_not_ends_with?: string | null;
  label_in?: string[] | null;
  label_not_in?: string[] | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * ProductSetInfoKey represents a valid key of information about a single ProductSet.
 */
export interface ProductSetInfoKeyFilter {
  AND?: ProductSetInfoKeyFilter[] | null;
  OR?: ProductSetInfoKeyFilter[] | null;
  key?: ProductSetInfoKeyType | null;
  key_not?: ProductSetInfoKeyType | null;
  key_in?: ProductSetInfoKeyType[] | null;
  key_not_in?: ProductSetInfoKeyType[] | null;
  key_gt?: ProductSetInfoKeyType | null;
  key_not_gt?: ProductSetInfoKeyType | null;
  key_gte?: ProductSetInfoKeyType | null;
  key_not_gte?: ProductSetInfoKeyType | null;
  key_lt?: ProductSetInfoKeyType | null;
  key_not_lt?: ProductSetInfoKeyType | null;
  key_lte?: ProductSetInfoKeyType | null;
  key_not_lte?: ProductSetInfoKeyType | null;
  label?: string | null;
  label_not?: string | null;
  label_contains?: string | null;
  label_not_contains?: string | null;
  label_starts_with?: string | null;
  label_not_starts_with?: string | null;
  label_ends_with?: string | null;
  label_not_ends_with?: string | null;
  label_in?: string[] | null;
  label_not_in?: string[] | null;
  translatedLabel?: string | null;
  translatedLabel_not?: string | null;
  translatedLabel_contains?: string | null;
  translatedLabel_not_contains?: string | null;
  translatedLabel_starts_with?: string | null;
  translatedLabel_not_starts_with?: string | null;
  translatedLabel_ends_with?: string | null;
  translatedLabel_not_ends_with?: string | null;
  translatedLabel_in?: (string | null)[] | null;
  translatedLabel_not_in?: (string | null)[] | null;
  dataType?: ProductSetInfoKeyDataType | null;
  dataType_not?: ProductSetInfoKeyDataType | null;
  dataType_in?: ProductSetInfoKeyDataType[] | null;
  dataType_not_in?: ProductSetInfoKeyDataType[] | null;
  dataType_gt?: ProductSetInfoKeyDataType | null;
  dataType_not_gt?: ProductSetInfoKeyDataType | null;
  dataType_gte?: ProductSetInfoKeyDataType | null;
  dataType_not_gte?: ProductSetInfoKeyDataType | null;
  dataType_lt?: ProductSetInfoKeyDataType | null;
  dataType_not_lt?: ProductSetInfoKeyDataType | null;
  dataType_lte?: ProductSetInfoKeyDataType | null;
  dataType_not_lte?: ProductSetInfoKeyDataType | null;
  isDisplayable?: boolean | null;
  isDisplayable_not?: boolean | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * Relational class that keeps track of relations (many to many) between ProductSet and Product.
 */
export interface ProductSetProductFilter {
  AND?: ProductSetProductFilter[] | null;
  OR?: ProductSetProductFilter[] | null;
  productSetId?: any | null;
  productSetId_not?: any | null;
  productSetId_in?: any[] | null;
  productSetId_not_in?: any[] | null;
  productSetId_gt?: any | null;
  productSetId_not_gt?: any | null;
  productSetId_gte?: any | null;
  productSetId_not_gte?: any | null;
  productSetId_lt?: any | null;
  productSetId_not_lt?: any | null;
  productSetId_lte?: any | null;
  productSetId_not_lte?: any | null;
  productSet?: ProductSetFilter | null;
  productId?: any | null;
  productId_not?: any | null;
  productId_in?: any[] | null;
  productId_not_in?: any[] | null;
  productId_gt?: any | null;
  productId_not_gt?: any | null;
  productId_gte?: any | null;
  productId_not_gte?: any | null;
  productId_lt?: any | null;
  productId_not_lt?: any | null;
  productId_lte?: any | null;
  productId_not_lte?: any | null;
  product?: ProductFilter | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * ProductSetRestriction represents a retriction on using a ProductSet in a DesignPackage.
 * Restriction can be baucause of previous ProductSet properties.
 */
export interface ProductSetRestrictionFilter {
  AND?: ProductSetRestrictionFilter[] | null;
  OR?: ProductSetRestrictionFilter[] | null;
  parentProductSetInfoKey?: ProductSetInfoKeyType | null;
  parentProductSetInfoKey_not?: ProductSetInfoKeyType | null;
  parentProductSetInfoKey_in?: ProductSetInfoKeyType[] | null;
  parentProductSetInfoKey_not_in?: ProductSetInfoKeyType[] | null;
  parentProductSetInfoKey_gt?: ProductSetInfoKeyType | null;
  parentProductSetInfoKey_not_gt?: ProductSetInfoKeyType | null;
  parentProductSetInfoKey_gte?: ProductSetInfoKeyType | null;
  parentProductSetInfoKey_not_gte?: ProductSetInfoKeyType | null;
  parentProductSetInfoKey_lt?: ProductSetInfoKeyType | null;
  parentProductSetInfoKey_not_lt?: ProductSetInfoKeyType | null;
  parentProductSetInfoKey_lte?: ProductSetInfoKeyType | null;
  parentProductSetInfoKey_not_lte?: ProductSetInfoKeyType | null;
  parentProductSetInfoCompareValue?: string | null;
  parentProductSetInfoCompareValue_not?: string | null;
  parentProductSetInfoCompareValue_contains?: string | null;
  parentProductSetInfoCompareValue_not_contains?: string | null;
  parentProductSetInfoCompareValue_starts_with?: string | null;
  parentProductSetInfoCompareValue_not_starts_with?: string | null;
  parentProductSetInfoCompareValue_ends_with?: string | null;
  parentProductSetInfoCompareValue_not_ends_with?: string | null;
  parentProductSetInfoCompareValue_in?: string[] | null;
  parentProductSetInfoCompareValue_not_in?: string[] | null;
  parentProductSetInfoCompareOperation?: ProductSetInfoCompareOperation | null;
  parentProductSetInfoCompareOperation_not?: ProductSetInfoCompareOperation | null;
  parentProductSetInfoCompareOperation_in?: ProductSetInfoCompareOperation[] | null;
  parentProductSetInfoCompareOperation_not_in?: ProductSetInfoCompareOperation[] | null;
  parentProductSetInfoCompareOperation_gt?: ProductSetInfoCompareOperation | null;
  parentProductSetInfoCompareOperation_not_gt?: ProductSetInfoCompareOperation | null;
  parentProductSetInfoCompareOperation_gte?: ProductSetInfoCompareOperation | null;
  parentProductSetInfoCompareOperation_not_gte?: ProductSetInfoCompareOperation | null;
  parentProductSetInfoCompareOperation_lt?: ProductSetInfoCompareOperation | null;
  parentProductSetInfoCompareOperation_not_lt?: ProductSetInfoCompareOperation | null;
  parentProductSetInfoCompareOperation_lte?: ProductSetInfoCompareOperation | null;
  parentProductSetInfoCompareOperation_not_lte?: ProductSetInfoCompareOperation | null;
  parentProductCategoryId?: any | null;
  parentProductCategoryId_not?: any | null;
  parentProductCategoryId_in?: any[] | null;
  parentProductCategoryId_not_in?: any[] | null;
  parentProductCategoryId_gt?: any | null;
  parentProductCategoryId_not_gt?: any | null;
  parentProductCategoryId_gte?: any | null;
  parentProductCategoryId_not_gte?: any | null;
  parentProductCategoryId_lt?: any | null;
  parentProductCategoryId_not_lt?: any | null;
  parentProductCategoryId_lte?: any | null;
  parentProductCategoryId_not_lte?: any | null;
  parentProductCategory?: ProductCategoryFilter | null;
  isCompareParentToChildProperty?: boolean | null;
  isCompareParentToChildProperty_not?: boolean | null;
  productPackageId?: any | null;
  productPackageId_not?: any | null;
  productPackageId_in?: any[] | null;
  productPackageId_not_in?: any[] | null;
  productPackageId_gt?: any | null;
  productPackageId_not_gt?: any | null;
  productPackageId_gte?: any | null;
  productPackageId_not_gte?: any | null;
  productPackageId_lt?: any | null;
  productPackageId_not_lt?: any | null;
  productPackageId_lte?: any | null;
  productPackageId_not_lte?: any | null;
  productSetInfoKey?: ProductSetInfoKeyType | null;
  productSetInfoKey_not?: ProductSetInfoKeyType | null;
  productSetInfoKey_in?: ProductSetInfoKeyType[] | null;
  productSetInfoKey_not_in?: ProductSetInfoKeyType[] | null;
  productSetInfoKey_gt?: ProductSetInfoKeyType | null;
  productSetInfoKey_not_gt?: ProductSetInfoKeyType | null;
  productSetInfoKey_gte?: ProductSetInfoKeyType | null;
  productSetInfoKey_not_gte?: ProductSetInfoKeyType | null;
  productSetInfoKey_lt?: ProductSetInfoKeyType | null;
  productSetInfoKey_not_lt?: ProductSetInfoKeyType | null;
  productSetInfoKey_lte?: ProductSetInfoKeyType | null;
  productSetInfoKey_not_lte?: ProductSetInfoKeyType | null;
  productSetInfoCompareValue?: string | null;
  productSetInfoCompareValue_not?: string | null;
  productSetInfoCompareValue_contains?: string | null;
  productSetInfoCompareValue_not_contains?: string | null;
  productSetInfoCompareValue_starts_with?: string | null;
  productSetInfoCompareValue_not_starts_with?: string | null;
  productSetInfoCompareValue_ends_with?: string | null;
  productSetInfoCompareValue_not_ends_with?: string | null;
  productSetInfoCompareValue_in?: string[] | null;
  productSetInfoCompareValue_not_in?: string[] | null;
  productSetInfoCompareOperation?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_not?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_in?: ProductSetInfoCompareOperation[] | null;
  productSetInfoCompareOperation_not_in?: ProductSetInfoCompareOperation[] | null;
  productSetInfoCompareOperation_gt?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_not_gt?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_gte?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_not_gte?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_lt?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_not_lt?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_lte?: ProductSetInfoCompareOperation | null;
  productSetInfoCompareOperation_not_lte?: ProductSetInfoCompareOperation | null;
  productCategoryId?: any | null;
  productCategoryId_not?: any | null;
  productCategoryId_in?: any[] | null;
  productCategoryId_not_in?: any[] | null;
  productCategoryId_gt?: any | null;
  productCategoryId_not_gt?: any | null;
  productCategoryId_gte?: any | null;
  productCategoryId_not_gte?: any | null;
  productCategoryId_lt?: any | null;
  productCategoryId_not_lt?: any | null;
  productCategoryId_lte?: any | null;
  productCategoryId_not_lte?: any | null;
  productCategory?: ProductCategoryFilter | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * ProductSet present in one or more ProductPackage.
 */
export interface ProductSetSort {
  name?: SortOperationKind | null;
  status?: SortOperationKind | null;
  isNotAvailable?: SortOperationKind | null;
  productSetBrandlineId?: SortOperationKind | null;
  brandline?: ProductSetBrandlineSort | null;
  costPrice?: MoneySort | null;
  fixedPrice?: MoneySort | null;
  id?: SortOperationKind | null;
}

/**
 * Product item represents a single product that is used in product sets.
 */
export interface ProductSort {
  code?: SortOperationKind | null;
  supplierCode?: SortOperationKind | null;
  brand?: SortOperationKind | null;
  description?: SortOperationKind | null;
  longDescription?: SortOperationKind | null;
  marktingText?: SortOperationKind | null;
  statusCode?: SortOperationKind | null;
  isNotAvailable?: SortOperationKind | null;
  costPrice?: MoneySort | null;
  id?: SortOperationKind | null;
}

/**
 * Relational object that describes the relation between a project and a brand.
 */
export interface ProjectBrandFilter {
  AND?: ProjectBrandFilter[] | null;
  OR?: ProjectBrandFilter[] | null;
  projectId?: any | null;
  projectId_not?: any | null;
  projectId_in?: any[] | null;
  projectId_not_in?: any[] | null;
  projectId_gt?: any | null;
  projectId_not_gt?: any | null;
  projectId_gte?: any | null;
  projectId_not_gte?: any | null;
  projectId_lt?: any | null;
  projectId_not_lt?: any | null;
  projectId_lte?: any | null;
  projectId_not_lte?: any | null;
  project?: ProjectFilter | null;
  brandId?: any | null;
  brandId_not?: any | null;
  brandId_in?: any[] | null;
  brandId_not_in?: any[] | null;
  brandId_gt?: any | null;
  brandId_not_gt?: any | null;
  brandId_gte?: any | null;
  brandId_not_gte?: any | null;
  brandId_lt?: any | null;
  brandId_not_lt?: any | null;
  brandId_lte?: any | null;
  brandId_not_lte?: any | null;
  brand?: BrandFilter | null;
}

/**
 * ProjectDefaultProductSet represents a default ProductSet for a ProductCategory in a Project.
 */
export interface ProjectDefaultProductSetFilter {
  AND?: ProjectDefaultProductSetFilter[] | null;
  OR?: ProjectDefaultProductSetFilter[] | null;
  projectId?: any | null;
  projectId_not?: any | null;
  projectId_in?: any[] | null;
  projectId_not_in?: any[] | null;
  projectId_gt?: any | null;
  projectId_not_gt?: any | null;
  projectId_gte?: any | null;
  projectId_not_gte?: any | null;
  projectId_lt?: any | null;
  projectId_not_lt?: any | null;
  projectId_lte?: any | null;
  projectId_not_lte?: any | null;
  project?: ProjectFilter | null;
  isAvailable?: boolean | null;
  isAvailable_not?: boolean | null;
  productCategoryId?: any | null;
  productCategoryId_not?: any | null;
  productCategoryId_in?: any[] | null;
  productCategoryId_not_in?: any[] | null;
  productCategoryId_gt?: any | null;
  productCategoryId_not_gt?: any | null;
  productCategoryId_gte?: any | null;
  productCategoryId_not_gte?: any | null;
  productCategoryId_lt?: any | null;
  productCategoryId_not_lt?: any | null;
  productCategoryId_lte?: any | null;
  productCategoryId_not_lte?: any | null;
  productCategory?: ProductCategoryFilter | null;
  productSetId?: any | null;
  productSetId_not?: any | null;
  productSetId_in?: any[] | null;
  productSetId_not_in?: any[] | null;
  productSetId_gt?: any | null;
  productSetId_not_gt?: any | null;
  productSetId_gte?: any | null;
  productSetId_not_gte?: any | null;
  productSetId_lt?: any | null;
  productSetId_not_lt?: any | null;
  productSetId_lte?: any | null;
  productSetId_not_lte?: any | null;
  productSet?: ProductSetFilter | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * Extended properties for Project used for listing.
 */
export interface ProjectFilter {
  AND?: ProjectFilter[] | null;
  OR?: ProjectFilter[] | null;
  uninvitedCount?: number | null;
  uninvitedCount_not?: number | null;
  uninvitedCount_in?: number[] | null;
  uninvitedCount_not_in?: number[] | null;
  uninvitedCount_gt?: number | null;
  uninvitedCount_not_gt?: number | null;
  uninvitedCount_gte?: number | null;
  uninvitedCount_not_gte?: number | null;
  uninvitedCount_lt?: number | null;
  uninvitedCount_not_lt?: number | null;
  uninvitedCount_lte?: number | null;
  uninvitedCount_not_lte?: number | null;
  name?: string | null;
  name_not?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  number?: string | null;
  number_not?: string | null;
  number_contains?: string | null;
  number_not_contains?: string | null;
  number_starts_with?: string | null;
  number_not_starts_with?: string | null;
  number_ends_with?: string | null;
  number_not_ends_with?: string | null;
  number_in?: string[] | null;
  number_not_in?: string[] | null;
  projectDeveloperName?: string | null;
  projectDeveloperName_not?: string | null;
  projectDeveloperName_contains?: string | null;
  projectDeveloperName_not_contains?: string | null;
  projectDeveloperName_starts_with?: string | null;
  projectDeveloperName_not_starts_with?: string | null;
  projectDeveloperName_ends_with?: string | null;
  projectDeveloperName_not_ends_with?: string | null;
  projectDeveloperName_in?: string[] | null;
  projectDeveloperName_not_in?: string[] | null;
  shellOnlyAllowed?: boolean | null;
  shellOnlyAllowed_not?: boolean | null;
  status?: ProjectStatus | null;
  status_not?: ProjectStatus | null;
  status_in?: ProjectStatus[] | null;
  status_not_in?: ProjectStatus[] | null;
  status_gt?: ProjectStatus | null;
  status_not_gt?: ProjectStatus | null;
  status_gte?: ProjectStatus | null;
  status_not_gte?: ProjectStatus | null;
  status_lt?: ProjectStatus | null;
  status_not_lt?: ProjectStatus | null;
  status_lte?: ProjectStatus | null;
  status_not_lte?: ProjectStatus | null;
  publishedOn?: any | null;
  publishedOn_not?: any | null;
  publishedOn_in?: (any | null)[] | null;
  publishedOn_not_in?: (any | null)[] | null;
  publishedOn_gt?: any | null;
  publishedOn_not_gt?: any | null;
  publishedOn_gte?: any | null;
  publishedOn_not_gte?: any | null;
  publishedOn_lt?: any | null;
  publishedOn_not_lt?: any | null;
  publishedOn_lte?: any | null;
  publishedOn_not_lte?: any | null;
  projectType?: badkamercore_ProjectType | null;
  projectType_not?: badkamercore_ProjectType | null;
  projectType_in?: badkamercore_ProjectType[] | null;
  projectType_not_in?: badkamercore_ProjectType[] | null;
  projectType_gt?: badkamercore_ProjectType | null;
  projectType_not_gt?: badkamercore_ProjectType | null;
  projectType_gte?: badkamercore_ProjectType | null;
  projectType_not_gte?: badkamercore_ProjectType | null;
  projectType_lt?: badkamercore_ProjectType | null;
  projectType_not_lt?: badkamercore_ProjectType | null;
  projectType_lte?: badkamercore_ProjectType | null;
  projectType_not_lte?: badkamercore_ProjectType | null;
  closedOn?: any | null;
  closedOn_not?: any | null;
  closedOn_in?: (any | null)[] | null;
  closedOn_not_in?: (any | null)[] | null;
  closedOn_gt?: any | null;
  closedOn_not_gt?: any | null;
  closedOn_gte?: any | null;
  closedOn_not_gte?: any | null;
  closedOn_lt?: any | null;
  closedOn_not_lt?: any | null;
  closedOn_lte?: any | null;
  closedOn_not_lte?: any | null;
  lastImportedOn?: any | null;
  lastImportedOn_not?: any | null;
  lastImportedOn_in?: (any | null)[] | null;
  lastImportedOn_not_in?: (any | null)[] | null;
  lastImportedOn_gt?: any | null;
  lastImportedOn_not_gt?: any | null;
  lastImportedOn_gte?: any | null;
  lastImportedOn_not_gte?: any | null;
  lastImportedOn_lt?: any | null;
  lastImportedOn_not_lt?: any | null;
  lastImportedOn_lte?: any | null;
  lastImportedOn_not_lte?: any | null;
  companyMarginSanitary?: any | null;
  companyMarginSanitary_not?: any | null;
  companyMarginSanitary_in?: (any | null)[] | null;
  companyMarginSanitary_not_in?: (any | null)[] | null;
  companyMarginSanitary_gt?: any | null;
  companyMarginSanitary_not_gt?: any | null;
  companyMarginSanitary_gte?: any | null;
  companyMarginSanitary_not_gte?: any | null;
  companyMarginSanitary_lt?: any | null;
  companyMarginSanitary_not_lt?: any | null;
  companyMarginSanitary_lte?: any | null;
  companyMarginSanitary_not_lte?: any | null;
  companyMarginTiles?: any | null;
  companyMarginTiles_not?: any | null;
  companyMarginTiles_in?: (any | null)[] | null;
  companyMarginTiles_not_in?: (any | null)[] | null;
  companyMarginTiles_gt?: any | null;
  companyMarginTiles_not_gt?: any | null;
  companyMarginTiles_gte?: any | null;
  companyMarginTiles_not_gte?: any | null;
  companyMarginTiles_lt?: any | null;
  companyMarginTiles_not_lt?: any | null;
  companyMarginTiles_lte?: any | null;
  companyMarginTiles_not_lte?: any | null;
  marginInstaller?: any | null;
  marginInstaller_not?: any | null;
  marginInstaller_in?: (any | null)[] | null;
  marginInstaller_not_in?: (any | null)[] | null;
  marginInstaller_gt?: any | null;
  marginInstaller_not_gt?: any | null;
  marginInstaller_gte?: any | null;
  marginInstaller_not_gte?: any | null;
  marginInstaller_lt?: any | null;
  marginInstaller_not_lt?: any | null;
  marginInstaller_lte?: any | null;
  marginInstaller_not_lte?: any | null;
  marginTiler?: any | null;
  marginTiler_not?: any | null;
  marginTiler_in?: (any | null)[] | null;
  marginTiler_not_in?: (any | null)[] | null;
  marginTiler_gt?: any | null;
  marginTiler_not_gt?: any | null;
  marginTiler_gte?: any | null;
  marginTiler_not_gte?: any | null;
  marginTiler_lt?: any | null;
  marginTiler_not_lt?: any | null;
  marginTiler_lte?: any | null;
  marginTiler_not_lte?: any | null;
  marginBuilder?: any | null;
  marginBuilder_not?: any | null;
  marginBuilder_in?: (any | null)[] | null;
  marginBuilder_not_in?: (any | null)[] | null;
  marginBuilder_gt?: any | null;
  marginBuilder_not_gt?: any | null;
  marginBuilder_gte?: any | null;
  marginBuilder_not_gte?: any | null;
  marginBuilder_lt?: any | null;
  marginBuilder_not_lt?: any | null;
  marginBuilder_lte?: any | null;
  marginBuilder_not_lte?: any | null;
  correctionInstallationFactor?: any | null;
  correctionInstallationFactor_not?: any | null;
  correctionInstallationFactor_in?: (any | null)[] | null;
  correctionInstallationFactor_not_in?: (any | null)[] | null;
  correctionInstallationFactor_gt?: any | null;
  correctionInstallationFactor_not_gt?: any | null;
  correctionInstallationFactor_gte?: any | null;
  correctionInstallationFactor_not_gte?: any | null;
  correctionInstallationFactor_lt?: any | null;
  correctionInstallationFactor_not_lt?: any | null;
  correctionInstallationFactor_lte?: any | null;
  correctionInstallationFactor_not_lte?: any | null;
  correctionInstallationWFactor?: any | null;
  correctionInstallationWFactor_not?: any | null;
  correctionInstallationWFactor_in?: (any | null)[] | null;
  correctionInstallationWFactor_not_in?: (any | null)[] | null;
  correctionInstallationWFactor_gt?: any | null;
  correctionInstallationWFactor_not_gt?: any | null;
  correctionInstallationWFactor_gte?: any | null;
  correctionInstallationWFactor_not_gte?: any | null;
  correctionInstallationWFactor_lt?: any | null;
  correctionInstallationWFactor_not_lt?: any | null;
  correctionInstallationWFactor_lte?: any | null;
  correctionInstallationWFactor_not_lte?: any | null;
  correctionInstallationEFactor?: any | null;
  correctionInstallationEFactor_not?: any | null;
  correctionInstallationEFactor_in?: (any | null)[] | null;
  correctionInstallationEFactor_not_in?: (any | null)[] | null;
  correctionInstallationEFactor_gt?: any | null;
  correctionInstallationEFactor_not_gt?: any | null;
  correctionInstallationEFactor_gte?: any | null;
  correctionInstallationEFactor_not_gte?: any | null;
  correctionInstallationEFactor_lt?: any | null;
  correctionInstallationEFactor_not_lt?: any | null;
  correctionInstallationEFactor_lte?: any | null;
  correctionInstallationEFactor_not_lte?: any | null;
  correctionInstallationCVFactor?: any | null;
  correctionInstallationCVFactor_not?: any | null;
  correctionInstallationCVFactor_in?: (any | null)[] | null;
  correctionInstallationCVFactor_not_in?: (any | null)[] | null;
  correctionInstallationCVFactor_gt?: any | null;
  correctionInstallationCVFactor_not_gt?: any | null;
  correctionInstallationCVFactor_gte?: any | null;
  correctionInstallationCVFactor_not_gte?: any | null;
  correctionInstallationCVFactor_lt?: any | null;
  correctionInstallationCVFactor_not_lt?: any | null;
  correctionInstallationCVFactor_lte?: any | null;
  correctionInstallationCVFactor_not_lte?: any | null;
  correctionTilerFactor?: any | null;
  correctionTilerFactor_not?: any | null;
  correctionTilerFactor_in?: (any | null)[] | null;
  correctionTilerFactor_not_in?: (any | null)[] | null;
  correctionTilerFactor_gt?: any | null;
  correctionTilerFactor_not_gt?: any | null;
  correctionTilerFactor_gte?: any | null;
  correctionTilerFactor_not_gte?: any | null;
  correctionTilerFactor_lt?: any | null;
  correctionTilerFactor_not_lt?: any | null;
  correctionTilerFactor_lte?: any | null;
  correctionTilerFactor_not_lte?: any | null;
  correctionConstructionFactor?: any | null;
  correctionConstructionFactor_not?: any | null;
  correctionConstructionFactor_in?: (any | null)[] | null;
  correctionConstructionFactor_not_in?: (any | null)[] | null;
  correctionConstructionFactor_gt?: any | null;
  correctionConstructionFactor_not_gt?: any | null;
  correctionConstructionFactor_gte?: any | null;
  correctionConstructionFactor_not_gte?: any | null;
  correctionConstructionFactor_lt?: any | null;
  correctionConstructionFactor_not_lt?: any | null;
  correctionConstructionFactor_lte?: any | null;
  correctionConstructionFactor_not_lte?: any | null;
  vAT?: any | null;
  vAT_not?: any | null;
  vAT_in?: (any | null)[] | null;
  vAT_not_in?: (any | null)[] | null;
  vAT_gt?: any | null;
  vAT_not_gt?: any | null;
  vAT_gte?: any | null;
  vAT_not_gte?: any | null;
  vAT_lt?: any | null;
  vAT_not_lt?: any | null;
  vAT_lte?: any | null;
  vAT_not_lte?: any | null;
  isCustomOfferFeatureActive?: boolean | null;
  isCustomOfferFeatureActive_not?: boolean | null;
  useMinusReturnPriceCalculation?: boolean | null;
  useMinusReturnPriceCalculation_not?: boolean | null;
  layouts_some?: LayoutFilter | null;
  layouts_none?: LayoutFilter | null;
  layouts_all?: LayoutFilter | null;
  layouts_any?: boolean | null;
  rooms_some?: RoomFilter | null;
  rooms_none?: RoomFilter | null;
  rooms_all?: RoomFilter | null;
  rooms_any?: boolean | null;
  realEstates_some?: RealEstateFilter | null;
  realEstates_none?: RealEstateFilter | null;
  realEstates_all?: RealEstateFilter | null;
  realEstates_any?: boolean | null;
  assignedStyles_some?: ProjectStyleFilter | null;
  assignedStyles_none?: ProjectStyleFilter | null;
  assignedStyles_all?: ProjectStyleFilter | null;
  assignedStyles_any?: boolean | null;
  assignedBrands_some?: ProjectBrandFilter | null;
  assignedBrands_none?: ProjectBrandFilter | null;
  assignedBrands_all?: ProjectBrandFilter | null;
  assignedBrands_any?: boolean | null;
  assignedProductPackages_some?: ProjectProductPackageFilter | null;
  assignedProductPackages_none?: ProjectProductPackageFilter | null;
  assignedProductPackages_all?: ProjectProductPackageFilter | null;
  assignedProductPackages_any?: boolean | null;
  assignedDefaultProducts_some?: ProjectDefaultProductSetFilter | null;
  assignedDefaultProducts_none?: ProjectDefaultProductSetFilter | null;
  assignedDefaultProducts_all?: ProjectDefaultProductSetFilter | null;
  assignedDefaultProducts_any?: boolean | null;
  assignedProjectProductReturnPrices_some?: ProjectProductReturnPriceFilter | null;
  assignedProjectProductReturnPrices_none?: ProjectProductReturnPriceFilter | null;
  assignedProjectProductReturnPrices_all?: ProjectProductReturnPriceFilter | null;
  assignedProjectProductReturnPrices_any?: boolean | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * Relational object that describes the relation between a project and a product package.
 */
export interface ProjectProductPackageFilter {
  AND?: ProjectProductPackageFilter[] | null;
  OR?: ProjectProductPackageFilter[] | null;
  projectId?: any | null;
  projectId_not?: any | null;
  projectId_in?: any[] | null;
  projectId_not_in?: any[] | null;
  projectId_gt?: any | null;
  projectId_not_gt?: any | null;
  projectId_gte?: any | null;
  projectId_not_gte?: any | null;
  projectId_lt?: any | null;
  projectId_not_lt?: any | null;
  projectId_lte?: any | null;
  projectId_not_lte?: any | null;
  project?: ProjectFilter | null;
  productPackageId?: any | null;
  productPackageId_not?: any | null;
  productPackageId_in?: any[] | null;
  productPackageId_not_in?: any[] | null;
  productPackageId_gt?: any | null;
  productPackageId_not_gt?: any | null;
  productPackageId_gte?: any | null;
  productPackageId_not_gte?: any | null;
  productPackageId_lt?: any | null;
  productPackageId_not_lt?: any | null;
  productPackageId_lte?: any | null;
  productPackageId_not_lte?: any | null;
  productPackage?: ProductPackageFilter | null;
}

/**
 * ProjectProductReturnPrice.
 */
export interface ProjectProductReturnPriceFilter {
  AND?: ProjectProductReturnPriceFilter[] | null;
  OR?: ProjectProductReturnPriceFilter[] | null;
  projectId?: any | null;
  projectId_not?: any | null;
  projectId_in?: any[] | null;
  projectId_not_in?: any[] | null;
  projectId_gt?: any | null;
  projectId_not_gt?: any | null;
  projectId_gte?: any | null;
  projectId_not_gte?: any | null;
  projectId_lt?: any | null;
  projectId_not_lt?: any | null;
  projectId_lte?: any | null;
  projectId_not_lte?: any | null;
  project?: ProjectFilter | null;
  productId?: any | null;
  productId_not?: any | null;
  productId_in?: any[] | null;
  productId_not_in?: any[] | null;
  productId_gt?: any | null;
  productId_not_gt?: any | null;
  productId_gte?: any | null;
  productId_not_gte?: any | null;
  productId_lt?: any | null;
  productId_not_lt?: any | null;
  productId_lte?: any | null;
  productId_not_lte?: any | null;
  product?: ProductFilter | null;
  returnPrice?: MoneyFilter | null;
  productCombinationId?: any | null;
  productCombinationId_not?: any | null;
  productCombinationId_in?: any[] | null;
  productCombinationId_not_in?: any[] | null;
  productCombinationId_gt?: any | null;
  productCombinationId_not_gt?: any | null;
  productCombinationId_gte?: any | null;
  productCombinationId_not_gte?: any | null;
  productCombinationId_lt?: any | null;
  productCombinationId_not_lt?: any | null;
  productCombinationId_lte?: any | null;
  productCombinationId_not_lte?: any | null;
  productCombination?: ProductCombinationFilter | null;
}

/**
 * ProjectProductReturnPrice.
 */
export interface ProjectProductReturnPriceSort {
  projectId?: SortOperationKind | null;
  project?: ProjectSort | null;
  productId?: SortOperationKind | null;
  product?: ProductSort | null;
  returnPrice?: MoneySort | null;
  productCombinationId?: SortOperationKind | null;
  productCombination?: ProductCombinationSort | null;
}

/**
 * Extended properties for Project used for listing.
 */
export interface ProjectSort {
  uninvitedCount?: SortOperationKind | null;
  name?: SortOperationKind | null;
  number?: SortOperationKind | null;
  projectDeveloperName?: SortOperationKind | null;
  shellOnlyAllowed?: SortOperationKind | null;
  status?: SortOperationKind | null;
  publishedOn?: SortOperationKind | null;
  projectType?: SortOperationKind | null;
  closedOn?: SortOperationKind | null;
  lastImportedOn?: SortOperationKind | null;
  companyMarginSanitary?: SortOperationKind | null;
  companyMarginTiles?: SortOperationKind | null;
  marginInstaller?: SortOperationKind | null;
  marginTiler?: SortOperationKind | null;
  marginBuilder?: SortOperationKind | null;
  correctionInstallationFactor?: SortOperationKind | null;
  correctionInstallationWFactor?: SortOperationKind | null;
  correctionInstallationEFactor?: SortOperationKind | null;
  correctionInstallationCVFactor?: SortOperationKind | null;
  correctionTilerFactor?: SortOperationKind | null;
  correctionConstructionFactor?: SortOperationKind | null;
  vAT?: SortOperationKind | null;
  isCustomOfferFeatureActive?: SortOperationKind | null;
  useMinusReturnPriceCalculation?: SortOperationKind | null;
  id?: SortOperationKind | null;
}

/**
 * Relational object that describes the relation between a project and a style.
 */
export interface ProjectStyleFilter {
  AND?: ProjectStyleFilter[] | null;
  OR?: ProjectStyleFilter[] | null;
  projectId?: any | null;
  projectId_not?: any | null;
  projectId_in?: any[] | null;
  projectId_not_in?: any[] | null;
  projectId_gt?: any | null;
  projectId_not_gt?: any | null;
  projectId_gte?: any | null;
  projectId_not_gte?: any | null;
  projectId_lt?: any | null;
  projectId_not_lt?: any | null;
  projectId_lte?: any | null;
  projectId_not_lte?: any | null;
  project?: ProjectFilter | null;
  styleId?: any | null;
  styleId_not?: any | null;
  styleId_in?: any[] | null;
  styleId_not_in?: any[] | null;
  styleId_gt?: any | null;
  styleId_not_gt?: any | null;
  styleId_gte?: any | null;
  styleId_not_gte?: any | null;
  styleId_lt?: any | null;
  styleId_not_lt?: any | null;
  styleId_lte?: any | null;
  styleId_not_lte?: any | null;
  style?: StyleFilter | null;
}

/**
 * Represents a single person that is a future owner of the RealEstate in the scope of one RealEstate within one Project.
 */
export interface RealEstateContactFilter {
  AND?: RealEstateContactFilter[] | null;
  OR?: RealEstateContactFilter[] | null;
  realEstateId?: any | null;
  realEstateId_not?: any | null;
  realEstateId_in?: any[] | null;
  realEstateId_not_in?: any[] | null;
  realEstateId_gt?: any | null;
  realEstateId_not_gt?: any | null;
  realEstateId_gte?: any | null;
  realEstateId_not_gte?: any | null;
  realEstateId_lt?: any | null;
  realEstateId_not_lt?: any | null;
  realEstateId_lte?: any | null;
  realEstateId_not_lte?: any | null;
  realEstate?: RealEstateFilter | null;
  email?: EmailFilter | null;
  phone?: PhoneNumberFilter | null;
  legalName?: string | null;
  legalName_not?: string | null;
  legalName_contains?: string | null;
  legalName_not_contains?: string | null;
  legalName_starts_with?: string | null;
  legalName_not_starts_with?: string | null;
  legalName_ends_with?: string | null;
  legalName_not_ends_with?: string | null;
  legalName_in?: (string | null)[] | null;
  legalName_not_in?: (string | null)[] | null;
  accessToken?: any | null;
  accessToken_not?: any | null;
  accessToken_in?: any[] | null;
  accessToken_not_in?: any[] | null;
  accessToken_gt?: any | null;
  accessToken_not_gt?: any | null;
  accessToken_gte?: any | null;
  accessToken_not_gte?: any | null;
  accessToken_lt?: any | null;
  accessToken_not_lt?: any | null;
  accessToken_lte?: any | null;
  accessToken_not_lte?: any | null;
  lastLogin?: any | null;
  lastLogin_not?: any | null;
  lastLogin_in?: (any | null)[] | null;
  lastLogin_not_in?: (any | null)[] | null;
  lastLogin_gt?: any | null;
  lastLogin_not_gt?: any | null;
  lastLogin_gte?: any | null;
  lastLogin_not_gte?: any | null;
  lastLogin_lt?: any | null;
  lastLogin_not_lt?: any | null;
  lastLogin_lte?: any | null;
  lastLogin_not_lte?: any | null;
  invitationSent?: any | null;
  invitationSent_not?: any | null;
  invitationSent_in?: (any | null)[] | null;
  invitationSent_not_in?: (any | null)[] | null;
  invitationSent_gt?: any | null;
  invitationSent_not_gt?: any | null;
  invitationSent_gte?: any | null;
  invitationSent_not_gte?: any | null;
  invitationSent_lt?: any | null;
  invitationSent_not_lt?: any | null;
  invitationSent_lte?: any | null;
  invitationSent_not_lte?: any | null;
  userId?: any | null;
  userId_not?: any | null;
  userId_in?: (any | null)[] | null;
  userId_not_in?: (any | null)[] | null;
  userId_gt?: any | null;
  userId_not_gt?: any | null;
  userId_gte?: any | null;
  userId_not_gte?: any | null;
  userId_lt?: any | null;
  userId_not_lt?: any | null;
  userId_lte?: any | null;
  userId_not_lte?: any | null;
  user?: UserFilter | null;
  realEstateContactNotifications_some?: RealEstateContactNotificationFilter | null;
  realEstateContactNotifications_none?: RealEstateContactNotificationFilter | null;
  realEstateContactNotifications_all?: RealEstateContactNotificationFilter | null;
  realEstateContactNotifications_any?: boolean | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * Represents a single notification sent to a person that is a future owner of the
 * RealEstate in the scope of one RealEstate within one Project.
 */
export interface RealEstateContactNotificationFilter {
  AND?: RealEstateContactNotificationFilter[] | null;
  OR?: RealEstateContactNotificationFilter[] | null;
  realEstateContactId?: any | null;
  realEstateContactId_not?: any | null;
  realEstateContactId_in?: any[] | null;
  realEstateContactId_not_in?: any[] | null;
  realEstateContactId_gt?: any | null;
  realEstateContactId_not_gt?: any | null;
  realEstateContactId_gte?: any | null;
  realEstateContactId_not_gte?: any | null;
  realEstateContactId_lt?: any | null;
  realEstateContactId_not_lt?: any | null;
  realEstateContactId_lte?: any | null;
  realEstateContactId_not_lte?: any | null;
  realEstateContact?: RealEstateContactFilter | null;
  notificationType?: ReminderNotificationType | null;
  notificationType_not?: ReminderNotificationType | null;
  notificationType_in?: ReminderNotificationType[] | null;
  notificationType_not_in?: ReminderNotificationType[] | null;
  notificationType_gt?: ReminderNotificationType | null;
  notificationType_not_gt?: ReminderNotificationType | null;
  notificationType_gte?: ReminderNotificationType | null;
  notificationType_not_gte?: ReminderNotificationType | null;
  notificationType_lt?: ReminderNotificationType | null;
  notificationType_not_lt?: ReminderNotificationType | null;
  notificationType_lte?: ReminderNotificationType | null;
  notificationType_not_lte?: ReminderNotificationType | null;
  sentDate?: any | null;
  sentDate_not?: any | null;
  sentDate_in?: any[] | null;
  sentDate_not_in?: any[] | null;
  sentDate_gt?: any | null;
  sentDate_not_gt?: any | null;
  sentDate_gte?: any | null;
  sentDate_not_gte?: any | null;
  sentDate_lt?: any | null;
  sentDate_not_lt?: any | null;
  sentDate_lte?: any | null;
  sentDate_not_lte?: any | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * Represents a single person that is a future owner of the RealEstate in the scope of one RealEstate within one Project.
 */
export interface RealEstateContactSort {
  realEstateId?: SortOperationKind | null;
  realEstate?: RealEstateSort | null;
  email?: EmailSort | null;
  phone?: PhoneNumberSort | null;
  legalName?: SortOperationKind | null;
  accessToken?: SortOperationKind | null;
  lastLogin?: SortOperationKind | null;
  invitationSent?: SortOperationKind | null;
  userId?: SortOperationKind | null;
  user?: UserSort | null;
  id?: SortOperationKind | null;
}

/**
 * Real estate represents a single building.
 */
export interface RealEstateFilter {
  AND?: RealEstateFilter[] | null;
  OR?: RealEstateFilter[] | null;
  number?: string | null;
  number_not?: string | null;
  number_contains?: string | null;
  number_not_contains?: string | null;
  number_starts_with?: string | null;
  number_not_starts_with?: string | null;
  number_ends_with?: string | null;
  number_not_ends_with?: string | null;
  number_in?: string[] | null;
  number_not_in?: string[] | null;
  buildNumber?: string | null;
  buildNumber_not?: string | null;
  buildNumber_contains?: string | null;
  buildNumber_not_contains?: string | null;
  buildNumber_starts_with?: string | null;
  buildNumber_not_starts_with?: string | null;
  buildNumber_ends_with?: string | null;
  buildNumber_not_ends_with?: string | null;
  buildNumber_in?: (string | null)[] | null;
  buildNumber_not_in?: (string | null)[] | null;
  layout?: LayoutFilter | null;
  contact?: RealEstateContactFilter | null;
  projectId?: any | null;
  projectId_not?: any | null;
  projectId_in?: any[] | null;
  projectId_not_in?: any[] | null;
  projectId_gt?: any | null;
  projectId_not_gt?: any | null;
  projectId_gte?: any | null;
  projectId_not_gte?: any | null;
  projectId_lt?: any | null;
  projectId_not_lt?: any | null;
  projectId_lte?: any | null;
  projectId_not_lte?: any | null;
  project?: ProjectFilter | null;
  closeDateUtc?: any | null;
  closeDateUtc_not?: any | null;
  closeDateUtc_in?: (any | null)[] | null;
  closeDateUtc_not_in?: (any | null)[] | null;
  closeDateUtc_gt?: any | null;
  closeDateUtc_not_gt?: any | null;
  closeDateUtc_gte?: any | null;
  closeDateUtc_not_gte?: any | null;
  closeDateUtc_lt?: any | null;
  closeDateUtc_not_lt?: any | null;
  closeDateUtc_lte?: any | null;
  closeDateUtc_not_lte?: any | null;
  requestForQuotes_some?: RequestForQuoteFilter | null;
  requestForQuotes_none?: RequestForQuoteFilter | null;
  requestForQuotes_all?: RequestForQuoteFilter | null;
  requestForQuotes_any?: boolean | null;
  requestFor3DDrawings_some?: RequestFor3DDrawingFilter | null;
  requestFor3DDrawings_none?: RequestFor3DDrawingFilter | null;
  requestFor3DDrawings_all?: RequestFor3DDrawingFilter | null;
  requestFor3DDrawings_any?: boolean | null;
  requestForCustomOffers_some?: RequestForCustomOfferFilter | null;
  requestForCustomOffers_none?: RequestForCustomOfferFilter | null;
  requestForCustomOffers_all?: RequestForCustomOfferFilter | null;
  requestForCustomOffers_any?: boolean | null;
  activeCustomOfferRequest?: RequestForCustomOfferFilter | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * Real estate represents a single building.
 */
export interface RealEstateSort {
  number?: SortOperationKind | null;
  buildNumber?: SortOperationKind | null;
  layout?: LayoutSort | null;
  contact?: RealEstateContactSort | null;
  projectId?: SortOperationKind | null;
  project?: ProjectSort | null;
  closeDateUtc?: SortOperationKind | null;
  activeCustomOfferRequest?: RequestForCustomOfferSort | null;
  id?: SortOperationKind | null;
}

/**
 * Represents a request for a 3D drawing on a selected DesignPackage (for a
 * specific room) in a specific RealEstate with a specific list of selected ProductSets.
 */
export interface RequestFor3DDrawingFilter {
  AND?: RequestFor3DDrawingFilter[] | null;
  OR?: RequestFor3DDrawingFilter[] | null;
  realEstate?: RealEstateFilter | null;
  realEstateId?: any | null;
  realEstateId_not?: any | null;
  realEstateId_in?: any[] | null;
  realEstateId_not_in?: any[] | null;
  realEstateId_gt?: any | null;
  realEstateId_not_gt?: any | null;
  realEstateId_gte?: any | null;
  realEstateId_not_gte?: any | null;
  realEstateId_lt?: any | null;
  realEstateId_not_lt?: any | null;
  realEstateId_lte?: any | null;
  realEstateId_not_lte?: any | null;
  designPackage?: DesignPackageFilter | null;
  designPackageId?: any | null;
  designPackageId_not?: any | null;
  designPackageId_in?: any[] | null;
  designPackageId_not_in?: any[] | null;
  designPackageId_gt?: any | null;
  designPackageId_not_gt?: any | null;
  designPackageId_gte?: any | null;
  designPackageId_not_gte?: any | null;
  designPackageId_lt?: any | null;
  designPackageId_not_lt?: any | null;
  designPackageId_lte?: any | null;
  designPackageId_not_lte?: any | null;
  productSelectionId?: any | null;
  productSelectionId_not?: any | null;
  productSelectionId_in?: any[] | null;
  productSelectionId_not_in?: any[] | null;
  productSelectionId_gt?: any | null;
  productSelectionId_not_gt?: any | null;
  productSelectionId_gte?: any | null;
  productSelectionId_not_gte?: any | null;
  productSelectionId_lt?: any | null;
  productSelectionId_not_lt?: any | null;
  productSelectionId_lte?: any | null;
  productSelectionId_not_lte?: any | null;
  roomSelectionId?: any | null;
  roomSelectionId_not?: any | null;
  roomSelectionId_in?: any[] | null;
  roomSelectionId_not_in?: any[] | null;
  roomSelectionId_gt?: any | null;
  roomSelectionId_not_gt?: any | null;
  roomSelectionId_gte?: any | null;
  roomSelectionId_not_gte?: any | null;
  roomSelectionId_lt?: any | null;
  roomSelectionId_not_lt?: any | null;
  roomSelectionId_lte?: any | null;
  roomSelectionId_not_lte?: any | null;
  selectionName?: string | null;
  selectionName_not?: string | null;
  selectionName_contains?: string | null;
  selectionName_not_contains?: string | null;
  selectionName_starts_with?: string | null;
  selectionName_not_starts_with?: string | null;
  selectionName_ends_with?: string | null;
  selectionName_not_ends_with?: string | null;
  selectionName_in?: string[] | null;
  selectionName_not_in?: string[] | null;
  request3DDrawingStatus?: DrawingRequestStatus | null;
  request3DDrawingStatus_not?: DrawingRequestStatus | null;
  request3DDrawingStatus_in?: (DrawingRequestStatus | null)[] | null;
  request3DDrawingStatus_not_in?: (DrawingRequestStatus | null)[] | null;
  request3DDrawingStatus_gt?: DrawingRequestStatus | null;
  request3DDrawingStatus_not_gt?: DrawingRequestStatus | null;
  request3DDrawingStatus_gte?: DrawingRequestStatus | null;
  request3DDrawingStatus_not_gte?: DrawingRequestStatus | null;
  request3DDrawingStatus_lt?: DrawingRequestStatus | null;
  request3DDrawingStatus_not_lt?: DrawingRequestStatus | null;
  request3DDrawingStatus_lte?: DrawingRequestStatus | null;
  request3DDrawingStatus_not_lte?: DrawingRequestStatus | null;
  requested?: any | null;
  requested_not?: any | null;
  requested_in?: any[] | null;
  requested_not_in?: any[] | null;
  requested_gt?: any | null;
  requested_not_gt?: any | null;
  requested_gte?: any | null;
  requested_not_gte?: any | null;
  requested_lt?: any | null;
  requested_not_lt?: any | null;
  requested_lte?: any | null;
  requested_not_lte?: any | null;
  deadline?: any | null;
  deadline_not?: any | null;
  deadline_in?: (any | null)[] | null;
  deadline_not_in?: (any | null)[] | null;
  deadline_gt?: any | null;
  deadline_not_gt?: any | null;
  deadline_gte?: any | null;
  deadline_not_gte?: any | null;
  deadline_lt?: any | null;
  deadline_not_lt?: any | null;
  deadline_lte?: any | null;
  deadline_not_lte?: any | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * Represents a request for a 3D drawing on a selected DesignPackage (for a
 * specific room) in a specific RealEstate with a specific list of selected ProductSets.
 */
export interface RequestFor3DDrawingSort {
  realEstate?: RealEstateSort | null;
  realEstateId?: SortOperationKind | null;
  designPackage?: DesignPackageSort | null;
  designPackageId?: SortOperationKind | null;
  productSelectionId?: SortOperationKind | null;
  roomSelectionId?: SortOperationKind | null;
  selectionName?: SortOperationKind | null;
  request3DDrawingStatus?: SortOperationKind | null;
  requested?: SortOperationKind | null;
  deadline?: SortOperationKind | null;
  id?: SortOperationKind | null;
}

/**
 * Represents a request for a custom offer in a specific RealEstate.
 */
export interface RequestForCustomOfferFilter {
  AND?: RequestForCustomOfferFilter[] | null;
  OR?: RequestForCustomOfferFilter[] | null;
  realEstate?: RealEstateFilter | null;
  realEstateId?: any | null;
  realEstateId_not?: any | null;
  realEstateId_in?: any[] | null;
  realEstateId_not_in?: any[] | null;
  realEstateId_gt?: any | null;
  realEstateId_not_gt?: any | null;
  realEstateId_gte?: any | null;
  realEstateId_not_gte?: any | null;
  realEstateId_lt?: any | null;
  realEstateId_not_lt?: any | null;
  realEstateId_lte?: any | null;
  realEstateId_not_lte?: any | null;
  appointmentType?: CustomOfferAppointmentType | null;
  appointmentType_not?: CustomOfferAppointmentType | null;
  appointmentType_in?: CustomOfferAppointmentType[] | null;
  appointmentType_not_in?: CustomOfferAppointmentType[] | null;
  appointmentType_gt?: CustomOfferAppointmentType | null;
  appointmentType_not_gt?: CustomOfferAppointmentType | null;
  appointmentType_gte?: CustomOfferAppointmentType | null;
  appointmentType_not_gte?: CustomOfferAppointmentType | null;
  appointmentType_lt?: CustomOfferAppointmentType | null;
  appointmentType_not_lt?: CustomOfferAppointmentType | null;
  appointmentType_lte?: CustomOfferAppointmentType | null;
  appointmentType_not_lte?: CustomOfferAppointmentType | null;
  location?: ShowroomLocation | null;
  location_not?: ShowroomLocation | null;
  location_in?: (ShowroomLocation | null)[] | null;
  location_not_in?: (ShowroomLocation | null)[] | null;
  location_gt?: ShowroomLocation | null;
  location_not_gt?: ShowroomLocation | null;
  location_gte?: ShowroomLocation | null;
  location_not_gte?: ShowroomLocation | null;
  location_lt?: ShowroomLocation | null;
  location_not_lt?: ShowroomLocation | null;
  location_lte?: ShowroomLocation | null;
  location_not_lte?: ShowroomLocation | null;
  contactType?: ContactType | null;
  contactType_not?: ContactType | null;
  contactType_in?: (ContactType | null)[] | null;
  contactType_not_in?: (ContactType | null)[] | null;
  contactType_gt?: ContactType | null;
  contactType_not_gt?: ContactType | null;
  contactType_gte?: ContactType | null;
  contactType_not_gte?: ContactType | null;
  contactType_lt?: ContactType | null;
  contactType_not_lt?: ContactType | null;
  contactType_lte?: ContactType | null;
  contactType_not_lte?: ContactType | null;
  requested?: any | null;
  requested_not?: any | null;
  requested_in?: any[] | null;
  requested_not_in?: any[] | null;
  requested_gt?: any | null;
  requested_not_gt?: any | null;
  requested_gte?: any | null;
  requested_not_gte?: any | null;
  requested_lt?: any | null;
  requested_not_lt?: any | null;
  requested_lte?: any | null;
  requested_not_lte?: any | null;
  requestStatus?: CustomOfferRequestStatus | null;
  requestStatus_not?: CustomOfferRequestStatus | null;
  requestStatus_in?: CustomOfferRequestStatus[] | null;
  requestStatus_not_in?: CustomOfferRequestStatus[] | null;
  requestStatus_gt?: CustomOfferRequestStatus | null;
  requestStatus_not_gt?: CustomOfferRequestStatus | null;
  requestStatus_gte?: CustomOfferRequestStatus | null;
  requestStatus_not_gte?: CustomOfferRequestStatus | null;
  requestStatus_lt?: CustomOfferRequestStatus | null;
  requestStatus_not_lt?: CustomOfferRequestStatus | null;
  requestStatus_lte?: CustomOfferRequestStatus | null;
  requestStatus_not_lte?: CustomOfferRequestStatus | null;
  phone?: string | null;
  phone_not?: string | null;
  phone_contains?: string | null;
  phone_not_contains?: string | null;
  phone_starts_with?: string | null;
  phone_not_starts_with?: string | null;
  phone_ends_with?: string | null;
  phone_not_ends_with?: string | null;
  phone_in?: (string | null)[] | null;
  phone_not_in?: (string | null)[] | null;
  appointmentDate?: any | null;
  appointmentDate_not?: any | null;
  appointmentDate_in?: (any | null)[] | null;
  appointmentDate_not_in?: (any | null)[] | null;
  appointmentDate_gt?: any | null;
  appointmentDate_not_gt?: any | null;
  appointmentDate_gte?: any | null;
  appointmentDate_not_gte?: any | null;
  appointmentDate_lt?: any | null;
  appointmentDate_not_lt?: any | null;
  appointmentDate_lte?: any | null;
  appointmentDate_not_lte?: any | null;
  assignedOffers_some?: CustomOfferFilter | null;
  assignedOffers_none?: CustomOfferFilter | null;
  assignedOffers_all?: CustomOfferFilter | null;
  assignedOffers_any?: boolean | null;
  assignedRooms_some?: RoomFilter | null;
  assignedRooms_none?: RoomFilter | null;
  assignedRooms_all?: RoomFilter | null;
  assignedRooms_any?: boolean | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * Represents a request for a custom offer in a specific RealEstate.
 */
export interface RequestForCustomOfferSort {
  realEstate?: RealEstateSort | null;
  realEstateId?: SortOperationKind | null;
  appointmentType?: SortOperationKind | null;
  location?: SortOperationKind | null;
  contactType?: SortOperationKind | null;
  requested?: SortOperationKind | null;
  requestStatus?: SortOperationKind | null;
  phone?: SortOperationKind | null;
  appointmentDate?: SortOperationKind | null;
  id?: SortOperationKind | null;
}

/**
 * Represents a request for a quotation on a selected DesignPackage (for a specific room) in a specific RealEstate.
 */
export interface RequestForQuoteFilter {
  AND?: RequestForQuoteFilter[] | null;
  OR?: RequestForQuoteFilter[] | null;
  designPackageHasBath?: boolean | null;
  designPackageHasBath_not?: boolean | null;
  designPackageIsFixedPrice?: boolean | null;
  designPackageIsFixedPrice_not?: boolean | null;
  designPackageIsXL?: boolean | null;
  designPackageIsXL_not?: boolean | null;
  designPackageName?: string | null;
  designPackageName_not?: string | null;
  designPackageName_contains?: string | null;
  designPackageName_not_contains?: string | null;
  designPackageName_starts_with?: string | null;
  designPackageName_not_starts_with?: string | null;
  designPackageName_ends_with?: string | null;
  designPackageName_not_ends_with?: string | null;
  designPackageName_in?: (string | null)[] | null;
  designPackageName_not_in?: (string | null)[] | null;
  designPackagePrice?: MoneyFilter | null;
  designPackageStyleName?: string | null;
  designPackageStyleName_not?: string | null;
  designPackageStyleName_contains?: string | null;
  designPackageStyleName_not_contains?: string | null;
  designPackageStyleName_starts_with?: string | null;
  designPackageStyleName_not_starts_with?: string | null;
  designPackageStyleName_ends_with?: string | null;
  designPackageStyleName_not_ends_with?: string | null;
  designPackageStyleName_in?: (string | null)[] | null;
  designPackageStyleName_not_in?: (string | null)[] | null;
  realEstate?: RealEstateFilter | null;
  realEstateId?: any | null;
  realEstateId_not?: any | null;
  realEstateId_in?: any[] | null;
  realEstateId_not_in?: any[] | null;
  realEstateId_gt?: any | null;
  realEstateId_not_gt?: any | null;
  realEstateId_gte?: any | null;
  realEstateId_not_gte?: any | null;
  realEstateId_lt?: any | null;
  realEstateId_not_lt?: any | null;
  realEstateId_lte?: any | null;
  realEstateId_not_lte?: any | null;
  roomName?: string | null;
  roomName_not?: string | null;
  roomName_contains?: string | null;
  roomName_not_contains?: string | null;
  roomName_starts_with?: string | null;
  roomName_not_starts_with?: string | null;
  roomName_ends_with?: string | null;
  roomName_not_ends_with?: string | null;
  roomName_in?: (string | null)[] | null;
  roomName_not_in?: (string | null)[] | null;
  created?: any | null;
  created_not?: any | null;
  created_in?: any[] | null;
  created_not_in?: any[] | null;
  created_gt?: any | null;
  created_not_gt?: any | null;
  created_gte?: any | null;
  created_not_gte?: any | null;
  created_lt?: any | null;
  created_not_lt?: any | null;
  created_lte?: any | null;
  created_not_lte?: any | null;
  selectionType?: SelectionType | null;
  selectionType_not?: SelectionType | null;
  selectionType_in?: SelectionType[] | null;
  selectionType_not_in?: SelectionType[] | null;
  selectionType_gt?: SelectionType | null;
  selectionType_not_gt?: SelectionType | null;
  selectionType_gte?: SelectionType | null;
  selectionType_not_gte?: SelectionType | null;
  selectionType_lt?: SelectionType | null;
  selectionType_not_lt?: SelectionType | null;
  selectionType_lte?: SelectionType | null;
  selectionType_not_lte?: SelectionType | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * Room present in one or more RealEstate in a Project.
 */
export interface RoomFilter {
  AND?: RoomFilter[] | null;
  OR?: RoomFilter[] | null;
  designPackages_some?: DesignPackageFilter | null;
  designPackages_none?: DesignPackageFilter | null;
  designPackages_all?: DesignPackageFilter | null;
  designPackages_any?: boolean | null;
  name?: string | null;
  name_not?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  position?: number | null;
  position_not?: number | null;
  position_in?: number[] | null;
  position_not_in?: number[] | null;
  position_gt?: number | null;
  position_not_gt?: number | null;
  position_gte?: number | null;
  position_not_gte?: number | null;
  position_lt?: number | null;
  position_not_lt?: number | null;
  position_lte?: number | null;
  position_not_lte?: number | null;
  layoutRooms_some?: LayoutRoomFilter | null;
  layoutRooms_none?: LayoutRoomFilter | null;
  layoutRooms_all?: LayoutRoomFilter | null;
  layoutRooms_any?: boolean | null;
  projectId?: any | null;
  projectId_not?: any | null;
  projectId_in?: any[] | null;
  projectId_not_in?: any[] | null;
  projectId_gt?: any | null;
  projectId_not_gt?: any | null;
  projectId_gte?: any | null;
  projectId_not_gte?: any | null;
  projectId_lt?: any | null;
  projectId_not_lt?: any | null;
  projectId_lte?: any | null;
  projectId_not_lte?: any | null;
  project?: ProjectFilter | null;
  customOfferRequests_some?: RequestForCustomOfferFilter | null;
  customOfferRequests_none?: RequestForCustomOfferFilter | null;
  customOfferRequests_all?: RequestForCustomOfferFilter | null;
  customOfferRequests_any?: boolean | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * Room present in one or more RealEstate in a Project.
 */
export interface RoomSort {
  name?: SortOperationKind | null;
  position?: SortOperationKind | null;
  projectId?: SortOperationKind | null;
  project?: ProjectSort | null;
  id?: SortOperationKind | null;
}

/**
 * Style represents a single, unified set of design elements for decorating rooms.
 */
export interface StyleFilter {
  AND?: StyleFilter[] | null;
  OR?: StyleFilter[] | null;
  name?: string | null;
  name_not?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  usedInProjects_some?: ProjectStyleFilter | null;
  usedInProjects_none?: ProjectStyleFilter | null;
  usedInProjects_all?: ProjectStyleFilter | null;
  usedInProjects_any?: boolean | null;
  usedInDesignPackages_some?: DesignPackageFilter | null;
  usedInDesignPackages_none?: DesignPackageFilter | null;
  usedInDesignPackages_all?: DesignPackageFilter | null;
  usedInDesignPackages_any?: boolean | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * Style represents a single, unified set of design elements for decorating rooms.
 */
export interface StyleSort {
  name?: SortOperationKind | null;
  id?: SortOperationKind | null;
}

export interface TupleOfCostTypeAndStringAndBooleanInput {
  item1: CostType;
  item2: string;
  item3: boolean;
}

export interface TupleOfCostTypeAndStringInput {
  item1: CostType;
  item2: string;
}

export interface TupleOfGuidAndStringInput {
  item1: any;
  item2: string;
}

/**
 * Represents a single User that is a future owner of the RealEstate.
 */
export interface UserFilter {
  AND?: UserFilter[] | null;
  OR?: UserFilter[] | null;
  realEstateContacts_some?: RealEstateContactFilter | null;
  realEstateContacts_none?: RealEstateContactFilter | null;
  realEstateContacts_all?: RealEstateContactFilter | null;
  realEstateContacts_any?: boolean | null;
  email?: string | null;
  email_not?: string | null;
  email_contains?: string | null;
  email_not_contains?: string | null;
  email_starts_with?: string | null;
  email_not_starts_with?: string | null;
  email_ends_with?: string | null;
  email_not_ends_with?: string | null;
  email_in?: string[] | null;
  email_not_in?: string[] | null;
  lastLogin?: any | null;
  lastLogin_not?: any | null;
  lastLogin_in?: (any | null)[] | null;
  lastLogin_not_in?: (any | null)[] | null;
  lastLogin_gt?: any | null;
  lastLogin_not_gt?: any | null;
  lastLogin_gte?: any | null;
  lastLogin_not_gte?: any | null;
  lastLogin_lt?: any | null;
  lastLogin_not_lt?: any | null;
  lastLogin_lte?: any | null;
  lastLogin_not_lte?: any | null;
  id?: any | null;
  id_not?: any | null;
  id_in?: any[] | null;
  id_not_in?: any[] | null;
  id_gt?: any | null;
  id_not_gt?: any | null;
  id_gte?: any | null;
  id_not_gte?: any | null;
  id_lt?: any | null;
  id_not_lt?: any | null;
  id_lte?: any | null;
  id_not_lte?: any | null;
}

/**
 * Represents a single User that is a future owner of the RealEstate.
 */
export interface UserSort {
  email?: SortOperationKind | null;
  lastLogin?: SortOperationKind | null;
  id?: SortOperationKind | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
