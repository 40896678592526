/* eslint-disable */
import type * as Types from '../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateActivityTemplateNameMutationVariables = Types.Exact<{
  activityTemplateId: Types.Scalars['UUID'];
  name: Types.Scalars['String'];
}>;


export type UpdateActivityTemplateNameMutation = { updateActivityTemplateName: any };


export const UpdateActivityTemplateNameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateActivityTemplateName"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"activityTemplateId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateActivityTemplateName"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"activityTemplateId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"activityTemplateId"}}},{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}}]}]}}]} as unknown as DocumentNode<UpdateActivityTemplateNameMutation, UpdateActivityTemplateNameMutationVariables>;