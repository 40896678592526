/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SendDossierMutationVariables = Types.Exact<{
  realEstateId: Types.Scalars['UUID'];
  fileNames: Array<Types.TupleOfStringAndUploadFileTypeInput> | Types.TupleOfStringAndUploadFileTypeInput;
  emailRecipients: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type SendDossierMutation = { sendRealEstateDossier: boolean };


export const SendDossierDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SendDossier"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"realEstateId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"fileNames"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TupleOfStringAndUploadFileTypeInput"}}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"emailRecipients"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendRealEstateDossier"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"realEstateId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"realEstateId"}}},{"kind":"Argument","name":{"kind":"Name","value":"files"},"value":{"kind":"Variable","name":{"kind":"Name","value":"fileNames"}}},{"kind":"Argument","name":{"kind":"Name","value":"emailRecipients"},"value":{"kind":"Variable","name":{"kind":"Name","value":"emailRecipients"}}}]}]}}]} as unknown as DocumentNode<SendDossierMutation, SendDossierMutationVariables>;