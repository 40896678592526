
import {
  AdUserDto,
  CustomOfferAppointmentType,
  CustomOfferRequestStatus,
  CustomOfferStatus,
  MessageDto
} from '@/__generated__/globalTypes'
import RequestVersionsList from '@/components/customOfferRequest/RequestVersionsList.vue'
import {
  CancelCustomOfferRequestDocument,
  CancelCustomOfferRequestMutation,
  CancelCustomOfferRequestMutationVariables
} from '@/components/customOfferRequest/gql/__generated__/cancelCustomOffer.mutation'
import DetailsList from '@/components/detailsList/DetailsList.vue'
import QUERIES from '@/queries/queries'
import { toLocaleDateTimeString } from '@/utils/dateUtils'
import { Component, Vue } from 'vue-property-decorator'
import {
  CustomOfferRequestDocument,
  CustomOfferRequestQuery,
  CustomOfferRequestQueryVariables
} from './gql/__generated__/customOfferRequestDetails.query'
import {
  ReopenCustomOfferRequestDocument,
  ReopenCustomOfferRequestMutation,
  ReopenCustomOfferRequestMutationVariables
} from './gql/__generated__/reopenCustomOfferRequest.mutation'
import {
  UpdateCustomOfferRequestDocument,
  UpdateCustomOfferRequestMutation,
  UpdateCustomOfferRequestMutationVariables
} from './gql/__generated__/updateCustomOfferRequest.mutation'
import RequestForChangeDialog from './RequestForChangeDialog.vue'
import {
  CancelCustomOfferChangeRequestMutation,
  CancelCustomOfferChangeRequestMutationVariables,
  CancelCustomOfferChangeRequestDocument
} from './gql/__generated__/cancelCustomOfferChangeRequest.mutation'

import {
  DeleteCustomOfferRequestAppointmentMutation,
  DeleteCustomOfferRequestAppointmentMutationVariables,
  DeleteCustomOfferRequestAppointmentDocument
} from './gql/__generated__/deleteCustomOfferRequestAppointment.mutation'
import {
  CompleteCustomOfferRequestDocument,
  CompleteCustomOfferRequestMutation,
  CompleteCustomOfferRequestMutationVariables
} from './gql/__generated__/completeCustomOfferRequest.mutation'
import { AppointmentItem } from './appointmentType'
import AppointmentModal from '@/views/customOfferDetailsView/AppointmentModal.vue'

@Component({
  components: {
    RequestVersionsList,
    DetailsList,
    RequestForChangeDialog,
    AppointmentModal
  }
})
export default class CustomOfferDetailsView extends Vue {
  MESSAGE_MAX_LENGTH = 500
  customOfferQuery: CustomOfferRequestQuery | null = null
  selectedGlobalType: CustomOfferAppointmentType | null | undefined = null
  selectedType: CustomOfferAppointmentType | null | undefined = null
  status: CustomOfferRequestStatus | null | undefined = null
  appointmentDateTime: Date | null = null
  appointmentDate = ''
  appointmentTime = ''
  requestNotes: MessageDto[] | null = null
  customOfferRequestSpecialists: AdUserDto[] | null = []
  assignedSpecialist: string | null = null
  hasFlag: boolean | undefined = false
  showRequestForChangePopUp = false
  showAddAppointmentModal = false
  showEditAppointmentModal = false

  isLoading = false

  appointmentId: string | null = null

  appointment: AppointmentItem | null = null

  mAppointments: AppointmentItem[] = []

  created() {
    this.$apollo.addSmartQuery<CustomOfferRequestQuery, CustomOfferRequestQueryVariables>(QUERIES.CustomOfferRequest, {
      query: CustomOfferRequestDocument,
      variables: () => ({ id: this.$route?.params?.id }),
      fetchPolicy: 'network-only',
      update: (data) => data,
      result: (result) => {
        this.customOfferQuery = result.data
        this.status = this.customOfferData?.requestStatus?.status
        if (this.customOfferData?.appointmentDates?.length) {
          this.appointmentDateTime = new Date(this.customOfferData?.appointmentDates[0]?.appointmentDate)
          this.mAppointments = this.customOfferData?.appointmentDates
            .sort((a, b) => (new Date(a?.appointmentDate) > new Date(b?.appointmentDate) ? 1 : -1))
            .map((x) => {
              const dateUTC = new Date(x?.appointmentDate)
              return {
                dateUTC,
                date: `${
                  (dateUTC.getFullYear() + '-' + (dateUTC.getMonth() + 1)).toString() + '-' + dateUTC.getDate()
                }`,
                time: `${
                  dateUTC.getHours().toString().padStart(2, '0') +
                  ':' +
                  dateUTC.getMinutes().toString().padStart(2, '0')
                }`,
                id: x?.id as string,
                type: x?.type as string
              }
            })
        } else {
          this.mAppointments = []
        }
        this.selectedGlobalType = this.customOfferData?.appointmentType ?? null
        this.customOfferRequestSpecialists = (result.data.rensaUsers as AdUserDto[]) ?? null
        this.assignedSpecialist = this.customOfferData?.assignedSpecialistId ?? null
        this.requestNotes = (this.customOfferData?.requestNotes as MessageDto[]) ?? null
        this.hasFlag = result?.data?.customOfferRequest?.sharedWaiting
      },
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4190',
          Message: error
        })
      }
    })
  }

  addAppointment(appointment: { type: string; date: string; time: string; id: string }) {
    const dateUTC = new Date(appointment.date)
    dateUTC.setHours(+appointment.time.substring(0, 2), +appointment.time.substring(4, 6))
    this.mAppointments.push({
      dateUTC,
      type: appointment.type,
      date: appointment.date,
      time: appointment.time,
      id: appointment.id
    })
  }

  deleteAppointment(appointment: AppointmentItem) {
    const date = new Date().toISOString().substring(0, 10)
    if (!this.isLoading) {
      if (appointment.date < date) {
        this.isLoading = true
        this.$apolloMutate<
          DeleteCustomOfferRequestAppointmentMutation,
          DeleteCustomOfferRequestAppointmentMutationVariables
        >({
          mutation: DeleteCustomOfferRequestAppointmentDocument,
          variables: {
            customOfferRequestId: this.customOfferRequestId,
            customOfferRequestAppointmentId: appointment.id
          },
          refetchQueries: [QUERIES.CustomOfferRequest],
          error: 'E1113'
        }).finally(() => (this.isLoading = false))
      } else
        return this.$showConfirmationDialog({
          Code: 'C4032',
          Callback: () => {
            this.$hideConfirmationDialog()
            this.$apolloMutate<
              DeleteCustomOfferRequestAppointmentMutation,
              DeleteCustomOfferRequestAppointmentMutationVariables
            >({
              mutation: DeleteCustomOfferRequestAppointmentDocument,
              variables: {
                customOfferRequestId: this.customOfferRequestId,
                customOfferRequestAppointmentId: appointment.id
              },
              refetchQueries: [QUERIES.CustomOfferRequest],
              error: 'E1113'
            }).finally(() => (this.isLoading = false))
          }
        })
    }
  }

  editAppointment(appointment: AppointmentItem) {
    this.showAddAppointmentModal = true
    this.appointmentId = appointment.id
    this.appointment = appointment
  }

  openAddAppointment() {
    this.showAddAppointmentModal = true
    this.appointment = null
  }

  closeAppointmentModal() {
    this.showAddAppointmentModal = false
    this.isLoading = false
  }

  get language() {
    return this.$i18n.locale
  }

  get customOfferData() {
    return this.customOfferQuery?.customOfferRequest ?? null
  }

  get divideByRooms() {
    return this.customOfferData?.realEstate?.project?.divideCustomOfferByRooms ?? false
  }

  // Create a custom offer Request global Appointment type List based on the translations
  get customOfferRequestGlobalAppointmentTypesTranslated() {
    return Object.values(CustomOfferAppointmentType).map((type) => ({
      text: this.$t(`global.customOfferGlobalAppointmentTypes.${type}`).toString(),
      value: type
    }))
  }

  // Create a custom offer Request Appointment types List based on the translations
  customOfferRequestAppointmentTypesTranslated(Type: string) {
    return this.$t(`global.customOfferAppointmentTypes.${Type}`).toString()
  }

  get appointmentPrice() {
    if (this.selectedGlobalType === CustomOfferAppointmentType.Contact) {
      return 150
    } else {
      return 350
    }
  }

  get isCancelled() {
    return this.customOfferData?.requestStatus?.status === CustomOfferRequestStatus.Cancelled
  }

  get isCompleted() {
    return this.customOfferData?.requestStatus?.status === CustomOfferRequestStatus.Completed
  }

  get isSharedWithCustomer() {
    return this.customOfferData?.requestStatus?.status === CustomOfferRequestStatus.SharedWithCustomer
  }

  get isRequestForChange() {
    return this.customOfferData?.requestStatus?.status === CustomOfferRequestStatus.RequestForChange
  }

  get canBeForceCompleted() {
    const allowCompleteStatuses = [
      CustomOfferRequestStatus.Appointment,
      CustomOfferRequestStatus.CreateOffer,
      CustomOfferRequestStatus.RequestForChange
    ]
    if (!this.customOfferData?.requestStatus?.status) return false

    return allowCompleteStatuses.includes(this.customOfferData?.requestStatus?.status)
  }

  get isCancelChangeRequestDisabled() {
    return !this.customOfferData?.assignedOffers?.some((x) => x?.status === CustomOfferStatus.Active)
  }

  get isCancelButtonDisabled() {
    return this.isCancelled || this.isCompleted
  }

  get customOfferRequestId() {
    return this.customOfferData?.id
  }

  get buildNumber() {
    return this.customOfferData?.realEstate.buildNumber
  }

  get requestStatus() {
    const status = this.customOfferData?.requestStatus?.status
    if (!status) return ''
    return this.$t(`global.customOfferStatuses.${status}`)?.toString() ?? ''
  }

  get closingDate() {
    return toLocaleDateTimeString(this.customOfferData?.realEstate.closeDateUtc, this.language)
  }

  get contactDetails() {
    const contact = this.customOfferData?.realEstate.contact
    if (!contact) return null
    return {
      name: contact.legalName,
      email: contact.email.address,
      phone: this.customOfferData?.phone
    }
  }

  get requestDetails() {
    const details = {
      requestedDate: toLocaleDateTimeString(this.customOfferData?.requested, this.language),
      rooms: this.assignedRooms?.flatMap((room) => room?.name).join(', ')
    }
    if (
      this.customOfferData?.appointmentType === CustomOfferAppointmentType.Contact &&
      this.customOfferData?.contactType
    )
      Object.assign(details, {
        contactPreference: this.$t(`global.customOfferContactTypes.${this.customOfferData?.contactType}`)
      })
    else if (
      this.customOfferData?.appointmentType === CustomOfferAppointmentType.Contact &&
      this.customOfferData?.contactType === null
    )
      Object.assign(details, {
        contactPreference: this.$t(`global.customOfferContactTypes.NO_SELECTION`)
      })
    return details
  }

  get assignedRooms() {
    return this.customOfferData?.assignedRooms?.sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0))
  }

  get assignedOffers() {
    return this.customOfferData?.assignedOffers
  }

  get realEstateRooms() {
    return this.customOfferData?.realEstate?.layout?.layoutRooms
      ?.map((x) => x.room)
      .sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0))
  }

  get isOrderSubmitted() {
    return this.customOfferData?.realEstate.areQuotesRequested ?? false
  }

  onDateChange() {
    if (!this.appointmentDate) return

    const date = new Date(this.appointmentDate)

    // if there's no date set yet, just set the whole selected date, otherwise we want the time to remain like it was, but just set the day, month and year.
    if (!this.appointmentDateTime) this.appointmentDateTime = date
    else {
      this.appointmentDateTime = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        this.appointmentDateTime.getHours(),
        this.appointmentDateTime.getMinutes()
      )
    }
  }

  onTimeChange() {
    if (!this.appointmentTime) return

    const hours = parseInt(this.appointmentTime.substring(0, 2))
    const minutes = parseInt(this.appointmentTime.substring(3))

    // If no appointment date time was set before, create a new date (today) and set the selected time
    if (!this.appointmentDateTime) this.appointmentDateTime = new Date()

    this.appointmentDateTime = new Date(
      this.appointmentDateTime.getFullYear(),
      this.appointmentDateTime.getMonth(),
      this.appointmentDateTime.getDate(),
      hours,
      minutes
    )
  }

  onPropertyChange(messageCode: string) {
    //if current type and status is the same as the one we got from the query, no need to open popup
    if (
      this.selectedGlobalType === this.customOfferData?.appointmentType &&
      this.status === this.customOfferData?.requestStatus &&
      this.assignedSpecialist === this.customOfferData?.assignedSpecialistId
    )
      return

    this.$store.dispatch('showConfirmationDialog', {
      Callback: () => this.confirmChange(),
      CancelCallback: () => this.handleCancelDialog(),
      Code: messageCode
    })
  }

  confirmChange() {
    if (!this.selectedGlobalType || !this.status) return
    this.updateCustomOfferRequest()
    this.$store.dispatch('hideConfirmationDialog')
  }

  handleCancelDialog() {
    if (this.status !== this.customOfferData?.requestStatus) {
      this.status = this.customOfferData?.requestStatus?.status
    }
    if (this.selectedGlobalType !== this.customOfferData?.appointmentType)
      this.selectedGlobalType = this.customOfferData?.appointmentType
    if (this.assignedSpecialist !== this.customOfferData?.assignedSpecialistId)
      this.assignedSpecialist = this.customOfferData?.assignedSpecialistId ?? null
  }

  cancelOffer() {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: () => this.confirmCancel(),
      Code: 'C4010'
    })
  }

  cancelChangeRequest() {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: () => this.confirmCancelChangeRequest(),
      Code: 'C4030'
    })
  }

  completeOffer() {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: () => this.confirmCompleteOffer(),
      Code: 'C4033'
    })
  }

  async confirmCancelChangeRequest() {
    await this.$apolloMutate<CancelCustomOfferChangeRequestMutation, CancelCustomOfferChangeRequestMutationVariables>({
      mutation: CancelCustomOfferChangeRequestDocument,
      variables: {
        customOfferRequestId: this.customOfferRequestId
      },
      error: 'E1111'
    }).then(() => {
      this.$store.dispatch('hideConfirmationDialog')
      this.$apollo.queries.CustomOfferRequest.refetch()
    })
  }

  async confirmCancel() {
    await this.$apolloMutate<CancelCustomOfferRequestMutation, CancelCustomOfferRequestMutationVariables>({
      mutation: CancelCustomOfferRequestDocument,
      variables: {
        customOfferRequestId: this.customOfferRequestId
      },
      error: 'E4193'
    }).then(() => {
      this.$store.dispatch('hideConfirmationDialog')
      this.$router.push({ name: 'custom-offers' })
      this.$apollo.queries.CustomOfferRequest.refetch()
    })
  }

  confirmCompleteOffer() {
    this.$apolloMutate<CompleteCustomOfferRequestMutation, CompleteCustomOfferRequestMutationVariables>({
      mutation: CompleteCustomOfferRequestDocument,
      variables: {
        customOfferRequestId: this.customOfferRequestId
      },
      refetchQueries: [
        {
          query: CustomOfferRequestDocument,
          variables: { id: this.$route.params?.id }
        }
      ],
      error: 'E4236'
    })
    this.$store.dispatch('hideConfirmationDialog')
  }

  reopenOffer() {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: () => {
        this.reopenCustomOfferRequest()
        this.$store.dispatch('hideConfirmationDialog')
      },
      Code: 'C4012'
    })
  }

  updateCustomOfferRequest() {
    return this.$apolloMutate<UpdateCustomOfferRequestMutation, UpdateCustomOfferRequestMutationVariables>({
      mutation: UpdateCustomOfferRequestDocument,
      variables: {
        customOfferRequestId: this.customOfferData?.id,
        appointmentType: this.selectedGlobalType as CustomOfferAppointmentType,
        assignedSpecialistId: this.assignedSpecialist
      },
      refetchQueries: [
        {
          query: CustomOfferRequestDocument,
          variables: { id: this.$route.params?.id }
        }
      ]
    })
  }

  reopenCustomOfferRequest() {
    return this.$apolloMutate<ReopenCustomOfferRequestMutation, ReopenCustomOfferRequestMutationVariables>({
      mutation: ReopenCustomOfferRequestDocument,
      variables: {
        customOfferRequestId: this.customOfferData?.id
      },
      refetchQueries: [
        {
          query: CustomOfferRequestDocument,
          variables: { id: this.$route.params?.id }
        }
      ]
    })
  }

  backToOverview() {
    this.$router.push({
      name: 'custom-offers'
    })
  }

  getStatusColor(status: string | null) {
    switch (status) {
      case CustomOfferRequestStatus.CreateOffer:
      case CustomOfferRequestStatus.RequestForChange:
        return 'yellow'
      case CustomOfferRequestStatus.Appointment:
      case CustomOfferRequestStatus.SharedWithCustomer:
      case CustomOfferRequestStatus.Completed:
        return 'green'
      case CustomOfferRequestStatus.Cancelled:
        return 'red'
      case CustomOfferRequestStatus.New:
        return 'grey'
      default:
        return 'grey'
    }
  }

  getMessageDateAndSender(msg: MessageDto) {
    return `[${new Date(Date.parse(msg.dateCreated)).toLocaleDateString(this.language, {
      dateStyle: 'full'
    })}] ${msg.sender}:`
  }
}
