
import DebounceMixin, { debounceDelay } from '@/mixins/debounceMixin'
import QUERIES from '@/queries/queries'
import { toLocaleDateTimeString } from '@/utils/dateUtils'
import { buildRequestVariables, defaultFooterProps, TableFilter } from '@/utils/tableUtils'
import { ProjectStatus } from '@/__generated__/globalTypes'
import { RefetchQueryDescription } from 'apollo-client/core/watchQueryOptions'
import { Component, Vue } from 'vue-property-decorator'
import { DataOptions, DataTableHeader } from 'vuetify'
import {
  DeleteProjectDocument,
  DeleteProjectMutation,
  DeleteProjectMutationVariables
} from './gql/__generated__/deleteProject.mutation'
import {
  ProjectListItemFragment,
  ProjectListQueryDocument,
  ProjectListQueryQuery
} from './gql/__generated__/projectList.query'
import { SyncWithEstimateDocument, SyncWithEstimateMutation } from './gql/__generated__/syncWithEstimate.mutation'
import { ProjectItem } from './projectListViewTypes'

@Component({
  mixins: [DebounceMixin]
})
export default class ProjectListView extends Vue {
  projects: (ProjectListItemFragment | null)[] = []
  search = ''
  totalCount = 0
  initialPageLoaded = false
  tableOptions: DataOptions = {} as DataOptions
  userFilters: TableFilter[] = [
    {
      keyPath: 'name',
      value: '',
      filterType: 'contains'
    },
    {
      keyPath: 'number',
      value: '',
      filterType: 'contains'
    },
    {
      keyPath: 'realEstates.some.buildNumber',
      value: '',
      filterType: 'contains'
    },
    {
      keyPath: 'projectDeveloperName',
      value: '',
      filterType: 'contains'
    }
  ]
  statusFilters: TableFilter[] = [
    {
      keyPath: 'status',
      value: ProjectStatus.Closed,
      filterType: 'neq'
    }
  ]

  hideClosedProjects = true
  projectStatus = ProjectStatus
  defaultFooterProps = defaultFooterProps
  changeSearch: ((value: boolean) => void) | null = null

  mounted() {
    this.changeSearch = this.debounce(() => {
      // Resets to the first page
      this.tableOptions.page = 1

      // Set the search string in all the filters
      Object.values(this.userFilters).forEach((filter) => {
        filter.value = this.search
      })
    }, debounceDelay)
  }

  created() {
    // We have an exception on the first load of the project table that we need to sort on two fields, so to set that manually
    this.tableOptions.sortBy = ['status', 'uninvitedCount', 'publishedOn']
    this.tableOptions.sortDesc = [false, true, true]

    // Do request
    this.$apollo.addSmartQuery<ProjectListQueryQuery>(QUERIES.ProjectListQuery, {
      query: ProjectListQueryDocument,
      variables: () => buildRequestVariables(this.tableOptions, [this.userFilters, this.statusFilters]),
      result: (result) => {
        this.projects = result.data?.projects?.items ?? []
        this.totalCount = result.data?.projects?.totalCount ?? 0
        this.initialPageLoaded = true
      },
      update: (data) => data,
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4101',
          Message: error.message
        })
      }
    })
  }

  isReadonly() {
    return !this.$store.getters.isAdmin
  }

  get headers() {
    return [
      { text: this.$t('project.list.table.name'), align: 'start', value: 'name', width: '25%' },
      { text: this.$t('project.list.table.projectType'), value: 'projectType', width: '7%' },
      { text: this.$t('project.list.table.projectDeveloper'), value: 'projectDeveloperName', width: '20%' },
      { text: this.$t('project.list.table.realEstateCount'), value: 'realEstateCount', width: '5%', sortable: false },
      {
        text: this.$t('project.list.table.realEstateUninvitedCount'),
        value: 'uninvitedCount',
        width: '7%',
        sortable: true
      },
      {
        text: this.$t('project.list.table.status'),
        value: 'status',
        width: '5%'
      },
      {
        text: this.$t('project.list.table.requestForQuoteCount'),
        value: 'requestForQuoteCount',
        width: '5%',
        sortable: false
      },
      { text: this.$t('project.list.table.lastImportedOn'), value: 'lastImportedOn', width: '13%' },
      { text: '', value: 'actions', sortable: false, align: 'end', width: '0%' }
    ] as DataTableHeader[]
  }

  get projectItems(): ProjectItem[] {
    return this.projects?.map((x) => {
      if (!x) return
      return {
        id: x.id,
        lastImportedOn: toLocaleDateTimeString(x.lastImportedOn, this.$i18n.locale) ?? '...',
        name: x.name,
        projectType: x.projectType,
        projectDeveloperName: x.projectDeveloperName,
        realEstateCount: x.realEstates.length,
        uninvitedCount: x.uninvitedCount,
        requestForQuoteCount: this.getRequestForQuoteCount(x),
        status: this.getStatusText(x.status),
        statusColor: this.getStatusColor(x.status)
      }
    }) as ProjectItem[]
  }

  getQueriesToRefetch(): RefetchQueryDescription {
    return [
      {
        query: ProjectListQueryDocument,
        variables: buildRequestVariables(this.tableOptions, [this.userFilters, this.statusFilters])
      }
    ]
  }

  clickSyncWithEstimate() {
    this.$apolloMutate<SyncWithEstimateMutation>({
      mutation: SyncWithEstimateDocument,
      refetchQueries: this.getQueriesToRefetch(),
      update: () => {
        this.$store.dispatch('showNotificationDialog', {
          Code: 'N4006'
        })
      },
      error: 'E4128'
    })
  }

  getStatusColor(status: ProjectStatus) {
    switch (status) {
      case ProjectStatus.Preview:
        return 'grey'
      case ProjectStatus.Open:
        return 'green'
      default:
        return 'red'
    }
  }

  getStatusText(status: ProjectStatus): string {
    switch (status) {
      case ProjectStatus.Preview:
        return this.$t('project.status.preview').toString()
      case ProjectStatus.Open:
        return this.$t('project.status.open').toString()
      default:
        return this.$t('project.status.closed').toString()
    }
  }

  changeProjectStatusFilter() {
    const filter = this.statusFilters.find((x) => x.keyPath === 'status' && x.filterType == 'neq')
    if (filter) filter.value = this.hideClosedProjects ? ProjectStatus.Closed : ''
  }

  getRequestForQuoteCount(project: ProjectListItemFragment): number {
    let count = 0
    if (project.realEstates) {
      for (const realEstate of project.realEstates) {
        if (realEstate.requestForQuotesV2?.length > 0) {
          count++
        }
      }
    }
    return count
  }

  toProjectDetails(item: ProjectItem) {
    this.$router.push({ name: 'project-details', params: { projectId: item.id } })
  }

  clickConfirm(item: ProjectItem) {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: () => this.confirmRemove(item),
      Code: 'C4008',
      Message: item.name
    })
  }

  confirmRemove(item: ProjectItem) {
    this.$apolloMutate<DeleteProjectMutation, DeleteProjectMutationVariables>({
      mutation: DeleteProjectDocument,
      variables: {
        projectId: item.id
      },
      refetchQueries: this.getQueriesToRefetch(),
      error: 'E4132'
    })
    this.$store.dispatch('hideConfirmationDialog')
  }
}
