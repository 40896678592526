
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ValidationRule } from 'vuelidate/lib/validators'
import { KeyValuePairOfStringAndString } from '@/__generated__/globalTypes'

@Component
export default class MultiEnumField extends Vue {
  @Prop() modelValue!: string | null
  @Prop({ default: [] }) rules?: ValidationRule[]
  @Prop({ default: '' }) label?: string
  @Prop({ required: true }) disabled!: boolean
  @Prop({ required: true }) possibleValues!: KeyValuePairOfStringAndString[]

  get selectedKeys() {
    return this.modelValue?.split('|') ?? []
  }

  get select() {
    return this.possibleValues.filter((x) => this.selectedKeys.includes(x.key))
  }

  onValueChange(inputValue?: string[] | null) {
    if (inputValue === undefined) {
      this.$emit('update:modelValue', null)
      return
    }

    this.$emit('update:modelValue', inputValue?.join('|'))
  }
}
