
import {
  ConfirmedStatusType,
  FileExtension,
  RealEstateExternalActionStatus,
  SelectionType,
  UploadFileType
} from '@/__generated__/globalTypes'
import DetailsList from '@/components/detailsList/DetailsList.vue'
import QUERIES from '@/queries/queries'
import { RouteNames } from '@/router/routeNames'
import { toLocaleDateString, toLocaleDateTimeString } from '@/utils/dateUtils'
import { Component, Vue } from 'vue-property-decorator'
import {
  ContactRequestFieldsFragment,
  ExternalActionFragment,
  RealEstateDetailsDocument,
  RealEstateDetailsQuery,
  RealEstateDetailsQueryVariables,
  RealEstateFragment
} from './gql/__generated__/realEstateDetails.query'

@Component({
  components: {
    DetailsList
  }
})
export default class RealEstateDetailsView extends Vue {
  realEstateData: RealEstateFragment | null = null
  notes: ContactRequestFieldsFragment[] | null = null

  RouteNames = RouteNames
  RealEstateExternalActionStatus = RealEstateExternalActionStatus
  FileExtension = FileExtension

  created() {
    this.$apollo.addSmartQuery<RealEstateDetailsQuery, RealEstateDetailsQueryVariables>(
      QUERIES.RealEstateDetailsQuery,
      {
        query: RealEstateDetailsDocument,
        variables: () => ({ id: this.$route.params?.id }),
        fetchPolicy: 'network-only',
        update: (data) => data,
        result: ({ data }) => {
          this.realEstateData = data?.realEstate ?? null
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.notes = this.realEstateData?.activeRequestForContacts?.filter((x) => !!x).map((x) => x!) ?? null
        },
        error: (error) => {
          this.$store.dispatch('showErrorDialog', {
            Code: 'E4212',
            Message: error
          })
        }
      }
    )
  }

  get language() {
    return this.$i18n.locale
  }

  get statusTranslated() {
    const status = this.realEstateData?.activeStatus?.status
    if (!status) {
      return ''
    }

    return this.$t(`global.realEstateStatuses.${status}`)
  }

  get closingDate() {
    const date = toLocaleDateTimeString(this.realEstateData?.closeDateUtc, this.language)
    if (date) return date.charAt(0).toUpperCase() + date.slice(1)
    return ''
  }

  get contact() {
    if (!this.realEstateData?.contact) return null
    return {
      email: this.realEstateData?.contact.email.address,
      legalName: this.realEstateData?.contact.legalName,
      phone: this.realEstateData?.contact.phone?.number,
      invitedDate: toLocaleDateString(this.realEstateData?.contact.invitationSent, this.language),
      lastLogin: toLocaleDateTimeString(this.realEstateData?.contact.lastLogin, this.language)
    }
  }

  get realEstateDetails() {
    if (!this.realEstateData) return null
    return {
      offerNumber: this.realEstateData?.number,
      layout: this.realEstateData?.layout?.name,
      rooms: this.rooms?.flatMap((room) => room?.name).join(', '),
      notificationsEnabled: this.realEstateData?.contact?.notificationsDisabled
        ? this.$t('global.no')
        : this.$t('global.yes')
    }
  }

  get notificationsSentDates() {
    return {
      lastNoLoginNotification: toLocaleDateTimeString(
        this.realEstateData?.contact?.lastNoLoginNotification?.sentDate,
        this.language
      ),
      lastNoQuoteNotification: toLocaleDateTimeString(
        this.realEstateData?.contact?.lastNoQuoteNotification?.sentDate,
        this.language
      )
    }
  }

  get rooms() {
    return this.realEstateData?.layout?.layoutRooms?.map((x) => x.room)
  }

  get offerRequestedDate() {
    return toLocaleDateTimeString(this.realEstateData?.realEstateOrder?.createdDate, this.language)
  }

  get dossierSentDate() {
    return toLocaleDateTimeString(this.realEstateData?.dossierSentDateUtc, this.language)
  }

  get orderRooms() {
    return this.realEstateData?.realEstateOrder?.rooms ?? []
  }

  get customOfferStatus() {
    if (!this.realEstateData?.customOfferRequest?.requestStatus) return ''
    return this.$t(`global.customOfferStatuses.${this.realEstateData.customOfferRequest.requestStatus.status}`)
  }

  get externalQuestions(): ExternalActionFragment[] {
    return (
      this.realEstateData?.externalActions?.map((externalAction) => ({
        ...externalAction,
        notes: externalAction.notes?.sort((a, b) => {
          if (a?.dateCreated < b?.dateCreated) return -1
          if (a?.dateCreated > b?.dateCreated) return 1
          return 0
        })
      })) ?? []
    )
  }

  get isGlobalCustomOffer(): boolean {
    return (
      !!this.offerRequestedDate &&
      (this.orderRooms.length === 0 || this.orderRooms.every((x) => x.selectionType === SelectionType.GlobalCustom))
    )
  }

  getQuestionNoteDateAndTarget(externalQuestion: ExternalActionFragment) {
    return `[${toLocaleDateTimeString(externalQuestion?.requestDateUtc, this.language)}] ${
      externalQuestion?.externalActionTarget
    }:`
  }

  getQuestionNoteDate(date: string) {
    return `[${toLocaleDateTimeString(date, this.language)}]`
  }

  downloadFile(event: PointerEvent, file: string | null, name: string, type: UploadFileType) {
    if (!file) return
    event.stopPropagation()
    const fileName = `${name}_${this.typeTranslation(type)}`
    return this.$fileApi.downloadFile(
      `/realEstate/${this.realEstateData?.id}/${encodeURIComponent(encodeURIComponent(file))}`,
      fileName
    )
  }

  getConfirmedStatus(statusType: ConfirmedStatusType, selectionType: SelectionType) {
    let status = this.$t(`projectRealEstates.confirmedStatus.${statusType.toLowerCase()}`).toString()

    if (selectionType === SelectionType.Custom) {
      status += ` (${this.$t(`global.selectionTypes.CUSTOM`)})`
    }

    return status
  }

  getMessageDateAndSender(msg: ContactRequestFieldsFragment) {
    return `[${toLocaleDateTimeString(msg.dateCreated, this.language)}] ${msg.sender}:`
  }

  getFileDate(date?: string) {
    if (!date) return ''
    return toLocaleDateTimeString(date, this.language, false)
  }

  typeTranslation(uploadFileType: UploadFileType) {
    return this.$t(`global.uploadFileTypes.${uploadFileType}`)
  }

  goToPage(type: UploadFileType) {
    switch (type) {
      case UploadFileType.TechnicalDrawing:
        window.open(
          `/project/${this.realEstateData?.project?.id}/real-estate-order/${this.realEstateData?.id}`,
          '_blank'
        )
        break
      case UploadFileType.CustomOffer:
        window.open(`/custom-offer-request/${this.realEstateData?.customOfferRequest?.id}`, '_blank')
        break
      case UploadFileType.Drawing3D:
      case UploadFileType.Manual:
      default:
        break
    }
  }
}
