
import { CustomOffer, CustomOfferStatus, Room } from '@/__generated__/globalTypes'
import { toLocaleDateString } from '@/utils/dateUtils'
import { CustomOfferRequestDocument } from '@/views/customOfferDetailsView/gql/__generated__/customOfferRequestDetails.query'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import OfferUpsertPopup from './OfferUpsertPopup.vue'
import { CustomOfferRequestStatus } from '@/__generated__/globalTypes'
import {
  DeleteCustomOfferDocument,
  DeleteCustomOfferMutation,
  DeleteCustomOfferMutationVariables
} from './gql/__generated__/deleteCustomOffer.mutation'
import { AppointmentItem } from '@/views/customOfferDetailsView/appointmentType'
import {
  UpdateCustomOfferShareMutation,
  UpdateCustomOfferShareMutationVariables,
  UpdateCustomOfferShareDocument
} from './gql/__generated__/updateCustomOfferShare.mutation'
import { customOfferItem } from './types'

@Component({
  components: {
    OfferUpsertPopup
  }
})
export default class RequestVersionsList extends Vue {
  @Prop() offers!: (CustomOffer | null)[]
  @Prop() realEstateRooms!: Room[]
  @Prop({ required: true }) offerRequestId!: string
  @Prop({ required: true }) disabled!: boolean
  @Prop({ required: true }) serviceCosts!: number
  @Prop({ default: false }) divideByRooms!: boolean
  @Prop() appointments!: AppointmentItem[]

  customOfferStatus = CustomOfferStatus
  offerToUpdate: CustomOffer | null = null
  showOfferPopup = false
  isLoading = false
  isDownloadingOfferFile = false
  isDownloadingDrawingFile = false
  CustomOfferRequestStatus = CustomOfferRequestStatus
  disableIsSharedToggle = false

  drawingFile: File | null = null

  get language() {
    return this.$i18n.locale
  }

  get offersItems() {
    return this.offers
      ?.map((offer) => {
        if (!offer) return

        let offerRooms = offer.assignedRooms?.filter((r) => !!r?.roomId).map((r) => r?.room)
        if (!offerRooms?.length) offerRooms = this.realEstateRooms

        return {
          id: offer.id,
          name: offer.name,
          link: offer.link,
          rooms: offerRooms.map((x) => x?.name ?? '').join(', '),
          creationDate: toLocaleDateString(offer.createdAtUtc, this.language),
          isShared: offer.isSharedWithCustomer,
          status: offer.status
        }
      })
      .reverse()
  }

  get headers(): DataTableHeader[] {
    return [
      {
        text: this.$t('customOfferDetails.offersList.columns.name').toString(),
        value: 'name',
        width: '30%',
        sortable: true
      },
      {
        text: this.$t('customOfferDetails.offersList.columns.rooms').toString(),
        value: 'rooms',
        sortable: true,
        width: '25%'
      },
      {
        text: this.$t('customOfferDetails.offersList.columns.created').toString(),
        value: 'creationDate',
        sortable: true,
        width: '10%'
      },
      {
        text: this.$t('customOfferDetails.offersList.columns.sharedWithCustomer').toString(),
        value: 'isShared',
        sortable: true,
        align: 'center',
        width: '15%'
      },
      {
        text: this.$t('customOfferDetails.offersList.columns.downloadFiles').toString(),
        value: 'downloadFiles',
        sortable: false,
        align: 'center',
        width: '15%'
      },
      { text: '', value: 'actions', sortable: false, align: 'end', width: '0%' }
    ]
  }

  openOfferPopup(item: CustomOffer | null) {
    this.showOfferPopup = true
    this.offerToUpdate = this.offers?.find((offer) => offer?.id === item?.id) ?? null
  }

  openOffer(item: CustomOffer) {
    this.isDownloadingOfferFile = true
    this.$fileApi
      .downloadFile(`/customOffer/${item.id}/offer`, item.name)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4195',
          Message: error
        })
      })
      .finally(() => {
        this.isDownloadingOfferFile = false
      })
  }

  openDrawing(item: CustomOffer) {
    this.isDownloadingOfferFile = true
    this.$fileApi
      .downloadFile(`/customOffer/${item.id}/drawing`, this.drawingFileName)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4195',
          Message: error
        })
      })
      .finally(() => {
        this.isDownloadingDrawingFile = false
      })
  }

  closeOfferPopup() {
    this.showOfferPopup = false
  }

  get isReadonly() {
    return this.requestStatus === CustomOfferRequestStatus.Completed
  }

  getItemRawClass(item: CustomOffer | null) {
    return ['c-custom-offer-list-view__table-row', { 'grey lighten-1': item?.status === CustomOfferStatus.Deleted }]
  }

  get drawingFileName() {
    return this.drawingFile?.name ?? 'drawing.pdf'
  }

  get requestStatus() {
    return this.offers?.[0]?.customOfferRequest?.requestStatus?.status
  }

  get isSharedToggleEnabled() {
    return (
      !this.offerToUpdate?.isSharedWithCustomer ||
      this.offerToUpdate?.customOfferRequest?.requestStatus?.status === CustomOfferRequestStatus.SharedWithCustomer ||
      this.offerToUpdate?.customOfferRequest?.requestStatus?.status === CustomOfferRequestStatus.RequestForChange
      
    )
  }

  isSharedAndInCreateOffer(item: customOfferItem ) { 
    return this.requestStatus === CustomOfferRequestStatus.CreateOffer && item.isShared
  }

  get hasFutureAppointments() {
    const latestAppointment = this.appointments?.[0]
    const currentDate = new Date()
    return new Date(latestAppointment?.date) > currentDate
  }

  async handleCustomOfferShareToggle(item: customOfferItem) {
    if (this.disableIsSharedToggle) return

    this.disableIsSharedToggle = true

    return this.$showConfirmationDialog({
      awaitConfirm: true,
      Callback: async () => {
        try {
          await this.$apolloMutate<UpdateCustomOfferShareMutation, UpdateCustomOfferShareMutationVariables>({
            mutation: UpdateCustomOfferShareDocument,
            variables: {
              customOfferId: item?.id,
              isShared: item.isShared
            },
            refetchQueries: [
              {
                query: CustomOfferRequestDocument,
                variables: { id: this.$route.params?.id }
              }
            ],
            error: 'E1117'
          })
        } finally {
          this.disableIsSharedToggle = false
        }
      },
      CancelCallback: () => {
        this.disableIsSharedToggle = false
        item.isShared = !item.isShared
      },
      Code: 'C4035'
    })
  }

  async deleteCustomOffer(item: CustomOffer | null) {
    this.$store.dispatch('showConfirmationDialog', {
      Callback: async () => {
        this.isLoading = true
        this.$store.dispatch('hideConfirmationDialog')
        try {
          const deleted = await this.$apolloMutate<DeleteCustomOfferMutation, DeleteCustomOfferMutationVariables>({
            mutation: DeleteCustomOfferDocument,
            variables: {
              customOfferId: item?.id
            },
            refetchQueries: [
              {
                query: CustomOfferRequestDocument,
                variables: { id: this.$route.params?.id }
              }
            ],
            error: 'E4196'
          })

          if (deleted) {
            this.$emit('custom-offer-deleted')
          }
        } finally {
          this.isLoading = false
        }
      },
      Code: 'C4014'
    })
  }
}
