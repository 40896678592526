
import { Component, Vue } from 'vue-property-decorator'
@Component
export default class LogoutComponent extends Vue {
  clickLogin() {
    this.$store.dispatch('authenticateOidc')
  }

  clickSignOut() {
    this.$store.dispatch('signOutCypress')
    this.$store.dispatch('signOutOidc')
  }
}
