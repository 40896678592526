
import { Component, Vue, Prop } from 'vue-property-decorator'
import { GET_PROJECT_PRODUCT_PACKAGES } from './ProjectProductPackagesQueries'
import {
  ProjectProductPackagesQuery,
  ProjectProductPackagesQueryVariables,
  ProjectProductPackagesQuery_productPackages
} from './__generated__/ProjectProductPackagesQuery'
import QUERIES from '@/queries/queries'
import {
  ASSIGN_PRODUCT_PACKAGE_TO_PROJECT,
  ASSIGN_SINGLE_PRODUCT_PACKAGE_TO_PROJECT,
  UNASSIGN_PRODUCT_PACKAGE_FROM_PROJECT
} from './ProjectProductPackagesMutations'
import {
  assignProductPackageToProject,
  assignProductPackageToProjectVariables
} from './__generated__/assignProductPackageToProject'
import { 
  assignSingleProductPackageToProject, 
  assignSingleProductPackageToProjectVariables
} from './__generated__/assignSingleProductPackageToProject'
import {
  unassignProductPackageFromProject,
  unassignProductPackageFromProjectVariables
} from './__generated__/unassignProductPackageFromProject'
import { ErrorDialogModel } from '@/types/dialogTypes'

type ProductPackageItem = {
  id: string
  name: string
  designPackageCount: number
}

@Component
export default class ProjectProductPackages extends Vue {
  @Prop() readonly projectId!: string
  selectedProductPackages = Array<string>()
  productPackages: ProjectProductPackagesQuery_productPackages[] | null = null
  loading = false  
  multiProductPackage = false

  created() {
    this.fetchData()
  }

  get isReadonly() {
    return !this.$store.getters.isAdmin
  }

  get isMultiProductPackage() {
    return this.multiProductPackage;
  }

  get isEditable() {
    return this.productPackageItems?.filter(x => x.designPackageCount > 0).length == 0;
  }

  fetchData() {
    this.$apollo.addSmartQuery<ProjectProductPackagesQuery>(
      QUERIES.ProjectProductPackagesQuery,
      {
        query: GET_PROJECT_PRODUCT_PACKAGES,
        variables: () => {
          return {
            projectId: this.projectId
          } as ProjectProductPackagesQueryVariables
        },
        result: (result) => {
          this.productPackages = result.data.productPackages
          this.setSelectedProductPackage()
        },
        update: (data) => data,
        error: (error) => {
          this.showErrorDialog({
            Code: 'E4153',
            Message: error.message
          })
        }
      }
    )
  }

  setSelectedProductPackage() {
    this.selectedProductPackages = this.productPackages
            ?.filter((x) => x.usedInProjects.length > 0)
            .map((x) => `${x.id}`) || []
  }

  get productPackageItems(): ProductPackageItem[] | undefined {
    return this.productPackages
      ?.sort((leftSide, rightSide): number => {
        if (leftSide.name < rightSide.name) return -1
        if (leftSide.name > rightSide.name) return 1
        return 0
      })
      .map<ProductPackageItem>((x) => {
        return {
          id: x.id,
          name: x.name,
          designPackageCount: this.designPackageCount(x),
          displayName: this.designPackageDisplayName(x)
        }
      })
  }

  designPackageCount(
    productPackage: ProjectProductPackagesQuery_productPackages
  ): number {
    const project = productPackage?.usedInProjects?.[0]?.project
    if (project) {
      return project.rooms
        .flatMap((x) => x.designPackages)
        .filter((x) => x.productPackage?.id == productPackage.id).length
    }
    return 0
  }

  designPackageDisplayName(
    productPackage: ProjectProductPackagesQuery_productPackages
  ): string {
    let designPackageCount = 0;
    const project = productPackage?.usedInProjects?.[0]?.project
    if (project) {
      designPackageCount = project.rooms
        .flatMap((x) => x.designPackages)
        .filter((x) => x.productPackage?.id == productPackage.id).length      
    }

    return `${productPackage.name} (${designPackageCount.toString()} ${designPackageCount == 1 ? this.$t('projectProductPackage.list.addedDesignPackage') : this.$t('projectProductPackage.list.addedDesignPackages') })`
  }

  toggleAssignment(productPackage: ProductPackageItem) {
    if(!productPackage){ 
      this.setSelectedProductPackage();
      return;
    }
    if (!this.multiProductPackage) {
      this.assignSingleProductPackage(productPackage)
    } else {
      if (this.selectedProductPackages.some((x) => x == productPackage.id)) {
        this.unassignProductPackage(productPackage)
      } else {
        this.assignProductPackage(productPackage)
      }
    }
  }

  assignSingleProductPackage(productPackage: ProductPackageItem) {
    this.loading = true
    const refetchQueries = [QUERIES.ProjectProductPackagesQuery]
    this.$apolloMutate<assignSingleProductPackageToProject, assignSingleProductPackageToProjectVariables>({
      mutation: ASSIGN_SINGLE_PRODUCT_PACKAGE_TO_PROJECT,
      variables: {
        projectId: this.projectId,
        productPackageId: productPackage.id
      },
      refetchQueries: refetchQueries,
      error: (error) => (
        this.setSelectedProductPackage(),
        {
          Code: 'E4118',
          Message: error.message,
          Sub: 'A'
        })
    }).finally(() => (this.loading = false))
  }

  assignProductPackage(productPackage: ProductPackageItem) {
    this.loading = true
    const refetchQueries = [QUERIES.ProjectProductPackagesQuery]
    this.$apolloMutate<assignProductPackageToProject, assignProductPackageToProjectVariables>({
      mutation: ASSIGN_PRODUCT_PACKAGE_TO_PROJECT,
      variables: {
        projectId: this.projectId,
        productPackageId: productPackage.id
      },
      refetchQueries: refetchQueries,
      error: (error) => ({
        Code: 'E4154',
        Message: error.message,
        Sub: 'A'
      })
    }).finally(() => (this.loading = false))
  }

  unassignProductPackage(productPackage: ProductPackageItem) {
    this.loading = true
    const refetchQueries = [QUERIES.ProjectProductPackagesQuery]
    this.$apolloMutate<unassignProductPackageFromProject, unassignProductPackageFromProjectVariables>({
      mutation: UNASSIGN_PRODUCT_PACKAGE_FROM_PROJECT,
      variables: {
        projectId: this.projectId,
        productPackageId: productPackage.id
      },
      refetchQueries: refetchQueries,
      error: (error) => ({
        Code: 'E4155',
        Message: error.message,
        Sub: 'B'
      })
    }).finally(() => (this.loading = false))
  }

  showErrorDialog(error: ErrorDialogModel) {
    this.$store.dispatch('showErrorDialog', error)
  }
}
