<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'OidcCallback',
  methods: {
    ...mapActions(['oidcSignInCallback'])
  },
  mounted() {
    this.oidcSignInCallback()
      .then((redirectPath) => {
        if (redirectPath) {
          this.$router.push(redirectPath)
        }
      })
      .catch((error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4001',
          Message: error
        })
      })
  }
}
</script>
