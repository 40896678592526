
import { CostType, ProjectStatus, ProjectActivityTarget } from '@/__generated__/globalTypes'
import QUERIES from '@/queries/queries'
import { getInputPrice } from '@/utils/currencyUtils'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { DataOptions, DataTableHeader } from 'vuetify'
import ActivityCostField from './ActivityCostField.vue'
import {
  ActivitiesDocument,
  ActivitiesQuery,
  ActivitiesQueryVariables,
  ProjectAssignedActivityFragment
} from './gql/__generated__/activities.query'
import {
  UpdateProjectActivityCostsDocument,
  UpdateProjectActivityCostsMutation,
  UpdateProjectActivityCostsMutationVariables
} from './gql/__generated__/updateProjectActivityCosts.mutation'
import { AssignedActivity } from './types'
import importActivityTemplateDialog from '@/components/activitiesTemplates/importActivityTemplateDialog.vue'
import {
  ActivityTemplatesQuery,
  ActivityTemplatesQueryVariables,
  ActivityTemplatesDocument
} from '../activitiesTemplates/__generated__/activityTemplates.query'

@Component({
  components: {
    ActivityCostField,
    importActivityTemplateDialog
  }
})
export default class ActivitiesList extends Vue {
  @Prop() projectId!: string

  search = ''
  activitiesRequestData: ActivitiesQuery | null = null
  projectStatus: ProjectStatus | null = null
  tableOptions: DataOptions = {} as DataOptions
  CostType = CostType
  ProjectStatus = ProjectStatus

  isloading = false
  showActivityTemplateImportDialog = false
  activityTemplates: ActivityTemplatesQuery | null = null

  costTypeSlots = [
    CostType.WInstallationCosts,
    CostType.CvInstallationCosts,
    CostType.EInstallationCosts,
    CostType.InstallationConstructionCosts,
    CostType.TilerInstallationCosts,
    CostType.KitterInstallationCosts
  ]

  @Ref() updateActivitiesForm?: HTMLFormElement

  created() {
    this.tableOptions.sortBy = ['activity.position', 'activity.activityName']
    this.tableOptions.sortDesc = [false]

    this.$apollo.addSmartQuery<ActivitiesQuery, ActivitiesQueryVariables>(QUERIES.Activities, {
      query: ActivitiesDocument,
      variables: () => ({
        projectId: this.projectId,
        lang: this.language
      }),
      fetchPolicy: 'network-only',
      update: (data) => data,
      result: (result) => {
        this.activitiesRequestData = result.data ?? null
        this.projectStatus = result.data.project?.status ?? null

        this.$nextTick(() => {
          this.updateActivitiesForm?.validate()
        })
      },
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4224',
          Message: error
        })
      },
      skip: () => !this.projectId
    })

    this.$apollo.addSmartQuery<ActivityTemplatesQuery, ActivityTemplatesQueryVariables>(QUERIES.ActivityTemplates, {
      query: ActivityTemplatesDocument,
      update: (data) => data,
      result: (result) => {
        this.activityTemplates = result.data
      },
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4224',
          Message: error
        })
      }
    })
  }

  get activityTemplateNames() {
    return this.activityTemplates?.activityTemplates?.items?.map((x) => ({ text: x.name, value: x.id }))
  }

  get language() {
    return this.$i18n.locale
  }

  get headers() {
    const headers: DataTableHeader[] = [
      {
        text: this.$t('activities.table.headers.combination').toString(),
        value: 'activity.productCombination.position',
        groupable: true,
        width: '10%',
        filterable: false
      },
      {
        text: this.$t('activities.table.headers.activity').toString(),
        value: 'activity.activityName',
        width: '25%'
      },
      // Hidden column so we can filter/search on the positionLabel
      { text: '', value: 'activity.positionLabel', class: 'd-none', cellClass: 'd-none' }
    ]

    this.costTypeSlots.forEach((costType) => {
      headers.push({
        text: this.$t(`activities.table.headers.${costType}`).toString(),
        value: costType,
        width: '10%',
        sortable: false,
        filterable: false
      })
    })

    return headers
  }

  get tableData(): AssignedActivity[] {
    return (
      this.activitiesRequestData?.project?.projectActivities.map((x) => ({
        activity: {
          id: x.activity.id,
          position: x.activity.position,
          positionLabel: x.activity.positionLabel,
          activityTarget: x.activity.activityTarget,
          activityName: x.activity?.activityName ?? '',
          activityAdditionalInfo: x.activity?.activityAdditionalInfo ?? '',
          productCombination: {
            name:
              this.$t(
                `global.combinations.${x.activity?.productCombination?.combinationType?.toLowerCase()}`
              ).toString() ?? '',
            position: x.activity?.productCombination?.position ?? 0
          }
        },
        W_INSTALLATION_COSTS: this.getCostTypeValue(x, CostType.WInstallationCosts),
        E_INSTALLATION_COSTS: this.getCostTypeValue(x, CostType.EInstallationCosts),
        CV_INSTALLATION_COSTS: this.getCostTypeValue(x, CostType.CvInstallationCosts),
        INSTALLATION_CONSTRUCTION_COSTS: this.getCostTypeValue(x, CostType.InstallationConstructionCosts),
        TILER_INSTALLATION_COSTS: this.getCostTypeValue(x, CostType.TilerInstallationCosts),
        KITTER_INSTALLATION_COSTS: this.getCostTypeValue(x, CostType.KitterInstallationCosts)
      })) ?? []
    )
  }

  getCostTypeValue(activity: ProjectAssignedActivityFragment, costType: CostType) {
    const rawVal = activity.activityCosts.find((y) => y.costType === costType)?.costValue?.amount

    return rawVal ? getInputPrice(rawVal).replace('.', '') : ''
  }

  get isPreviewProject() {
    return this.projectStatus === ProjectStatus.Preview
  }

  getActivityTargetIcon(target: ProjectActivityTarget): string {
    switch (target) {
      case ProjectActivityTarget.Meters:
        return 'mdi-arrow-expand-horizontal'
      case ProjectActivityTarget.SquareMeters:
        return 'mdi-texture-box'
      default:
        return ''
    }
  }

  getActivityTargetTitle(target: ProjectActivityTarget): string {
    return this.$t(`activities.table.activityTarget.${target}`).toString()
  }

  groupTable(groupBy: string, groupDesc = false) {
    this.tableOptions.groupBy = [groupBy]
    this.tableOptions.groupDesc = [groupDesc]
  }

  unGroupTable(ungroup: () => void) {
    ungroup()
    this.$nextTick(() => this.updateActivitiesForm?.validate())
  }

  onSortChange() {
    if (this.tableOptions.groupBy.length) {
      this.tableOptions.sortBy.unshift('activity.position')
      // this.tableOptions.sortBy.unshift('activity.productCombination.position')
      this.tableOptions.sortDesc.unshift(false)
    }
  }

  onCombinationToggle(isOpen: boolean, toggle: () => void) {
    toggle()
    this.$nextTick(() => {
      if (!isOpen) this.updateActivitiesForm?.validate()
    })
  }

  expandAllCombinations() {
    const categoriesToExpand = document.querySelectorAll('[data-js="expand-category"]')
    const clickEvent = new MouseEvent('click')

    categoriesToExpand.forEach((item) => {
      item.dispatchEvent(clickEvent)
    })
  }

  async savePrice(item: AssignedActivity) {
    if (!this.updateActivitiesForm?.validate()) return

    const mutationData = {
      projectId: this.projectId,
      projectActivityId: item.activity.id,
      costs: this.costTypeSlots.map((costType) => ({
        costType: costType,
        amount: item?.[costType.toString() as keyof AssignedActivity]?.toString() ?? ''
      }))
    }

    try {
      this.isloading = true

      await this.$apolloMutate<UpdateProjectActivityCostsMutation, UpdateProjectActivityCostsMutationVariables>({
        mutation: UpdateProjectActivityCostsDocument,
        variables: mutationData,
        refetchQueries: [QUERIES.Activities],
        error: 'E4225'
      })
    } finally {
      this.isloading = false
    }
  }
}
