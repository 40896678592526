
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ValidationRule } from 'vuelidate/lib/validators'

@Component
export default class PercentageField extends Vue {
  @Prop() modelValue!: string | null
  @Prop({ default: [] }) rules?: ValidationRule[]
  @Prop({ default: '' }) label?: string
  @Prop({ required: true }) disabled!: boolean

  get displayValue() {
    const modelVal = Number(this.modelValue)
    if (!this.modelValue || isNaN(modelVal)) return null

    // Value comes in as 0.xxxx, so times 10000 is a full number, dividing by 100 makes it a percentage.
    return (Math.round(modelVal * 10000) / 100).toString()
  }

  set displayValue(value: string | null) {
    const displayVal = Number(value ? value.replace(',', '.') : null)
    if (!displayVal || isNaN(displayVal)) {
      this.$emit('update:modelValue', null)
      return
    }

    if (displayVal < 0 || displayVal > 100) return

    // Make sure we save only the correct decimal places
    const emitVal = Number((displayVal * 100).toString().substring(0, 5)) / 10000
    this.$emit('update:modelValue', emitVal)
  }
}
