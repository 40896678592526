
import { AdUserDto } from '@/__generated__/globalTypes'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
AssignTo3DDrawingDocument,
AssignTo3DDrawingMutation,
AssignTo3DDrawingMutationVariables
} from './gql/__generated__/assignTo3DDrawing.mutation'
import {
AssignToTechnicalDrawingDocument,
AssignToTechnicalDrawingMutation,
AssignToTechnicalDrawingMutationVariables
} from './gql/__generated__/assignToTechnicalDrawing.mutation'
import { DrawingTypes } from './types'

@Component
export default class AssignDrawerDialog extends Vue {
  @Prop() show!: boolean
  @Prop() drawers!: AdUserDto[]
  @Prop() drawingRequestId!: string
  @Prop() drawingType!: DrawingTypes

  assignedDrawerId = ''
  isLoading = false 

  @Watch('show')
  onVisibilityChange() {
    if (!this.show) {
      this.assignedDrawerId
    }
  }

  async assignDrawer() {
    try {
      this.isLoading = true
      if (this.drawingType === DrawingTypes.THREE_D) await this.assignTo3DDrawing()
      if (this.drawingType === DrawingTypes.TECHNICAL) await this.assignToTechnicalDrawing()
    } finally {
      this.isLoading = false
    }
    this.$emit('drawer-assigned')
  }

  async assignTo3DDrawing() {
    await this.$apolloMutate<AssignTo3DDrawingMutation, AssignTo3DDrawingMutationVariables>({
      mutation: AssignTo3DDrawingDocument,
      variables: {
        drawerId: this.assignedDrawerId,
        requestId: this.drawingRequestId
      },
      error: 'E4230'
    })
  }

  async assignToTechnicalDrawing() {
    await this.$apolloMutate<AssignToTechnicalDrawingMutation, AssignToTechnicalDrawingMutationVariables>({
      mutation: AssignToTechnicalDrawingDocument,
      variables: {
        drawerId: this.assignedDrawerId,
        requestId: this.drawingRequestId
      },
      error: 'E4231'
    })
  }
}
