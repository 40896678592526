/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CopyStyleMutationVariables = Types.Exact<{
  styleId: Types.Scalars['UUID'];
  styleName: Types.Scalars['String'];
}>;


export type CopyStyleMutation = { copyStyle: any };


export const CopyStyleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CopyStyle"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"styleId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"styleName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"copyStyle"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"styleId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"styleId"}}},{"kind":"Argument","name":{"kind":"Name","value":"styleName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"styleName"}}}]}]}}]} as unknown as DocumentNode<CopyStyleMutation, CopyStyleMutationVariables>;