
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Component
export default class Toast extends Vue {
  @Prop({ required: true }) id!: number
  @Prop({ required: true }) value!: boolean
  @Prop({ default: 4000, type: Number }) timeout!: number
  @Prop({ default: 'success' }) color!: string
  @Prop({ default: 'gray' }) closeIconColor!: string
  @Prop({ default: false }) hideCloseButton!: boolean
  @Prop({ type: Number, default: 0 }) paddingBottom!: number

  @Ref('snackbar') snackbarRef!: Vue

  get paddingBottomStyle() {
    if (this.paddingBottom <= 0) return null

    return {
      paddingBottom: this.paddingBottom + 'px'
    }
  }

  get contentHeight() {
    return this.snackbarRef.$el.firstElementChild?.clientHeight
  }

  changeVisibility(value: boolean) {
    this.$emit('visibility-changed', this.id, value)
  }
}
