
import { ProductSetInfoKey, ProductSetInfoKeyDataType } from '@/__generated__/globalTypes'
import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { FieldInformation } from './types'

enum MeasurementUnits {
  mm2 = 1,
  cm2 = 100,
  m2 = 1000000
}

@Component({
  name: 'AreaMeasurementField',
  mixins: [validationMixin]
})
export default class AreaMeasurementField extends Vue {
  @Prop({ required: true }) readonly id!: string
  @Prop({ required: true }) readonly isOptional!: boolean
  @Prop({ required: true }) readonly productSetInfoKey!: ProductSetInfoKey
  @Prop({ required: true }) value!: FieldInformation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({ required: true }) v!: any
  @Prop({ required: true }) disabled!: boolean
  @Prop({ default: false }) disableMeasurementUnitSelection?: boolean
  @Prop({ validator: (input: string) => ['mm2', 'cm2', 'm2'].includes(input), default: 'm2' })
  defaultMeasurementUnit?: string

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Ref() areaMeasurementInput?: any

  measurementUnit = 1000000
  measurementUnits = [
    {
      text: 'mm2',
      value: 1
    },
    {
      text: 'cm2',
      value: 100
    },
    {
      text: 'm2',
      value: 1000000
    }
  ]

  @Watch('defaultMeasurementUnit', { immediate: true })
  onDefaultMeasurementUnitChange(unit: string) {
    this.measurementUnit = MeasurementUnits[unit as keyof typeof MeasurementUnits]
  }

  mounted() {
    this.validates()
  }

  get displayValue() {
    return this.recalculateDisplayValue()
  }

  set displayValue(value: string | null) {
    value = value === null ? value : value.replace(',', '.').trim()
    const displayVal = Number(value)
    if (isNaN(displayVal) || !value) {
      this.$emit('input', {
        item1: this.id,
        item2: null,
        isOptional: this.isOptional,
        dataType: ProductSetInfoKeyDataType.AreaMeasurement
      } as FieldInformation)
    } else {
      const emitVal = (displayVal * this.measurementUnit).toString()
      this.$emit('input', {
        item1: this.id,
        item2: emitVal,
        isOptional: this.isOptional,
        dataType: ProductSetInfoKeyDataType.AreaMeasurement
      } as FieldInformation)
    }
  }

  recalculateDisplayValue() {
    const modelVal = Number(this.value.item2)
    if (isNaN(modelVal) || !this.value.item2) return null
    return (modelVal / this.measurementUnit).toString()
  }

  get labelText() {
    return this.$t(`projectDesignPackage.labels.restrictionKeys.${this.productSetInfoKey.key}`)
  }

  get labelTextTranslationKey() {
    return `projectDesignPackage.labels.restrictionKeys.${this.productSetInfoKey.key}`
  }

  containsText() {
    // When non-numeric characters are entered, displayValue stays null internally, yet the text remains visible in the input field
    // even though displayValue is used as v-model for the input field. Vuetify probably does some caching since the v-model value
    // remains the same. The input field does have a lazyValue data property which is always up to date, so we use this to check
    // for non-numeric input.
    return !!this.areaMeasurementInput?.lazyValue
  }

  get getErrors() {
    const errors = new Array<string>()
    if (!this.v.item2.$dirty) {
      return errors
    }

    const rule = this.customRule()[0]
    const ruleResult = rule(this.displayValue ?? '')
    if (ruleResult !== true) {
      errors.push(`${this.labelText} ${ruleResult}`)
    }

    const decimalRule = this.rulesInternal.decimals
    const decimalRuleResult = decimalRule(this.displayValue ?? '')
    if (decimalRuleResult !== true) {
      errors.push(`${this.labelText} ${decimalRuleResult}`)
    }

    if (!this.displayValue && this.containsText()) {
      errors.push(
        `${this.labelText} ${this.$t('productPackage.edit.category.productSets.upsert.validations.decimalPlaces2')}`
      )
    }

    if (!this.v.item2.required && !this.containsText()) {
      errors.push(`${this.labelText} ${this.$t('global.validationRequired')}`)
    }
    return errors
  }

  validates() {
    this.v.item2.$touch()
  }

  rulesInternal = {
    allowAnything: () => {
      return true
    },
    milimeters: (value: string) => {
      const valNumber = Number(value?.replace(',', '.'))
      return (
        !value?.toString()?.length ||
        (valNumber >= 0 && valNumber < 1000000000) ||
        this.$t('productPackage.edit.category.productSets.upsert.validations.maxValue_1000000')
      )
    },
    centimeters: (value: string) => {
      const valNumber = Number(value?.replace(',', '.'))
      return (
        !value?.toString()?.length ||
        (valNumber >= 0 && valNumber < 10000000) ||
        this.$t('productPackage.edit.category.productSets.upsert.validations.maxValue_100000')
      )
    },
    meters: (value: string) => {
      const valNumber = Number(value?.replace(',', '.'))
      return (
        !value?.toString()?.length ||
        (valNumber >= 0 && valNumber < 1000) ||
        this.$t('productPackage.edit.category.productSets.upsert.validations.maxValue_1000')
      )
    },
    decimals: (value: string) => {
      const regex = /^[0-9]{1,9}([,.][0-9]{1,2})?$/
      return (
        !value?.toString()?.length ||
        regex.test(value) ||
        this.$t('productPackage.edit.category.productSets.upsert.validations.decimalPlaces2')
      )
    }
  }

  customRule() {
    switch (this.measurementUnit) {
      case 1:
        return [this.rulesInternal.milimeters]
      case 100:
        return [this.rulesInternal.centimeters]
      case 1000000:
        return [this.rulesInternal.meters]
    }

    return [this.rulesInternal.allowAnything]
  }
}
