
import { ProductSetInfoKeyType } from '@/__generated__/globalTypes'
import QUERIES from '@/queries/queries'
import { ProductCombinations } from '@/views/categoriesView/CategoriesViewTypes'
import {
  CategoryProductSetInfoKeyFragment,
  ProductCategoryFragment
} from '@/views/categoriesView/gql/__generated__/CategoriesView.query'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import {
  CreateProductCategoryDocument,
  CreateProductCategoryMutation,
  CreateProductCategoryMutationVariables
} from './gql/__generated__/createProductCategory.mutation'
import {
  UpdateProductCategoryDocument,
  UpdateProductCategoryMutation,
  UpdateProductCategoryMutationVariables
} from './gql/__generated__/updateProductCategory.mutation'
import { CategoryNamesPerCombination, UpsertActionType } from './types'

@Component({
  mixins: [validationMixin]
})
export default class CategoryUpsertModal extends Vue {
  @Prop({ required: true, default: false }) show!: boolean
  @Prop() categoryToUpdate?: ProductCategoryFragment | null
  @Prop() productCombinations?: (ProductCombinations | null)[]
  @Prop() actionType?: UpsertActionType | null
  @Prop({ default: () => [] }) existingCategoryNamesPerCombination!: CategoryNamesPerCombination[]

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Ref() upsertCategoryForm?: any

  ProductSetInfoKeyType = ProductSetInfoKeyType

  //Structure to save
  categoryName = ''
  categoryPosition = 0
  categoryProductCombinationId = ''
  mInfoKeys: CategoryProductSetInfoKeyFragment[] = []

  isLoading = false

  //For validation
  previousCategoryName = ''
  get rules() {
    return {
      isUniqueCategoryName: (value: string) => {
        const index = this.existingCategoryNamesPerCombination.findIndex(
          (x) => x.combinationId === this.categoryProductCombinationId
        )

        let currentCombinationCategoryNames = this.existingCategoryNamesPerCombination[index]?.categoryNames
        if (this.actionType === UpsertActionType.UPDATE) {
          currentCombinationCategoryNames = currentCombinationCategoryNames?.filter(
            (x) => !(x === this.previousCategoryName)
          )
        }
        return (
          !currentCombinationCategoryNames?.includes(value) ||
          this.$t('application.categories.list.upsert.errors.name.isUnique')
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      required: (value: any) =>
        !!value?.toString()?.length || this.$t('productPackage.edit.category.productSets.upsert.validations.required'),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      numeric: (value: any) => {
        return (
          (Number.isInteger(Number(value)) && Number(value) >= 0) ||
          this.$t('productPackage.edit.category.productSets.upsert.validations.numeric')
        )
      }
    }
  }

  get infoKeyTableHeaders() {
    return [
      {
        text: this.$t('application.categories.list.upsert.infoKeys.list.headers.name').toString(),
        value: 'name',
        width: '65%',
        sortable: false
      },
      {
        text: this.$t('application.categories.list.upsert.infoKeys.list.headers.position').toString(),
        value: 'position',
        width: '10%',
        sortable: false
      },
      {
        text: this.$t('application.categories.list.upsert.infoKeys.list.headers.displayable').toString(),
        value: 'displayable',
        width: '10%',
        sortable: false
      },
      {
        text: this.$t('application.categories.list.upsert.infoKeys.list.headers.filterable').toString(),
        value: 'filterable',
        width: '10%',
        sortable: false
      },
      {
        text: '',
        value: 'delete',
        width: '5%',
        sortable: false
      }
    ]
  }

  @Watch('show')
  onshowUpdate() {
    this.setCategoryModal()
  }

  @Watch('categoryToUpdate')
  onCategoryToUpdate() {
    this.setCategoryModal()
  }

  clickClose() {
    this.resetCategoryModal()
    this.upsertCategoryForm?.reset()
    this.$emit('close')
  }

  resetCategoryModal() {
    this.categoryName = ''
    this.categoryPosition = 0
    this.categoryProductCombinationId = ''
    this.mInfoKeys = []
  }

  setCategoryModal() {
    this.categoryName = this.categoryToUpdate?.name ?? ''
    this.categoryPosition = this.categoryToUpdate?.position ?? 0
    this.categoryProductCombinationId = this.categoryToUpdate?.productCombination?.id ?? ''
    this.previousCategoryName = this.categoryName
    this.mInfoKeys =
      this.categoryToUpdate?.assignedProductCategoryProductSetInfoKeys
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ?.map((x) => ({ ...x! }))
        ?.sort((a, b) => a.position - b.position) ?? []
  }

  getCategoryMutationData() {
    return {
      name: this.categoryName,
      position: parseInt(this.categoryPosition.toString()),
      productCombinationId: this.categoryProductCombinationId
    } as CreateProductCategoryMutationVariables
  }

  async upsertCategory(closeModalAfterSave = true) {
    if (!this.upsertCategoryForm?.validate()) return

    const requestData = this.getCategoryMutationData()

    this.isLoading = true
    try {
      if (this.actionType === UpsertActionType.UPDATE) {
        await this.updateCategory(requestData)
      }
      if (this.actionType === UpsertActionType.SAVE) {
        await this.createCategory(requestData)
      }
    } finally {
      this.isLoading = false
      if (closeModalAfterSave) this.$emit('close')
    }
  }

  async createCategory(requestData: CreateProductCategoryMutationVariables) {
    await this.$apolloMutate<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>({
      mutation: CreateProductCategoryDocument,
      variables: requestData,
      refetchQueries: [QUERIES.CategoriesViewQuery],
      error: 'E4181'
    })
  }

  async updateCategory(requestData: CreateProductCategoryMutationVariables) {
    await this.$apolloMutate<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>({
      mutation: UpdateProductCategoryDocument,
      variables: {
        id: this.categoryToUpdate?.id,
        name: requestData.name,
        position: requestData.position,
        productCombinationId: requestData.productCombinationId,
        productSetInfoKeys: this.mInfoKeys.map((x) => ({
          isDisplayable: x.isDisplayable,
          isFilterable: x.isFilterable,
          isOptional: x.isOptional,
          position: x.position || 999,
          productCategoryProductSetInfoKeyId: x.id,
          productSetInfoKeyId: x.productSetInfoKey.id
        }))
      },
      refetchQueries: [QUERIES.CategoriesViewQuery],
      error: 'E4182'
    })
  }

  removeCategoryInfoKey(productSetInfoKeyId: string) {
    this.mInfoKeys = this.mInfoKeys.filter((x) => x.productSetInfoKey.id !== productSetInfoKeyId)
  }
}
