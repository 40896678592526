import Vue from 'vue'
import Vuex from 'vuex'
// @ts-ignore
import { oidcSettings } from '@/config/oidc'
import { ConfirmationDialogModel, ErrorDialogModel, NotificationDialogModel } from '@/types/dialogTypes'
import { ToastOptions, ToastState } from '@/types/toastTypes'
import { GetClaimForToken } from '@/utils/tokenUtils'
import { VuexOidcState, vuexOidcCreateStoreModule } from 'vuex-oidc'

Vue.use(Vuex)

export interface DialogVisibility {
  IsVisible: boolean
}

export interface State {
  errorDialog: ErrorDialogModel & DialogVisibility
  confirmationDialog: ConfirmationDialogModel & DialogVisibility
  notificationDialog: NotificationDialogModel & DialogVisibility
  visibleToasts: ToastState[]
  oidcStore: VuexOidcState
  cypressToken: string | null
  masterProductPackageId: string | null
}

export default new Vuex.Store({
  state: {
    errorDialog: {
      Code: '',
      IsVisible: false,
      Message: '',
      Sub: '',
      Callback: null
    },
    confirmationDialog: {
      awaitConfirm: false,
      Callback: null,
      CancelCallback: null,
      Code: '',
      IsVisible: false,
      Message: '',
      Sub: '',
      HasTextArea: false,
      Notes: ''
    },
    notificationDialog: {
      Code: '',
      IsVisible: false,
      Message: '',
      Sub: ''
    },
    masterProductPackageId: null,
    visibleToasts: Array<ToastState>(),
    cypressToken: null
  } as State,
  getters: {
    isAdmin(state: State): boolean {
      if (state.oidcStore.access_token) {
        const roles = GetClaimForToken<string>(state.oidcStore.access_token, 'roles')
        if (roles) {
          return roles.includes('Admin')
        }
      }
      if (state.cypressToken) {
        const roles = GetClaimForToken<string>(state.cypressToken, 'role')
        if (roles) {
          return roles.includes('Admin')
        }
      }
      return false
    },
    username(state: State): string | null {
      const user = state?.oidcStore?.user
      if (user) {
        return user?.name ?? user?.preferred_username ?? user?.email ?? null
      }
      if (state.cypressToken) {
        return 'Cypress tester'
      }

      return null
    },
    accessToken(state: State): string | null {
      return state.oidcStore.access_token || state.cypressToken
    },
    accessTokenExp(state: State): Date {
      //return state.oidcStore.access_token || state.cypressToken
      if (state?.oidcStore?.expires_at) {
        return new Date(state.oidcStore.expires_at)
      }
      if (state.cypressToken) {
        Date.now() + 600
      }

      return new Date()
    }
  },
  mutations: {
    showConfirmationDialog(state: State, model: ConfirmationDialogModel) {
      state.confirmationDialog.awaitConfirm = model.awaitConfirm
      state.confirmationDialog.Callback = model.Callback
      state.confirmationDialog.CancelCallback = model.CancelCallback
      state.confirmationDialog.Code = model.Code
      state.confirmationDialog.IsVisible = true
      state.confirmationDialog.HasTextArea = model.HasTextArea
      state.confirmationDialog.Notes = model.Notes ?? ''
      state.confirmationDialog.Message = model.Message ?? ''
      state.confirmationDialog.Sub = model.Sub ?? ''
    },
    updateConfirmationDialogNotes(state: State, model: ConfirmationDialogModel) {
      state.confirmationDialog.Notes = model.Notes ?? ''
    },
    hideConfirmationDialog(state: State) {
      state.confirmationDialog.awaitConfirm = false
      state.confirmationDialog.Callback = null
      state.confirmationDialog.CancelCallback = null
      state.confirmationDialog.Code = ''
      state.confirmationDialog.IsVisible = false
      state.confirmationDialog.Message = ''
      state.confirmationDialog.Notes = ''
      state.confirmationDialog.HasTextArea = false
      state.confirmationDialog.Sub = ''
    },
    showErrorDialog(state: State, model: ErrorDialogModel) {
      state.errorDialog.Code = model.Code
      state.errorDialog.IsVisible = true
      state.errorDialog.Message = model.Message ?? ''
      state.errorDialog.Sub = model.Sub ?? ''
      state.errorDialog.Data = model.Data ?? []
      state.errorDialog.Callback = model.Callback
    },
    hideErrorDialog(state: State) {
      state.errorDialog.Code = ''
      state.errorDialog.IsVisible = false
      state.errorDialog.Message = ''
      state.errorDialog.Sub = ''
      state.errorDialog.Callback = null
    },
    showNotificationDialog(state: State, model: NotificationDialogModel) {
      state.notificationDialog.Code = model.Code
      state.notificationDialog.IsVisible = true
      state.notificationDialog.Message = model.Message ?? ''
      state.notificationDialog.Sub = model.Sub ?? ''
    },
    hideNotificationDialog(state: State) {
      state.notificationDialog.Code = ''
      state.notificationDialog.IsVisible = false
      state.notificationDialog.Message = ''
      state.notificationDialog.Sub = ''
    },
    showToast(state: State, model: ToastState) {
      state.visibleToasts.push(model)
    },
    hideToast(state: State, model: number) {
      state.visibleToasts = state.visibleToasts.filter((x) => x.id !== model)
    },
    setCypressToken(state: State, token: string) {
      state.cypressToken = token
    },
    setMasterProductPackageId(state: State, masterProductPackageId: string) {
      state.masterProductPackageId = masterProductPackageId
    },
    unsetCypressToken(state: State) {
      state.cypressToken = null
      const cypressTokenStorageKey = `badkamer.user`
      localStorage.removeItem(cypressTokenStorageKey)
    }
  },
  actions: {
    showConfirmationDialog(context, model: ConfirmationDialogModel) {
      context.commit('showConfirmationDialog', model)
    },
    hideConfirmationDialog(context) {
      context.commit('hideConfirmationDialog')
    },
    showErrorDialog(context, model: ErrorDialogModel) {
      context.commit('showErrorDialog', model)
    },
    hideErrorDialog(context) {
      context.commit('hideErrorDialog')
    },
    showNotificationDialog(context, model: NotificationDialogModel) {
      context.commit('showNotificationDialog', model)
    },
    hideNotificationDialog(context) {
      context.commit('hideNotificationDialog')
    },
    showToastText(context, model: string) {
      const toast: ToastState = {
        id: Date.now(),
        state: true,
        text: model
      }
      context.commit('showToast', toast)
    },
    showToast(context, model: ToastOptions) {
      const toast: ToastState = {
        id: Date.now(),
        state: true,
        ...model
      }
      context.commit('showToast', toast)
    },
    hideToast(context, model: number) {
      context.commit('hideToast', model)
    },
    signOutCypress(context) {
      context.commit('unsetCypressToken')
    }
  },
  modules: {
    oidcStore: vuexOidcCreateStoreModule(oidcSettings, {
      publicRoutePaths: ['/logout', '/cypress-login']
    })
  }
})
