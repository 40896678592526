
import { ProjectFilterInput } from '@/__generated__/globalTypes'
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  GetProjectsAndRealEstatesDocument,
  GetProjectsAndRealEstatesQuery,
  GetProjectsAndRealEstatesQueryVariables,
  ProjectItemFragment,
  ProjectRealEstateItemFragment
} from './gql/__generated__/getProjectsAndRealEstates.query'

export interface SelectedProjectRealEstate {
  projectId: string | null
  realEstateId: string | null
}

@Component
export default class ProjectRealEstatesSelector extends Vue {
  @Prop({ required: true }) value!: SelectedProjectRealEstate
  @Prop({ default: '' }) projectLabel!: string
  @Prop({ default: '' }) realEstateLabel!: string
  @Prop({ default: null }) projectsQueryFilter!: ProjectFilterInput | null
  @Prop() projectsFilter?: (r: ProjectItemFragment) => boolean
  @Prop() realEstatesFilter?: (r: ProjectRealEstateItemFragment) => boolean

  projectsAndRealEstates: GetProjectsAndRealEstatesQuery | null = null

  created() {
    this.$apollo.addSmartQuery<GetProjectsAndRealEstatesQuery, GetProjectsAndRealEstatesQueryVariables>(
      'GetProjectsAndRealEstatesQuery',
      {
        query: GetProjectsAndRealEstatesDocument,
        variables: () => ({
          filters: this.projectsQueryFilter
        }),
        fetchPolicy: 'network-only',
        update: (data) => data,
        result: (result) => {
          this.projectsAndRealEstates = result.data ?? null
        }
      }
    )
  }

  //#region selectedProjectId
  get selectedProjectId() {
    return this.value.projectId
  }
  set selectedProjectId(value: string | null) {
    this.$emit('input', {
      projectId: value,
      realEstateId: null
    } as SelectedProjectRealEstate)
  }
  //#endregion selectedProjectId

  //#region selectedRealEstateId
  get selectedRealEstateId() {
    return this.value.realEstateId
  }
  set selectedRealEstateId(value: string | null) {
    this.$emit('input', {
      projectId: this.selectedProjectId,
      realEstateId: value
    } as SelectedProjectRealEstate)
  }
  //#endregion selectedRealEstateId

  get availableProjects() {
    let projects = this.projectsAndRealEstates?.projects?.items

    if (projects && this.projectsFilter) {
      projects = projects.filter(this.projectsFilter)
    }

    return projects
  }

  get availableRealEstates() {
    if (!this.selectedProjectId) return []

    let projectRealEstates = this.availableProjects?.find((p) => p.id === this.selectedProjectId)?.realEstates

    if (projectRealEstates && this.realEstatesFilter) {
      projectRealEstates = projectRealEstates.filter(this.realEstatesFilter)
    }

    return (
      projectRealEstates?.sort((a, b) => {
        const aValue = parseInt(a.buildNumber || '') || a.buildNumber || 0
        const bValue = parseInt(b.buildNumber || '') || b.buildNumber || 0
        if (aValue == bValue) return 0
        else if (aValue > bValue) return 1
        return -1
      }) ?? []
    )
  }
}
