
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ReturnedProductSet } from './types'

@Component
export default class ReturnedProductSets extends Vue {
  @Prop() returnedProductSets!: ReturnedProductSet[]

  get returnedProductsPerCombination() {
    return this.returnedProductSets?.map((x) =>
      x?.articles?.map((x) => {
        return {
          name: x?.name,
          code: x?.code,
          price: x?.price
        }
      })
    )
  }

  totalPrice(prices: (number | null)[]) {
    return prices.reduce((x, y) => (x ?? 0) + (y ?? 0), 0)
  }
}
