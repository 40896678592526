/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type StylesViewQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type StylesViewQueryQuery = { styles: Array<{ id: any, name: string, productPackageId?: any | null, productPackage?: { id: any, isMain: boolean } | null }> };

export type StyleFragment = { id: any, name: string, productPackageId?: any | null, productPackage?: { id: any, isMain: boolean } | null };

export const StyleFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"style"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Style"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"productPackageId"}},{"kind":"Field","name":{"kind":"Name","value":"productPackage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isMain"}}]}}]}}]} as unknown as DocumentNode<StyleFragment, unknown>;
export const StylesViewQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"StylesViewQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"styles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"style"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"style"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Style"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"productPackageId"}},{"kind":"Field","name":{"kind":"Name","value":"productPackage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isMain"}}]}}]}}]} as unknown as DocumentNode<StylesViewQueryQuery, StylesViewQueryQueryVariables>;