
import ProductCategorySection from '@/components/productPackages/ProductCategorySection.vue'
import ProductCombinationTabs from '@/components/productPackages/ProductCombinationTabs.vue'
import QUERIES from '@/queries/queries'
import { RouteNames } from '@/router/routeNames'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import {
  ProductPackageViewDataDocument,
  ProductPackageViewDataQuery,
  ProductPackageViewDataQueryVariables
} from './gql/__generated__/getProductPackageViewData.query'

import {
  Badkamercore_ProjectType,
  Category,
  ProductCategoryProductSetInfoKey,
  ProductCombination,
  ProductCombinationType,
  ProductPackage,
  ProductPackageCategory
} from '@/__generated__/globalTypes'
import { unique } from '@/utils/collectionUtils'
import { maxLength, required } from 'vuelidate/lib/validators'
import {
  CombinationProductSetActivitiesDocument,
  CombinationProductSetActivitiesQuery,
  CombinationProductSetActivitiesQueryVariables,
  CombinationProductSetActivityFragment
} from './gql/__generated__/combinationProductSetActivities.query'
import {
  ProductSetInfoKeysDocument,
  ProductSetInfoKeysQuery,
  ProductSetInfoKeysQueryVariables
} from './gql/__generated__/getProductSetInfoKeys.query'
import {
  UpdateProductPackageDocument,
  UpdateProductPackageMutation,
  UpdateProductPackageMutationVariables
} from './gql/__generated__/updateProductPackage.mutation'
import { CategoryWithProductSetInfoKeysStructure } from './types'
import { GetLocalizedContent } from '@/utils/translationUtils'
import {
  CategoriesQueryDocument,
  CategoriesQueryQuery,
  CategoriesQueryQueryVariables,
  CategoryFieldsFragment
} from '@/views/categoriesView/gql/__generated__/CategoryCMS.query'

@Component({
  components: {
    ProductCombinationTabs,
    ProductCategorySection
  },
  mixins: [validationMixin]
})
export default class ProductPackageView extends Vue {
  @Prop({ required: false }) productPackageId!: string

  name = ''
  currentCombinationType: ProductCombinationType | null = null
  combinations: ProductCombination[] | null = null
  productPackageData: ProductPackage | null = null
  isMasterProductPackage = false
  RouteNames = RouteNames

  productCategoryProductSetInfoKeys: ProductCategoryProductSetInfoKey[] = []
  combinationProductSetActivities: CombinationProductSetActivityFragment[] = []

  allCategories: ProductPackageCategory[] | undefined = []
  categoriesCMSContent: CategoryFieldsFragment[] = []

  get categoriesPages(): Category[] {
    return this.categoriesCMSContent.map((cmsCat) => GetLocalizedContent(cmsCat))
  }

  getCategoryCMSContent(): Category[] | undefined {
    const catIds = this.allCategories?.map((combCat) => combCat.productCategory?.id) ?? []
    return this.categoriesPages.filter((cat) => catIds.includes(cat?.alias?.alias ?? ''))
  }

  get overviewRouteName(): string {
    switch (this.$route.params.propositionType) {
      case Badkamercore_ProjectType.DeBadkamerwerelden:
        return RouteNames.STYLES
      case Badkamercore_ProjectType.Merkenpropositie:
        return RouteNames.BRANDS
      case Badkamercore_ProjectType.Productenpropositie:
        return RouteNames.PRODUCT_PACKAGES
      default:
        break
    }
    return RouteNames.PROJECTS
  }

  get titlePrefix(): string {
    switch (this.$route.params.propositionType) {
      case Badkamercore_ProjectType.DeBadkamerwerelden:
        return `${this.$t('header.styles').toString()} - `
      case Badkamercore_ProjectType.Merkenpropositie:
        return `${this.$t('header.brands').toString()} - `
      case Badkamercore_ProjectType.Productenpropositie:
        return `${this.$t('header.products').toString()} - `
      default:
        break
    }
    return ''
  }

  get lang() {
    return this.$i18n.locale
  }

  get isLoadingCombinations() {
    return this.$apollo.queries[QUERIES.ProductPackageViewQuery].loading
  }

  get combinationTypes() {
    return this.combinations?.map((x) => x?.combinationType)
  }

  get currentCombination() {
    return this.combinations?.find((x) => x.combinationType === this.currentCombinationType)
  }

  get combinationCategories(): CategoryWithProductSetInfoKeysStructure[] | null {
    this.allCategories = this.productPackageData?.assignedProductPackageCategories.filter(
      (x) => x.productCategory?.productCombination?.combinationType === this.currentCombinationType
    )

    const categories = this.productPackageData?.assignedProductPackageCategories.filter(
      (x) =>
        x.productCategory?.productCombination?.combinationType === this.currentCombinationType &&
        (!x.productCategory?.hasYesNoQuestion ?? true) &&
        x.productCategory?.loadProductSetsFromCategoryId == null &&
        x.productCategory?.loadProductSetsFromAdditionalCategoryId == null
    )

    const values = categories?.map(
      (packageCategory) =>
        ({
          id: packageCategory.id,
          combination: packageCategory.productCategory?.productCombination?.combinationType,
          allowPickByBrand: packageCategory.allowPickByBrand,
          isSelectionOptional: packageCategory.productCategory?.isSelectionOptional,
          categoryId: packageCategory.productCategory?.id,
          name: packageCategory.productCategory?.categoryName,
          position: packageCategory.productCategory?.position,
          assignedBrandlines: packageCategory.productCategory?.assignedBrandlines,
          productSetInfoKeys:
            this.productCategoryProductSetInfoKeys
              ?.filter((infoKey) => infoKey?.productCategory?.id === packageCategory.productCategory?.id)
              ?.map((psik) => ({
                ...psik.productSetInfoKey,
                isOptional: psik.isOptional
              })) ?? []
        } as CategoryWithProductSetInfoKeysStructure)
    )

    return values?.sort((a, b) => a.position - b.position) ?? null
  }

  get nameErrors() {
    const errors = new Array<string>()
    if (!this.$v.name.$dirty) {
      return errors
    }
    if (!this.$v.name.maxLength) {
      errors.push(this.$t('productPackage.create.errors.name.maxLength').toString())
    }
    if (!this.$v.name.required) {
      errors.push(this.$t('productPackage.create.errors.name.required').toString())
    }
    return errors
  }

  get currentProductPackageId(): string | null {
    if (this.isMasterProductPackage) {
      return this.$store.state.masterProductPackageId
    } else {
      return this.productPackageId
    }
  }

  created() {
    this.isMasterProductPackage = !this.productPackageId

    // Query to get the initial page data based on the product package and current combination
    this.fetchProductPackage()

    // Query to get all the properties for creating a new product set
    this.fetchProductSetInfoKeys()

    // Query to get all the product set activities for the current combination, for creating a new product set
    this.fetchCombinationProductSetActivities()

    this.fetchCMSContent()
  }

  updated() {
    // To force a refresh
    this.isMasterProductPackage = !this.productPackageId
  }

  fetchProductPackage() {
    this.$apollo.addSmartQuery<ProductPackageViewDataQuery, ProductPackageViewDataQueryVariables>(
      QUERIES.ProductPackageViewQuery,
      {
        query: ProductPackageViewDataDocument,
        variables: () => ({
          productPackageId: this.currentProductPackageId
        }),
        update: (data) => data,
        result: (result) => {
          this.productPackageData = result.data.productPackage as ProductPackage

          this.name = this.productPackageData?.name ?? ''

          const combinations = unique(
            this.productPackageData?.assignedProductPackageCategories.map((x) => x.productCategory?.productCombination),
            (x) => x?.combinationType
          )

          this.combinations =
            (combinations?.sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0)) as ProductCombination[]) ?? null
          this.currentCombinationType = this.combinations?.[0].combinationType ?? null
        },
        skip: () => {
          return !this.currentProductPackageId
        },
        fetchPolicy: 'network-only'
      }
    )
  }

  fetchCMSContent() {
    this.$apollo.addSmartQuery<CategoriesQueryQuery, CategoriesQueryQueryVariables>(QUERIES.CategoryCMSQuery, {
      query: CategoriesQueryDocument,
      variables: () => ({
        defaultLang: 'nl',
        currentLang: this.lang
      }),
      update: (data) => data,
      result: (result) => {
        if (result) {
          this.categoriesCMSContent = (result.data?.category ?? []) as CategoryFieldsFragment[]
        }
      },
      skip: () => {
        return !this.lang
      }
    })
  }

  fetchProductSetInfoKeys() {
    this.$apollo.addSmartQuery<ProductSetInfoKeysQuery, ProductSetInfoKeysQueryVariables>(QUERIES.ProductSetInfoKeys, {
      query: ProductSetInfoKeysDocument,
      variables: () => ({
        combinationType: this.currentCombinationType,
        language: this.lang
      }),
      update: (data) => data,
      result: (result) =>
        (this.productCategoryProductSetInfoKeys = result.data
          ?.productCategoryProductSetInfoKeys as ProductCategoryProductSetInfoKey[]),
      skip: () => {
        return !this.currentProductPackageId || !this.currentCombinationType || !this.combinations?.length
      }
    })
  }

  fetchCombinationProductSetActivities() {
    this.$apollo.addSmartQuery<CombinationProductSetActivitiesQuery, CombinationProductSetActivitiesQueryVariables>(
      QUERIES.CombinationProductSetActivities,
      {
        query: CombinationProductSetActivitiesDocument,
        variables: () => ({
          combinationType: this.currentCombinationType,
          lang: this.lang
        }),
        update: (data) => data,
        result: (result) => (this.combinationProductSetActivities = result.data?.projectActivities),
        skip: () => {
          return !this.currentProductPackageId || !this.currentCombinationType || !this.combinations?.length
        }
      }
    )
  }

  validations() {
    return {
      name: {
        required,
        maxLength: maxLength(50)
      }
    }
  }

  onCombinationTabClick(combination: ProductCombinationType) {
    this.currentCombinationType = combination
  }

  updateProductPackage() {
    this.$v.$touch()
    this.$v.name.$touch()
    if (this.name.trim() === this.productPackageData?.name?.trim() || this.$v.name.$pending || this.$v.name.$error) {
      return
    }

    this.$apolloMutate<UpdateProductPackageMutation, UpdateProductPackageMutationVariables>({
      mutation: UpdateProductPackageDocument,
      variables: {
        productPackageId: this.currentProductPackageId,
        name: this.name
      },
      refetchQueries: [
        {
          query: ProductPackageViewDataDocument,
          variables: {
            language: this.lang,
            productPackageId: this.currentProductPackageId
          }
        }
      ],
      error: 'E4160'
    }).then((result) => {
      if (result !== false) this.$v.$reset()
    })
  }
}
