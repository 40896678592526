import gql from 'graphql-tag'

export const GET_PROJECT_PRODUCT_PACKAGES = gql`
  query ProjectProductPackagesQuery($projectId: UUID) {
    productPackages {
      id
      name
      usedInProjects(where: { projectId: { eq: $projectId } }) {
        project {
          id
          name
          rooms {
            id
            name
            designPackages {
              id
              name
              productPackage {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`
