
import {
  CustomOfferAppointmentType,
  CustomOfferRequestStatus,
  ProjectFilterInput,
  RealEstateStatus
} from '@/__generated__/globalTypes'
import ProjectRealEstatesSelector, {
  SelectedProjectRealEstate
} from '@/components/projectRealEstatesSelector/ProjectRealEstatesSelector.vue'
import QUERIES from '@/queries/queries'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ProjectRealEstateItemFragment } from '../projectRealEstatesSelector/gql/__generated__/getProjectsAndRealEstates.query'
import {
  RequestForCustomOfferDocument,
  RequestForCustomOfferMutation,
  RequestForCustomOfferMutationVariables
} from './gql/__generated__/requestForCustomOffer.mutation'

@Component({
  components: {
    ProjectRealEstatesSelector
  }
})
export default class NewCustomOfferRequestDialog extends Vue {
  @Prop({ default: false }) show!: boolean

  MESSAGE_MAX_LENGTH = 500

  isLoading = false
  selectedProjectRealEstate: SelectedProjectRealEstate = {
    projectId: null,
    realEstateId: null
  }
  selectedType: CustomOfferAppointmentType | null = null
  requestNotes = ''

  @Watch('show')
  reset() {
    if (!this.show) {
      this.isLoading = false
      this.selectedProjectRealEstate.projectId = null
      this.selectedProjectRealEstate.realEstateId = null
      this.selectedType = null
      this.requestNotes = ''
    }
  }

  get projectsQueryFilter(): ProjectFilterInput {
    return { isCustomOfferFeatureActive: { eq: true } }
  }

  get isValid() {
    return (
      !!this.selectedProjectRealEstate.projectId &&
      !!this.selectedProjectRealEstate.realEstateId &&
      !!this.selectedType &&
      this.requestNotes.length <= this.MESSAGE_MAX_LENGTH
    )
  }

  // Create a custom offer Request Appointment types List based on the translations
  get customOfferRequestGlobalAppointmentTypesTranslated() {
    return Object.values(CustomOfferAppointmentType).map((type) => ({
      text: this.$t(`global.customOfferGlobalAppointmentTypes.${type}`),
      value: type
    }))
  }

  realEstateFilter(realEstate: ProjectRealEstateItemFragment) {
    return (
      (!realEstate.customOfferRequest ||
        realEstate.customOfferRequest.requestStatus?.status === CustomOfferRequestStatus.Cancelled) &&
      (!realEstate.activeStatus ||
        ![RealEstateStatus.Completed, RealEstateStatus.SendDossier, RealEstateStatus.Unsold, RealEstateStatus.TechnicalDrawing, RealEstateStatus.NewBuyer].includes(
          realEstate.activeStatus.status
        ))
    )
  }

  createOfferRequest() {
    if (!this.isValid) return

    return this.$showConfirmationDialog({
      Code: 'C4026',

      Callback: () => {
        this.isLoading = true
        this.$hideConfirmationDialog()
        this.$apolloMutate<RequestForCustomOfferMutation, RequestForCustomOfferMutationVariables>({
          mutation: RequestForCustomOfferDocument,
          variables: {
            realEstateId: this.selectedProjectRealEstate.realEstateId,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            appointmentType: this.selectedType!,
            requestNotes: this.requestNotes
          },
          refetchQueries: [QUERIES.CustomOfferRequests],
          error: 'E4214'
        })
          .then(() => this.$emit('close'))
          .finally(() => (this.isLoading = false))
      }
    })
  }
}
