/* eslint-disable @typescript-eslint/no-explicit-any */
import { SortOperationKind } from '@/../__generated__/globalTypes'
import { DataOptions } from 'vuetify'

export enum FilterType {
  AND,
  OR
}

export type TableFilter = {
  keyPath: string
  value: string | Array<string> | null
  filterType?: 'eq' | 'neq' | 'contains' | 'in' | 'nin'
}

type RequestVariables = {
  skip: number
  take: number
  order: any
  filters?: any
}

export const defaultFooterProps = {
  itemsPerPageOptions: [5, 10, 20, 50]
}

const buildNestedOrderBys = (orderByKeyPaths: string[], orderByDirections: boolean[]) => {
  let combinedOrderBys = {} as any

  orderByKeyPaths.forEach((orderByKeyPath, index) => {
    const complexOrderBy = {} as any
    const pathArray = orderByKeyPath.split('.')

    pathArray.reduce((list, current, currentIndex) => {
      if (currentIndex < pathArray.length - 1) return (list[current] = {})
      return (list[current] = orderByDirections[index] ? SortOperationKind.DESC : SortOperationKind.ASC)
    }, complexOrderBy)

    combinedOrderBys = {
      ...combinedOrderBys,
      ...complexOrderBy
    }
  })

  return combinedOrderBys
}

const buildNestedFilters = (filters: TableFilter[][]) => {
  const complexFilterResult = { and: [] } as any

  // Filter out all the values that are empty strings
  const truthyFilters = filters.filter((nestedOrFilters) => nestedOrFilters.some((x) => x.value !== '' && x.value != null)) ?? []
  if (!truthyFilters.length) return null

  // Each array in the TableFilter[][] is a nested `or` filter that we then need to combine with `and` operators
  // Example: [[], [], []] => [[{} or {}] and [{} or {}] and [{} or {}]]
  truthyFilters.forEach((nestedOrFilters) => {
    if (!nestedOrFilters.length) return

    const complexOrFilterResult = { or: [] } as any

    nestedOrFilters.forEach((filter) => {
      if (!filter.value) {
        filter.value = ''
        return
      }

      const pathArray = filter.keyPath.split('.')
      const filterResult = {} as any

      pathArray.reduce((list, current, currentIndex) => {
        if (currentIndex < pathArray.length - 1) return (list[current] = list[current] ?? {})
        const filterType = filter.filterType ?? 'eq'
        return (list[current] = { [filterType]: filter.value })
      }, filterResult)

      complexOrFilterResult.or.push(filterResult)
    })

    complexFilterResult.and.push(complexOrFilterResult)
  })

  return complexFilterResult
}

export const buildRequestVariables = (tableOptions: DataOptions, filters: TableFilter[][] | null = null, totalItems?: number) => {
  // Always set the skip and take first
  const variables = {
    take: (tableOptions?.itemsPerPage === -1 ? totalItems : tableOptions?.itemsPerPage) ?? 10,
    skip: (tableOptions?.itemsPerPage ?? 0) * (tableOptions?.page ? tableOptions.page - 1 : 1)
  } as RequestVariables

  // Set order by if sortBy is provided in the table options
  if (tableOptions.sortBy?.length) variables.order = buildNestedOrderBys(tableOptions.sortBy, tableOptions.sortDesc)

  // Set filters if filters are provided in the table options
  if (filters?.length) variables.filters = buildNestedFilters(filters)

  return variables
}
