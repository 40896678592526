
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DetailsList extends Vue {
  @Prop() details!: unknown
  @Prop() translationPath!: string

  get detailsObject() {
    if (typeof this.details !== 'object') return null
    return this.details
  }
}
