export function toLocaleDateString(dateString: string, locale?: string) {
  if (!dateString) return null
  if (!locale) locale = 'nl-NL'
  return new Date(dateString).toLocaleDateString(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
}

export function toLocaleDateTimeString(dateString: string, locale?: string, includeWeek = true) {
  if (!dateString) return null
  if (!locale) locale = 'nl-NL'
  return new Date(dateString).toLocaleString(locale, {
    weekday: includeWeek ? 'long' : undefined,
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  })
}
