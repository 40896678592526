/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RequestChangesCustomOfferMutationVariables = Types.Exact<{
  customOfferRequestId: Types.Scalars['UUID'];
  changesNotes: Types.Scalars['String'];
}>;


export type RequestChangesCustomOfferMutation = { requestChangesCustomOffer: boolean };


export const RequestChangesCustomOfferDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RequestChangesCustomOffer"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"customOfferRequestId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"changesNotes"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requestChangesCustomOffer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"customOfferRequestId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"customOfferRequestId"}}},{"kind":"Argument","name":{"kind":"Name","value":"changesNotes"},"value":{"kind":"Variable","name":{"kind":"Name","value":"changesNotes"}}}]}]}}]} as unknown as DocumentNode<RequestChangesCustomOfferMutation, RequestChangesCustomOfferMutationVariables>;