
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ProductSetInfoKey } from '@/__generated__/globalTypes'
import { FieldInformation } from './types'

@Component
export default class PercentageField extends Vue {
  @Prop({ required: true }) readonly id!: string
  @Prop({ required: true }) readonly isOptional!: boolean
  @Prop({ required: true }) readonly productSetInfoKey!: ProductSetInfoKey
  @Prop({ required: true }) value!: FieldInformation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({ required: true }) v!: any
  @Prop({ required: true }) disabled!: boolean

  get displayValue() {
    return this.recalculateDisplayValue()
  }

  set displayValue(value: string | null) {
    value = value === null ? value : value.replace(',', '.')
    const displayVal = Number(value)
    if (isNaN(displayVal))
      this.$emit('input', {
        item1: this.id,
        item2: null,
        isOptional: this.isOptional,
        dataType: this.productSetInfoKey.dataType
      } as FieldInformation)

    // Make sure we save only the correct decimal places
    const emitVal = Number((displayVal * 100).toString().substring(0, 4)) / 10000

    this.$emit('input', {
      item1: this.id,
      item2: emitVal,
      isOptional: this.isOptional,
      dataType: this.productSetInfoKey.dataType
    } as FieldInformation)
  }

  recalculateDisplayValue() {
    const modelVal = Number(this.v.item2)
    if (isNaN(modelVal)) return null

    // limmit the value to 2 decimal places when displaying
    return (Math.round(modelVal * 100 * 1000) / 1000).toString()
  }

  get getErrors() {
    const errors = new Array<string>()
    if (!this.v.item2.$dirty) {
      return errors
    }
    if (!this.v.item2.required) {
      errors.push(`${this.getLabelText()} ${this.$t('global.validationRequired')}`)
    }
    if (!this.v.item2.percentage) {
      errors.push(`${this.getLabelText()} ${this.$t('global.validationPercentage')}`)
    }
    return errors
  }

  validates() {
    this.v.item2.$touch()
  }

  getLabelText() {
    return this.$t(`projectDesignPackage.labels.restrictionKeys.${this.productSetInfoKey.key}`)
  }
}
