/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateCustomOfferShareMutationVariables = Types.Exact<{
  customOfferId: Types.Scalars['UUID'];
  isShared: Types.Scalars['Boolean'];
}>;


export type UpdateCustomOfferShareMutation = { updateCustomOfferShared: { id: any } };


export const UpdateCustomOfferShareDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateCustomOfferShare"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"customOfferId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"isShared"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateCustomOfferShared"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"customOfferId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"customOfferId"}}},{"kind":"Argument","name":{"kind":"Name","value":"isShared"},"value":{"kind":"Variable","name":{"kind":"Name","value":"isShared"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdateCustomOfferShareMutation, UpdateCustomOfferShareMutationVariables>;