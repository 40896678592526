
import { CustomOffer } from '@/__generated__/globalTypes'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  ResolveSharedWaitingCustomOfferDocument,
  ResolveSharedWaitingCustomOfferMutation,
  ResolveSharedWaitingCustomOfferMutationVariables
} from './__generated__/resolveSharedWaitingCustomOffer.mutation'

@Component
export default class CustomOfferFollowUpDialog extends Vue {
  @Prop({ default: false }) show!: boolean
  @Prop({ default: null }) customOffer!: CustomOffer | null

  MESSAGE_MAX_LENGTH = 500
  actionContent = ''

  isLoading = false

  @Watch('show')
  onVisibilityChange() {
    if (!this.show) {
      this.actionContent = ''

      this.isLoading = false
    }
  }

  get actionContentErrorMessages() {
    const errors = []

    if (this.actionContent.length > this.MESSAGE_MAX_LENGTH) {
      errors.push(this.$t('global.forms.textarea.maxLength').toString())
    }

    return errors
  }

  get hasErrors() {
    return !!this.actionContentErrorMessages.length
  }

  async resolveAction() {
    try {
      this.isLoading = true
      await this.$apolloMutate<
        ResolveSharedWaitingCustomOfferMutation,
        ResolveSharedWaitingCustomOfferMutationVariables
      >({
        mutation: ResolveSharedWaitingCustomOfferDocument,
        variables: {
          customOfferRequestId: this.customOffer?.id,
          notes: this.actionContent
        }
      })
      this.$emit('refetch-list')
    } finally {
      this.isLoading = false
      this.$emit('close')
    }
  }
}
