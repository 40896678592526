/* eslint-disable @typescript-eslint/no-explicit-any */
import { helpers, ValidationRule } from 'vuelidate/lib/validators'

// The following regex matches all strings containing only alphaNumeric characters or spaces,
// as long as the spaces are not at the start or end of the string.
export function alphaNumSpace(): ValidationRule {
  return helpers.regex('alphaNumSpace', /^[a-zA-Z0-9][a-zA-Z0-9 ]*[a-zA-Z0-9]$/)
}

// The following regex matches any number that either omits a decimal sign and decimal digits,
// or ends with one decimal sign (either comma or dot) followed by one or two digits.
export function money(): ValidationRule {
  return helpers.regex('money', /^[0-9]{1,6}([,.][0-9]{1,2})?$/)
}

// The following regex matches any number that either omits a decimal sign and decimal digits,
// or ends with one decimal sign (either comma or dot) followed by one or two digits.
export function moneyIf(x: any, a: any) {
  if (a.dataType === 'NUMBER' && a.item2 !== '') {
    const regex: any = /^[0-9]{1,6}([,.][0-9]{1,2})?$/
    return regex.test(a.item2)
  }
  return true
}

// The following regex matches any number that either omits a decimal sign and decimal digits,
// or ends with one decimal sign (either comma or dot) followed by one or two digits.
export function lengthMeasureIf(x: any, a: any): boolean {
  if (a.dataType === 'LENGTH_MEASUREMENT' && a.item2 !== '') {
    const valNumber = Number(a.item2?.replace(',', '.'))
    if (!(valNumber >= 0 && valNumber <= 1000000)) return false
    const regex: any = /^[0-9]{1,8}([,.][0-9]{1,2})?$/
    return regex.test(a.item2)
  }
  return true
}

// The following regex matches any number that either omits a decimal sign and decimal digits,
// or ends with one decimal sign (either comma or dot) followed by one or two digits.
export function areaMeasureIf(x: any, a: any): boolean {
  if (a.dataType === 'AREA_MEASUREMENT' && a.item2 !== '') {
    const valNumber = Number(a.item2?.replace(',', '.'))
    if (!(valNumber >= 0 && valNumber <= 1000000000)) return false
    const regex: any = /^[0-9]{1,9}([,.][0-9]{1,2})?$/
    return regex.test(a.item2)
  }
  return true
}

export function selectedCombination(x: any) {
  return x.some((y: { isSelected: boolean }) => y.isSelected === true)
}

export function isOptionalButMandatoryCategory(productCategoryId: string | undefined | null): boolean {
  return productCategoryId !== 'ea050f8c-b1a1-497f-9a49-af7687ea5bde' // This is the identifier for ShowerFloor ProductCategory
}
