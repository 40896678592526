
import QUERIES from '@/queries/queries'
import { ProjectViewDocument } from '@/views/projectView/gql/__generated__/projectView.query'
import { ApolloError } from 'apollo-client'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { GetProjectDetailsDocument } from './gql/__generated__/getProjectDetails.query'
import { ENRICH_PROJECT, GET_PROJECT_LIST_DIALOG } from './projectListQueries'
import { EnrichProject, EnrichProjectVariables } from './__generated__/EnrichProject'
import { ProjectListDialogQuery, ProjectListDialogQuery_projects_items } from './__generated__/ProjectListDialogQuery'

interface ProjectListItem {
  id: string
  name: string
  layouts: Layout[]
}

type Layout = {
  id: string
  name: string
}

@Component
export default class ProjectListDialogComponent extends Vue {
  @Prop() projectId!: string
  @Prop() show!: boolean
  @Prop() layoutId!: string
  projectList: ProjectListDialogQuery_projects_items[] = []
  showInternal = false
  selectedProjectId = ''
  layouts: Layout[] = []

  @Watch('show')
  onShowChanged() {
    this.showInternal = this.show
  }

  created() {
    this.fetchData()
  }

  fetchData() {
    this.$apollo.addSmartQuery<ProjectListDialogQuery>(QUERIES.ProjectListDialogQuery, {
      query: GET_PROJECT_LIST_DIALOG,
      result: (result) => {
        this.projectList =
          (result.data.projects?.items?.filter((x) => !!x) as ProjectListDialogQuery_projects_items[]) ?? []
      },
      update: (data) => data.projects,
      error: (error: ApolloError) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4106',
          Message: error,
          Sub: 'A'
        })
      }
    })
  }

  get isShow() {
    return this.showInternal
  }

  get projects(): ProjectListItem[] {
    return this.projectList.sort((a, b) => a.name.localeCompare(b.name))
  }

  get projectWasSelected(): boolean {
    return this.selectedProjectId !== ''
  }

  selectProject(selectedProject: ProjectListItem, event: Event) {
    event.preventDefault()

    this.selectedProjectId = selectedProject.id
    this.layouts = selectedProject.layouts
  }

  copyFromLayoutToLayout(selectedLayoutId: string, event: Event) {
    event.preventDefault()

    this.$apolloMutate<EnrichProject, EnrichProjectVariables>({
      mutation: ENRICH_PROJECT,
      variables: {
        projectToCopyTowardsId: this.projectId,
        projectToCopyFromId: this.selectedProjectId,
        layoutToCopyTowardsId: this.layoutId,
        layoutToCopyFromId: selectedLayoutId
      },
      refetchQueries: [
        {
          query: GetProjectDetailsDocument,
          variables: { projectId: this.projectId }
        },
        QUERIES.ProjectRoomsQuery,
        {
          query: ProjectViewDocument,
          variables: { projectId: this.projectId }
        },
        QUERIES.ProjectStylesQuery,
        QUERIES.DesignPackagesForProjectQuery
      ]
    }).then((result) => {
      if (result !== false) this.clickClose()
    })
  }

  clickClose() {
    this.showInternal = false
    this.selectedProjectId = ''
    this.$emit('close')
  }
}
