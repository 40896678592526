
import { Component, Vue } from 'vue-property-decorator'
import HeaderComponent from '@/components/HeaderComponent.vue'
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue'
import ErrorDialog from '@/components/dialogs/ErrorDialog.vue'
import NotificationDialog from '@/components/dialogs/NotificationDialog.vue'
import ToastManager from '@/components/toast/ToastManager.vue'
import { RouteNames } from './router/routeNames'
import {
  MasterProductPackageDocument,
  MasterProductPackageQuery,
  MasterProductPackageQueryVariables
} from '@/sharedQueries/gql/__generated__/getMasterProductPackage.query'

@Component({
  components: {
    HeaderComponent,
    ConfirmationDialog,
    ErrorDialog,
    NotificationDialog,
    ToastManager
  }
})
export default class App extends Vue {
  get showRedirectToLoginInfo() {
    return (
      !this.$store.getters.oidcIsAuthenticated &&
      this.$route.name !== RouteNames.LOGOUT &&
      this.$route.name !== RouteNames.CYPRESSLOGIN &&
      !this.$store.state.cypressToken
    )
  }

  updated() {
    if (!this.$store.state.masterProductPackageId && this.$store.getters.accessToken != null) {
      this.fetchMasterProductPackageInfo()
    }
  }

  fetchMasterProductPackageInfo() {
    this.$apollo
      .query<MasterProductPackageQuery, MasterProductPackageQueryVariables>({
        query: MasterProductPackageDocument
      })
      .then((result) => {
        this.$store.commit('setMasterProductPackageId', result?.data?.masterProductPackage?.id)
      })
  }
}
