
import { Category, ProductPackageSet } from '@/__generated__/globalTypes'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { CategoryDefaultProductSets, GuidEmpty } from './types'

@Component({
  name: 'DefaultProductSet',
  mixins: [validationMixin]
})
export default class DefaultProductSet extends Vue {
  @Prop({ required: true }) categoryCMS!: Category | undefined
  @Prop({ required: true }) isBasicInRoom!: boolean
  @Prop({ required: true }) assignedDefaultProductSetsList!: ProductPackageSet[]
  @Prop({ required: true }) value!: CategoryDefaultProductSets
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({ required: true }) v!: any
  @Prop({ required: true }) disabled!: boolean
  @Prop({ required: true }) selectedDefaultProductSetIds!: string[]
  @Prop({ required: true }) noProductProductSetId!: string

  get selectedProductSets() {
    if (this.isBasicInRoom) {
      let selectedList =
        this.assignedDefaultProductSets.filter((x) =>
          this.selectedDefaultProductSetIds?.filter((y) => y != GuidEmpty)?.includes(x.value)
        ) ?? []

      if (!selectedList.length) {
        selectedList = this.assignedDefaultProductSets.filter((x) => x.value === GuidEmpty) ?? []
      }
      return selectedList
    }
    return this.assignedDefaultProductSets.filter((x) => x.value === this.noProductProductSetId)
  }

  get getProductSetIdErrors() {
    const errors = new Array<string>()
    if (!this.v.productSetIds.$dirty) {
      return errors
    }
    if (!this.v.productSetIds.required) {
      errors.push(`${this.categoryCMS?.title} ${this.$t('global.isRequired').toString().toLowerCase()}.`)
    }

    return errors
  }

  getDefaultProductSetsTitle() {
    return `${this.categoryCMS?.name} ${this.$t('global.standard').toString().toLowerCase()}`
  }

  get assignedDefaultProductSets() {
    if (!this.assignedDefaultProductSetsList?.length) return []
    return this.assignedDefaultProductSetsList.map((x) => ({ text: x.productSet?.name, value: x.productSet.id }))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDropDownValueChange(inputValue: any) {
    this.v.productSetIds.$touch()
    this.$emit('input', {
      productCategoryId: this.value.productCategoryId,
      productSetIds: inputValue,
      setAsProjectDefault: inputValue ? this.value.setAsProjectDefault : false
    } as CategoryDefaultProductSets)
  }

  get isDisabled() {
    return !this.isBasicInRoom
  }
}
