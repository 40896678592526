import gql from 'graphql-tag'

export const UPDATE_PROJECT_COST_SETTINGS = gql`
  mutation UpdateProjectCostSettings(
    $projectId: UUID!
    $companyMarginSanitary: String
    $companyMarginTiles: String
    $correctionInstallationWFactor: String
    $correctionInstallationEFactor: String
    $correctionInstallationCVFactor: String
    $correctionTilerFactor: String
    $marginBuilder: String
    $marginInstaller: String
    $marginTiler: String
  ) {
    updateProjectCostSettings(
      projectId: $projectId
      companyMarginSanitary: $companyMarginSanitary
      companyMarginTiles: $companyMarginTiles
      correctionInstallationWFactor: $correctionInstallationWFactor
      correctionInstallationEFactor: $correctionInstallationEFactor
      correctionInstallationCVFactor: $correctionInstallationCVFactor
      correctionTilerFactor: $correctionTilerFactor
      marginBuilder: $marginBuilder
      marginInstaller: $marginInstaller
      marginTiler: $marginTiler
    )
  }
`

export const TOGGLE_CUSTOM_OFFER_SETTING = gql`
  mutation CheckCustomOfferForProject($projectId: UUID!, $isActive: Boolean!) {
    checkCustomOfferForProject(projectId: $projectId, isActive: $isActive)
  }
`

export const TOGGLE_USE_RETURNPRICECALCULATION_SETTING = gql`
  mutation CheckUseReturnPriceCalculationForProject($projectId: UUID!, $isActive: Boolean!) {
    checkUseReturnPriceCalculationForProject(projectId: $projectId, isActive: $isActive)
  }
`