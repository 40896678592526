
import AssignDrawerDialog from '@/components/assignDrawerDialog/AssignDrawerDialog.vue'
import { DrawingTypes } from '@/components/assignDrawerDialog/types'
import {
  RensaUsersDocument,
  RensaUsersFragment,
  RensaUsersQuery,
  RensaUsersQueryVariables
} from '@/views/customOfferListView/gql/__generated__/rensaUsers.query'
import { Component, Vue } from 'vue-property-decorator'
import { DataOptions, DataTableHeader } from 'vuetify'
import {
  DrawingRequestStatus,
  RealEstateStatus,
  RequestForTechnicalDrawing,
  SelectionType,
  UsersGroupFilterType
} from '../../__generated__/globalTypes'
import DebounceMixin, { debounceDelay } from '../../mixins/debounceMixin'
import QUERIES from '../../queries/queries'
import { RouteNames } from '../../router/routeNames'
import { toLocaleDateTimeString } from '../../utils/dateUtils'
import { TableFilter, buildRequestVariables, defaultFooterProps } from '../../utils/tableUtils'
import TechnicalDrawingUploadPopup from './TechnicalDrawingUploadPopup.vue'
import {
  CancelTechnicalDrawingDocument,
  CancelTechnicalDrawingMutation,
  CancelTechnicalDrawingMutationVariables
} from './gql/__generated__/cancelTechnicalDrawing.mutation'
import {
  RequestForTechicalDrawingFragment,
  RequestForTechnicalDrawingsDocument,
  RequestForTechnicalDrawingsQuery
} from './gql/__generated__/technicalDrawings.query'

@Component({
  components: {
    TechnicalDrawingUploadPopup,
    AssignDrawerDialog
  },
  mixins: [DebounceMixin]
})
export default class TechnicalDrawingsView extends Vue {
  search = ''
  technicalDrawingSpecialists: RensaUsersFragment[] = []

  assignedDrawerIdFilter = ''

  userFilters: TableFilter[] = [
    {
      keyPath: 'realEstate.project.name',
      value: '',
      filterType: 'contains'
    },
    {
      keyPath: 'realEstate.buildNumber',
      value: '',
      filterType: 'contains'
    },
    {
      keyPath: 'realEstate.contact.legalName',
      value: '',
      filterType: 'contains'
    }
  ]
  defaultFilterAssignDrawerId: TableFilter[] = [
    {
      keyPath: 'assignedDrawerId',
      value: ''
    }
  ]
  statusFilters: TableFilter[] = [
    {
      keyPath: 'requestTechnicalDrawingStatus',
      value: ''
    }
  ]
  closedStatusFilters: TableFilter[] = [
    {
      keyPath: 'requestTechnicalDrawingStatus',
      value: [DrawingRequestStatus.Cancelled, DrawingRequestStatus.Ready],
      filterType: 'nin'
    }
  ]
  hideClosedDrawings = true
  technicalDrawingsRequestData: RequestForTechicalDrawingFragment[] = []
  totalCount = 0
  tableOptions: DataOptions = {} as DataOptions
  selectedDrawing: RequestForTechnicalDrawing | null = null
  showTechnicalDrawingUploadDialog = false
  showAssignDrawerDialog = false
  changeSearch: ((value: string) => void) | null = null
  selectedDrawingStatus: string = DrawingRequestStatus.Requested
  DAY_IN_MILLISECONDS = 86400000
  isDownloading = false
  initialLoad = false

  formatDate = toLocaleDateTimeString
  defaultFooterProps = defaultFooterProps
  DrawingRequestStatus = DrawingRequestStatus
  DrawingTypes = DrawingTypes
  RealEstateStatus = RealEstateStatus
  RouteNames = RouteNames

  created() {
    this.initialLoad = true
    this.$apollo.addSmartQuery<RequestForTechnicalDrawingsQuery>(QUERIES.RequestForTechnicalDrawing, {
      query: RequestForTechnicalDrawingsDocument,
      variables: () => this.drawingRequestTableVariables,
      fetchPolicy: 'network-only',
      update: (data) => data,
      pollInterval: 60000,
      result: (result) => {
        this.totalCount = result.data?.requestsForTechnicalDrawings?.totalCount ?? 0
        this.technicalDrawingsRequestData = result.data?.requestsForTechnicalDrawings?.items ?? []
        this.initialLoad = false
      },
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4226',
          Message: error
        })
        this.initialLoad = false
      }
    })

    this.$apollo.addSmartQuery<RensaUsersQuery, RensaUsersQueryVariables>(QUERIES.RensaUsers, {
      query: RensaUsersDocument,
      fetchPolicy: 'network-only',
      update: (data) => data,
      variables: {
        searchTerm: '',
        filterType: UsersGroupFilterType.Draftsman
      },
      result: (result) => {
        this.technicalDrawingSpecialists = result.data?.rensaUsers ?? []
      },
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4227',
          Message: error
        })
      }
    })
  }

  mounted() {
    this.changeSearch = this.debounce(() => {
      // Resets to the first page
      this.tableOptions.page = 1

      // Set the search string in all the filters
      Object.values(this.userFilters).forEach((filter) => {
        filter.value = this.search
      })
    }, debounceDelay)
  }

  get headers(): DataTableHeader[] {
    return [
      {
        text: this.$t('technicalDrawings.list.headers.requested').toString(),
        value: 'requested',
        width: '15%'
      },
      {
        text: this.$t('technicalDrawings.list.headers.project').toString(),
        value: 'realEstate.project.name',
        width: '15%'
      },
      {
        text: this.$t('technicalDrawings.list.headers.buildNr').toString(),
        value: 'realEstate.buildNumber',
        width: '10%'
      },
      {
        text: this.$t('technicalDrawings.list.headers.room').toString(),
        value: 'realEstate.layout.layoutRooms',
        sortable: false,
        width: '15%'
      },
      {
        text: this.$t('technicalDrawings.list.headers.contact').toString(),
        value: 'realEstate.contact.legalName',
        width: '10%'
      },
      {
        text: this.$t('technicalDrawings.list.headers.drawer').toString(),
        value: 'assignedDrawer.displayName',
        sortable: false,
        width: '10%'
      },
      {
        text: this.$t('technicalDrawings.list.headers.email').toString(),
        value: 'realEstate.contact.email.address',
        width: '9%'
      },
      {
        text: this.$t('technicalDrawings.list.headers.deadline').toString(),
        value: 'deadline',
        width: '9%'
      },
      {
        text: this.$t('technicalDrawings.list.headers.status').toString(),
        value: 'requestTechnicalDrawingStatus',
        width: '7%'
      },
      { text: '', value: 'actions', sortable: false, width: '0%' }
    ]
  }

  get drawingRequestTableVariables() {
    const filterList = [this.userFilters, this.defaultFilterAssignDrawerId, this.statusFilters]
    if (this.hideClosedDrawings) filterList.push(this.closedStatusFilters)
    return buildRequestVariables(this.tableOptions, filterList)
  }

  get drawingRequestStatusListTranslated() {
    return Object.values(DrawingRequestStatus)
      .filter((status) => status !== DrawingRequestStatus.NotRequested)
      .map((status) => ({
        text: this.$t(`technicalDrawings.status.${status}`).toString(),
        value: status,
        disabled:
          this.hideClosedDrawings &&
          status &&
          [DrawingRequestStatus.Cancelled, DrawingRequestStatus.Ready].includes(status)
      }))
  }

  get language() {
    return this.$i18n.locale
  }

  uploadTechnicalDrawing(item: RequestForTechnicalDrawing) {
    this.selectedDrawing = item
    this.showTechnicalDrawingUploadDialog = true
  }

  clearStatusFilter() {
    if (
      this.hideClosedDrawings &&
      (this.statusFilters[0]?.value === DrawingRequestStatus.Cancelled ||
        this.statusFilters[0]?.value === DrawingRequestStatus.Ready)
    ) {
      this.statusFilters[0].value = ''
    }
  }

  assignDrawer(item: RequestForTechnicalDrawing) {
    this.selectedDrawing = item
    this.showAssignDrawerDialog = true
  }

  closeAssignDrawerDialog() {
    this.showAssignDrawerDialog = false
    this.selectedDrawing = null
  }

  onAssignDrawer() {
    this.closeAssignDrawerDialog()
    this.$apollo.queries[QUERIES.RequestForTechnicalDrawing].refetch()
  }

  closeTechnicalDrawingDialog() {
    this.showTechnicalDrawingUploadDialog = false
    this.selectedDrawing = null
  }

  onUploadTechnicalDrawing() {
    this.closeTechnicalDrawingDialog()
    this.$apollo.queries[QUERIES.RequestForTechnicalDrawing].refetch()
  }

  isRequestCancellable(request: RequestForTechicalDrawingFragment) {
    // TODO: Must be changed when custom offer are no longer only grouped type ( when we can have multiple/different per room )
    return (
      request.requestTechnicalDrawingStatus !== DrawingRequestStatus.Ready &&
      request.requestTechnicalDrawingStatus !== DrawingRequestStatus.Cancelled
    )
  }

  getStatusColor(request: RequestForTechicalDrawingFragment) {
    switch (request.requestTechnicalDrawingStatus) {
      case DrawingRequestStatus.Ready:
        return 'green'
      case DrawingRequestStatus.Cancelled:
        return 'red'
      case DrawingRequestStatus.Requested:
        return this.isRequestCancellable(request) ? 'orange darken-2' : 'yellow darken-2'
      case DrawingRequestStatus.InProgress:
        return 'yellow darken-2'
      default:
        return 'grey'
    }
  }

  getStatusTranslation(request: RequestForTechicalDrawingFragment) {
    const statusKey =
      request.requestTechnicalDrawingStatus === DrawingRequestStatus.Requested &&
      this.isRequestCancellable(request) &&
      request?.realEstate?.requestForQuotes?.[0]?.selectionType === SelectionType.GlobalCustom
        ? 'REQUESTED_AND_CANCELABLE'
        : request.requestTechnicalDrawingStatus

    return this.$t(`technicalDrawings.status.${statusKey}`)
  }

  getStatusIcon(request: RequestForTechicalDrawingFragment) {
    switch (request.requestTechnicalDrawingStatus) {
      case DrawingRequestStatus.Ready:
        return 'mdi-check-circle-outline'
      case DrawingRequestStatus.Cancelled:
        return 'mdi-close-circle-outline'
      case DrawingRequestStatus.Requested:
        return this.isRequestCancellable(request) ? 'mdi-clock-alert-outline' : 'mdi-progress-clock'
      case DrawingRequestStatus.InProgress:
        return 'mdi-progress-clock'
      default:
        return 'mdi-progress-clock'
    }
  }

  downloadTechnicalDrawing(item: RequestForTechnicalDrawing) {
    this.isDownloading = true

    this.$fileApi
      .downloadFile(`/technicaldrawing/${item.id}`, 'Technical Drawing')

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        if (error.toString().includes("Unexpected token 'T'")) {
          this.$store.dispatch('showErrorDialog', {
            Code: 'E4235'
          })
        } else {
          this.$store.dispatch('showErrorDialog', {
            Code: 'E4229',
            Message: error
          })
        }
      })
      .finally(() => {
        this.isDownloading = false
      })
  }

  onDefaultFilterUpdate() {
    this.tableOptions.page = 1
  }

  cancelTechnicalDrawingRequest(item: RequestForTechnicalDrawing) {
    if (
      item?.realEstate?.requestForQuotes?.[0]?.selectionType === SelectionType.GlobalCustom ||
      item?.realEstate?.requestForQuotes?.find((x) => x.selectionType === SelectionType.Custom)
    ) {
      this.$store.dispatch('showConfirmationDialog', {
        awaitConfirm: true,
        HasTextArea: true,
        Callback: async () => await this.confirmCancelTechnicalDrawingRequest(item),
        Code: 'C4027'
      })
    } else {
      this.$store.dispatch('showErrorDialog', {
        Code: 'E1104'
      })
    }
  }

  async confirmCancelTechnicalDrawingRequest(item: RequestForTechnicalDrawing) {
    this.isDownloading = true
    try {
      await this.$apolloMutate<CancelTechnicalDrawingMutation, CancelTechnicalDrawingMutationVariables>({
        mutation: CancelTechnicalDrawingDocument,
        refetchQueries: [
          {
            query: RequestForTechnicalDrawingsDocument,
            variables: this.drawingRequestTableVariables
          }
        ],
        variables: {
          requestTechnicalDrawingId: item.id,
          notes: this.$store.state.confirmationDialog.Notes
        },
        error: (err) => {
          const error = err.error?.graphQLErrors?.[0] ?? err.error?.networkError?.result?.errors?.[0]
          return {
            Code: 'E4210',
            Message: error?.extensions?.message ?? err.error,
            Callback: err.Callback
          }
        }
      })
    } finally {
      this.$store.dispatch('hideConfirmationDialog')
      this.isDownloading = false
    }
  }
}
