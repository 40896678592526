/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateArticlePriceMutationVariables = Types.Exact<{
  projectId: Types.Scalars['UUID'];
  productId: Types.Scalars['UUID'];
  productCombinationId: Types.Scalars['UUID'];
  returnPrice: Types.Scalars['String'];
}>;


export type UpdateArticlePriceMutation = { updateProductReturnPrice: boolean };


export const UpdateArticlePriceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateArticlePrice"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"projectId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"productId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"productCombinationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"returnPrice"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateProductReturnPrice"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"projectId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"projectId"}}},{"kind":"Argument","name":{"kind":"Name","value":"productId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"productId"}}},{"kind":"Argument","name":{"kind":"Name","value":"productCombinationId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"productCombinationId"}}},{"kind":"Argument","name":{"kind":"Name","value":"returnPrice"},"value":{"kind":"Variable","name":{"kind":"Name","value":"returnPrice"}}}]}]}}]} as unknown as DocumentNode<UpdateArticlePriceMutation, UpdateArticlePriceMutationVariables>;