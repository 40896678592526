
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { ValidationProperties } from 'vue/types/vue'
import { Validation } from 'vuelidate'

type MarginValidations = {
  required?: boolean
  decimal?: boolean
  percentagePositive?: boolean
  percentageAllowNegative?: boolean
}

@Component
export default class ProjectDetailsMarginField extends Vue {
  @Prop() v!: Validation & ValidationProperties<MarginValidations>
  @Prop({ default: false }) disabled!: boolean

  @Ref('vtextfield') vTextField!: Vue

  currentRealValue: number | null = null

  created() {
    this.currentRealValue = this.v.$model
  }

  get prefix() {
    return this.v.percentagePositive === undefined ? '+/-' : '+'
  }

  get errors() {
    const errors = new Array<string>()
    if (!this.v.$dirty) {
      return errors
    }
    if (!this.v.required || !this.v.decimal) {
      if (this.v.percentagePositive) {
        errors.push(this.$t('projectDetails.errors.percentagePositive').toString())
      }
      if (this.v.percentageAllowNegative) {
        errors.push(this.$t('projectDetails.errors.percentageAllowNegative').toString())
      }
    }
    if (this.v.percentagePositive !== undefined && !this.v.percentagePositive) {
      errors.push(this.$t('projectDetails.errors.percentagePositive').toString())
    }
    if (this.v.percentageAllowNegative !== undefined && !this.v.percentageAllowNegative) {
      errors.push(this.$t('projectDetails.errors.percentageAllowNegative').toString())
    }
    return errors
  }

  onBlur() {
    this.v.$touch()
    if (this.currentRealValue === this.v.$model || this.v.$pending || this.v.$error) return

    this.currentRealValue = this.v.$model
    this.$emit('blur')
  }

  onFocus() {
    const input = this.vTextField.$refs.input as HTMLInputElement
    input.setSelectionRange(0, input.value.length + 1)
  }
}
