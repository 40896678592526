
import { validationMixin } from 'vuelidate'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { required, maxLength } from 'vuelidate/lib/validators'
import QUERIES from '@/queries/queries'
import { ProjectTypeEnum } from '@/__generated__/globalTypes'
import {
  CreateProductPackageMutation,
  CreateProductPackageMutationVariables,
  CreateProductPackageDocument
} from '../projectProductPackages/gql/__generated__/createProductPackage.mutation'

@Component({
  mixins: [validationMixin]
})
export default class AddStyleComponent extends Vue {
  @Prop({ default: false }) show!: boolean
  @Prop({ default: () => [] }) existingStyles!: string[]

  name = ''
  isDisabled = false

  clickClose() {
    this.$v.$reset()
    this.name = ''
    this.$emit('close')
  }

  clickSave() {
    this.$v.$touch()
    if (this.$v.name.$pending || this.$v.name.$error) return
    this.isDisabled = true
    this.$apolloMutate<CreateProductPackageMutation, CreateProductPackageMutationVariables>({
      mutation: CreateProductPackageDocument,
      variables: {
        productPackageName: this.name,
        productPackageType: ProjectTypeEnum.DeBadkamerwerelden
      },
      refetchQueries: [QUERIES.StylesViewQuery],
      error: 'E4111'
    }).then((result) => {
      this.isDisabled = false
      if (result !== false) this.clickClose()
    })
  }

  validations() {
    return {
      name: {
        required,
        maxLength: maxLength(50),
        isUnique: (value: string) => {
          return !this.existingStyles.includes(value)
        }
      }
    }
  }

  get nameErrors() {
    const errors = new Array<string>()
    if (!this.$v.name.$dirty) {
      return errors
    }
    if (!this.$v.name.isUnique) {
      errors.push(this.$t('style.create.errors.name.isUnique').toString())
    }
    if (!this.$v.name.maxLength) {
      errors.push(this.$t('style.create.errors.name.maxLength').toString())
    }
    if (!this.$v.name.required) {
      errors.push(this.$t('style.create.errors.name.required').toString())
    }
    return errors
  }
}
