
import { MessageDto } from '@/__generated__/globalTypes'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MessageThread extends Vue {
  @Prop({ required: true }) messages!: MessageDto[]

  get language() {
    return this.$i18n.locale
  }

  getMessageDateAndSender(msg: MessageDto) {
    return `[${new Date(Date.parse(msg.dateCreated)).toLocaleDateString(this.language, {
      dateStyle: 'full'
    })}] ${msg.sender}:`
  }
}
