
import { validationMixin } from 'vuelidate'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { required, maxLength } from 'vuelidate/lib/validators'
import {
  CopyProductSetDocument,
  CopyProductSetMutation,
  CopyProductSetMutationVariables
} from './gql/__generated__/copyProductSet.mutation'
import { ProductPackageViewDataDocument } from '@/views/productPackageView/gql/__generated__/getProductPackageViewData.query'

@Component({
  mixins: [validationMixin]
})
export default class ProductSetCopyModal extends Vue {
  @Prop({ default: false }) show!: boolean
  @Prop() readonly productSetId!: string
  @Prop() readonly productCombinationId!: string
  @Prop() readonly productPackageId!: string
  @Prop() productSetName!: string

  loading = false
  name = ''

  @Watch('productSetName', { immediate: true })
  onProductSetNameChange() {
    this.name = this.productSetName
  }

  get language() {
    return window.localStorage.getItem('language')
  }

  clickClose(created = false) {
    this.$v.$reset()
    this.name = ''
    this.$emit('close', created)
  }

  clickSave() {
    this.$v.$touch()
    if (this.$v.name.$pending || this.$v.name.$error) return

    this.loading = true
    this.$apolloMutate<CopyProductSetMutation, CopyProductSetMutationVariables>({
      mutation: CopyProductSetDocument,
      variables: {
        productSetId: this.productSetId,
        productPackageId: this.productPackageId,
        productCombinationId: this.productCombinationId,
        name: this.name
      },
      refetchQueries: [
        {
          query: ProductPackageViewDataDocument,
          variables: {
            language: this.language,
            productPackageId: this.productPackageId
          }
        }
      ],
      error: 'E4173'
    })
      .then((result) => {
        if (result !== false) this.clickClose(true)
      })
      .finally(() => (this.loading = false))
  }

  validations() {
    return {
      name: {
        required,
        maxLength: maxLength(150)
      }
    }
  }

  get nameErrors() {
    const errors = new Array<string>()
    if (!this.$v.name.$dirty) {
      return errors
    }
    if (!this.$v.name.maxLength) {
      errors.push(this.$t('productPackage.edit.category.productSets.copy.errors.name.maxLength').toString())
    }
    if (!this.$v.name.required) {
      errors.push(this.$t('productPackage.edit.category.productSets.copy.errors.name.required').toString())
    }
    return errors
  }
}
