/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateCustomOfferRequestMutationVariables = Types.Exact<{
  customOfferRequestId: Types.Scalars['UUID'];
  appointmentType: Types.CustomOfferAppointmentType;
  assignedSpecialistId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateCustomOfferRequestMutation = { updateCustomOfferRequest: { id: any } };


export const UpdateCustomOfferRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateCustomOfferRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"customOfferRequestId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"appointmentType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CustomOfferAppointmentType"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assignedSpecialistId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateCustomOfferRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"customOfferRequestId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"customOfferRequestId"}}},{"kind":"Argument","name":{"kind":"Name","value":"appointmentType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"appointmentType"}}},{"kind":"Argument","name":{"kind":"Name","value":"assignedSpecialistId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assignedSpecialistId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdateCustomOfferRequestMutation, UpdateCustomOfferRequestMutationVariables>;