
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ValidationRule } from 'vuelidate/lib/validators'
import { KeyValuePairOfStringAndString } from '@/__generated__/globalTypes'

@Component
export default class EnumField extends Vue {
  @Prop() modelValue!: string | null
  @Prop({ default: [] }) rules?: ValidationRule[]
  @Prop({ default: '' }) label?: string
  @Prop({ required: true }) disabled!: boolean
  @Prop({ required: true }) possibleValues!: KeyValuePairOfStringAndString[]

  get select() {
    return this.possibleValues.find((x) => x.key == this.modelValue)
  }

  onValueChange(inputValue?: string | null) {
    if (inputValue === undefined) {
      inputValue = null
    }
    this.$emit('update:modelValue', inputValue)
  }
}
