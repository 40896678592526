import { VuexOidcClientSettings } from 'vuex-oidc'

export const oidcSettings: VuexOidcClientSettings = {
  authority: `https://login.microsoftonline.com/${process.env.VUE_APP_TENANT_ID}/v2.0`,
  clientId: process.env.VUE_APP_AUTH_CLIENT_ID ?? '',
  redirectUri: process.env.VUE_APP_AUTH_REDIRECT_URI ?? '',
  responseType: 'code',
  scope: process.env.VUE_APP_AUTH_SCOPE ?? '', 
  loadUserInfo: false,
  automaticSilentRenew: true,
  silentRedirectUri: process.env.VUE_APP_AUTH_REDIRECT_URI ?? ''
}
