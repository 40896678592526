import gql from 'graphql-tag'

export const GET_ROOM = (roomId: string) => {
  return gql`
    query RoomQuery {
    rooms (where: { id: { eq: "${roomId}" } }) {
      id
      name
      position
      layoutRooms {
        layout {
          id
          name
        }
      }
    }
  }`
}