
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ProductCombinationType } from '@/__generated__/globalTypes'
import QRCode from 'qrcode'
import { createCanvas, loadImage } from 'canvas'

@Component
export default class QrGenerator extends Vue {
  @Prop({ default: false }) show!: boolean
  @Prop({ default: '' }) productPackageId?: string
  @Prop({ default: '' }) productSetName?: string
  @Prop({ default: '' }) productSetId?: string
  @Prop({ default: ProductCombinationType.NotSet }) combinationType?: ProductCombinationType

  qrCodeImageSrc = ''
  loading = false

  @Watch('show')
  async onshowUpdate() {
    if (!this.show || !this.productSetId || !this.productPackageId || !this.combinationType) return

    // We need a short delay to make sure the popup animation is finished.
    this.loading = true
    setTimeout(async () => {
      await this.generateQRCode().finally(() => {
        this.loading = false
      })
    }, 50)
  }

  async generateQRCode() {
    // Get the combination type icon
    const imageUrl = this.combinationType
      ? require(`@/assets/product_combinations_png/${this.combinationType.toLowerCase()}.png`)
      : ''

    // Build url to link to
    const qrCodeUrl = `${process.env.VUE_APP_APP_URL}/product-package/${this.productPackageId}/product-set/${this.productSetId}`

    // Create a canvas element
    const size = 2100
    const iconArea = 560
    const iconSize = 420
    const canvas = createCanvas(size, size)
    const context = canvas.getContext('2d')

    // Calculate the position of the icon background
    const iconAreaX = (size - iconArea) / 2
    const iconAreaY = (size - iconArea) / 2

    // Generate QR code with a specific error correction level.
    // The correction level is needed for creating a blank space in the middle.
    const qrCodeData = await QRCode.toDataURL(qrCodeUrl, {
      width: size,
      errorCorrectionLevel: 'H',
      color: {
        dark: '#000'
      }
    })

    // Make an image of the qr code data and draw on the canvas
    const qrImage = await loadImage(qrCodeData)
    context.drawImage(qrImage, 0, 0, size, size)

    // Create blank space for the icon
    context.fillStyle = '#fff'
    context.fillRect(iconAreaX, iconAreaY, iconArea, iconArea)

    // Create an image element for the logo
    const iconImage = await loadImage(imageUrl)

    // Resize the icon, keeping the right scale
    const scale = iconImage.height > iconImage.width ? iconSize / iconImage.height : iconSize / iconImage.width
    const [trueIconHeight, trueIconWidth] = [iconImage.height * scale, iconImage.width * scale]

    // Draw the logo on top of the QR code
    context.drawImage(
      iconImage,
      iconAreaX + (iconArea - trueIconWidth) / 2,
      iconAreaY + (iconArea - trueIconHeight) / 2,
      trueIconWidth,
      trueIconHeight
    )

    // Set the data in the image url
    this.qrCodeImageSrc = canvas.toDataURL()
  }

  async copyToclipboard() {
    if (!this.qrCodeImageSrc) return

    const img = new Image()
    img.src = this.qrCodeImageSrc

    img.onload = async () => {
      // Create a canvas to draw the image
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      ctx?.drawImage(img, 0, 0)

      // Convert canvas to Blob
      canvas.toBlob(async (blob) => {
        if (!blob) return

        const clipboardItem = new ClipboardItem({ 'image/png': blob })
        await navigator.clipboard.write([clipboardItem])
      }, 'image/png')
    }
    this.$emit('close')
    this.$store.dispatch('showToast', { text: this.$t('global.copiedToClipboard').toString() })
  }

  downloadToDevice() {
    const downloadLink = document.createElement('a')
    downloadLink.href = this.qrCodeImageSrc
    downloadLink.download = `${this.productSetName}.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
    this.$emit('close')
  }
}
