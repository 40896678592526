enum QUERIES {
  ProjectListQuery = 'ProjectListQuery',
  ProjectListDialogQuery = 'ProjectListDialogQuery',
  ProjectDetailsQuery = 'ProjectDetailsQuery',
  ProjectStylesQuery = 'ProjectStyles',
  ProjectBrandsQuery = 'ProjectBrands',
  DesignPackagesForProjectQuery = 'DesignPackagesForProjectQuery',
  ProjectRealEstatesQuery = 'ProjectRealEstatesQuery',
  StylesViewQuery = 'StylesViewQuery',
  BrandsViewQuery = 'BrandsViewQuery',
  ProjectViewQuery = 'ProjectViewQuery',
  ProjectRoomsQuery = 'ProjectRoomsQuery',
  ProductPackageListViewQuery = 'ProductPackageListViewQuery',
  ProjectProductPackagesQuery = 'ProjectProductPackagesQuery',
  DesignPackageRestrictionKeys = 'DesignPackageProductSetRestrictionInfoKeys',
  ProductPackageViewQuery = 'ProductPackageViewQuery',
  MasterProductPackageQuery = 'MasterProductPackageQuery',
  ProductPackageCombinationsQuery = 'ProductPackageCombinationsQuery',
  ProductSetInfoKeys = 'ProductSetInfoKeys',
  CombinationProductSetActivities = 'CombinationProductSetActivities',
  RequestsFor3DDrawings = 'RequestsFor3DDrawings',
  RequestFor3DDrawingDetails = 'RequestFor3DDrawingDetails',
  RequestForTechnicalDrawing = 'RequestForTechnicalDrawing',
  RequestForTechnicalDrawingDetails = 'RequestForTechnicalDrawingDetails',
  ProductSetDetails = 'ProductSetDetails',
  CategoriesViewQuery = 'CategoriesViewQuery',
  CategoryCMSQuery = 'CategoryCMSQuery',
  ImportProductPackages = 'ImportProductPackages',
  ArticlePrices = 'ArticlePrices',
  Activities = 'Activities',
  CustomOfferRequests = 'CustomOfferRequests',
  CustomOfferRequest = 'CustomOfferRequest',
  RealEstateOrderQuery = 'RealEstateOrderQuery',
  RealEstateDetailsQuery = 'RealEstateDetailsQuery',
  ProductSetListQuery = 'ProductSetListQuery',
  GetDesignPackageNamesQuery = 'GetDesignPackageNamesQuery',
  CypressTokenQuery = 'CypressTokenQuery',
  GetContactRequestsQuery = 'GetContactRequestsQuery',
  GetContactRequestsDetailsQuery = 'GetContactRequestsDetailsQuery',
  ActiveExternalAction = 'ActiveExternalAction',
  GetProjectOptionsForDesignPackageQuery = 'GetProjectOptionsForDesignPackageQuery',
  GetDesignPackageQuery = 'GetDesignPackageQuery',
  ActivityTemplates = 'ActivityTemplatesQuery',
  RensaUsers = 'RensaUsers',
  GetMasterProductPackage = 'GetMasterProductPackage',
  OrderCategoryDetails = 'OrderCategoryDetails'
}

export default QUERIES
