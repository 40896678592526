import gql from 'graphql-tag'

export const createRoomMutation = gql`
  mutation CreateRoom(
    $projectId: UUID!
    $roomName: String!
    $roomPosition: Int!
    $layoutIds: [UUID!]!
  ) {
    createRoom(
      projectId: $projectId
      roomName: $roomName
      roomPosition: $roomPosition
      layoutIds: $layoutIds
    )
  }
`
