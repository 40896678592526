
import { ProjectActivityTarget, ProjectStatus } from '@/__generated__/globalTypes'
import { ArticlePrice } from '@/components/articlePrices/types'
import DebounceMixin, { debounceDelay } from '@/mixins/debounceMixin'
import QUERIES from '@/queries/queries'
import { ToastState } from '@/types/toastTypes'
import { getInputPrice } from '@/utils/currencyUtils'
import { buildRequestVariables, TableFilter } from '@/utils/tableUtils'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { DataOptions, DataTableHeader } from 'vuetify'
import {
  ArticlePricesDocument,
  ArticlePricesQuery,
  ArticlePricesQueryVariables,
  ProductReturnPriceFragment
} from './gql/__generated__/articlePrices.query'
import {
  UpdateArticlePriceDocument,
  UpdateArticlePriceMutation,
  UpdateArticlePriceMutationVariables
} from './gql/__generated__/updateArticlePrice.mutation'

@Component({
  mixins: [DebounceMixin]
})
export default class ArticlePricesList extends Vue {
  @Prop() projectId!: string

  search = ''
  userFilters: TableFilter[] = [
    {
      keyPath: 'product.code',
      value: '',
      filterType: 'contains'
    },
    {
      keyPath: 'product.description',
      value: '',
      filterType: 'contains'
    }
  ]
  articlePricesRequestData: (ProductReturnPriceFragment | null)[] = []
  projectStatus: ProjectStatus | null = null
  tableOptions: DataOptions = {} as DataOptions
  changeSearch: ((value: string) => void) | null = null
  isloading = false
  emptyFieldsToastWarningId: number | null = null

  defaultFooterProps = {
    itemsPerPageOptions: [100]
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Ref() updateArticlePriceForm?: any

  rules = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    required: (value: any) =>
      !!value?.toString()?.length || this.$t('productPackage.edit.category.productSets.upsert.validations.required'),

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    money: (value: any) => {
      // Can contain numbers, commas and dots
      if (!value) {
        return true
      }
      const regex = /^(?=.*[1-9])\d{1,9}(?:[.,]\d{1,2})?$/
      return regex.test(value) || this.$t('productPackage.edit.category.productSets.upsert.validations.money')
    }
  }

  getCellValidationRules(priceType: ProjectActivityTarget) {
    const selectedRules = [this.rules.money]
    if (priceType != ProjectActivityTarget.Meters) selectedRules.unshift(this.rules.required)
    return selectedRules
  }

  isMetersPrice(priceType: ProjectActivityTarget) {
    return priceType === ProjectActivityTarget.Meters
  }

  getActivityTargetIcon(priceType: ProjectActivityTarget): string {
    switch (priceType) {
      case ProjectActivityTarget.Meters:
        return 'mdi-arrow-expand-horizontal'
      case ProjectActivityTarget.SquareMeters:
        return 'mdi-texture-box'
      default:
        return ''
    }
  }

  getActivityTargetTitle(priceType: ProjectActivityTarget): string {
    return this.$t(`activities.table.activityTarget.${priceType}`).toString()
  }

  created() {
    this.tableOptions.sortBy = ['productCombination.position', 'code']
    this.tableOptions.sortDesc = [false]

    this.$apollo.addSmartQuery<ArticlePricesQuery, ArticlePricesQueryVariables>(QUERIES.ArticlePrices, {
      query: ArticlePricesDocument,
      variables: () => ({
        projectId: this.projectId,
        ...buildRequestVariables(this.tableOptions, [this.userFilters])
      }),
      update: (data) => data,
      result: (result) => {
        this.articlePricesRequestData = result.data.project?.projectProductReturnPrices?.items ?? []
        this.projectStatus = result.data.project?.status ?? null
        this.emptyFieldsToastHandler()
        this.$nextTick(() => {
          this.updateArticlePriceForm?.validate()
        })
      },
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4183',
          Message: error
        })
      },
      skip: () => !this.projectId
    })
  }

  mounted() {
    this.changeSearch = this.debounce(() => {
      // Set the search string in all the filters
      Object.values(this.userFilters).forEach((filter) => {
        filter.value = this.search
      })
    }, debounceDelay)
  }

  beforeDestroy() {
    if (this.emptyFieldsToastWarningId) this.$store.dispatch('hideToast', this.emptyFieldsToastWarningId)
  }

  get headers(): DataTableHeader[] {
    return [
      {
        text: this.$t('projectArticleReturnPrices.list.headers.combination').toString(),
        value: 'productCombination.position',
        groupable: true,
        width: '25%'
      },
      { text: this.$t('projectArticleReturnPrices.list.headers.code').toString(), value: 'product.code', width: '15%' },
      {
        text: this.$t('projectArticleReturnPrices.list.headers.description').toString(),
        value: 'product.description',
        width: '30%'
      },
      {
        text: this.$t('projectArticleReturnPrices.list.headers.returnPrice').toString(),
        value: 'returnPrice.amount',
        width: '30%'
      }
    ]
  }

  get tableData() {
    return this.articlePricesRequestData.map(
      (x) =>
        ({
          uid: `${x?.productCombination?.id}${x?.product?.code}`,
          id: x?.product?.id,
          code: x?.product?.code,
          description: x?.product?.description,
          price: x?.returnPrice?.amount ? getInputPrice(x?.returnPrice?.amount).replace('.', '') : '',
          priceType: x?.product.priceType,
          productCombination: {
            ...x?.productCombination,
            name:
              this.$t(`global.combinations.${x?.productCombination?.combinationType?.toLowerCase()}`).toString() ?? ''
          }
        } as ArticlePrice)
    )
  }

  get isPreviewProject() {
    return this.projectStatus === ProjectStatus.Preview
  }

  groupTable(groupBy: string, groupDesc = false) {
    this.tableOptions.groupBy = [groupBy]
    this.tableOptions.groupDesc = [groupDesc]
  }

  unGroupTable(ungroup: () => void) {
    ungroup()
    this.$nextTick(() => this.updateArticlePriceForm?.validate())
  }

  onSortChange() {
    if (this.tableOptions.groupBy.length) {
      this.tableOptions.sortBy.unshift('productCombination.position')
      this.tableOptions.sortDesc.unshift(false)
    }
  }

  onCombinationToggle(isOpen: boolean, toggle: () => void) {
    toggle()
    this.$nextTick(() => {
      if (!isOpen) this.updateArticlePriceForm?.validate()
    })
  }

  emptyFieldsToastHandler() {
    this.updateArticlePriceForm?.validate()
    const emptyFields = this.tableData
      ?.filter((y) => y.priceType != ProjectActivityTarget.Meters)
      .some((x) => x.price.length < 1)
    if (emptyFields && !this.emptyFieldsToastWarningId) {
      this.emptyFieldsToastWarningId = Math.floor(Math.random() * 99999999)
      this.$store.dispatch('showToast', {
        id: this.emptyFieldsToastWarningId,
        text: this.$t('projectArticleReturnPrices.list.emptyFieldsWarning'),
        color: 'warning',
        hideCloseButton: true,
        timeout: -1
      } as ToastState)
    } else if (!emptyFields && this.emptyFieldsToastWarningId) {
      this.$store.dispatch('hideToast', this.emptyFieldsToastWarningId)
      this.emptyFieldsToastWarningId = null
    }
  }

  async savePrice(item: ArticlePrice, inputId: string) {
    await this.emptyFieldsToastHandler()
    const index = this.updateArticlePriceForm?.inputs?.findIndex(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (x: any) => x.id === inputId
    )

    if (!this.updateArticlePriceForm?.inputs?.[index]?.validate()) {
      if (
        this.updateArticlePriceForm?.inputs?.[index].errorBucket[0] ===
        this.$t('productPackage.edit.category.productSets.upsert.validations.money')
      ) {
        return
      }
    }

    const itemOldState = this.articlePricesRequestData?.find(
      (x) => x?.product?.code === item.code && x.productCombination.id === item.productCombination.id
    )

    const oldPrice = itemOldState?.returnPrice?.amount
      ? getInputPrice(itemOldState?.returnPrice?.amount)?.replace('.', '')
      : ''

    if (oldPrice === item.price) {
      return
    }

    try {
      this.isloading = true
      await this.$apolloMutate<UpdateArticlePriceMutation, UpdateArticlePriceMutationVariables>({
        mutation: UpdateArticlePriceDocument,
        variables: {
          projectId: this.projectId,
          productId: item.id,
          productCombinationId: item.productCombination.id,
          returnPrice: item.price
        },
        refetchQueries: [QUERIES.ArticlePrices],
        error: 'E4183'
      })
    } finally {
      this.isloading = false
    }
  }
}
